import { Button } from '@material-ui/core';
import React, { Component } from 'react';
import utf8 from 'utf8';
import "./Login.css";
import { auth, provider } from "../../Firebase";
import { commonForumFlows, fetchForumInfo, fetchForumInfo1, getLasTfiftydata, handleLanguage, langListCall, languageLiterals, loginWithGoogleAndFB, paymentBridge, signInUsingGoogle } from '../../services/LoginLogoutService';
import { logOutFromSystem } from '../../App';
import { decode as base64_decode, encode as base64_encode } from 'base-64';
import { ToastContainer, toast } from 'react-toastify';
import { userJoinToForum } from '../../services/LoginLogoutService';
import { bridgeSendingByUser } from '../../services/LoginLogoutService';
import 'react-toastify/dist/ReactToastify.css';
import DropdownFilter from './loginFilter';
import Avatar from '@material-ui/core/Avatar';
import { objectNullandUndefined } from '../Dictionary/Dictionary';
import CircularProgress from '@material-ui/core/CircularProgress';
import Modal from '@material-ui/core/Modal';
import axios from 'axios';
import { CommonForumID, FSServerID, MacAddress, ArchiveTemp, ArchiveTempName, forumServerIP, CommonForumFlow } from '../Dictionary/CommonStrings';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import Loader from "react-js-loader";
import { green } from '@material-ui/core/colors';
import { LogoSpinner } from '../../services/LoginLogoutService';
import { LLServerID } from '../Dictionary/CommonStrings';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import uuid from 'react-uuid';
// import ReactGA from 'react-ga';


window.tokenID = '';
const options = [
    // objectNullandUndefined('23'),
    // objectNullandUndefined('24'),
    // objectNullandUndefined('25'),
    // objectNullandUndefined('26'),
    // objectNullandUndefined('27'),
    // objectNullandUndefined('28'),
    // objectNullandUndefined('29'),
    objectNullandUndefined('30'),
    objectNullandUndefined('31'),
    objectNullandUndefined('32'),
    objectNullandUndefined('33'),
    // objectNullandUndefined('34'),
    // objectNullandUndefined('35'),
    // objectNullandUndefined('36'),
    objectNullandUndefined('37'),
    objectNullandUndefined('38'),
    // objectNullandUndefined('39'),
    objectNullandUndefined('40'),
    objectNullandUndefined('41'),
    objectNullandUndefined('42'),
    objectNullandUndefined('43'),
    objectNullandUndefined('44'),
    "Testing Forum"
];
const myUuid = uuid();
class Login extends React.Component {


    bridgeSending = async (selectedJobCategoryList, selectedLocation) => {
        let commonForumTemp = localStorage.getItem("commonUserFlows")
        let parsedFlow = JSON.parse(commonForumTemp)
        let parsedCommonFlow = parsedFlow["UserFlow"]
        let commonTempName = parsedFlow["Template"]
        let filterFlowID = parsedCommonFlow[commonTempName + '_CandidateFilter']
        try {
            let uuid = myUuid

            let userDetails = localStorage.getItem('userProfile');
            userDetails = base64_decode(userDetails);
            userDetails = JSON.parse(userDetails);
            let sessionID = userDetails.SessionID;
            let macAddress = userDetails.MACAddress;
            let flowName = commonTempName + '_CandidateFilter';;
            let fID = filterFlowID['FID']
            let tempJson = {
                ForumID: CommonForumID,
                SessionID: sessionID,
                MACAddress: macAddress,
                Time: '6516516156161',
                ScheduledDateTime: 'now',
                ScheduledBoolean: 0,
                FlowID: flowName,
                EnableChat: 0,
                FlowType: 'Custom',
                BridgeForward: 0,
                TemplateID: parsedFlow["TemplateID"],
                TextCount: 6,
                ImageCount: 0,
                InvoiceID: '',
                DocumentCount: 0,
                User: true,
                VideoCount: 0,
                ServerID: FSServerID,
                ReplyBridgeID: '',
                HiddenFlow: false,
                BilledForum: '',
                TempBridgeId: uuid,
                FID: fID,
                SentTo: '0',
            };

            let data1 = {
                'FilterType': (selectedJobCategoryList),
                'CityType': (selectedLocation)

            }

            let jsonData = {
                [CommonForumFlow + '_CandidateFilter_Main-88f212c1-b826-490c-a351-ff093f97b87b_2']: base64_encode(JSON.stringify(data1)),
            };
            tempJson[flowName] = jsonData;
            const data = new FormData();
            data.append('Data', JSON.stringify(tempJson));
            let bridgeData = await bridgeSendingByUser(data);
            if (bridgeData["ErrorCode"] === 1074) {
                toast.error((objectNullandUndefined('221')));
                setTimeout(() => {
                    localStorage.clear();
                    sessionStorage.clear();
                    window.location.reload(false);
                }, 3000)
            }
        } catch (err) {
            return;
        }
    }
    checkForLinks() {
        const queryParameters = new URLSearchParams(window.location.search)
        
        if (queryParameters.size != 0) {
            // window.location.reload()
            localStorage.setItem('parameter',queryParameters.size);
            this.candidate("auto")
            
        }else{
            localStorage.setItem('parameter',"0");

        }
    }

    componentDidMount() {
        const label = { inputProps: { 'aria-label': 'Checkbox demo' } };
        let data = localStorage.getItem('userProfile')
        this.disableBackButton();
        // ReactGA.pageview(window.location.pathname)
        if (localStorage.getItem('userProfile') === null) {
            this.props.history.push("/Login");
        }
        else if (localStorage.getItem('userProfile') != null && localStorage.getItem('recruitValue') === 'false') {
            this.props.history.push("/ShortContainer");
        }
        else if (localStorage.getItem('userProfile') != null && localStorage.getItem('recruitValue') == 'true') {
            const queryParameters = new URLSearchParams(window.location.search)
            if (queryParameters.size != 0) {
                alert(objectNullandUndefined('182'))
                const urlWithoutParams = window.location.href.split('?')[0];
                window.history.replaceState({}, document.title, urlWithoutParams);
            }
            this.props.history.push("/Notification");
        }
        else {
            this.props.history.push("/Login");

        }

    }


    constructor(props) {
        super(props);
        this.state = {
            isloader: false,
            isLoading: false,
            isChecked: false,
            iconloading: false,
            ispopup: true,
        };
        this.loader = this.loader.bind(this);
    }


    disableBackButton() {
        window.history.pushState(null, document.title, window.location.href);
        window.onpopstate = () => {
            window.history.pushState(null, document.title, window.location.href);
        };
    }

    signIn = () => {
        // this.props.history.push("/PushNotification");
        if (this.state.isChecked === true) {

            auth
                .signInWithPopup(provider)
                .then(async (result) => {
                    const user = result.user;
                    var emailID = user.email;
                    window.tokenID = await auth.currentUser.getIdToken()
                    let loginJson = {
                        'UserName': emailID,
                        'LoginToken': window.tokenID
                    }
                    console.log(loginJson);

                    localStorage.setItem('userEmail', base64_encode(JSON.stringify(loginJson)));
                    this.loginCredential(loginJson, user);
                    this.setState({ isRecruiter: true });
                })

        }
        else {
            alert(objectNullandUndefined('183'))
        }

    };

    loginCredential = async (loginJson, user) => {
        this.setState({ iconloading: true });
        const jsonData = localStorage.getItem('fcmtoken');
        let fcmTokenId;
        if (jsonData) {
            if (jsonData == "null") {
                fcmTokenId = 'null';
            } else {
                let decoded = base64_decode(jsonData);
                fcmTokenId = JSON.parse(decoded);
            }
        } else {
            fcmTokenId = 'null';
        }
        // const myUuid = uuid();
        const myUuid = 'Bridge-Web';
        if (user != null) {
            let formData = new FormData();
            var firstName = user.displayName;
            var emailId = user.email;
            var phoneNumber = user.phoneNumber;
            var logintoken = loginJson.LoginToken;
            if (emailId == null && phoneNumber != null) {
                emailId = phoneNumber;
            }
            var lastName = "";
            var aliasUUID = this.generateUUID(10);
            var userName = "@user" + aliasUUID;
            let jsonObject = {
                "UserName": userName,
                "FirstName": firstName,
                "LastName": lastName,
                "MACAddress": myUuid,
                "UPI": "",
                "TokenID": fcmTokenId,
                "DefaultForumAcceptance": true,
                "SignInID": emailId,
                "ServerID": LLServerID,
                "LoginToken": logintoken
            }
            // formData.append('Data', JSON.stringify(jsonObject));
            // signInUsingGoogle(formData).then(data => {
                loginWithGoogleAndFB(loginJson).then(async data => {
                    if (data['ErrorCode'] == 1155) {
                        let themeList = ["Bridge Default",
                            'Chestnut Rose',
                            'Dodger Blue',
                            'Emperor',
                            'Peru',]
                        let userData = {};
                        let userData1 = {};
                        userData = data['ErrorMessage'];
                        let countryCode = userData['CountryCode'];
                        let mobileNum = userData['MobileNumber'];
                        let concatNumber = countryCode + mobileNum;
                        userData1['MobileNumber'] = concatNumber;
                        userData1['UserName'] = userData['UserName'];
                        userData1['FirstName'] = userData['FirstName'];
                        userData1['LastName'] = userData['LastName'];
                        userData1['CountryCode'] = countryCode;
                        userData1['SessionID'] = userData['SessionID'];
                        let Language = "English";
                        try{
                            Language = utf8.encode(userData['Language'])
                        }catch(err){
                            Language = "English"
                        }
                        userData1['Language'] = Language;
                        userData1['MACAddress'] = myUuid;
                        if (Object.keys(userData).includes('ThemeColorName')) {
                            let location = userData['ThemeColorName'];
                            if (themeList.includes(location)) {
                                location = 'Bangalore'
                            }
                            userData1['Location'] = location;
                            localStorage.setItem('selectedLocation', location);
                        }
                        localStorage.setItem('userProfile', base64_encode(JSON.stringify(userData1)));
                        localStorage.setItem('selectedLanguage',userData['Language'])
                        const userDetails = localStorage.getItem('userProfile');
                        let decoded = base64_decode(userDetails);
                        let userDetail = JSON.parse(decoded);
                        let jsonToSend1 = {
                          "SelectedLanguage": userData['Language'],
                          "ServerID": FSServerID,
                          "MACAddress": userDetail['MACAddress'],
                          "ForumID": CommonForumID,
                          "SessionID": userDetail['SessionID'],
                        }
                        let languageStrings = await languageLiterals(jsonToSend1)
                        localStorage.setItem('Languages', JSON.stringify(languageStrings))
                        this.setState({ iconloading: false });
                        this.checkForLinks()
                        this.loader();

                    }else if(data['ErrorCode'] == 1074){
                        let formData = new FormData();
                        var firstName = user.displayName;
                        var emailId = user.email;
                        var phoneNumber = user.phoneNumber;
                        var logintoken = loginJson.LoginToken;
                        if (emailId == null && phoneNumber != null) {
                            emailId = phoneNumber;
                        }
                        var lastName = "";
                        var aliasUUID = this.generateUUID(10);
                        var userName = "@user" + aliasUUID;
                        let jsonObject = {
                            "UserName": userName,
                            "FirstName": firstName,
                            "LastName": lastName,
                            "MACAddress": myUuid,
                            "UPI": "",
                            "TokenID": fcmTokenId,
                            "DefaultForumAcceptance": true,
                            "SignInID": emailId,
                            "ServerID": LLServerID,
                            "LoginToken": logintoken
                        }
                        formData.append('Data', JSON.stringify(jsonObject));
                        signInUsingGoogle(formData).then(async data => {
                            if (data['ErrorCode'] == 1005) {
                                var firstName = user.displayName;
                                var lastName = "";
                                let themeList = ["Bridge Default",
                                    'Chestnut Rose',
                                    'Dodger Blue',
                                    'Emperor',
                                    'Peru',]
                                let userData = {};
                                let userData1 = {};
                                userData = data['ErrorMessage'];
                                let countryCode = userData['CountryCode'];
                                let mobileNum = userData['MobileNumber'];
                                let concatNumber = countryCode + mobileNum;
                                userData1['MobileNumber'] = concatNumber;
                                userData1['UserName'] = userName;
                                userData1['FirstName'] = firstName;
                                userData1['LastName'] = lastName;
                                userData1['CountryCode'] = countryCode;
                                userData1['SessionID'] = userData['SessionID'];
                                let Language = "English";
                                try{
                                    Language = utf8.encode(userData['Language'])
                                }catch(err){
                                    Language = "English"
                                }
                                userData1['Language'] = Language;
                                // userData1['Language'] = "English";
                                userData1['MACAddress'] = myUuid;
                                if (Object.keys(userData).includes('ThemeColorName')) {
                                    let location = userData['ThemeColorName'];
                                    if (themeList.includes(location)) {
                                        location = 'Bangalore'
                                    }
                                    userData1['Location'] = location;
                                    localStorage.setItem('selectedLocation', location);
                                }
                                localStorage.setItem('userProfile', base64_encode(JSON.stringify(userData1)));
                                localStorage.setItem('selectedLanguage',userData['Language'])
                                const userDetails = localStorage.getItem('userProfile');
                                let decoded = base64_decode(userDetails);
                                let userDetail = JSON.parse(decoded);
                                let jsonToSend1 = {
                                "SelectedLanguage": userData['Language'],
                                "ServerID": FSServerID,
                                "MACAddress": userDetail['MACAddress'],
                                "ForumID": CommonForumID,
                                "SessionID": userDetail['SessionID'],
                                }
                                let languageStrings = await languageLiterals(jsonToSend1)
                                localStorage.setItem('Languages', JSON.stringify(languageStrings))
                                this.setState({ iconloading: false });
                                this.checkForLinks()
                                this.loader();
                            }
                            else if (data['ErrorCode'] == 1069) {
                                let themeList = ["Bridge Default",
                                    'Chestnut Rose',
                                    'Dodger Blue',
                                    'Emperor',
                                    'Peru',]
                                let userData = {};
                                let userData2 = {};
                                userData = data['ErrorMessage']['ErrorMessage'];
                                let countryCode = userData['CountryCode'];
                                let mobileNum = userData['MobileNumber'];
                                let concatNumber = countryCode + mobileNum;
                                userData2['MobileNumber'] = concatNumber;
                                userData2['UserName'] = userData['UserName'];
                                userData2['FirstName'] = userData['FirstName'];
                                userData2['LastName'] = userData["LastName"];
                                userData2['CountryCode'] = countryCode;
                                userData2['SessionID'] = userData['SessionID'];
                                userData2['Language'] = utf8.encode(userData['Language']);
                                userData2['MACAddress'] = myUuid;
                                userData2['UserProfilePicture'] = userData['UserProfilePicture'];
                                if (Object.keys(userData).includes('ThemeColorName')) {
                                    let location = userData['ThemeColorName'];
                                    if (themeList.includes(location)) {
                                        location = 'Bangalore'
                                    }
                                    userData2['Location'] = location;
                                    localStorage.setItem('selectedLocation', location);
                                }
                                localStorage.setItem('userProfile', base64_encode(JSON.stringify(userData2)));
                                localStorage.setItem('selectedLanguage',userData['Language'])
                                const userDetails = localStorage.getItem('userProfile');
                                let decoded = base64_decode(userDetails);
                                let userDetail = JSON.parse(decoded);
                                let jsonToSend1 = {
                                "SelectedLanguage": userData['Language'],
                                "ServerID": FSServerID,
                                "MACAddress": userDetail['MACAddress'],
                                "ForumID": CommonForumID,
                                "SessionID": userDetail['SessionID'],
                                }
                                let languageStrings = await languageLiterals(jsonToSend1)
                                localStorage.setItem('Languages', JSON.stringify(languageStrings))
                                this.setState({ iconloading: false });
                                this.checkForLinks()
                                this.loader();
                            }
                        });
                    }
                });
        }
    }

    message = () => {

        alert(objectNullandUndefined('124'));

    }

    generateUUID = (digits) => {
        let str = '0123456789abcdefghijklmnopqrstuvwxyz';
        let uuid = [];
        for (let i = 0; i < digits; i++) {
            uuid.push(str[Math.floor(Math.random() * str.length)]);
        }
        return uuid.join('');
    }

    OldNotification = async () => {
        let CommonForumdata = await getLasTfiftydata(CommonForumID)
        if (CommonForumdata['ErrorCode'] == 1053) {
          let dataList = CommonForumdata["ErrorMessage"]['User']
          let messageDisplayList = []
          let userData = []
          let newList = []
          for (let index = 0; index < dataList.length; index++) {
              let singleMessage = {}
              const element = dataList[index]['Bridge'];
              if(element['FlowID'] === CommonForumFlow + "_NotifyUser"){
                let forumID = element["ForumID"]
                let bridgeID = element["BridgeID"]
                let data = element[CommonForumFlow + "_NotifyUser"]
                let status = base64_decode(data[CommonForumFlow + '_NotifyUser_Main-3696a421-97d2-407d-add3-15a07edcfb10_2'])
                let RequestDatas = base64_decode(data[CommonForumFlow + '_NotifyUser_Main-3696a421-97d2-407d-add3-15a07edcfb10_4'])
                let type = base64_decode(data[CommonForumFlow + '_NotifyUser_Main-3696a421-97d2-407d-add3-15a07edcfb10_6'])
                let RequestData = RequestDatas
                data = RequestData
                let isCandidate = ""
                if(status === "Under Manual Review" || status === "Profinity Passed" || status === "Profinity Failed"){
                    RequestData = JSON.parse(RequestDatas)
                    data = RequestData['CompanyName']['CompanyName']
                    isCandidate = RequestData['isCandidate']
                } 
                let notify = {}
                notify['status'] = status
                notify['RequestData'] = data
                notify['type'] = type
                notify['BridgeID'] = bridgeID
                notify['isCandidate'] = isCandidate
                let notifyExists = userData.some(item => item.BridgeID === notify.BridgeID);
                if (!notifyExists) {
                    newList = [notify, ...userData];
                  }
              }
            }
            localStorage.setItem('userData', JSON.stringify(newList))
        }
    }

    recruiter = async () => {
        // this.props.history.push("/PushNotification");
        this.setState({ isLoading: true });
        fetchForumInfo();
        langListCall()
        localStorage.setItem('recruitValue', 'true');
        let userDetails = localStorage.getItem('userProfile');
        let decoded = base64_decode(userDetails);
        const userData = JSON.parse(decoded);
        let jsonObject = {
            "MACAddress": userData.MACAddress,
            "ForumID": CommonForumID,
            "SessionID": userData.SessionID,
            "Owner": userData.UserName,
            "OwnerMobileNumber": userData.MobileNumber,
            'ServerID': FSServerID,
        }
        let data = await userJoinToForum(jsonObject);
        if (data["ErrorCode"] === 1074) {
            toast.error((objectNullandUndefined('221')));
            setTimeout(() => {
                localStorage.clear();
                sessionStorage.clear();
                window.location.reload(false);
            }, 3000)
        }
        // console.log(data);
        let responseCommonFlow = await commonForumFlows()
        // handleLanguage();
        setTimeout(() => {
            const userdata = localStorage.getItem('userData');
            if (userdata == null || userdata == '[]') {
                const key = localStorage.getItem('Olddata');
                const isTrue = JSON.parse(key);
                if (isTrue == null) {
                    const jsonData = localStorage.getItem('userProfile');
                    let decoded = base64_decode(jsonData);
                    const userData = JSON.parse(decoded);
                    let users = []
                    users.push(userData["MobileNumber"])
                    let tempJsonData = {
                        "SessionID": userData["SessionID"],
                        "MACAddress": userData["MACAddress"],
                        "AddUsers": users,
                        "CloneTemplateFromForum": false,
                        "UPI": "UPI",
                        "TemplateName": ArchiveTempName,
                        "ForumName": 'Archive492000',
                        "TemplateID": ArchiveTemp,
                        "ForumDomain": 'others',
                        "ChatForum": false,
                        "Owner": userData["UserName"],
                        "ForumDescription": 'ForumDescription',
                        "Time": "1643620059",
                        "ForumType": 'Public',
                        'ServerID': FSServerID,
                    }
                    const dataForum = new FormData();
                    dataForum.append('Data', JSON.stringify(tempJsonData))
                    axios({
                        method: 'post',
                        url: forumServerIP + 'newForum',
                        data: dataForum,
                        headers: { 'Content-Type': 'multipart/form-data' },
                    })
                        .then(function (response) {
                        })
                        .catch(function (response) {
                            // console.log(response);
                        });
                }
                this.OldNotification()
                this.setState({ isLoading: false });
                this.props.history.push("/Recruiter");
            }
            else {
                this.OldNotification()
                this.setState({ isLoading: false });
                this.props.history.push("/Notification");
            }
        }, 10000);

    }
    candidate = async (type) => {
        this.setState({ isLoading: true });
        localStorage.setItem('recruitValue', 'false');
        let userDetails = localStorage.getItem('userProfile');
        let decoded = base64_decode(userDetails);
        const userData = JSON.parse(decoded);
        let jsonObject = {
            "MACAddress": userData.MACAddress,
            "ForumID": CommonForumID,
            "SessionID": userData.SessionID,
            "Owner": userData.UserName,
            "OwnerMobileNumber": userData.MobileNumber,
            'ServerID': FSServerID,
        }
        let data = await userJoinToForum(jsonObject);
        if (data["ErrorCode"] === 1074) {
            toast.error((objectNullandUndefined('221')));
            setTimeout(() => {
                localStorage.clear();
                sessionStorage.clear();
                window.location.reload(false);
            }, 3000)
        }
        // handleLanguage()
        langListCall()
        let responseCommonFlow = await commonForumFlows()
        let response = await fetchForumInfo1();
        if (response.length > 0) {
            this.setState({ isloader: false });
            if (type == "auto") {
                const queryParameters = new URLSearchParams(window.location.search)
                let storedLocation = localStorage.getItem('selectedLocation');
                if (queryParameters.size != 0) {
                    const cN = queryParameters.get("cY")
                    let selectedLocation = cN
                    if (storedLocation == null || storedLocation == undefined) {
                        storedLocation = selectedLocation
                        localStorage.setItem('selectedLocation', selectedLocation);
                    }
                } else {
                    this.props.history.push("/JobLocationFilter");
                }
                if (storedLocation == null || storedLocation == undefined) {
                    storedLocation = 'Bangalore'
                }
                this.bridgeSending(response, storedLocation)
                this.props.history.push("/ShortContainer");
            } else {
                let selectedLocation = localStorage.getItem('selectedLocation');
                if (selectedLocation == null || selectedLocation == undefined) {
                    selectedLocation = 'Bangalore'
                }
                this.bridgeSending(response, selectedLocation)
                this.props.history.push("/ShortContainer");
            }
        } else {
            this.setState({ isloader: false });
            // this.props.history.push("/JobLocationFilter");
            if (type == "auto") {
                const queryParameters = new URLSearchParams(window.location.search)
                let storedLocation = localStorage.getItem('selectedLocation');
                if (queryParameters.size != 0) {
                    const cN = queryParameters.get("cY")
                    let selectedLocation = cN
                    if (storedLocation == null || storedLocation == undefined) {
                        localStorage.setItem('selectedLocation', selectedLocation);
                    }
                } else {
                    this.props.history.push("/JobLocationFilter");
                }
                this.props.history.push("/ShortContainer");
            } else {
                this.props.history.push("/JobLocationFilter");
            }
        }

    }

    loader = () => {
        this.setState({ isloader: true });
        this.setState({ ispopup: false });
        if (document.getElementById('googleSignIn')) {
            document.getElementById('googleSignIn').hidden = true;
        } else {
            console.log("Element with ID 'googleSignIn' not found");
        }
    }
    handleCheckboxChange = (event) => {
        this.setState({
            isChecked: event.target.checked,
        });

    };
    HandleLogOut = async () => {
        let res = await logOutFromSystem();
        localStorage.clear();
        sessionStorage.clear();
        // history.push('/');
        window.location.reload(false);

    };

    render() {

        return (
            <>

                <div id="accountCreationMessage"></div><div className="fill-window">
                    <NavBar>
                    </NavBar>
                    
                    {this.state.iconloading ? (
                        <div className='positioner'>
                        <LogoSpinner></LogoSpinner>
                        </div>
                    ) : this.state.ispopup ? (
                        <div className="center" id='googleSignIn'>
                            <Button
                                onClick={() => {
                                    this.signIn();
                                }}
                                variant="outlined"
                                className='loginbutton8'
                                style={{
                                    textTransform: "none",
                                    background: "white",
                                }}>
                                <img
                                    src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQBRF__vDRer9N3lzmW-FJTnaiCi1Vd7TvcHrdcjzU28RHD2kcpRVdZIQhLvZaksbBPpak&usqp=CAU"
                                    style={{
                                        width: "20px",
                                        height: "20px",
                                        marginRight: "10px"
                                    }}
                                    alt="google" />
                                {objectNullandUndefined('19')}
                            </Button>
                            <div style={{ position: "absolute", left: "16%" }}>
                                <FormGroup>
                                    <FormControlLabel
                                        control={
                                            <Checkbox style={{ color: 'white' }}
                                                checked={this.state.isChecked}
                                                onChange={this.handleCheckboxChange}
                                            />
                                        }
                                        label={
                                            <span style={{ color: 'white', fontSize: '14px' }}>
                                                <a href="https://prescreener.in/#/policy" target="_blank" rel="noopener noreferrer" style={{ color: 'inherit' }}>
                                                    {objectNullandUndefined('184')}
                                                </a>
                                            </span>
                                        }
                                    />
                                </FormGroup>
                            </div>
                        </div>
                    ) : (
                        <div></div>
                    )}
                    
                    {this.state.isloader === true && (
                        <div className='positioner'>
                            {this.state.isLoading ? (
                                <LogoSpinner></LogoSpinner>
                            ) : (
                                <div className='Container'>
                                    <div className='hover' onClick={() => { this.recruiter() }} >
                                        <Card className='card0' sx={{ borderRadius: 4, border: 2, borderColor: 'white' }}>
                                            <CardMedia
                                                sx={{ height: '200px' }}
                                                image={"/19873.jpg"}
                                                title={objectNullandUndefined('15')}
                                            />
                                            <CardContent>
                                                <Typography gutterBottom variant="h5" component="div" style={{ color: 'rgb(234,67,53)' }}>
                                                    {objectNullandUndefined('15')}
                                                </Typography>
                                                <Typography variant="body2" color="text.secondary">
                                                    {objectNullandUndefined('186')}
                                                </Typography>
                                            </CardContent>
                                        </Card>
                                    </div>
                                    <div className='hover' onClick={() => { this.candidate("click"); }}>
                                        <Card className='card0' sx={{ borderRadius: 4, border: 2, borderColor: 'white' }}>
                                            <CardMedia
                                                sx={{ height: '200px' }}
                                                image={'/candidate.jpg'}
                                                title={objectNullandUndefined('14')}
                                            />
                                            <CardContent>
                                                <Typography gutterBottom variant="h5" component="div" style={{ color: '#2BAE66FF' }}>
                                                    {objectNullandUndefined('14')}
                                                </Typography>
                                                <Typography variant="body2" color="text.secondary">
                                                    {objectNullandUndefined('185')}
                                                    {/* Filter through videos and Apply to your dream job within seconds */}
                                                </Typography>
                                            </CardContent>
                                        </Card>
                                    </div>
                                    <p className="back-icon1" onClick={this.HandleLogOut}>
                                        <ArrowBackIcon style={{ color: 'black', fontSize: '2rem' }} />
                                    </p>
                                </div>
                            )}

                        </div>

                    )}

                </div></>
        )
    }

}

const NavBar = (props) => {
    return (
        <div>
            <nav className="navigation">
                <a className="brand-name">
                    <h2>Prescreener</h2>
                </a>
                <nav style={{ position: "absolute", top: "25%", right: "20px" }}>
                    <a href="https://prescreener.in/#/" target="_blank" rel="noopener noreferrer" style={{ color: 'inherit' }}>
                        <Avatar sx={{ width: 56, height: 56 }} style={{ marginRight: "0" }} alt="" src="/logo.png" />
                    </a>
                </nav>
            </nav>
            <nav className="nav2">
                <h6 style={{ backgroundColor: 'lightgrey', width: '100%', textAlign: 'center' }}>
                    <a href="https://cosmitude.com" target="_blank" rel="noopener noreferrer" style={{ color: 'inherit' }}>
                        By Cosmitude Softwares Private Limited
                    </a>
                </h6>
            </nav>
        </div>
    )
}

export default Login;