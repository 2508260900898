import React, { useState, useEffect } from 'react';
import {
  Dialog,
  DialogActions,
  Typography,
  IconButton,
  Card,
  CardContent,
  List,
  ListItem,
  ListItemText,
  Divider
} from '@material-ui/core';
import Button from '@material-ui/core/Button';
import CloseIcon from '@mui/icons-material/Close';
import './Dictionary.css'
import { languageLiterals, saveLanguageToProfile } from '../../services/LoginLogoutService';
import { decode as base64_decode, encode as base64_encode } from 'base-64';
import { CommonForumID, FSServerID, MacAddress } from './CommonStrings';
let literalObject = {
  "1": "HOME",
  "2": "FEED",
  "3": "SETTINGS",
  "4": "ADMIN",
  "5": "NOTIFICATION",
  "6": "Company Name",
  "7": "Last date of Recruitment",
  "8": "Job Description",
  "9": "SUBMIT",
  "10": "APPLY",
  "11": "CLOSE",
  "12": "Login as",
  "13": "LOGOUT",
  "14": "CANDIDATE",
  "15": "RECRUITER",
  "16": "CANCEL",
  "17": "ACCOUNT",
  "18": "PRIVACY POLICY",
  "19": "Sign in with Google",
  "20": "Welcome to Prescreener",
  "21": "Are you sure you want to logout?",
  "22": "Upload",
  "23": "Data Scientist",
  "24": "Software Engineer",
  "25": "Project Manager",
  "26": "Business Analyst",
  "27": "Human Resources Manager",
  "28": "Financial Analyst",
  "29": "Marketing Manager",
  "30": "Sales Representative",
  "31": "Operations Manager",
  "32": "Nurse",
  "33": "Physical Therapist",
  "34": "Dental Hygienist",
  "35": "Civil Engineer",
  "36": "Architect",
  "37": "Delivery Partner",
  "38": "Supply Chain Manager",
  "39": "Quality Control Analyst",
  "40": "Domestic Worker",
  "41": "Chef",
  "42": "Construction Worker",
  "43": "Agriculture Worker",
  "44": "Garments Worker",
  "45": "RECRUIT",
  "46": "Enter recruitment message",
  "47": "SEND",
  "48": "Save",
  "49": "RECRUIT ALERTS",
  "50": "RECRUITMENT",
  "51": "ARCHIVE",
  "52": "Select Job Type",
  "53": "Select Job Location",
  "54": "You have already applied for this recruitment",
  "55": "Failed!",
  "56": "Something went wrong, Please try again later",
  "57": "Uploading....",
  "58": "Please wait, your video is loading...",
  "59": "CAPTURE VIDEO",
  "60": "UPLOAD VIDEO",
  "61": "Recruitment deleted Succesfully",
  "62": "Delete Recruitment",
  "63": "Are you sure you want to delete?",
  "64": "Restart",
  "65": "Continue",
  "66": "No videos have been submitted yet!",
  "67": "Error...",
  "68": "Recruitment message cannot be empty",
  "69": "Success",
  "70": "Recruitment message sent successfully!",
  "71": "User Name",
  "72": 'Ahmedabad',
  "73": 'Bangalore',
  "74": 'Chennai',
  "75": 'Davangere',
  "76": 'Delhi',
  "77": 'Gurgaon',
  "78": 'Hubli',
  "79": 'Hyderabad',
  "80": 'Jaipur',
  "81": 'Kanpur',
  "82": 'Kolkata',
  "83": 'Mangalore',
  "84": 'Mumbai',
  "85": 'Panji',
  "86": 'Pune',
  "87": "Name",
  "88": "Email",
  "89": "Cancelled Image selection",
  "90": "Maximum image size exceeded",
  "91": "Please choose a file under 2 MB",
  "92": "Upload profile picture",
  "93": "Select an option",
  "94": "User profile updated successfully",
  "95": "User Name already exist",
  "96": "Update",
  "97": "First Name",
  "98": "Last Name",
  "99": "Loading...",
  "100": "CREATING...",
  "101": "Please upload Recruitment Video",
  "102": "Please select Job Type",
  "103": "Please select Job Location",
  "104": "Please select Last Date of Recruitment",
  "105": "Please enter Company Name",
  "106": "Please enter Job Description",
  "107": "Recruitment created Successfully",
  "108": "Invalid file type, Please upload a video",
  "109": "Video Options",
  "110": "Do you want to remove the video?",
  "111": "Unable to create Recruitment",
  "112": "OR",
  "113": "Search options...",
  "114": "Please select both JobCategory and Location",
  "115": "Please Upload the Video",
  "116": "LANGUAGE",
  "117": "English",
  "118": "Kannada",
  "119": "Hindi",
  "120": "Telugu",
  "121": "Your video has been Archived",
  "122": "No Archive Video found",
  "123": "Select a Job Category and Location",
  "124": "Your account has been created, Please Login again",
  "125": "No recruitments created",
  "126": "No notification found",
  "127": "Please wait recruitment creation in progress",
  "128": "Stop Recording",
  "129": "Would you like to continue or restart screening videos?",
  "130": "Our Suggestion",
  "131": "If you have further interview process you may use such tools",
  "132": "Create link and paste above to send",
  "133": "Your video has been uploaded succesfully",
  "134": "Report",
  "135": "Video is reported and will be removed",
  "136": "Pay for priority",
  "137": "Free",
  "138": "Pay ₹21/-",
  "139": "No recruitments uploaded for this job",
  "140": "You have reached the end for the current category",
  "141": "Pay for recruitment",
  "142": "Pay ₹100/-",
  "143": "Select Language:",
  "144": "Recruitment message",
  "145": "User name updated successfully",
  "146": "Picture size is greater than 2MB",
  "147": "First Name and Last Name must not exceeds 33 alphabet characters",
  "148": "First Name and Last Name should not be empty",
  "149": "First Name and Last Name must have alphabet characters only",
  "150": "User Name must contain minimum 3 and maximum 33 characters",
  "151": "IMAGE LIBRARY",
  "152": "REMOVE IMAGE",
  "153": "Select an option",
  "154": "No changes detected",
  "155": "My video",
  "156": "Delete",
  "157": "Are you sure you want to delete recruitment?",
  "158": 'Video is Reported and will be reviewed',
  "159": 'Please select a report reason',
  "160": "First character must be an alphabet letter",
  "161": "User has withdrawn the application",
  "162": "User Name can not contain special characters",
  "163":"No jobs have been submitted to this category",
  "164":"User Name already exists",
  "165": "Deleted Succesfully",
  "166":"CLEAR",
  "167":"Try again",
  "168":"Reported content will be removed", 
  "169": 'It\'s Spam',
  "170": 'False Information',
  "171": 'Scam or Fraud',
  "172": 'Nudity or Sexual activity',
  "173": 'Inappropriate Content',
  "174": "Sending in progress",
  "175":"No profile picture to remove",
  "176": "Company Name cannot contain only spaces",
  "177": "Job Description cannot contain only spaces",
  "178": "User Name must start with 3 alphabets",
  "179":'Recruitment limit reached, Delete old recruitments to create new',
  "180":"Content Creator",
  "181":"Digital Marketing Intern",
  "182":"Please login as candidate to view shared video",
  "183":"Please agree to the terms and conditions to proceed",
  "184":"I agree to the terms and conditions",
  "185":"Find your dream job and apply within seconds",
  "186":"Record/Upload a recruitment video and find the right match for your team within seconds",
  "187":"Applied to:-",
  "188":"Download QR Code",
  "189":"Selected file is too large (greater than 64MB)",
  "190":"Selected file is not an MP4 video",
  "191":"Are you sure you want to remove the video?",
  "192":"Please make sure the selected date is after today",
  "193":"Option is not available contact feedback@cosmitude.com",
  "194":"Job location is not available contact feedback@cosmitude.com",
  "195":"Camera and microphone permissions are required to record. Please grant the necessary permissions",
  "196":"OK",
  "197":"By Cosmitude Softwares Private Limited",
  "198":"Link copied to clipBoard",
  "199":"Please Wait!",
  "200": "Tabs",
  "201": "JobLocationFilter",
  "202": "LOGINMODAL",
  "203": "PrivacyPolicy",
  "204": "Languages",
  "205": "Login",
  "206": "Apply",
  "207": "AdminDetails",
  "208": "Feed",
  "209": "Archive",
  "210": "ArchiveVideo",
  // "211": "candidateVideo",
  "211": "Video",
  "212": "RTabs",
  "213":"Remove",
  "214":"Unable to share, Recruitment expired",
  "215":"Share link copied to clipboard",
  "216":"New videos have been loaded",
  "217":"No recruitment found, choose a different category",
  "218":"Recruitment Creation Failed!, Please Try Again",
  "219":"Candidate has withdrawn the application",
  "220":" ",
  "221":"Your session has ended. Please login again",
  "222":"Recruiter Message",
  "223":"Company Reply",
  "224":"Are you sure want to Delete the account",
  "225":"Please delete the following recruitments to delete your account",
  "226":"Delete Account",
  "227":"Account has been deleted successfully",
  "228":"Login as recruiter and delete the following recruitment to delete this account.",
  "229":"Send Reply",
  "230":"Showing Recruited Candidate videos",
  "231":"No Recruited candidate to show",
  "232":"You can only select maximun 3 option",
  "233":"INTERESTS",
  "234":"Click to view",
  "235":"Recruited Candidate",
  "236":"Applied Candidate",
  "237":"JOB INTEREST",
  "238":"CHANGE LOCATION",
  "239":"REQUEST NEW JOB TYPE",
  "240":"SEND",
  "241":"REQUEST NEW LOCATION",
  "242":"Request for new Job type is sent for Review",
  "243":"Request for new Location is sent for Review",
  "244":"ENTER NEW JOB TYPE",
  "245":"ENTER NEW LOCATION",
  "246":"Request Approved",
  "247":"Request Denied",
  "248":"your request for addition ",
  "249":"list has been Approved. click to create recruitment",
  "250":"list has been Rejected. click to delete",
  "251":"to ",
  "252":"Location",
  "253":"Job interest will be updated!!",
  "254":"Option is not available, please request for addition using '+' button",
  "255":"Apply Changes",
  "256":"CONFIRM",
  "257":'Option 1',
  "258":'Option 2',
  "259":'Option 3',
  "260":'New Recruitment added at the Last',
  "261":'Select atleast one interest',
  "262":"Demo Videos",
  "263":"Update Your Interest",
  "264":"Cannot apply to demo videos",
  "265":"Cannot recruit to demo videos",
  "266":"Cannot archive to demo videos",
  "267":"Cannot share demo videos",
  "268":"It is taking longer than expected, Please wait",
  "269":"New videos have been loaded,Click OK to View",
  "270":"No recruitment found showing demo videos, choose a different category",
  "271":"This recruitment is no longer accepting applications",
  "272":'Under Review',
  "273":'Nothing To Load',
  "274":'Media not found',
  "275":'User Not Found.',
  "276":'Message cannot be empty',
  "277":'Recruitment Details',
  "278":'Application for Recruitment',
  "279":'is currently under manual review for profinity.',
  "280":'Recruitment',
  "281":' has passed manual review for profinity and will be propagated',
  "282":' has failed manual review for profinity and will not reach Recruiter.',
  "283":' has failed manual review for profinity and will not reach candidates.',
  "284":'Self Chat is Restricted',
  "285":'You cannot apply for the recruitment as you are the creator.',
  "286":'only "@" and "." are the special characters allowed',
  "287":'Company name field does not accept special character except "@" and "."',
  "288":'Job Description field does not accept special character except "@" and "."',
  "289":'DeadLine',
  "290":'Applied On :',
  "291":'Not Specified',
  "292":'Expires on',
  "293":'OverAll Applied Candidate',
  "294":'Download Analytics',
  "295":'FullName',
  "Agriculture Worker":"Agriculture Worker",
  "Human Resource Manager":"Human Resource Manager",
  "Quality Control Analyst":"Quality Control Analyst",
  "Sales Representative":"Sales Representative",
  "Operations Manager":"Operations Manager",
  "Nurse":"Nurse",
  "Physical Therapist":"Physical Therapist",
  "Delivery Partner":"Delivery Partner",
  "Supply Chain Manager":"Supply Chain Manager",
  "Domestic Worker":"Domestic Worker",
  "Chef":"Chef",
  "Construction Worker":"Construction Worker",
  "Garments Worker":"Garments Worker",
  "Testing Forum":"Testing Forum",
  "Software Engineer":"Software Engineer",
  "locomotive":"locomotive",
  "Computer technician":"Computer technician",
};
let literalObject1 = {
  "1": "HOME",
  "2": "FEED",
  "3": "SETTINGS",
  "4": "ADMIN",
  "5": "NOTIFICATION",
  "6": "Company Name",
  "7": "Last date of Recruitment",
  "8": "Job Description",
  "9": "SUBMIT",
  "10": "APPLY",
  "11": "CLOSE",
  "12": "Login as",
  "13": "LOGOUT",
  "14": "CANDIDATE",
  "15": "RECRUITER",
  "16": "CANCEL",
  "17": "ACCOUNT",
  "18": "PRIVACY POLICY",
  "19": "Sign in with Google",
  "20": "Welcome to Prescreener",
  "21": "Are you sure you want to logout?",
  "22": "Upload",
  "23": "Data Scientist",
  "24": "Software Engineer",
  "25": "Project Manager",
  "26": "Business Analyst",
  "27": "Human Resources Manager",
  "28": "Financial Analyst",
  "29": "Marketing Manager",
  "30": "Sales Representative",
  "31": "Operations Manager",
  "32": "Nurse",
  "33": "Physical Therapist",
  "34": "Dental Hygienist",
  "35": "Civil Engineer",
  "36": "Architect",
  "37": "Delivery Partner",
  "38": "Supply Chain Manager",
  "39": "Quality Control Analyst",
  "40": "Domestic Worker",
  "41": "Chef",
  "42": "Construction Worker",
  "43": "Agriculture Worker",
  "44": "Garments Worker",
  "45": "RECRUIT",
  "46": "Enter recruitment message",
  "47": "SEND",
  "48": "Save",
  "49": "RECRUIT ALERTS",
  "50": "RECRUITMENT",
  "51": "ARCHIVE",
  "52": "Select Job Type",
  "53": "Select Job Location",
  "54": "You have already applied for this recruitment",
  "55": "Failed!",
  "56": "Something went wrong, Please try again later",
  "57": "Uploading....",
  "58": "Please wait, your video is loading...",
  "59": "CAPTURE VIDEO",
  "60": "UPLOAD VIDEO",
  "61": "Recruitment deleted Succesfully",
  "62": "Delete Recruitment",
  "63": "Are you sure you want to delete?",
  "64": "Restart",
  "65": "Continue",
  "66": "No videos have been submitted yet!",
  "67": "Error...",
  "68": "Recruitment message cannot be empty",
  "69": "Success",
  "70": "Recruitment message sent successfully!",
  "71": "User Name",
  "72": 'Ahmedabad',
  "73": 'Bangalore',
  "74": 'Chennai',
  "75": 'Davangere',
  "76": 'Delhi',
  "77": 'Gurgaon',
  "78": 'Hubli',
  "79": 'Hyderabad',
  "80": 'Jaipur',
  "81": 'Kanpur',
  "82": 'Kolkata',
  "83": 'Mangalore',
  "84": 'Mumbai',
  "85": 'Panji',
  "86": 'Pune',
  "87": "Name",
  "88": "Email",
  "89": "Cancelled Image selection",
  "90": "Maximum image size exceeded",
  "91": "Please choose a file under 2 MB",
  "92": "Upload profile picture",
  "93": "Select an option",
  "94": "User profile updated successfully",
  "95": "User Name already exist",
  "96": "Update",
  "97": "First Name",
  "98": "Last Name",
  "99": "Loading...",
  "100": "CREATING...",
  "101": "Please upload Recruitment Video",
  "102": "Please select Job Type",
  "103": "Please select Job Location",
  "104": "Please select Last Date of Recruitment",
  "105": "Please enter Company Name",
  "106": "Please enter Job Description",
  "107": "Recruitment created Successfully",
  "108": "Invalid file type, Please upload a video",
  "109": "Video Options",
  "110": "Do you want to remove the video?",
  "111": "Unable to create Recruitment",
  "112": "OR",
  "113": "Search options...",
  "114": "Please select both JobCategory and Location",
  "115": "Please Upload the Video",
  "116": "LANGUAGE",
  "117": "English",
  "118": "Kannada",
  "119": "Hindi",
  "120": "Telugu",
  "121": "Your video has been Archived",
  "122": "No Archive Video found",
  "123": "Select a Job Category and Location",
  "124": "Your account has been created, Please Login again",
  "125": "No recruitments created",
  "126": "No notification found",
  "127": "Please wait recruitment creation in progress",
  "128": "Stop Recording",
  "129": "Would you like to continue or restart screening videos?",
  "130": "Our Suggestion",
  "131": "If you have further interview process you may use such tools",
  "132": "Create link and paste above to send",
  "133": "Your video has been uploaded succesfully",
  "134": "Report",
  "135": "Video is reported and will be removed",
  "136": "Pay for priority",
  "137": "Free",
  "138": "Pay ₹21/-",
  "139": "No recruitments uploaded for this job",
  "140": "You have reached the end for the current category",
  "141": "Pay for recruitment",
  "142": "Pay ₹100/-",
  "143": "Select Language:",
  "144": "Recruitment message",
  "145": "User name updated successfully",
  "146": "Picture size is greater than 2MB",
  "147": "First Name and Last Name must not exceeds 33 alphabet characters",
  "148": "First Name and Last Name should not be empty",
  "149": "First Name and Last Name must have alphabet characters only",
  "150": "User Name must contain minimum 3 and maximum 33 characters",
  "151": "IMAGE LIBRARY",
  "152": "REMOVE IMAGE",
  "153": "Select an option",
  "154": "No changes detected",
  "155": "My video",
  "156": "Delete",
  "157": "Are you sure you want to delete recruitment?",
  "158": 'Video is Reported and will be reviewed',
  "159": 'Please select a report reason',
  "160": "First character must be an alphabet letter",
  "161": "User has withdrawn the application",
  "162": "User Name can not contain special characters",
  "163":"No jobs have been submitted to this category",
  "164":"User Name already exists",
  "165": "Deleted Succesfully",
  "166":"CLEAR",
  "167":"Try again",
  "168":"Reported content will be removed", 
  "169": 'It\'s Spam',
  "170": 'False Information',
  "171": 'Scam or Fraud',
  "172": 'Nudity or Sexual activity',
  "173": 'Inappropriate Content',
  "174": "Sending in progress",
  "175":"No profile picture to remove",
  "176": "Company Name cannot contain only spaces",
  "177": "Job Description cannot contain only spaces",
  "178": "User Name must start with 3 alphabets",
  "179":'Recruitment limit reached, Delete old recruitments to create new',
  "180":"Content Creator",
  "181":"Digital Marketing Intern",
  "182":"Please login as candidate to view shared video",
  "183":"Please agree to the terms and conditions to proceed",
  "184":"I agree to the terms and conditions",
  "185":"Find your dream job and apply within seconds",
  "186":"Record/Upload a recruitment video and find the right match for your team within seconds",
  "187":"Applied to:-",
  "188":"Download QR Code",
  "189":"Selected file is too large (greater than 64MB)",
  "190":"Selected file is not an MP4 video",
  "191":"Are you sure you want to remove the video?",
  "192":"Please make sure the selected date is after today",
  "193":"Option is not available contact feedback@cosmitude.com",
  "194":"Job location is not available contact feedback@cosmitude.com",
  "195":"Camera and microphone permissions are required to record. Please grant the necessary permissions",
  "196":"OK",
  "197":"By Cosmitude Softwares Private Limited",
  "198":"Link copied to clipBoard",
  "199":"Please Wait!",
  "200": "Tabs",
  "201": "JobLocationFilter",
  "202": "LOGINMODAL",
  "203": "PrivacyPolicy",
  "204": "Languages",
  "205": "Login",
  "206": "Apply",
  "207": "AdminDetails",
  "208": "Feed",
  "209": "Archive",
  "210": "ArchiveVideo",
  // "211": "candidateVideo",
  "211": "Video",
  "212": "RTabs",
  "213":"Remove",
  "214":"Unable to share, Recruitment expired",
  "215":"Share link copied to clipboard",
  "216":"New videos have been loaded",
  "217":"No recruitment found, choose a different category",
  "218":"Recruitment Creation Failed!, Please Try Again",
  "219":"Candidate has withdrawn the application",
  "220":" ",
  "221":"Your session has ended. Please login again",
  "222":"Recruiter Message",
  "223":"Company Reply",
  "224":"Are you sure want to Delete the account",
  "225":"Please delete the following recruitments to delete your account",
  "226":"Delete Account",
  "227":"Account has been deleted successfully",
  "228":"Login as recruiter and delete the following recruitment to delete this account.",
  "229":"Send Reply",
  "230":"Showing Recruited Candidate videos",
  "231":"No Recruited candidate to show",
  "232":"You can only select maximun 3 option",
  "233":"INTERESTS",
  "234":"Click to view",
  "235":"Recruited Candidate",
  "236":"Applied Candidate",
  "237":"JOB INTEREST",
  "238":"CHANGE LOCATION",
  "239":"REQUEST NEW JOB TYPE",
  "240":"SEND",
  "241":"REQUEST NEW LOCATION",
  "242":"Request for new Job type is sent for Review",
  "243":"Request for new Location is sent for Review",
  "244":"ENTER NEW JOB TYPE",
  "245":"ENTER NEW LOCATION",
  "246":"Request Approved",
  "247":"Request Denied",
  "248":"your request for addition ",
  "249":"list has been Approved. click to create recruitment",
  "250":"list has been Rejected. click to delete",
  "251":"to ",
  "252":"Location",
  "253":"Job interest will be updated!!",
  "254":"Option is not available, please request for addition using '+' button",
  "255":"Apply Changes",
  "256":"CONFIRM",
  "257":'Option 1',
  "258":'Option 2',
  "259":'Option 3',
  "260":'New Recruitment added at the Last',
  "261":'Select atleast one interest',
  "262":"Demo Videos",
  "263":"Update Your Interest",
  "264":"Cannot apply to demo videos",
  "265":"Cannot recruit to demo videos",
  "266":"Cannot archive to demo videos",
  "267":"Cannot share demo videos",
  "268":"It is taking longer than expected, Please wait",
  "269":"New videos have been loaded,Click OK to View",
  "270":"No recruitment found showing demo videos, choose a different category",
  "271":"This recruitment is no longer accepting applications",
  "272":'Under Review',
  "273":'Nothing To Load',
  "274":'Media not found',
  "275":'User Not Found.',
  "276":'Message cannot be empty',
  "277":'Recruitment Details',
  "278":'Application for Recruitment',
  "279":'is currently under manual review for profinity.',
  "280":'Recruitment',
  "281":' has passed manual review for profinity and will be propagated',
  "282":' has failed manual review for profinity and will not reach Recruiter.',
  "283":' has failed manual review for profinity and will not reach candidates.',
  "284":'Self Chat is Restricted',
  "285":'You cannot apply for the recruitment as you are the creator.',
  "286":'only "@" and "." are the special characters allowed',
  "287":'Company name field does not accept special character except "@" and "."',
  "288":'Job Description field does not accept special character except "@" and "."',
  "289":'DeadLine',
  "290":'Applied On :',
  "291":'Not Specified',
  "292":'Expires on',
  "293":'OverAll Applied Candidate',
  "294":'Download Analytics',
  "295":'FullName',
  "Agriculture Worker":"Agriculture Worker",
  "Human Resource Manager":"Human Resource Manager",
  "Quality Control Analyst":"Quality Control Analyst",
  "Sales Representative":"Sales Representative",
  "Operations Manager":"Operations Manager",
  "Nurse":"Nurse",
  "Physical Therapist":"Physical Therapist",
  "Delivery Partner":"Delivery Partner",
  "Supply Chain Manager":"Supply Chain Manager",
  "Domestic Worker":"Domestic Worker",
  "Chef":"Chef",
  "Construction Worker":"Construction Worker",
  "Garments Worker":"Garments Worker",
  "Testing Forum":"Testing Forum",
  "Software Engineer":"Software Engineer",
  "locomotive":"locomotive",
  "Computer technician":"Computer technician",
};


const TranslatePopup = ({ onClose }) => {
  const [selectedLanguage, setSelectedLanguage] = useState(null);
  const [languages, setLanguages] = useState([]);


  useEffect(()=>{
    callLang()
  },[]);

  async function callLang(){
    let languages1 = localStorage.getItem("LanguageLists");
    let parsedArr = JSON.parse(languages1)
      setLanguages(parsedArr)
  }

  // const languages = [
  //   { id: "English", name: 'English' },
  //   { id: "Kannada - ಕನ್ನಡ", name: 'Kannada - ಕನ್ನಡ' },
  //   { id: "Hindi - हिंदी", name: 'Hindi - हिंदी' },
  //   { id: "Telugu - తెలుగు", name: 'Telugu - తెలుగు' },
  // ];

  const handleOptionPress = async (id) => {
    // console.log(id);
    let selectedLanguageObject = JSON.parse(localStorage.getItem('Languages'));
    // let selectedLanguageObject = null;

    // if (id === 'Kannada - ಕನ್ನಡ') {
    //   selectedLanguageObject = language['Kannada - ಕನ್ನಡ'];
    // } else if (id === 'Hindi - हिंदी') {
    //   selectedLanguageObject = language['Hindi - हिंदी'];
    // } else if (id === 'Telugu - తెలుగు') {
    //   selectedLanguageObject = language['Telugu - తెలుగు'];
    // }
 
    if (selectedLanguageObject) {
      for (const key of Object.keys(selectedLanguageObject)) {
        if (!selectedLanguageObject[key] || selectedLanguageObject[key].trim() === '') {
          selectedLanguageObject[key] = objectNullandUndefined(key);
        }
      }
      literalObject = { ...literalObject, ...selectedLanguageObject };
    } else if (id === 'English') {
      literalObject = {
        "1": "HOME",
        "2": "FEED",
        "3": "SETTINGS",
        "4": "ADMIN",
        "5": "NOTIFICATION",
        "6": "Company Name",
        "7": "Last date of Recruitment",
        "8": "Job Description",
        "9": "SUBMIT",
        "10": "APPLY",
        "11": "CLOSE",
        "12": "Login as",
        "13": "LOGOUT",
        "14": "CANDIDATE",
        "15": "RECRUITER",
        "16": "CANCEL",
        "17": "ACCOUNT",
        "18": "PRIVACY POLICY",
        "19": "Sign in with Google",
        "20": "Welcome to Prescreener",
        "21": "Are you sure you want to logout?",
        "22": "Upload",
        "23": "Data Scientist",
        "24": "Software Engineer",
        "25": "Project Manager",
        "26": "Business Analyst",
        "27": "Human Resources Manager",
        "28": "Financial Analyst",
        "29": "Marketing Manager",
        "30": "Sales Representative",
        "31": "Operations Manager",
        "32": "Nurse",
        "33": "Physical Therapist",
        "34": "Dental Hygienist",
        "35": "Civil Engineer",
        "36": "Architect",
        "37": "Delivery Partner",
        "38": "Supply Chain Manager",
        "39": "Quality Control Analyst",
        "40": "Domestic Worker",
        "41": "Chef",
        "42": "Construction Worker",
        "43": "Agriculture Worker",
        "44": "Garments Worker",
        "45": "RECRUIT",
        "46": "Enter recruitment message",
        "47": "SEND",
        "48": "Save",
        "49": "RECRUIT ALERTS",
        "50": "RECRUITMENT",
        "51": "ARCHIVE",
        "52": "Select Job Type",
        "53": "Select Job Location",
        "54": "You have already applied for this recruitment",
        "55": "Failed!",
        "56": "Something went wrong, Please try again later",
        "57": "Uploading....",
        "58": "Please wait, your video is loading...",
        "59": "CAPTURE VIDEO",
        "60": "UPLOAD VIDEO",
        "61": "Recruitment deleted Succesfully",
        "62": "Delete Recruitment",
        "63": "Are you sure you want to delete?",
        "64": "Restart",
        "65": "Continue",
        "66": "No videos have been submitted yet!",
        "67": "Error...",
        "68": "Recruitment message cannot be empty",
        "69": "Success",
        "70": "Recruitment message sent successfully!",
        "71": "User Name",
        "72": 'Ahmedabad',
        "73": 'Bangalore',
        "74": 'Chennai',
        "75": 'Davangere',
        "76": 'Delhi',
        "77": 'Gurgaon',
        "78": 'Hubli',
        "79": 'Hyderabad',
        "80": 'Jaipur',
        "81": 'Kanpur',
        "82": 'Kolkata',
        "83": 'Mangalore',
        "84": 'Mumbai',
        "85": 'Panji',
        "86": 'Pune',
        "87": "Name",
        "88": "Email",
        "89": "Cancelled Image selection",
        "90": "Maximum image size exceeded",
        "91": "Please choose a file under 2 MB",
        "92": "Upload profile picture",
        "93": "Select an option",
        "94": "User profile updated successfully",
        "95": "User Name already exist",
        "96": "Update",
        "97": "First Name",
        "98": "Last Name",
        "99": "Loading...",
        "100": "CREATING...",
        "101": "Please upload Recruitment Video",
        "102": "Please select Job Type",
        "103": "Please select Job Location",
        "104": "Please select Last Date of Recruitment",
        "105": "Please enter Company Name",
        "106": "Please enter Job Description",
        "107": "Recruitment created Successfully",
        "108": "Invalid file type, Please upload a video",
        "109": "Video Options",
        "110": "Do you want to remove the video?",
        "111": "Unable to create Recruitment",
        "112": "OR",
        "113": "Search options...",
        "114": "Please select both JobCategory and Location",
        "115": "Please Upload the Video",
        "116": "LANGUAGE",
        "117": "English",
        "118": "Kannada",
        "119": "Hindi",
        "120": "Telugu",
        "121": "Your video has been Archived",
        "122": "No Archive Video found",
        "123": "Select a Job Category and Location",
        "124": "Your account has been created, Please Login again",
        "125": "No recruitments created",
        "126": "No notification found",
        "127": "Please wait recruitment creation in progress",
        "128": "Stop Recording",
        "129": "Would you like to continue or restart screening videos?",
        "130": "Our Suggestion",
        "131": "If you have further interview process you may use such tools",
        "132": "Create link and paste above to send",
        "133": "Your video has been uploaded succesfully",
        "134": "Report",
        "135": "Video is reported and will be removed",
        "136": "Pay for priority",
        "137": "Free",
        "138": "Pay ₹21/-",
        "139": "No recruitments uploaded for this job",
        "140": "You have reached the end for the current category",
        "141": "Pay for recruitment",
        "142": "Pay ₹100/-",
        "143": "Select Language:",
        "144": "Recruitment message",
        "145": "User name updated successfully",
        "146": "Picture size is greater than 2MB",
        "147": "First Name and Last Name must not exceeds 33 alphabet characters",
        "148": "First Name and Last Name should not be empty",
        "149": "First Name and Last Name must have alphabet characters only",
        "150": "User Name must contain minimum 3 and maximum 33 characters",
        "151": "IMAGE LIBRARY",
        "152": "REMOVE IMAGE",
        "153": "Select an option",
        "154": "No changes detected",
        "155": "My video",
        "156": "Delete",
        "157": "Are you sure you want to delete recruitment?",
        "158": 'Video is Reported and will be reviewed',
        "159": 'Please select a report reason',
        "160": "First character must be an alphabet letter",
        "161": "User has withdrawn the application",
        "162": "User Name can not contain special characters",
        "163":"No jobs have been submitted to this category",
        "164":"User Name already exists",
        "165": "Deleted Succesfully",
        "166":"CLEAR",
        "167":"Try again",
        "168":"Reported content will be removed", 
        "169": 'It\'s Spam',
        "170": 'False Information',
        "171": 'Scam or Fraud',
        "172": 'Nudity or Sexual activity',
        "173": 'Inappropriate Content',
        "174": "Sending in progress",
        "175":"No profile picture to remove",
        "176": "Company Name cannot contain only spaces",
        "177": "Job Description cannot contain only spaces",
        "178": "User Name must start with 3 alphabets",
        "179":'Recruitment limit reached, Delete old recruitments to create new',
        "180":"Content Creator",
        "181":"Digital Marketing Intern",
        "182":"Please login as candidate to view shared video",
        "183":"Please agree to the terms and conditions to proceed",
        "184":"I agree to the terms and conditions",
        "185":"Find your dream job and apply within seconds",
        "186":"Record/Upload a recruitment video and find the right match for your team within seconds",
        "187":"Applied to:",
        "188":"Download QR Code",
        "189":"Selected file is too large (greater than 64MB)",
        "190":"Selected file is not an MP4 video",
        "191":"Are you sure you want to remove the video?",
        "192":"Please make sure the selected date is after today",
        "193":"Option is not available contact feedback@cosmitude.com",
        "194":"Job location is not available contact feedback@cosmitude.com",
        "195":"Camera and microphone permissions are required to record. Please grant the necessary permissions",
        "196":"OK",
        "197":"By Cosmitude Softwares Private Limited",
        "198":"Link copied to clipBoard",
        "199":"Please Wait!",
        "200": "Tabs",
        "201": "JobLocationFilter",
        "202": "LOGINMODAL",
        "203": "PrivacyPolicy",
        "204": "Languages",
        "205": "Login",
        "206": "Apply",
        "207": "AdminDetails",
        "208": "Feed",
        "209": "Archive",
        "210": "ArchiveVideo",
        // "211": "candidateVideo",
        "211": "Video",
        "212": "RTabs",
        "213":"Remove",
        "214":"Unable to share, Recruitment expired",
        "215":"Share link copied to clipboard",
        "216":"New videos have been loaded",
        "217":"No recruitment found, choose a different category",
        "218":"Recruitment Creation Failed!, Please Try Again",
        "219":"Candidate has withdrawn the application",
        "220":" ",
        "221":"Your session has ended. Please login again",
        "222":"Recruiter Message",
        "223":"Company Reply",
        "224":"Are you sure want to Delete the account",
        "225":"Please delete the following recruitments to delete your account",
        "226":"Delete Account",
        "227":"Account has been deleted successfully",
        "228":"Login as recruiter and delete the following recruitment to delete this account.",
        "229":"Send Reply",
        "230":"Showing Recruited Candidate videos",
        "231":"No Recruited candidate to show",
        "232":"You can only select maximun 3 option",
        "233":"INTERESTS",
        "234":"Click to view",
        "235":"Recruited Candidate",
        "236":"Applied Candidate",
        "237":"JOB INTEREST",
        "238":"CHANGE LOCATION",
        "239":"REQUEST NEW JOB TYPE",
        "240":"SEND",
        "241":"REQUEST NEW LOCATION",
        "242":"Request for new Job type is sent for Review",
        "243":"Request for new Location is sent for Review",
        "244":"ENTER NEW JOB TYPE",
        "245":"ENTER NEW LOCATION",
        "246":"Request Approved",
        "247":"Request Denied",
        "248":"your request for addition ",
        "249":" list has been Approved. click to create recruitment",
        "250":" list has been Rejected. click to delete",
        "251":" to ",
        "252":"Location",
        "253":"Job interest will be updated!!",
        "254":"Option is not available, please request for addition using '+' icon",
        "255":"Apply Changes",
        "256":"CONFIRM",
        "257":'Option 1',
        "258":'Option 2',
        "259":'Option 3',
        "260":'New Recruitment added at the Last',
        "261":'Select atleast one interest',
        "262":"Demo Videos",
        "263":"Update Your Interest",
        "264":"Cannot apply to demo videos",
        "265":"Cannot recruit demo candidate",
        "266":"Cannot archive demo archive",
        "267":"Cannot share demo videos",
        "268":"It is taking longer than expected, Please wait",
        "269":"New videos have been loaded,Click OK to View",
        "270":"No recruitment found showing demo videos, choose a different category",
        "271":"This recruitment is no longer accepting applications",
        "272":'Under Review',
        "273":'Nothing To Load',
        "274":'Media not found',
        "275":'User Not Found.',
        "276":'Message cannot be empty',
        "277":'Recruitment Details',
        "278":'Application for Recruitment',
        "279":'is currently under manual review for profinity.',
        "280":'Recruitment',
        "281":' has passed manual review for profinity and will be propagated',
        "282":' has failed manual review for profinity and will not reach Recruiter.',
        "283":' has failed manual review for profinity and will not reach candidates.',
        "284":'Self Chat is Restricted',
        "285":'You cannot apply for the recruitment as you are the creator.',
        "286":'only "@" and "." are the special characters allowed',
        "287":'Company name field does not accept special character except "@" and "."',
        "288":'Job Description field does not accept special character except "@" and "."',
        "289":'DeadLine',
        "290":'Applied On :',
        "291":'Not Specified',
        "292":'Expires on',
        "293":'OverAll Applied Candidate',
        "294":'Download Analytics',
        "295":'FullName',
        "Agriculture Worker":"Agriculture Worker",
        "Human Resource Manager":"Human Resource Manager",
        "Quality Control Analyst":"Quality Control Analyst",
        "Sales Representative":"Sales Representative",
        "Operations Manager":"Operations Manager",
        "Nurse":"Nurse",
        "Physical Therapist":"Physical Therapist",
        "Delivery Partner":"Delivery Partner",
        "Supply Chain Manager":"Supply Chain Manager",
        "Domestic Worker":"Domestic Worker",
        "Chef":"Chef",
        "Construction Worker":"Construction Worker",
        "Garments Worker":"Garments Worker",
        "Testing Forum":"Testing Forum",
        "Software Engineer":"Software Engineer",
        "locomotive":"locomotive",
        "Computer technician":"Computer technician",
      };
    } 
    else {
      // console.log(`Selected language (${id}) not found.`);
    }
  };

  const handleLanguageSelect = async (language) => {
    setSelectedLanguage(language);
    if(language != null){
      localStorage.setItem('selectedLanguage',language)
      const userDetails = localStorage.getItem('userProfile');
      let decoded = base64_decode(userDetails);
      let userDetail = JSON.parse(decoded);
      let jsonToSend1 = {
        "SelectedLanguage": language,
        "ServerID": FSServerID,
        "MACAddress": userDetail['MACAddress'],
        "ForumID": CommonForumID,
        "SessionID": userDetail['SessionID'],
      }
      let languageStrings = await languageLiterals(jsonToSend1)
      let languageSaved = await saveLanguageToProfile(jsonToSend1)
      localStorage.setItem('Languages', JSON.stringify(languageStrings))
    }
    handleOptionPress(language);
    onClose();
    window.location.reload();
  };




  return (
    <div className="transparent-overlay2">
    <Dialog
      open={true}
      onClose={onClose}
      fullWidth
      maxWidth="sm"
      PaperProps={{ style: { backgroundColor: '#f1f1f1', borderRadius: '12px' } }}
      classes={{ paperWidthSm: 'dialog-paper' }}
    >
      <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', padding: '8px 0px 11px 14px', color: '#000000', backgroundColor: '#20B2AA', borderTopLeftRadius: '12px', borderTopRightRadius: '12px' }}>
        <Typography variant="h6">
          {objectNullandUndefined('143')}
        </Typography>
        <Button onClick={onClose} color="primary">
          <CloseIcon style={{ color: '#000000' }} />
        </Button>
      </div>
      <CardContent style={{ display: 'flex', justifyContent: 'center', backgroundColor: 'white',padding:0 }}>
        <div className="language-options-container">
          <List style={{ padding:0 }}>
            {languages.map((language) => (
              <>
              <ListItem
                key={language}
                button
                onClick={() => handleLanguageSelect(language)}
                onMouseEnter={() => setSelectedLanguage(language)}
                onMouseLeave={() => setSelectedLanguage(null)}
                className={`language-option${selectedLanguage === language ? ' selected' : ''}${selectedLanguage === language && language.id === selectedLanguage ? ' active' : ''}`}
              >
                <ListItemText primary={language} />
              </ListItem>
              <Divider style={{ borderWidth: '1px', backgroundColor: 'rgba(0, 0, 0, 5.12)' }} />
            </>
            ))}
          </List>
        </div>
      </CardContent>
    </Dialog>
    </div>
  );
};

export default TranslatePopup;
export function objectNullandUndefined(key) {
  let value;
  let selectedLanguageObject = JSON.parse(localStorage.getItem('Languages'));
  // let selectedLanguageObject;
  try {
    let id  = localStorage.getItem("selectedLanguage")
    if(id){
      value = selectedLanguageObject[key];
      // if (id === 'Kannada - ಕನ್ನಡ') {
      //   selectedLanguageObject = language['Kannada - ಕನ್ನಡ'];
      //   value = selectedLanguageObject[key];
      // } else if (id === 'Hindi - हिंदी') {
      //   selectedLanguageObject = language['Hindi - हिंदी'];
      //   value = selectedLanguageObject[key];
      // } else if (id === 'Telugu - తెలుగు') {
      //   selectedLanguageObject = language['Telugu - తెలుగు'];
      //   value = selectedLanguageObject[key];
      // } else{
      //   value = literalObject[key];
      // }
    }
    else{
      value = literalObject[key];
    }
    if (value === null || value === undefined) {
      return literalObject1[key]
      // throw new Error("Value is null or undefined");
    } else {
      return value;
    }
  } catch (error) {
    let value = literalObject[key];
    return value;
  }
}