import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './ArchiveShort.css';
import { useEffect, useRef, useState } from 'react';
import { downloadMedia } from '../../services/LoginLogoutService';
import { decode as base64_decode, encode as base64_encode } from 'base-64';
import axios from 'axios';
import uuid from 'react-uuid';
import utf8 from 'utf8';
import defaultPhoto from '../../assets/img/default.jpeg'
import { downloadProfilePhoto, bridgeSendingByUser ,DeleteBridge} from '../../services/LoginLogoutService';
import { objectNullandUndefined } from '../Dictionary/Dictionary';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import { ArchiveForumReport, FSServerID, LLServerID, RecruitedCandidateTempFlowID,RecruitedCandidateTemp,MacAddress, RecMessageFlow, RecMessageFlowID, RecruiterForumTemp, caServerIP,RecruitedCandidateTempFlow, RecCreateTempName, ChatFlowID, ChatFID } from '../Dictionary/CommonStrings.js';
import { CAServerID } from '../Dictionary/CommonStrings';
import { useHistory } from 'react-router-dom';

function ArchiveShort({ short, shortContainerRef, onRemove }) {
  const playPauseRef = useRef();
  const videoRef = useRef();
  const [isPlaying, setIsPlaying] = useState(false);
  const [isMuted, setIsMuted] = useState(false);
  const [videoUrl, setVideoUrl] = useState(null);
  const [isPopupVisible, setPopupVisible] = useState(false);
  const [inputValue, setInputValue] = useState('');
  const [picUrl, setPicUrl] = useState('');
  const [firstname, setFirstName] = useState('');
  const [username, setUserName] = useState('');
  const history = useHistory();
  const [isLoadingApply, setisLoadingApply] = useState(false);
  const [redirectUrl, setRedirectUrl] = useState('https://help.calendly.com/hc/en-us');
  const [testUrl, setTestUrl] = useState('https://support.testgorilla.com/hc/en-us')

  const myUuid = uuid();
  useEffect(() => {
    const handleServiceWorkerMessage = (event) => {
      if (event.data.type === 'navigate') {
        history.push('/Notification');
      }
    };

    navigator.serviceWorker.addEventListener('message', handleServiceWorkerMessage);

    return () => {
      navigator.serviceWorker.removeEventListener('message', handleServiceWorkerMessage);
    };
  }, []);

  useEffect(() => {
    const jsonData = localStorage.getItem('userProfile');
    let decoded = base64_decode(jsonData);
    const userData = JSON.parse(decoded);
    setFirstName(userData.FirstName  + userData.LastName);
    setUserName(userData.UserName);


    shortContainerRef.current.addEventListener('scroll', handleVideo);
    downloadVideo();
    setIsPlaying(!videoRef.current.paused);
    setIsMuted(videoRef.current.muted);

    window.addEventListener('blur', () => {
      if (isActiveVideo(videoRef)) {
        videoRef.current.pause();
        setIsPlaying(false);
      }
    });

    window.addEventListener('focus', () => {
      if (isActiveVideo(videoRef)) {
        videoRef.current.play();
        setIsPlaying(true);
      }
    });
  }, [shortContainerRef]);
  useEffect(() => {
    async function DownloadProfilePicture() {
      const userDetails = localStorage.getItem('userProfile');
      let decoded = base64_decode(userDetails);
      let userDetail = JSON.parse(decoded);
      const companyNameObject = (short.Jd);
      let jsonData = {
        "SessionID": userDetail['SessionID'],
        "MACAddress":userDetail['MACAddress'],
        "MediaID": companyNameObject,
        "ServerID": LLServerID
      }
      const value = await downloadProfilePhoto(jsonData)
      let ObjectUrl = window.URL.createObjectURL(new Blob([value]));
      setPicUrl(ObjectUrl);
    }
    DownloadProfilePicture();
  }, []);


  async function downloadVideo() {
    let userDetails =  localStorage.getItem('userProfile');
    userDetails = JSON.parse(base64_decode(userDetails));
    let jsonData = {
      SessionID: userDetails.SessionID,
      MACAddress: userDetails.MACAddress,
      MediaID: short.mediaID,
      ServerID: FSServerID,
    };

    const resValue = await downloadMedia(jsonData);
    if (resValue["ErrorCode"] === 1074) {
      toast.error((objectNullandUndefined('221')));
      setTimeout(() => {
        localStorage.clear();
        sessionStorage.clear();
        history.push('/');
        window.location.reload(false);
      }, 3000)
    }
    let ObjectUrl = window.URL.createObjectURL(new Blob([resValue]));
    setVideoUrl(ObjectUrl);
  }
  const handleDeletebridge = async () => {
    let userDetails =  localStorage.getItem('userProfile');
    userDetails = JSON.parse(base64_decode(userDetails));
    let flowID = ArchiveForumReport;
    let tempJson = {
        ForumID : short.ForumID[0],
        BridgeID : short.jType,
        FlowID : flowID,
        MACAddress : userDetails["MACAddress"],
        SessionID : userDetails["SessionID"],
        ServerID: FSServerID,
    }
    let deletebridge = await DeleteBridge(tempJson);
    if (deletebridge["ErrorCode"] === 1074) {
      toast.error((objectNullandUndefined('221')));
      setTimeout(() => {
        localStorage.clear();
        sessionStorage.clear();
        history.push('/');
        window.location.reload(false);
      }, 3000)
    }

}


  useEffect(() => {
    if (isPopupVisible || inputValue !== '') {
      videoRef.current.pause();
      setIsPlaying(false);
    } else {
      videoRef.current.play();
      setIsPlaying(true);
    }
  }, [isPopupVisible, inputValue]);

  const bridgeSending = async() => {
    try {
      let encodedRecMessage;
      let uuid = myUuid;     
      const data = localStorage.getItem("userProfile");
      let decoded = base64_decode(data);
      const userDetails = JSON.parse(decoded);
      let sessionID = userDetails.SessionID;
      let macAddress =userDetails.MACAddress;
      let flowName = RecMessageFlow;
      let fID = RecMessageFlowID;
      let tempJson = {
        ForumID: short.DeleteforumID,
        SessionID: sessionID,
        MACAddress: macAddress,
        Time: '6516516156161',
        ScheduledDateTime: 'now',
        ScheduledBoolean: 0,
        FlowID: flowName,
        EnableChat: 0,
        FlowType: 'Custom',
        BridgeForward: 0,
        TemplateID: RecruiterForumTemp,
        TextCount: 1,
        ImageCount: 0,
        InvoiceID: '',
        DocumentCount: 0,
        User: false,
        VideoCount: 0,
        ReplyBridgeID: short.jType,
        ServerID: CAServerID,
        HiddenFlow: false,
        BilledForum: '',
        TempBridgeId: uuid,
        FID: fID,
        "WhisperToUser": true,
        "WhisperReceiver": short.MobileNumber,
        SentTo: '3',
      };
      try{
        encodedRecMessage = base64_encode(inputValue)
      }catch(error){
        let utfMessage = utf8.encode(inputValue)
        encodedRecMessage = base64_encode(utfMessage)
      }
      let jsonData = {
        [RecCreateTempName+"_RecruiterMessage_Main-6b141153-d01e-4749-9ddb-5542358d0ba4_2"]: encodedRecMessage,
      };
      tempJson[flowName] = jsonData;
      const dataForum = new FormData();
      dataForum.append('Data', JSON.stringify(tempJson));
      let res = await axios({
        method: "post",
        url: caServerIP + "bridgeWhisperSending",
        data: dataForum,
        headers: { "Content-Type": "multipart/form-data" },
      })
        .then(function (response) {
          // console.log(response);
          return response.data;
        })
        return res;
    } catch (err) {
      return;
    }
  };

  const wisperSendingFor2WayCommunicaton = async() =>{
    try {
      let encodedRecMessage;
      let MobileNumber;
      let uuid = myUuid;     
      const data = localStorage.getItem("userProfile");
      let decoded = base64_decode(data);
      const userDetails = JSON.parse(decoded);
      let sessionID = userDetails.SessionID;
      let macAddress =userDetails.MACAddress;
      let flowName = ChatFlowID;
      let fID = ChatFID;
      let tempJson = {
        ForumID: short.DeleteforumID,
        SessionID: sessionID,
        MACAddress: macAddress,
        Time: '6516516156161',
        ScheduledDateTime: 'now',
        ScheduledBoolean: 0,
        FlowID: flowName,
        EnableChat: 0,
        FlowType: 'Custom',
        BridgeForward: 0,
        TemplateID: RecruiterForumTemp,
        TextCount: 1,
        ImageCount: 0,
        InvoiceID: '',
        DocumentCount: 0,
        User: true,
        VideoCount: 0,
        // ReplyBridgeID: short.jType,
        ReplyBridgeID: '',
        ServerID: CAServerID,
        HiddenFlow: false,
        BilledForum: '',
        TempBridgeId: uuid,
        FID: fID,
        "WhisperToUser": true,
        "WhisperReceiver": short.MobileNumber,
        SentTo: '3',
      };
      try {
        MobileNumber = base64_encode(short.MobileNumber)
        encodedRecMessage = base64_encode(inputValue)
      } catch (error) {
        let utfMessage = utf8.encode(inputValue)
        encodedRecMessage = base64_encode(utfMessage)
      }
      let jsonData = {
        [RecCreateTempName+"_Chat_Main-ff165321-0b90-4d07-917c-da5d002f11ba_2"]: encodedRecMessage,
        [RecCreateTempName+"_Chat_Main-ff165321-0b90-4d07-917c-da5d002f11ba_4"]: MobileNumber,
      };
      tempJson[flowName] = jsonData;
      const dataForum = new FormData();
      dataForum.append('Data', JSON.stringify(tempJson));
      let res = await axios({
        method: "post",
        url: caServerIP + "bridgeWhisperSending",
        data: dataForum,
        headers: { "Content-Type": "multipart/form-data" },
      })
        .then(function (response) {
          // console.log(response);
          return response.data;
        })
        return res;
    } catch (err) {
      return;
    }
  }
  const RecruitedBridgesending = async () => {
    try {
      let uuid = myUuid;
      const data = localStorage.getItem("userProfile");
      let decoded = base64_decode(data);
      const userDetails = JSON.parse(decoded);
      let sessionID = userDetails.SessionID;
      let macAddress = userDetails.MACAddress;
      let flowName = RecruitedCandidateTempFlow;
      let fID = RecruitedCandidateTempFlowID;
      let tempJson = {
        ForumID: short.DeleteforumID,
        SessionID: sessionID,
        MACAddress: macAddress,
        Time: '6516516156161',
        ScheduledDateTime: 'now',
        ScheduledBoolean: 0,
        FlowID: flowName,
        EnableChat: 0,
        FlowType: 'Custom',
        BridgeForward: 0,
        TemplateID: RecruitedCandidateTemp,
        TextCount: 6,
        ImageCount: 0,
        InvoiceID: '',
        DocumentCount: 0,
        User: false,
        VideoCount: 0,
        ServerID: FSServerID,
        ReplyBridgeID: '',
        HiddenFlow: false,
        BilledForum: '',
        TempBridgeId: uuid,
        FID: fID,
        SentTo: '0',
      };
      // const profile2 = "null";
      // if(short.Jd){
      //   let profiel = short.Jd;
      //   let profile1 = JSON.parse(profiel);
      //   profile2 = profile1.MediaID;
      // }   
      let data1 = {
        "CompanyName": short.companyName, // and again they are not sennding profile pic media Id
        "MediaID": short.mediaID,
        "ForumID": short.DeleteforumID,
        "mobileNumber": short.MobileNumber,
        "FullName":short.FullName
      }
      let info = data1;
      let jsonData = {
        [RecCreateTempName+"_RecruitedCandidate_Main-345bd685-8f77-47c4-ac6c-824a6b3cb791_2"]: base64_encode(short.ForumID),
        [RecCreateTempName+"_RecruitedCandidate_Main-345bd685-8f77-47c4-ac6c-824a6b3cb791_4"]: base64_encode(JSON.stringify(info)),
      };
      tempJson[flowName] = jsonData;
      const dataForum = new FormData();
      dataForum.append('Data', JSON.stringify(tempJson));
      let bridgeData = await bridgeSendingByUser(dataForum);
      if (bridgeData["ErrorCode"] === 1074) {
        toast.error((objectNullandUndefined('221')));
        setTimeout(() => {
          localStorage.clear();
          sessionStorage.clear();
          history.push('/');
          window.location.reload(false);
        }, 3000)
      }
    } catch (err) {
      return;
    }
  };

  async function handleVideo() {
    const videoTop = videoRef.current.getBoundingClientRect().top;
    if (videoTop > 0 && videoTop <= 150) {
      try {
        await videoRef.current.play();
        setIsPlaying(true);
      } catch (error) {
        setIsPlaying(false);
        videoRef.current.pause();
      }
    } else {
      videoRef.current.currentTime = 0;
      videoRef.current.pause();
    }
  }

  const handleSubmit = async () => {
    localStorage.setItem('recruitmentText',inputValue);
    if (inputValue.trim() === '') {
      toast.warning(objectNullandUndefined('46'), {
        position: toast.POSITION.TOP_CENTER,
        autoClose: 2000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        className: 'toast-over-navbar'
      });
      return;
    }
    else {
      toast.warning(objectNullandUndefined('174') , {
        position: toast.POSITION.TOP_CENTER,
        autoClose: 500,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        className: 'toast-over-navbar'
      });

    }
    setisLoadingApply(true)
    let res = await wisperSendingFor2WayCommunicaton();
    // let res = await bridgeSending();
    if(res['ErrorCode'] == '1079'){
      let data = await RecruitedBridgesending();
      toast.success(objectNullandUndefined('70'), {
        position: toast.POSITION.TOP_CENTER,
        autoClose: 4000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        className: 'toast-over-navbar',
      })
      setInputValue('');
      setPopupVisible(false);
      setisLoadingApply(false)
      onRemove()
    }else if (res['ErrorCode'] == '1031'){
      toast.error(objectNullandUndefined('219'), {
        position: toast.POSITION.TOP_CENTER,
        autoClose: 4000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        progress: undefined,
        className: 'toast-over-navbar',
      })
      setInputValue('');
      setPopupVisible(false);
      setisLoadingApply(false)
      onRemove()
    }else{
      toast.error(objectNullandUndefined('167'), {
        position: toast.POSITION.TOP_CENTER,
        autoClose: 4000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        progress: undefined,
        className: 'toast-over-navbar',
      })
    }
  };

  const handleCancel = () => {
    setInputValue('');
    setPopupVisible(false);
  };

  const toPopup = () => {
    setPopupVisible(!isPopupVisible);
  };

  const handleDate = () => {
    setRedirectUrl('https://help.calendly.com/hc/en-us');
  };

  const handleTest = () => {
    setTestUrl('https://support.testgorilla.com/hc/en-us');
  };



  const toggleVideoPlayback = () => {
    if (!isPlaying) {
      videoRef.current.play();
      setIsPlaying(true);
    } else {
      videoRef.current.pause();
      setIsPlaying(false);
    }
  };

  const toggleVideoMute = () => {
    videoRef.current.muted = !isMuted;
    setIsMuted(!isMuted);
  };

  const recruitButtonClicked = () => {
    const storedRecruitmentText = localStorage.getItem('recruitmentText');
    if (storedRecruitmentText) {
      setInputValue(storedRecruitmentText);
    }
    videoRef.current.pause();
    setIsPlaying(false);
    toPopup();
    const video = document.getElementById('backgroundVideo');
    if (video) {
      video.pause();
    }
  };



  const handleInputChange = (e) => {
    setInputValue(e.target.value);
  };


  return (
    <><div className='reel5'>
        <div className="reel111">
          

            <div className="video">

              <video
                ref={videoRef}
                onClick={toggleVideoPlayback}
                disableRemotePlayback
                playsInline
                loop
                src={videoUrl}
                height="100%"
                width="100%"

              >
              </video>
              <h5 className="text-overlay12">{firstname + username}</h5>


              <div className="controls">
                <span onClick={toggleVideoPlayback}>
                  <ion-icon name={`${isPlaying ? 'pause' : 'play'}-outline`}></ion-icon>
                </span>
                <span onClick={toggleVideoMute}>
                  <ion-icon name={`volume-${isMuted ? 'mute' : 'medium'}-outline`}></ion-icon>
                </span>

              </div>

              <div
                ref={playPauseRef}
                onClick={toggleVideoPlayback}
                className={`play-pause ${isPlaying ? '' : 'show-play-pause'}`}
              >
                <ion-icon name="play-outline"></ion-icon>
              </div>
              <div className="foot">
                <div className="title">{short.title}</div>
                <div className="rec-info1">
                  <div className="recinnerdiv1">
                    <img src={picUrl} onError={(e) => {
                      e.target.src = defaultPhoto;
                    } } />
                    <span>{short.companyName}</span>
                  </div>
                  <div className="title">{objectNullandUndefined('6')} : {utf8.decode(short.ForumName)}</div>
                  <div className="title">{objectNullandUndefined('295')} : {utf8.decode(short.FullName)}</div>
                  <div className="recinnerdiv2">
                  <button className="Recruit" onClick={recruitButtonClicked}>
                    {objectNullandUndefined('45')}
                  </button>
                  </div>
                  <Dialog open={isPopupVisible} onClose={toPopup} >
                    <DialogTitle>{objectNullandUndefined("144")}</DialogTitle>
                    <DialogContent>
                      <TextField
                        value={inputValue}
                        onChange={handleInputChange}
                        className="input-text"
                        placeholder={objectNullandUndefined('46')}
                        sx={{ width: '100%' }}
                        multiline
                        rows={3} />
                      <h5 style={{ color: 'black', fontSize: '100%', marginTop: '10px' }}>
                        {objectNullandUndefined('130')}
                      </h5>
                      <span
                        style={{
                          position: 'relative',
                          color: 'blue',
                          fontStyle: 'italic',
                          fontSize: '80%',
                          marginBottom: '0%',
                        }}
                      >
                        {objectNullandUndefined('131')}
                      </span>

                      <div style={{ display: 'flex', justifyContent: 'center', marginTop: '15px' }}>
                        <a href={redirectUrl} target="_blank">
                          <img
                            src={require('./Calendly.png')}
                            alt="Clickable Image"
                            onClick={handleDate}
                            style={{ height: '-28px', width: '120px', marginRight: '120px' }} />
                        </a>
                        <a href={testUrl} target="_blank">
                          <img
                            src={require('./TestGorilla.png')}
                            alt="Clickable Image"
                            onClick={handleTest}
                            style={{ height: '-28px', width: '135px', marginTop: '8%' }} />
                        </a>
                      </div>
                      <span style={{ position: 'relative', color: 'blue', fontStyle: 'italic', fontSize: '80%', marginBottom: '0%' }}>{objectNullandUndefined('132')}</span>
                    </DialogContent>

                    <DialogActions style={{ justifyContent: 'center', marginBottom: '20px' }}>
                      <button
                        onClick={handleCancel}
                        style={{
                          backgroundColor: 'rgb(234,67,53)',
                          borderRadius: '7px',
                          borderColor: 'rgb(234,67,53)',
                          padding: '0.5rem',
                          marginRight: '10px',
                          color: '#ffffff',
                        }}
                      >
                        {objectNullandUndefined('16')}
                      </button>
                      <button
                       disabled={isLoadingApply}
                        onClick={() => {
                          handleSubmit();
                          handleDeletebridge();
                        } }
                        style={{
                          backgroundColor: 'rgb(234,67,53)',
                          borderRadius: '7px',
                          borderColor: 'rgb(234,67,53)',
                          padding: '0.5rem',
                          marginLeft: '10px',
                          color: '#ffffff',
                        }}
                      >
                        {isLoadingApply && objectNullandUndefined('99')}
                        {!isLoadingApply && objectNullandUndefined('9')}
                      </button>
                    </DialogActions>
                  </Dialog>
                </div>
              </div>
            </div>
         
          <ToastContainer position="top-center" autoClose={2000} hideProgressBar />
        </div>
      </div></>
  );




}
function isActiveVideo(videoRef) {
  const videoTop = videoRef.current.getBoundingClientRect().top;
  return videoTop > 0 && videoTop <= 150;
}

export default ArchiveShort;




