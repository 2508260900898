import  { useState } from 'react';
import { useEffect } from 'react';
import { decode as base64_decode, encode as base64_encode} from 'base-64';
import axios from 'axios';
import ArchiveShort from './ArchiveShort';
import { useHistory } from 'react-router-dom';
import ArchiveContainer from './ArchiveContainer';
import { objectNullandUndefined } from '../Dictionary/Dictionary';
import { ArchiveForumReport, FSServerID, MacAddress, forumServerIP } from '../Dictionary/CommonStrings';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { ArchiveConsolidatedReportGeneration } from '../../services/LoginLogoutService';

export default function ArchiveVideo () {
    const [videoData, setVideoData] = useState();
    // console.log('this is video',videoData);
    const history = useHistory();

    useEffect(() => {
      async function fetchData1() {
      const videoArr = [];
      const jsonData =  localStorage.getItem('forumdata');
      const userData = JSON.parse(jsonData);
      if(userData != null)
      {
       let data = await ArchiveConsolidatedReportGeneration('null');
       if (data.length === 0) {
        toast.warning(objectNullandUndefined('66'));
        setTimeout(() => {
          history.push('/Notification');
        }, 2000)
      }
      for (let i = 0; i < data.length; i++){
        setVideoData(data);
       }
      }
      else{
        const feedJson = {
          "companyName": null,
          "Jd": null,
          "jType": null,
          "mediaID": '',
          "ForumID": '',
          "MobileNumber": ''
      }
      let decodedreport = feedJson;
      videoArr.push(decodedreport)
      setVideoData(videoArr);
        
      }
    }
    fetchData1();
        
      }, []);
     
    return (
      <div>
        <ToastContainer position="top-center" autoClose={2000} hideProgressBar/>
         <main className="main">
         {videoData && (
        <ArchiveContainer videos={videoData}   />
      )}
</main>
      
    </div>
    );

};