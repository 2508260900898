import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './RecruiterShort.css';
import { useEffect, useRef, useState } from 'react';
import { DeleteBridge, downloadMedia } from '../../services/LoginLogoutService';
import { decode as base64_decode, encode as base64_encode } from 'base-64';
import axios from 'axios';
import uuid from 'react-uuid';
import defaultPhoto from '../../assets/img/default.jpeg'
import { downloadProfilePhoto, bridgeSendingByUser, fetchForumInfo, RecruitedConsolidationReportGenration } from '../../services/LoginLogoutService';
import { objectNullandUndefined } from '../Dictionary/Dictionary';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Button,
  FormControl,
  RadioGroup,
  FormControlLabel,
  Radio,
  DialogActions
} from '@mui/material';
import TextField from '@mui/material/TextField';
import FlagOutlinedIcon from "@mui/icons-material/FlagOutlined";
import { ArchiveTempName, forumServerIP, ArchiveTemp, ArchiveTempFlow, ArchiveTempFlowID, CAServerID, CommonForumID, CommonForumReport, FSServerID, LLServerID, MacAddress, RecMessageFlow, RecMessageFlowID, RecruiterForumTemp, ReportContent, ReportContentID, caServerIP, RecruitedCandidateTempFlow, RecruitedCandidateTempFlowID, RecruitedCandidateTemp, RecCreateTempName, ChatFID, ChatFlowID } from '../Dictionary/CommonStrings';
import FlagIcon from '@mui/icons-material/Flag';
import IconButton from '@mui/material/IconButton';
import utf8 from 'utf8';
import { useHistory } from 'react-router-dom';

function RecruiterShort({ short, shortContainerRef, onRemove, key }) {
  const playPauseRef = useRef();
  const videoRef = useRef();
  const history = useHistory();
  const [isPlaying, setIsPlaying] = useState(false);
  const [isMuted, setIsMuted] = useState(false);
  const [videoUrl, setVideoUrl] = useState(null);
  const [isPopupVisible, setPopupVisible] = useState(false);
  const [isLoadingApply, setisLoadingApply] = useState(false);
  const [inputValue, setInputValue] = useState('');
  const [picUrl, setPicUrl] = useState('');
  const [firstname, setFirstName] = useState('');
  const [username, setUserName] = useState('');
  const [redirectUrl, setRedirectUrl] = useState('https://help.calendly.com/hc/en-us');
  const [testUrl, setTestUrl] = useState('https://support.testgorilla.com/hc/en-us')
  const [reportContent, setReportContent] = useState(false);
  const [button, setButton] = useState(false);
  const [selectedOption, setSelectedOption] = useState('');
  const myUuid = uuid();

  const [isViewed, setIsViewed] = useState(false);


  useEffect(() => {
    const handleScroll = () => {
      const rect = videoRef.current.getBoundingClientRect();
      const isVisible = rect.top >= 0 && rect.bottom <= (shortContainerRef.current.innerHeight || document.documentElement.clientHeight) && (rect.bottom - rect.top) / rect.height > 0.5;

      if (isVisible && !isViewed) {
        setIsViewed(true);
        let set;
        let viewedMediaIdString = localStorage.getItem(short.ForumID + "viewedMediaIds");
        let viewedMediaId = [];
        if(viewedMediaIdString != null){
          viewedMediaId = JSON.parse(viewedMediaIdString)
          set = new Set(viewedMediaId)
          set.add(short.mediaID)
        }else{
          set = new Set()
          set.add(short.mediaID)
        }
        const viewedMediaIds = Array.from(set);
        localStorage.setItem(short.ForumID+"viewedMediaIds",JSON.stringify(viewedMediaIds));
        // if (viewedMediaIdString != null) {
        //   viewedMediaId = JSON.parse(viewedMediaIdString);
        // }
        // viewedMediaId.push(short.mediaID);
        // localStorage.setItem(short.ForumID + "viewedMediaIds", JSON.stringify(viewedMediaId));
      }
    };

    shortContainerRef.current.addEventListener('scroll', handleScroll);
  }, [isViewed, short.ForumID, short.mediaID]);

  const options = [
    objectNullandUndefined('169'),
    objectNullandUndefined('170'),
    objectNullandUndefined('171'),
    objectNullandUndefined('172'),
    objectNullandUndefined('173'),
  ];
  useEffect(() => {
    localStorage.setItem('recruitedforumid', short.ForumID)
    const handleServiceWorkerMessage = (event) => {
      if (event.data.type === 'navigate') {
        history.push('/Notification');
      }
    };

    navigator.serviceWorker.addEventListener('message', handleServiceWorkerMessage);

    return () => {
      navigator.serviceWorker.removeEventListener('message', handleServiceWorkerMessage);
    };
  }, []);

  useEffect(() => {
    const jsonData = localStorage.getItem('userProfile');
    let decoded = base64_decode(jsonData);
    const userData = JSON.parse(decoded);
    setFirstName(userData.FirstName + userData.LastName);
    setUserName(userData.UserName);

    shortContainerRef.current.addEventListener('scroll', handleVideo);
    downloadVideo();
    if (videoUrl) {
      setIsPlaying(!videoRef.current.paused);
      setIsMuted(videoRef.current.muted);
    }
    window.addEventListener('blur', () => {
      try {
        if (isActiveVideo(videoRef)) {
          videoRef.current.pause();
          setIsPlaying(false);
        }
      } catch (e) { }
    });

    window.addEventListener('focus', () => {
      try {
        if (isActiveVideo(videoRef)) {
          videoRef.current.play();
          setIsPlaying(true);
        }
      } catch (e) { }
    });
  }, [shortContainerRef]);

  const handleDate = () => {
    setRedirectUrl('https://help.calendly.com/hc/en-us');
  };

  const handleTest = () => {
    setTestUrl('https://support.testgorilla.com/hc/en-us');
  };

  const handleOptionChange = (option) => {
    setSelectedOption(option);
  };

  const handleSendRep = () => {
    if (selectedOption === null || selectedOption === '') {
      toast.error(objectNullandUndefined('159'));
      return;
    }
    videoRef.current.play();
    reportSending()
    reportBridgeDelete()
    setReportContent(!reportContent)
    setSelectedOption('')
    toast.warning(objectNullandUndefined('168'))
  }

  async function reportBridgeDelete() {
    let userDetails = localStorage.getItem('userProfile');
    userDetails = base64_decode(userDetails);
    userDetails = JSON.parse(userDetails);
    let jsonObject = {
      "ForumID": short.ForumID,
      "BridgeID": short.jType,
      "FlowID": CommonForumReport,
      "MACAddress": userDetails['MACAddress'],
      'SessionID': userDetails['SessionID'],
      'ServerID': FSServerID,
    }
    let deleteBridge = await DeleteBridge(jsonObject);
    if (deleteBridge["ErrorCode"] === 1074) {
      toast.error((objectNullandUndefined('221')));
      setTimeout(() => {
        localStorage.clear();
        sessionStorage.clear();
        history.push('/');
        window.location.reload(false);
      }, 3000)
    }
    videoRef.current.pause();
    setIsPlaying(false);
    setVideoUrl(null);
    setTimeout(() => {
      onRemove()
    }, 1000);
  }

  const reporting = () => {
    videoRef.current.pause();
    setReportContent(!reportContent)
    setSelectedOption('')
  }
  const handleCancelRep = () => {
    videoRef.current.play();
    setReportContent(!reportContent)
    setSelectedOption('')
  }

  useEffect(() => {
    async function DownloadProfilePicture() {
      const userDetails = localStorage.getItem('userProfile');
      let decoded = base64_decode(userDetails);
      let userDetail = JSON.parse(decoded);
      const companyNameObject = JSON.parse(short.Jd);
      const companyName = companyNameObject.MediaID;
      //document.getElementById('profileImage').src = defaultPhoto;
      let jsonData = {
        "SessionID": userDetail['SessionID'],
        "MACAddress": userDetail['MACAddress'],
        "MediaID": companyName,
        "ServerID": LLServerID
      }
      const value = await downloadProfilePhoto(jsonData)
      let ObjectUrl = window.URL.createObjectURL(new Blob([value]));
      setPicUrl(ObjectUrl);
    }
    DownloadProfilePicture();
  }, []);

  async function reportSending() {
    let commonForumTemp = localStorage.getItem("commonUserFlows")
    let parsedFlow = JSON.parse(commonForumTemp)
    let parsedCommonFlow = parsedFlow["UserFlow"]
    let commonTempName = parsedFlow["Template"]
    let reportFlowID = parsedCommonFlow[commonTempName + '_ReportContent']
    try {
      let uuid = myUuid

      let userDetails = localStorage.getItem('userProfile');
      userDetails = base64_decode(userDetails);
      userDetails = JSON.parse(userDetails);
      let sessionID = userDetails['SessionID'];
      let macAddress = userDetails['MACAddress'];
      let flowName = commonTempName + '_ReportContent';;
      let fID = reportFlowID["FID"]
      let tempJson = {
        ForumID: CommonForumID,
        SessionID: sessionID,
        MACAddress: macAddress,
        Time: '6516516156161',
        ScheduledDateTime: 'now',
        ScheduledBoolean: 0,
        FlowID: flowName,
        EnableChat: 0,
        FlowType: 'Custom',
        BridgeForward: 0,
        TemplateID: parsedFlow["TemplateID"],
        TextCount: 6,
        ImageCount: 0,
        InvoiceID: '',
        DocumentCount: 0,
        User: true,
        VideoCount: 0,
        ServerID: FSServerID,
        ReplyBridgeID: '',
        HiddenFlow: false,
        BilledForum: '',
        TempBridgeId: uuid,
        FID: fID,
        SentTo: '0',
      };

      let reportData = {
        "CompanyName": short.companyName,
        "MediaID": short.mediaID,
        "BridgeID": short.jType,
        "ForumID": short.ForumID
      }
      let createFlows = flowName + '_Main-f64c104d-64cb-428f-a6d3-474c3628691f'
      let jsonData = {};
      jsonData[createFlows + '_2'] = base64_encode(JSON.stringify(selectedOption));
      jsonData[createFlows + '_4'] = base64_encode(JSON.stringify(reportData));
      tempJson[flowName] = jsonData;
      const data = new FormData();
      data.append('Data', JSON.stringify(tempJson));
      let bridgeData = await bridgeSendingByUser(data);
      if (bridgeData["ErrorCode"] === 1074) {
        toast.error((objectNullandUndefined('221')));
        setTimeout(() => {
          localStorage.clear();
          sessionStorage.clear();
          history.push('/');
          window.location.reload(false);
        }, 3000)
      }
    } catch (err) {
      return;
    }
  }

  async function downloadVideo() {
    let userDetails = localStorage.getItem('userProfile');
    userDetails = base64_decode(userDetails);
    userDetails = JSON.parse(userDetails);
    let jsonData = {
      SessionID: userDetails['SessionID'],
      MACAddress: userDetails['MACAddress'],
      MediaID: short.mediaID,
      ServerID: FSServerID,
    };

    const resValue = await downloadMedia(jsonData);
    let ObjectUrl = window.URL.createObjectURL(new Blob([resValue]));
    setVideoUrl(ObjectUrl);
  }


  useEffect(() => {
    if (isPopupVisible || inputValue !== '') {
      videoRef.current.pause();
      setIsPlaying(false);
    } else {
      videoRef.current.play();
      setIsPlaying(true);
    }
  }, [isPopupVisible, inputValue]);

  const bridgeSending = async () => {
    try {
      let encodedRecMessage;
      let uuid = myUuid;
      const data = localStorage.getItem("userProfile");
      let decoded = base64_decode(data);
      const userDetails = JSON.parse(decoded);
      let sessionID = userDetails.SessionID;
      let macAddress = userDetails.MACAddress;
      let flowName = RecMessageFlow;
      let fID = RecMessageFlowID;
      let tempJson = {
        ForumID: short.ForumID,
        SessionID: sessionID,
        MACAddress: macAddress,
        Time: '6516516156161',
        ScheduledDateTime: 'now',
        ScheduledBoolean: 0,
        FlowID: flowName,
        EnableChat: 0,
        FlowType: 'Custom',
        BridgeForward: 0,
        TemplateID: RecruiterForumTemp,
        TextCount: 1,
        ImageCount: 0,
        InvoiceID: '',
        DocumentCount: 0,
        User: false,
        VideoCount: 0,
        ReplyBridgeID: short.jType,
        ServerID: CAServerID,
        HiddenFlow: false,
        BilledForum: '',
        TempBridgeId: uuid,
        FID: fID,
        "WhisperToUser": true,
        "WhisperReceiver": short.MobileNumber,
        SentTo: '3',
      };

      try {
        encodedRecMessage = base64_encode(inputValue)
      } catch (error) {
        let utfMessage = utf8.encode(inputValue)
        encodedRecMessage = base64_encode(utfMessage)
      }
      let jsonData = {
        [RecCreateTempName+"_RecruiterMessage_Main-6b141153-d01e-4749-9ddb-5542358d0ba4_2"]: encodedRecMessage,
      };
      tempJson[flowName] = jsonData;
      const dataForum = new FormData();
      dataForum.append('Data', JSON.stringify(tempJson));
      let res = await axios({
        method: "post",
        url: caServerIP + "bridgeWhisperSending",
        data: dataForum,
        headers: { "Content-Type": "multipart/form-data" },
      })
        .then(function (response) {
          console.log(response)
          if(!short.DeleteforumID){
            setTimeout(() => {
              onRemove()
            }, 1000);
          }        
          return response.data;
        })
      return res;
    } catch (err) {
      return;
    }
  };

  async function handleVideo() {
    const videoTop = videoRef.current.getBoundingClientRect().top;
    if (videoTop > 0 && videoTop <= 150) {
      try {
        await videoRef.current.play();
        setIsPlaying(true);
      } catch (error) {
        setIsPlaying(false);
        videoRef.current.pause();
      }
    } else {
      videoRef.current.currentTime = 0;
      videoRef.current.pause();
    }
    localStorage.setItem(short.ForumID+"LastePlayedMID",short.mediaID);
  }

  const handleSubmit = async () => {
    let data = await RecruitedBridgesending();
    localStorage.setItem('recruitmentText', inputValue);
    if (inputValue.trim() === '') {
      toast.warning(objectNullandUndefined('46'), {
        position: toast.POSITION.TOP_CENTER,
        autoClose: 500,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        className: 'toast-over-navbar'
      });
      return;
    }else{
      toast.warning(objectNullandUndefined('174') , {
        position: toast.POSITION.TOP_CENTER,
        autoClose: 500,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        className: 'toast-over-navbar'
      });
    }
    setisLoadingApply(true)
    // if (/^\s*$/.test(inputValue)) {
    //   toast.error("TextField cannot contain only spaces");
    //   return;
    // }
    let res = await wisperSendingFor2WayCommunicaton();
    // let res = await bridgeSending();
    if(res['ErrorCode'] == '1079'){
      toast.success(objectNullandUndefined('70'), {
        position: toast.POSITION.TOP_CENTER,
        autoClose: 2000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        className: 'toast-over-navbar',
      })
      setInputValue('');
      setisLoadingApply(false)
      setPopupVisible(false);
      const data = localStorage.getItem("userProfile");
      let decoded = base64_decode(data);
      const userDetails = JSON.parse(decoded);
      let sessionID = userDetails.SessionID;
      let macAddress = userDetails.MACAddress;
      let flowId = RecCreateTempName+"_User_f945924a-31d1-cc25-c626-f493aaa9d04c_User"
      let JsonObject = {
        "ForumID": short.ForumID,
        "BridgeID": short.jType,
        "FlowID": flowId,
        "MACAddress": macAddress,
        "SessionID": sessionID,
        "ServerID": FSServerID,
      }
      let deletebridge = await DeleteBridge(JsonObject);
      if (deletebridge["ErrorCode"] === 1074) {
        toast.error((objectNullandUndefined('221')));
        setTimeout(() => {
          localStorage.clear();
          sessionStorage.clear();
          history.push('/');
          window.location.reload(false);
        }, 3000)
      }
    }else if (res['ErrorCode'] == '1031'){
      toast.error(objectNullandUndefined('219'), {
        position: toast.POSITION.TOP_CENTER,
        autoClose: 2000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        progress: undefined,
        className: 'toast-over-navbar',
      })
      setInputValue('');
      setisLoadingApply(false)
      setPopupVisible(false);
      const data = localStorage.getItem("userProfile");
      let decoded = base64_decode(data);
      const userDetails = JSON.parse(decoded);
      let sessionID = userDetails.SessionID;
      let macAddress = userDetails.MACAddress;
      let flowId = RecCreateTempName+"_User_f945924a-31d1-cc25-c626-f493aaa9d04c_User"
      let JsonObject = {
        "ForumID": short.ForumID,
        "BridgeID": short.jType,
        "FlowID": flowId,
        "MACAddress": macAddress,
        "SessionID": sessionID,
        "ServerID": FSServerID,
      }
      let deletebridge = await DeleteBridge(JsonObject);
    }else{
      toast.error(objectNullandUndefined('167'), {
        position: toast.POSITION.TOP_CENTER,
        autoClose: 2000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        progress: undefined,
        className: 'toast-over-navbar',
      })
    }


  };




  const wisperSendingFor2WayCommunicaton = async() =>{
    try {
      let encodedRecMessage;
      let MobileNumber;
      let uuid = myUuid;
      const data = localStorage.getItem("userProfile");
      let decoded = base64_decode(data);
      const userDetails = JSON.parse(decoded);
      let sessionID = userDetails.SessionID;
      let macAddress = userDetails.MACAddress;
      let flowName = ChatFlowID;
      let fID = ChatFID;
      let tempJson = {
        ForumID: short.ForumID,
        SessionID: sessionID,
        MACAddress: macAddress,
        Time: '6516516156161',
        ScheduledDateTime: 'now',
        ScheduledBoolean: 0,
        FlowID: flowName,
        EnableChat: 0,
        FlowType: 'Custom',
        BridgeForward: 0,
        TemplateID: RecruiterForumTemp,
        TextCount: 1,
        ImageCount: 0,
        InvoiceID: '',
        DocumentCount: 0,
        User: true,
        VideoCount: 0,
        // ReplyBridgeID: short.jType,
        ReplyBridgeID: '',
        ServerID: CAServerID,
        HiddenFlow: false,
        BilledForum: '',
        TempBridgeId: uuid,
        FID: fID,
        "WhisperToUser": true,
        "WhisperReceiver": short.MobileNumber,
        SentTo: '3',
      };

      try {
        encodedRecMessage = base64_encode(inputValue)
        MobileNumber = base64_encode(short.MobileNumber)
      } catch (error) {
        let utfMessage = utf8.encode(inputValue)
        encodedRecMessage = base64_encode(utfMessage)
      }
      let jsonData = {
        [RecCreateTempName+"_Chat_Main-ff165321-0b90-4d07-917c-da5d002f11ba_2"]: encodedRecMessage,
        [RecCreateTempName+"_Chat_Main-ff165321-0b90-4d07-917c-da5d002f11ba_4"]: MobileNumber,
      };
      tempJson[flowName] = jsonData;
      const dataForum = new FormData();
      dataForum.append('Data', JSON.stringify(tempJson));
      let res = await axios({
        method: "post",
        url: caServerIP + "bridgeWhisperSending",
        data: dataForum,
        headers: { "Content-Type": "multipart/form-data" },
      })
        .then(function (response) {
          console.log(response)
          if(!short.DeleteforumID){
            setTimeout(() => {
              onRemove()
            }, 1000);
          }        
          return response.data;
        })
      return res;
    } catch (err) {
      return;
    }
  }

  const handleCancel = () => {
    setInputValue('');
    setPopupVisible(false);
  };

  const toPopup = () => {
    setPopupVisible(!isPopupVisible);
  };

  const toggleVideoPlayback = () => {
    if (!isPlaying) {
      videoRef.current.play();
      setIsPlaying(true);
    } else {
      videoRef.current.pause();
      setIsPlaying(false);
    }
  };

  const toggleVideoMute = () => {
    videoRef.current.muted = !isMuted;
    setIsMuted(!isMuted);
  };

  const recruitButtonClicked = () => {
    let ForumID = short.ForumID;
    if(ForumID == "f7783ed-b88c-47bc-84f3-f07cb0f5c47a"){
      toast.error((objectNullandUndefined('265')));
    }else{
    const storedRecruitmentText = localStorage.getItem('recruitmentText');
    if (storedRecruitmentText) {
      setInputValue(storedRecruitmentText);
    }
    videoRef.current.pause();
    setIsPlaying(false);
    toPopup();
    const video = document.getElementById('backgroundVideo');
    if (video) {
      video.pause();
    }
  }
  };
  const RecruitedBridgesending = async () => {
    try {
      let uuid = myUuid;
      const data = localStorage.getItem("userProfile");
      let decoded = base64_decode(data);
      const userDetails = JSON.parse(decoded);
      let sessionID = userDetails.SessionID;
      let macAddress = userDetails.MACAddress;
      let flowName = RecruitedCandidateTempFlow;
      let fID = RecruitedCandidateTempFlowID;
      let tempJson = {
        ForumID: short.ForumID,
        SessionID: sessionID,
        MACAddress: macAddress,
        Time: '6516516156161',
        ScheduledDateTime: 'now',
        ScheduledBoolean: 0,
        FlowID: flowName,
        EnableChat: 0,
        FlowType: 'Custom',
        BridgeForward: 0,
        TemplateID: RecruitedCandidateTemp,
        TextCount: 6,
        ImageCount: 0,
        InvoiceID: '',
        DocumentCount: 0,
        User: false,
        VideoCount: 0,
        ServerID: FSServerID,
        ReplyBridgeID: '',
        HiddenFlow: false,
        BilledForum: '',
        TempBridgeId: uuid,
        FID: fID,
        SentTo: '0',
      };
      let profiel = short.Jd;
      let profile1 = JSON.parse(profiel);
      let profile2 = profile1.MediaID;
      let data1 = {
        "CompanyName": short.companyName,
        "MediaID": short.mediaID,
        "Profilepic": profile2 ?? "", //incase if the pic is not sent setting it to ""
        "ForumID": short.ForumID,
        "mobileNumber": short.MobileNumber,
        "FullName":short.FullName
      }
      let info = data1;
      let jsonData = {
        [RecCreateTempName+"_RecruitedCandidate_Main-345bd685-8f77-47c4-ac6c-824a6b3cb791_2"]: base64_encode(short.ForumID),
        [RecCreateTempName+"_RecruitedCandidate_Main-345bd685-8f77-47c4-ac6c-824a6b3cb791_4"]: base64_encode(JSON.stringify(info)),
      };
      tempJson[flowName] = jsonData;
      const dataForum = new FormData();
      dataForum.append('Data', JSON.stringify(tempJson));
      let bridgeData = await bridgeSendingByUser(dataForum);
      if (bridgeData["ErrorCode"] === 1074) {
        toast.error((objectNullandUndefined('221')));
        setTimeout(() => {
          localStorage.clear();
          sessionStorage.clear();
          history.push('/');
          window.location.reload(false);
        }, 3000)
      }
    } catch (err) {
      return;
    }
  };
  const bridgeSending1 = async (forumId) => {
    let ForumId = forumId;
    try {
      let uuid = myUuid;
      const data = localStorage.getItem("userProfile");
      const forumname= localStorage.getItem("ForumnameforArchive");
      let decoded = base64_decode(data);
      const userDetails = JSON.parse(decoded);
      let sessionID = userDetails.SessionID;
      let macAddress = userDetails.MACAddress;
      let flowName = ArchiveTempFlow;
      let fID = ArchiveTempFlowID;
      let tempJson = {
        ForumID: forumId,
        SessionID: sessionID,
        MACAddress: macAddress,
        Time: '6516516156161',
        ScheduledDateTime: 'now',
        ScheduledBoolean: 0,
        FlowID: flowName,
        EnableChat: 0,
        FlowType: 'Custom',
        BridgeForward: 0,
        TemplateID: ArchiveTemp,
        TextCount: 6,
        ImageCount: 0,
        InvoiceID: '',
        DocumentCount: 0,
        User: false,
        VideoCount: 0,
        ServerID: FSServerID,
        ReplyBridgeID: '',
        HiddenFlow: false,
        BilledForum: '',
        TempBridgeId: uuid,
        FID: fID,
        SentTo: '0',
      };
      let profiel = short.Jd;
      let profile1 = JSON.parse(profiel);
      let profile2 = profile1.MediaID;
      let IdData = {}
      IdData["ForumID"] = short.ForumID;
      const utf8EncodedName = utf8.encode(forumname);
      IdData["ForumName"] = utf8EncodedName;
      let data1 = {
        "CompanyName": short.companyName,
        "MediaID": short.mediaID,
        "Profilepic": profile2, //They are not sending user profile picture media Id is not sent 
        "ForumID": short.ForumID,
        "mobileNumber": short.MobileNumber,
        "forumData":IdData,
        "FullName":short.FullName
      }
      let info = data1;
      let jsonData = {
        "ArchiveTemp_ArchiveVideo_Main-839837f8-217b-45a4-9f47-3e4fe8975a98_2": base64_encode(short.ForumID),
        "ArchiveTemp_ArchiveVideo_Main-839837f8-217b-45a4-9f47-3e4fe8975a98_4": base64_encode(JSON.stringify(info)),
      };
      tempJson[flowName] = jsonData;
      const dataForum = new FormData();
      dataForum.append('Data', JSON.stringify(tempJson));
      let bridgeData = await bridgeSendingByUser(dataForum);
      toast(objectNullandUndefined('121'));
      videoRef.current.pause();
      setIsPlaying(false);
      setVideoUrl(null);
      setTimeout(() => {
        onRemove()
      }, 1000);
    } catch (err) {
      return;
    }
  };
  const archiveButtonClicked = async () => {
    let ForumID = short.ForumID;
    if(ForumID == "f7783ed-b88c-47bc-84f3-f07cb0f5c47a"){
      toast.error((objectNullandUndefined('266')));
    }else{
    const jsonData = localStorage.getItem('forumdata');
    const userData = JSON.parse(jsonData);
    fetchForumInfo();
    const key = localStorage.getItem('Olddata');
    const isTrue = JSON.parse(key);
    // console.log(isTrue);
    if (isTrue == null) {
      const jsonData = localStorage.getItem('userProfile');
      let decoded = base64_decode(jsonData);
      const userData = JSON.parse(decoded);
      let users = []
      users.push(userData["MobileNumber"])
      let tempJsonData = {
        "SessionID": userData["SessionID"],
        "MACAddress": userData["MACAddress"],
        "AddUsers": users,
        "CloneTemplateFromForum": false,
        "UPI": "UPI",
        "TemplateName": ArchiveTempName,
        "ForumName": 'Archive492000',
        "TemplateID": ArchiveTemp,
        "ForumDomain": 'others',
        "ChatForum": false,
        "Owner": userData["UserName"],
        "ForumDescription": 'ForumDescription',
        "Time": "1643620059",
        "ForumType": 'Public',
        'ServerID': FSServerID,
      }
      const dataForum = new FormData();
      dataForum.append('Data', JSON.stringify(tempJsonData))
      axios({
        method: 'post',
        url: forumServerIP + 'newForum',
        data: dataForum,
        headers: { 'Content-Type': 'multipart/form-data' },
      })
        .then(function (response) {
        })
        .catch(function (response) {
          // console.log(response);
        });
    }
    await bridgeSending1(userData.ForumID);
    const data = localStorage.getItem("userProfile");
    let decoded = base64_decode(data);
    const userDetails = JSON.parse(decoded);
    let sessionID = userDetails.SessionID;
    let macAddress = userDetails.MACAddress;
    let flowId = RecCreateTempName+"_User_f945924a-31d1-cc25-c626-f493aaa9d04c_User"
    let JsonObject = {
      "ForumID": short.ForumID,
      "BridgeID": short.jType,
      "FlowID": flowId,
      "MACAddress": macAddress,
      "SessionID": sessionID,
      "ServerID": FSServerID,
    }
    let deletebridge = await DeleteBridge(JsonObject);
  }
  };

  const handleInputChange = (e) => {
    setInputValue(e.target.value);
  };

  const onError = (e) => {
    console.log("Error.....", e)
  }
  if(short.DeleteforumID){
    return (
      <><div className='reel5'>
        <div className="reel">
          <div className="video">
            <video
              ref={videoRef}
              onClick={toggleVideoPlayback}
              disableRemotePlayback
              playsInline
              loop
              onError={onError}
              src={videoUrl ? videoUrl : null}
              height="100%"
              width="100%"
            ></video>
            <h5 className="text-overlay12">{firstname + username}</h5>
            <div className="controls">
              <span onClick={toggleVideoPlayback}>
                <ion-icon name={`${isPlaying ? 'pause' : 'play'}-outline`}></ion-icon>
              </span>
              <span onClick={toggleVideoMute}>
                <ion-icon name={`volume-${isMuted ? 'mute' : 'medium'}-outline`}></ion-icon>
              </span>
            </div>
            <div
              ref={playPauseRef}
              onClick={toggleVideoPlayback}
              className={`play-pause ${isPlaying ? '' : 'show-play-pause'}`}
            >
              <ion-icon name="play-outline"></ion-icon>
            </div>
            <div className="foot">
              <div className="title">{short.title}</div>

              <IconButton
                style={{ color: '#ffffff', marginLeft: '85%', marginBottom: '5%' }}
                onClick={reporting}
              >
                <FlagIcon style={{ fontSize: '32px' }} />
              </IconButton>

              {/* Video Reporting modal */}

              <Dialog open={reportContent} onClose={reporting} >
                <DialogTitle style={{ color: '#DC3545', fontWeight: 'bold' }}>{objectNullandUndefined('134')}</DialogTitle>
                <DialogContent>
                  <FormControl component="fieldset">
                    <RadioGroup
                      value={selectedOption}
                      onChange={(e) => handleOptionChange(e.target.value)}
                    >
                      {options.map((option, index) => (
                        <FormControlLabel
                          key={index}
                          value={option}
                          control={<Radio />}
                          label={option} />
                      ))}
                    </RadioGroup>
                  </FormControl>
                </DialogContent>
                <DialogActions style={{ bottom: 0, right: 0, marginBottom: '20px' }}>
                  <Button onClick={handleCancelRep} color="primary" >
                    {objectNullandUndefined('16')}
                  </Button>
                  <Button onClick={handleSendRep} color="primary">
                    {objectNullandUndefined('9')}
                  </Button>
                </DialogActions>
              </Dialog>

              <div className="rec-info3">
                <img src={picUrl} onError={(e) => {
                  e.target.src = defaultPhoto;
                }} />
                <span>{short.companyName}</span>
              </div>
              <div className="rec-info2">
              <button className="button3" onClick={recruitButtonClicked}>
              {objectNullandUndefined('229')}
                    </button>
                <Dialog open={isPopupVisible} onClose={toPopup} >
                  <DialogTitle>{objectNullandUndefined('144')}</DialogTitle>
                  <DialogContent>
                    <TextField
                      value={inputValue}
                      onChange={handleInputChange}
                      className="input-text"
                      placeholder={objectNullandUndefined('46')}
                      sx={{ width: '100%' }}
                      multiline
                      rows={3} />
                    <h5 style={{ color: 'black', fontSize: '100%', marginTop: '10px' }}>
                      {objectNullandUndefined('130')}
                    </h5>
                    <span
                      style={{
                        position: 'relative',
                        color: 'blue',
                        fontStyle: 'italic',
                        fontSize: '80%',
                        marginBottom: '0%',
                      }}
                    >
                      {objectNullandUndefined('131')}
                    </span>

                    <div style={{ display: 'flex', justifyContent: 'center', marginTop: '15px' }}>
                      <a href={redirectUrl} target="_blank">
                        <img
                          src={require('./Calendly.png')}
                          alt="Clickable Image"
                          onClick={handleDate}
                          style={{ height: '-28px', width: '120px', marginRight: '120px' }} />
                      </a>
                      <a href={testUrl} target="_blank">
                        <img
                          src={require('./TestGorilla.png')}
                          alt="Clickable Image"
                          onClick={handleTest}
                          style={{ height: '-28px', width: '135px', marginTop: '8%' }} />
                      </a>
                    </div>
                    <span style={{ position: 'relative', color: 'blue', fontStyle: 'italic', fontSize: '80%', marginBottom: '0%' }}>{objectNullandUndefined('132')}</span>
                  </DialogContent>

                  <DialogActions style={{ justifyContent: 'center', marginBottom: '20px' }}>
                    <button
                      onClick={handleCancel}
                      style={{
                        backgroundColor: 'rgb(234,67,53)',
                        borderRadius: '7px',
                        borderColor: 'rgb(234,67,53)',
                        padding: '0.5rem',
                        marginRight: '10px',
                        color: 'black',
                      }}
                    >
                      {objectNullandUndefined('16')}
                    </button>
                    <button
                      onClick={handleSubmit}
                      disabled={isLoadingApply}
                      style={{
                        backgroundColor: 'rgb(234,67,53)',
                        borderRadius: '7px',
                        borderColor: 'rgb(234,67,53)',
                        padding: '0.5rem',
                        marginRight: '10px',
                        color: 'black',
                      }}
                    >
                        {isLoadingApply && objectNullandUndefined('99')}
                        {!isLoadingApply && objectNullandUndefined('9')}
                    </button>
                  </DialogActions>
                </Dialog>
              </div>
            </div>
          </div>

          {/* <ToastContainer position="top-center" hideProgressBar /> */}
        </div>
      </div></>
    );
  }else{
    return (
      <><div className='reel5'>
        <div className="reel">
          <div className="video">
            <video
              ref={videoRef}
              onClick={toggleVideoPlayback}
              disableRemotePlayback
              playsInline
              loop
              onError={onError}
              src={videoUrl ? videoUrl : null}
              height="100%"
              width="100%"
            ></video>
            <h5 className="text-overlay12">{firstname + username}</h5>
            <div className="controls">
              <span onClick={toggleVideoPlayback}>
                <ion-icon name={`${isPlaying ? 'pause' : 'play'}-outline`}></ion-icon>
              </span>
              <span onClick={toggleVideoMute}>
                <ion-icon name={`volume-${isMuted ? 'mute' : 'medium'}-outline`}></ion-icon>
              </span>
            </div>
            <div
              ref={playPauseRef}
              onClick={toggleVideoPlayback}
              className={`play-pause ${isPlaying ? '' : 'show-play-pause'}`}
            >
              <ion-icon name="play-outline"></ion-icon>
            </div>
            <div className="foot">
              <div className="title">{short.title}</div>

              <IconButton
                style={{ color: '#ffffff', marginLeft: '85%', marginBottom: '5%' }}
                onClick={reporting}
              >
                <FlagIcon style={{ fontSize: '32px' }} />
              </IconButton>

              {/* Video Reporting modal */}

              <Dialog open={reportContent} onClose={reporting} >
                <DialogTitle style={{ color: '#DC3545', fontWeight: 'bold' }}>{objectNullandUndefined('134')}</DialogTitle>
                <DialogContent>
                  <FormControl component="fieldset">
                    <RadioGroup
                      value={selectedOption}
                      onChange={(e) => handleOptionChange(e.target.value)}
                    >
                      {options.map((option, index) => (
                        <FormControlLabel
                          key={index}
                          value={option}
                          control={<Radio />}
                          label={option} />
                      ))}
                    </RadioGroup>
                  </FormControl>
                </DialogContent>
                <DialogActions style={{ bottom: 0, right: 0, marginBottom: '20px' }}>
                  <Button onClick={handleCancelRep} color="primary" >
                    {objectNullandUndefined('16')}
                  </Button>
                  <Button onClick={handleSendRep} color="primary">
                    {objectNullandUndefined('9')}
                  </Button>
                </DialogActions>
              </Dialog>

              <div className="rec-info3">
                <img src={picUrl} onError={(e) => {
                  e.target.src = defaultPhoto;
                }} />
                <span style={{paddingLeft:'10px'}}>{short.companyName}</span>
              </div>
              <div style={{color:'white'}}>
                <span>{objectNullandUndefined('290')+(short.AppliedTime ?? ' Not Specified')}</span>
                <br></br>
                <span>{objectNullandUndefined('295')+ " : "+(short.FullName ?? " Jack Doe")}</span>
              </div>
              <div className="rec-info2">

                    <button className="button1" onClick={recruitButtonClicked}>
                      {objectNullandUndefined('45')} 
                    </button>
                    <button className="button2" onClick={archiveButtonClicked}>
                      {objectNullandUndefined('51')}
                    </button>


                {/* Recruitment message sending dialog box */}

                <Dialog open={isPopupVisible} onClose={toPopup} >
                  <DialogTitle>{objectNullandUndefined('144')}</DialogTitle>
                  <DialogContent>
                    <TextField
                      value={inputValue}
                      onChange={handleInputChange}
                      className="input-text"
                      placeholder={objectNullandUndefined('46')}
                      sx={{ width: '100%' }}
                      multiline
                      rows={3} />
                    <h5 style={{ color: 'black', fontSize: '100%', marginTop: '10px' }}>
                      {objectNullandUndefined('130')}
                    </h5>
                    <span
                      style={{
                        position: 'relative',
                        color: 'blue',
                        fontStyle: 'italic',
                        fontSize: '80%',
                        marginBottom: '0%',
                      }}
                    >
                      {objectNullandUndefined('131')}
                    </span>

                    <div style={{ display: 'flex', justifyContent: 'center', marginTop: '15px' }}>
                      <a href={redirectUrl} target="_blank">
                        <img
                          src={require('./Calendly.png')}
                          alt="Clickable Image"
                          onClick={handleDate}
                          style={{ height: '-28px', width: '120px', marginRight: '120px' }} />
                      </a>
                      <a href={testUrl} target="_blank">
                        <img
                          src={require('./TestGorilla.png')}
                          alt="Clickable Image"
                          onClick={handleTest}
                          style={{ height: '-28px', width: '135px', marginTop: '8%' }} />
                      </a>
                    </div>
                    <span style={{ position: 'relative', color: 'blue', fontStyle: 'italic', fontSize: '80%', marginBottom: '0%' }}>{objectNullandUndefined('132')}</span>
                  </DialogContent>

                  <DialogActions style={{ justifyContent: 'center', marginBottom: '20px' }}>
                    <button
                      onClick={handleCancel}
                      style={{
                        backgroundColor: 'rgb(234,67,53)',
                        borderRadius: '7px',
                        borderColor: 'rgb(234,67,53)',
                        padding: '0.5rem',
                        marginRight: '10px',
                        color: 'black',
                      }}
                    >
                      {objectNullandUndefined('16')}
                    </button>
                    <button
                      onClick={handleSubmit}
                      disabled={isLoadingApply}
                      style={{
                        backgroundColor: 'rgb(234,67,53)',
                        borderRadius: '7px',
                        borderColor: 'rgb(234,67,53)',
                        padding: '0.5rem',
                        marginRight: '10px',
                        color: 'black',
                      }}
                    >
                          {isLoadingApply && objectNullandUndefined('99')}
                          {!isLoadingApply && objectNullandUndefined('9')}
                      {/* {objectNullandUndefined('9')} */}
                    </button>
                  </DialogActions>
                </Dialog>
              </div>
            </div>
          </div>

          {/* <ToastContainer position="top-center" hideProgressBar /> */}
        </div>
      </div></>
    );

  }

}
function isActiveVideo(videoRef) {
  const videoTop = videoRef.current.getBoundingClientRect().top;
  return videoTop > 0 && videoTop <= 150;
}

export default RecruiterShort;