import "./Short.css";
import "./Hook.css";
import { useEffect, useRef, useState } from "react";
import { CircularProgress } from '@mui/material';
import User from "./User/User";
import { decode as base64_decode, encode as base64_encode } from 'base-64';
import { useHistory } from "react-router-dom";
import defaultPhoto from '../assets/img/default.jpeg'
import { downloadProfilePhoto, bridgeSendingByUser, downloadMedia, GenerateApi, forumDetails } from '../services/LoginLogoutService';
import { objectNullandUndefined } from "./Dictionary/Dictionary";
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Hook from "./Hook";
import ShareIcon from '@mui/icons-material/Share';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Button,
  FormControl,
  RadioGroup,
  FormControlLabel,
  Radio,
  DialogActions
} from '@mui/material';
import Dropdown from './User/Filter';
import { getLasTfiftydata } from "../services/LoginLogoutService";

import IconButton from '@mui/material/IconButton';
import uuid from 'react-uuid';
import { CAServerID, CommonForumID, FSServerID, LLServerID, MacAddress, ReportContent, ReportContentID } from "./Dictionary/CommonStrings";
import FlagIcon from '@mui/icons-material/Flag';
function Short({ short, shortContainerRef }) {
  const history = useHistory();
  const playPauseRef = useRef();
  const videoRef = useRef();
  const [isPlaying, setIsPlaying] = useState(false);
  const [isMuted, setIsMuted] = useState(false);
  const [videoUrl, setVideoUrl] = useState(null)
  const [name, setName] = useState('')
  const [city, setCity] = useState('')
  const [endDate, setEndDate] = useState('');
  const [picUrl, setPicUrl] = useState('');
  const [reportContent, setReportContent] = useState(false);
  const [selectedOption, setSelectedOption] = useState('');
  const [username, setUserName] = useState('');
  const myUuid = uuid();

  const [firstname, setFirstName] = useState('');
  const [isHovered, setIsHovered] = useState(false);
  const [isLoading, setIsLoading] = useState(false);


  const options1 = [
    { id: "Sales Representative", label: objectNullandUndefined('30') },
    { id: "Operations Manager", label: objectNullandUndefined('31') },
    { id: "Nurse", label: objectNullandUndefined('32') },
    { id: "Physical Therapist", label: objectNullandUndefined('33') },
    { id: "Delivery Partner", label: objectNullandUndefined('37') },
    { id: "Supply Chain Manager", label: objectNullandUndefined('38') },
    { id: "Domestic worker", label: objectNullandUndefined('40') },
    { id: "Chef", label: objectNullandUndefined('41') },
    { id: "Construction worker", label: objectNullandUndefined('42') },
    { id: "Agriculture worker", label: objectNullandUndefined('43') },
    { id: "Garment worker", label: objectNullandUndefined('44') },
    { id: "Testing Forum", label: "Testing Forum" }
  ]

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  useEffect(() => {
    const jsonData = localStorage.getItem('userProfile');
    let decoded = base64_decode(jsonData);
    const userData = JSON.parse(decoded);
    setFirstName(userData.FirstName + userData.LastName);
    setUserName(userData.UserName);
    const functio = () => {
      const companyNameString = short.companyName;
      const isJsonObject = companyNameString.trim().startsWith('{') && companyNameString.trim().endsWith('}');

      if (isJsonObject) {
        try {
          const companyNameObject = JSON.parse(short.companyName);
          const companyName = companyNameObject.CompanyName;
          let enddate = "Not Specified"
          const city = companyNameObject.City
          let Time = objectNullandUndefined('291')
          try{
              enddate = JSON.parse(short.Duration);
              const date = new Date(enddate * 1000);
              const options = {
                  timeZone: 'Asia/Kolkata',
                  hour: '2-digit',
                  minute: '2-digit',
                  year: 'numeric',
                  month: 'short',
                  day: 'numeric',
              };
              Time = date.toLocaleString('en-IN', options);
          }catch (error) {
              console.log("Duration Not Specified in shareLink")
          }
          setCity(city)
          setEndDate(Time)
          setName(companyName);
        } catch (error) {
          console.error('Error parsing JSON:', error);
        }
      } else {
        setName(short.companyName);
      }

    };
    functio();
  }, []);

  const options = [
    objectNullandUndefined('169'),
    objectNullandUndefined('170'),
    objectNullandUndefined('171'),
    objectNullandUndefined('172'),
    objectNullandUndefined('173'),
  ];

  const handleOptionChange = (option) => {
    setSelectedOption(option);
  };

//   const ToApply = async () => {
//     setIsLoading(true);
//     const forumId = short.ForumID;
//     if(forumId == "1f7783ed-b88c-47bc-84f3-f07cb0f5c47a"){
//       setIsLoading(false);
//       toast.error(objectNullandUndefined('264'))
//     }else{
//       localStorage.setItem("candidateSelectedToApplyForumID",forumId)
//       localStorage.setItem("candidateSelectedToApplyForumName",name)
//       let data = await CheckIfForumDeletedOrNot(forumId);
//       if(data == true){
//         setIsLoading(false);
//         toast.error(objectNullandUndefined('271'))
//       }else{
//         let matchedForum = false;
//         let fiftybridge = await getLasTfiftydata(forumId)
//         if (fiftybridge["ErrorCode"] === 1074) {
//           toast.error((objectNullandUndefined('221')));
//           setTimeout(() => {
//             localStorage.clear();
//             sessionStorage.clear();
//             history.push('/');
//             window.location.reload(false);
//           }, 3000)
//         }
//         let count = fiftybridge["ErrorMessage"]["UserDataCount"];
//         let item = localStorage.getItem('userData');
//         if(item == null){
//           toast(objectNullandUndefined('268'), {
//             autoClose: 20000,
//           });
//           setTimeout(() => {
//             let item = localStorage.getItem('userData');
//             let parsedData = JSON.parse(item)
//             for (let i = 0; i < parsedData.length; i++) {
//               const currentForum = parsedData[i];
//               let ApplyForumID = currentForum["ForumID"]
//               if (ApplyForumID === short.ForumID) {
//                 matchedForum = true
//                 break;
//               }
//             }
//             if (matchedForum) {
//               toast(objectNullandUndefined('54'))
//               setIsLoading(false);
//             }
//             else if (count > 0) {
//               toast(objectNullandUndefined('54'))
//               setIsLoading(false);
//             } else {
//               setIsLoading(false);
//               history.push({
//                 pathname: "/User",
//                 state: {
//                   forumId: forumId,
//                   forumName: name
//                 }
//               });
//             }
//           }, 10000)
//         }else{
//           let parsedData = JSON.parse(item)
//           for (let i = 0; i < parsedData.length; i++) {
//             const currentForum = parsedData[i];
//             let ApplyForumID = currentForum["ForumID"]
//             if (ApplyForumID === short.ForumID) {
//               matchedForum = true
//               break;
//             }
//           }
//           if (matchedForum) {
//             toast(objectNullandUndefined('54'))
//             setIsLoading(false);
//           }
//           else if (count > 0) {
//             toast(objectNullandUndefined('54'))
//             setIsLoading(false);
//           } else {
//             setIsLoading(false);
//             history.push({
//               pathname: "/User",
//               state: {
//                 forumId: forumId,
//                 forumName: name
//               }
//             });
//           }
//         }
//       }
//     }
//   }

//   const CheckIfForumDeletedOrNot = async(id) =>{
//     let userDetails = localStorage.getItem('userProfile');
//       userDetails = base64_decode(userDetails);
//       userDetails = JSON.parse(userDetails);
//       let sessionID = userDetails['SessionID'];
//       let macAddress = userDetails['MACAddress']
//     let tempJson = {
//         "MACAddress":macAddress,
//         "ForumID": id,
//         "SessionID": sessionID,
//         "ServerID": CAServerID
//     }
//     let response = await forumDetails(tempJson)
//     if(response['ErrorCode'] == 1130){
//         return true;
//     }
//     return false;
// }


const ToApply = async () => {
  setIsLoading(true);
  const forumId = short.ForumID;
  if(forumId == "1f7783ed-b88c-47bc-84f3-f07cb0f5c47a"){
    setIsLoading(false);
    toast.error(objectNullandUndefined('264'))
  }else{
    localStorage.setItem("candidateSelectedToApplyForumID",forumId)
    localStorage.setItem("candidateSelectedToApplyForumName",name)
    let data = await CheckIfForumDeletedOrNot(forumId);
    if (data === "deleted"){
      setIsLoading(false);
      toast.error(objectNullandUndefined('271'))
    }else if(data === "YouAreTheCreator"){
      setIsLoading(false);
      toast.error(objectNullandUndefined('285'))
    }
    else if (data === "AlreadyPartOfForum"){
      setIsLoading(false);
      toast.error(objectNullandUndefined('54'))
    }else if (data === "NotPartofForum"){
      setIsLoading(false);
      history.push({
        pathname: "/User",
        state: {
          forumId: forumId,
          forumName: name
        }
      });
    }else if (data === "SomethingWentWrong"){
      setIsLoading(false);
      toast.error(objectNullandUndefined('56'))
    }
  }
}

const CheckIfForumDeletedOrNot = async(id) =>{
  let userDetails = localStorage.getItem('userProfile');
    userDetails = base64_decode(userDetails);
    userDetails = JSON.parse(userDetails);
    let sessionID = userDetails['SessionID'];
    let macAddress = userDetails['MACAddress']
  let tempJson = {
      "MACAddress":macAddress,
      "ForumID": id,
      "SessionID": sessionID,
      "ServerID": CAServerID
  }
  let response = await forumDetails(tempJson)
  if(response['ErrorCode'] == 1130){
    return "deleted";
  }else if(response['ErrorCode'] == 1172){
      if(userDetails['MobileNumber'] === response['ErrorMessage']['ForumDataArray'][0][id]['ForumDetails']['ForumCreator']){
        return "YouAreTheCreator";
      }
      return "AlreadyPartOfForum";
  }else if(response['ErrorCode'] == 1190){
      return "NotPartofForum";
  }else if(response['ErrorCode'] == 1074){
    toast.error((objectNullandUndefined('221')));
    setTimeout(() => {
      localStorage.clear();
      sessionStorage.clear();
      history.push('/');
      window.location.reload(false);
    }, 3000)
    return "Logout";
  }
  return "SomethingWentWrong";
}

  const handleSendRep = () => {
    if (selectedOption === null || selectedOption === '') {
      toast.error(objectNullandUndefined('159'));
      return;
    }
    reportSending()
    setReportContent(!reportContent)
    setSelectedOption('')
    toast.warning(objectNullandUndefined('158'))
  }

  const reporting = () => {
    videoRef.current.pause();
    setReportContent(!reportContent)
    setSelectedOption('')
  }

  const handleCancelRep = () => {
    videoRef.current.play();
    setReportContent(!reportContent)
    setSelectedOption('')
  }

  async function reportSending() {
    let commonForumTemp = localStorage.getItem("commonUserFlows")
    let parsedFlow = JSON.parse(commonForumTemp)
    let parsedCommonFlow = parsedFlow["UserFlow"]
    let commonTempName = parsedFlow["Template"]
    let reportFlowID = parsedCommonFlow[commonTempName + '_ReportContent']
    try {
      let uuid = myUuid
      let userDetails = localStorage.getItem('userProfile');
      userDetails = base64_decode(userDetails);
      userDetails = JSON.parse(userDetails);
      let sessionID = userDetails['SessionID'];
      let macAddress = userDetails['MACAddress']
      let flowName = commonTempName + '_ReportContent';
      let fID = reportFlowID["FID"]
      let tempJson = {
        ForumID: CommonForumID,
        SessionID: sessionID,
        MACAddress: macAddress,
        Time: '6516516156161',
        ScheduledDateTime: 'now',
        ScheduledBoolean: 0,
        FlowID: flowName,
        EnableChat: 0,
        FlowType: 'Custom',
        BridgeForward: 0,
        TemplateID: parsedFlow["TemplateID"],
        TextCount: 6,
        ImageCount: 0,
        InvoiceID: '',
        DocumentCount: 0,
        User: true,
        VideoCount: 0,
        ServerID: FSServerID,
        ReplyBridgeID: '',
        HiddenFlow: false,
        BilledForum: '',
        TempBridgeId: uuid,
        FID: fID,
        SentTo: '0',
      };

      let reportData = {
        "CompanyName": short.companyName,
        "MediaID": short.mediaID,
        "jobType": short.jType,
        "ForumID": short.ForumID,
        "BridgeID": short.BridgeID
      }
      let createReportFlow = flowName + '_Main-f64c104d-64cb-428f-a6d3-474c3628691f'
      let jsonData = {}
      jsonData[createReportFlow + '_2'] = base64_encode(JSON.stringify(selectedOption));
      jsonData[createReportFlow + '_4'] = base64_encode(JSON.stringify(reportData));
      tempJson[flowName] = jsonData;
      const data = new FormData();
      data.append('Data', JSON.stringify(tempJson));
      let bridgeData = await bridgeSendingByUser(data);
      if (bridgeData["ErrorCode"] === 1074) {
        toast.error((objectNullandUndefined('221')));
        setTimeout(() => {
          localStorage.clear();
          sessionStorage.clear();
          history.push('/');
          window.location.reload(false);
        }, 3000)
      }
    } catch (err) {
      return;
    }
  }

  useEffect(() => {
    shortContainerRef.current.addEventListener("scroll", handleVideo);
    downloadVideo()
    setIsPlaying(!videoRef.current.paused);
    setIsMuted(videoRef.current.muted);
    window.addEventListener("blur", () => {
      if (isActiveVideo(videoRef)) {
        videoRef.current.pause();
        setIsPlaying(false);
      }
    });

    window.addEventListener("focus", () => {
      if (isActiveVideo(videoRef)) {
        videoRef.current.play();
        setIsPlaying(true);
      }
    });
    toast.dismiss()
  }, [shortContainerRef]);
  async function handleVideo() {
    const videoTop = videoRef.current.getBoundingClientRect().top;
    if (videoTop > 0 && videoTop <= 150) {
      try {
        await videoRef.current.play();
        setIsPlaying(true);
      } catch (error) {
        setIsPlaying(false);
        videoRef.current.pause();
      }
    } else {
      videoRef.current.currentTime = 0;
      videoRef.current.pause();
    }
    localStorage.setItem("LastePlayedMID", short.mediaID);
  }
  async function downloadVideo() {
    let userDetails = localStorage.getItem('userProfile');
    userDetails = JSON.parse(base64_decode(userDetails));
    let jsonData = {
      "SessionID": userDetails['SessionID'],
      "MACAddress": userDetails['MACAddress'],
      "MediaID": short.mediaID,
      "ServerID": FSServerID
    }
    const resValue = await downloadMedia(jsonData);
    let ObjectUrl = window.URL.createObjectURL(new Blob([resValue]));
    setVideoUrl(ObjectUrl)
  }
  const toggleVideoPlayback = () => {
    if (!isPlaying) {
      videoRef.current.play();
      setIsPlaying(true);
    } else {
      videoRef.current.pause();
      setIsPlaying(false);
    }
  };
  const generatLink = async () => {
    let ForumID = short.ForumID
    if(ForumID == "1f7783ed-b88c-47bc-84f3-f07cb0f5c47a"){
      toast.error(objectNullandUndefined('267'))
    }else{
      let companyName1 = short.companyName
      // try {
      //   let data = JSON.parse(companyName1);
      //   let companyName2 = data.CompanyName;
      //   let companyName = encodeURIComponent(companyName2);
      //   let mediaID = short.mediaID
      //   let decription1 = short.Jd
      //   let decription = encodeURIComponent(decription1);
      //   let city = data.city;
      //   // let baseUrl = new URL("http://localhost:3000");
      //   // let fallback_link = window.location.origin
      //   let fallback_link = "https://web.prescreener.in/"
      //   const shareLink = `${fallback_link}?FiD=${ForumID}&cN=${companyName}&dT=${decription}&cY=${city}&ViD=${mediaID}&/ShortContainer`;
      //   let firebaseLink = await GenerateApi(shareLink)
      //   let fireBaseLink = firebaseLink.shortLink
      //   await navigator.clipboard.writeText(fireBaseLink)
      //     .then(() => {
      //       toast.success(objectNullandUndefined('198'))
      //     })
      //     .catch(error => {
      //       console.error('Error copying to clipboard:', error);
      //       // Handle clipboard copy error here
      //     });
      //   } catch (error) {
      //     //this fix will work on all device but it will not show location.
      //     let ForumID = short.ForumID
      //     let companyName = short.companyName
      //     let decription = short.Jd
      //     let city = ''
      //     let mediaID = short.mediaID
      //     // let baseUrl = new URL("http://localhost:3000");
      //     // let fallback_link = window.location.origin
      //     let fallback_link = "https://web.prescreener.in/"
      //     const shareLink = `${fallback_link}?FiD=${ForumID}&cN=${companyName}&dT=${decription}&cY=${city}&ViD=${mediaID}&/ShortContainer`;
      //     let firebaseLink = await GenerateApi(shareLink)
      //     let fireBaseLink = firebaseLink.shortLink
      //     await navigator.clipboard.writeText(fireBaseLink)
      //     .then(() => {
      //       toast.success(objectNullandUndefined('198'))
      //     })
      //     .catch(error => {
      //       console.error('Error copying to clipboard:', error);
      //       // Handle clipboard copy error here
      //     });
      //   }
      
         //this will solve sharing the shared video.
      try {
        let data = JSON.parse(companyName1);
        let companyName2 = data.CompanyName;
        let companyName = encodeURIComponent(companyName2);
        let mediaID = short.mediaID
        let decription1 = short.Jd
        let decription = encodeURIComponent(decription1);
        let city = data.city;
        if(city == undefined || city == null){
          city = data.City;
        }
        // let baseUrl = new URL("http://localhost:3000");
        // let fallback_link = window.location.origin
        let fallback_link = "https://web.prescreener.in/"
        const shareLink = `${fallback_link}?FiD=${ForumID}&cN=${companyName}&dT=${decription}&cY=${city}&ViD=${mediaID}&/ShortContainer`;
        let firebaseLink = await GenerateApi(shareLink)
        let fireBaseLink = firebaseLink.shortLink
        await navigator.clipboard.writeText(fireBaseLink)
          .then(() => {
            toast.success(objectNullandUndefined('198'))
          })
          .catch(error => {
            console.error('Error copying to clipboard:', error);
            // Handle clipboard copy error here
          });
      } catch (error) {
        toast.info("error generating link for shared video")
      }
    }

    //this will solve sharing the shared video.
    // try {
    //   let data = JSON.parse(companyName1);
    //   let companyName2 = data.CompanyName;
    //   let companyName = encodeURIComponent(companyName2);
    //   let mediaID = short.mediaID
    //   let decription1 = short.Jd
    //   let decription = encodeURIComponent(decription1);
    //   let city = data.city;
    //   if(city == undefined || city == null){
    //     city = data.City;
    //   }
    //   // let baseUrl = new URL("http://localhost:3000");
    //   // let fallback_link = window.location.origin
    //   let fallback_link = "https://web.prescreener.in/"
    //   const shareLink = `${fallback_link}?FiD=${ForumID}&cN=${companyName}&dT=${decription}&cY=${city}&ViD=${mediaID}&/ShortContainer`;
    //   let firebaseLink = await GenerateApi(shareLink)
    //   let fireBaseLink = firebaseLink.shortLink
    //   await navigator.clipboard.writeText(fireBaseLink)
    //     .then(() => {
    //       toast.success(objectNullandUndefined('198'))
    //     })
    //     .catch(error => {
    //       console.error('Error copying to clipboard:', error);
    //       // Handle clipboard copy error here
    //     });
    // } catch (error) {
    //   toast.info("error generating link for shared video")
    // }
  };
  const toggleVideoMute = () => {
    videoRef.current.muted = !isMuted;
    setIsMuted(!isMuted);
  };
  useEffect(() => {
    async function DownloadProfilePicture() {
      const userDetails = localStorage.getItem('userProfile');
      let decoded = base64_decode(userDetails);
      let userDetail = JSON.parse(decoded);
      const companyNameObject = JSON.parse(short.companyName);
      const companyName = companyNameObject.MediaID;
      //document.getElementById('profileImage').src = defaultPhoto;
      let jsonData = {
        "SessionID": userDetail['SessionID'],
        "MACAddress": userDetail['MACAddress'],
        "MediaID": companyName,
        "ServerID": LLServerID
      }
      const value = await downloadProfilePhoto(jsonData)
      let ObjectUrl = window.URL.createObjectURL(new Blob([value]));
      setPicUrl(ObjectUrl);
    }
    DownloadProfilePicture();
  }, []);
  return (
    <>
      <div className='reel10'>
        <div className="reel">

          <div className="video">
            {/* <div className="video-con"> */}
            <video
              ref={videoRef}
              onClick={toggleVideoPlayback}
              disableRemotePlayback
              playsInline
              loop
              src={videoUrl}
              height="100%"
              width="100%"
            ></video>
            <h5 className="text-overlay12">{firstname + username}</h5>
            {/* </div> */}
            <div className="controls">
              <span onClick={toggleVideoPlayback}>
                <ion-icon name={`${isPlaying ? 'pause' : 'play'}-outline`}></ion-icon>
              </span>
              <span onClick={toggleVideoMute}>
                <ion-icon name={`volume-${isMuted ? 'mute' : 'medium'}-outline`}></ion-icon>
              </span>
            </div>
            <div
              ref={playPauseRef}
              onClick={toggleVideoPlayback}
              className={`play-pause ${isPlaying ? '' : 'show-play-pause'}`}
            >
              <ion-icon name="play-outline"></ion-icon>
            </div>
            <div className="foot">
              <IconButton
                style={{ color: '#ffffff', marginLeft: '85%', marginBottom: '5%' }}
                onClick={reporting}
              >
                <FlagIcon style={{ fontSize: '32px' }} />
              </IconButton>
              <IconButton
                style={{ color: '#ffffff', marginLeft: '85%', marginBottom: '5%' }}
                onClick={generatLink}
              >
                <ShareIcon style={{ fontSize: '32px' }} />
              </IconButton>
              <Dialog open={reportContent} onClose={reporting}>
                <DialogTitle style={{ color: '#DC3545', fontWeight: 'bold' }}>{objectNullandUndefined('134')}</DialogTitle>
                <DialogContent>
                  <FormControl component="fieldset">
                    <RadioGroup
                      value={selectedOption}
                      onChange={(e) => handleOptionChange(e.target.value)}
                    >
                      {options.map((option, index) => (
                        <FormControlLabel
                          key={index}
                          value={option}
                          control={<Radio />}
                          label={option} />
                      ))}
                    </RadioGroup>
                  </FormControl>
                </DialogContent>
                <DialogActions style={{ bottom: 0, right: 0, marginBottom: '20px' }}>
                  <Button onClick={handleCancelRep} color="primary">
                    {objectNullandUndefined('16')}
                  </Button>
                  <Button onClick={handleSendRep} color="primary">
                    {objectNullandUndefined('9')}
                  </Button>
                </DialogActions>
              </Dialog>
              <div className="user-info">
                <div>
                  <img src={picUrl} onError={(e) => {
                    e.target.src = defaultPhoto;
                  }} />
                </div>
                <button 
                  disabled={isLoading} 
                  className="apply" 
                  onClick={() => ToApply()}>
                    {isLoading ? (
                        // <span>Loading...</span> // Display a spinner or text while loading
                        <div className="loader-container">
                          <CircularProgress size={23} style={{ color: "white"}} />
                        </div>
                      ) : (
                        objectNullandUndefined('10')
                      )}
                  
                  </button>
              </div>
              <div className="text-container1">
                <div className={`text ${isHovered ? 'hovered' : ''}`} style={{ width: '60px', height: '30px' }}
                  onMouseEnter={handleMouseEnter}
                  onMouseLeave={handleMouseLeave}>{name}</div>
              </div>
              <div className="text-container" >
                <div className={`text ${isHovered ? 'hovered' : ''}`} style={{ width: '90px', height: '30px' }}
                  onMouseEnter={handleMouseEnter}
                  onMouseLeave={handleMouseLeave}>{short.Jd}</div>

                {isHovered && (
                  <div className="popup19">
                    {objectNullandUndefined('6')} : {name}
                    <br></br>
                    {objectNullandUndefined('8')} : {short.Jd}
                    <br></br>
                    {objectNullandUndefined('252')} : {city}
                    <br></br>
                    {objectNullandUndefined('289')} : {endDate}
                  </div>
                )}

              </div>
            </div>
          </div>
        </div>
        {/* <ToastContainer position="top-center" autoClose={2000} hideProgressBar /> */}
      </div></>
  );

  function isActiveVideo(videoRef) {
    try {
      const videoTop = videoRef.current.getBoundingClientRect().top;
      return videoTop > 0 && videoTop <= 150;
    } catch (e) {

    }

  }

}




export default Short;
