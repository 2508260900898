import { useState } from 'react';
import { useEffect } from 'react';
import axios from 'axios';
import { decode as base64_decode, encode as base64_encode } from 'base-64';
import { useHistory } from 'react-router-dom';
import RecruitContainer from '../Recruiter/RecruitContainer';
import { objectNullandUndefined } from '../Dictionary/Dictionary';
import { ArchiveForumReport, CommonForumID, CommonForumReport, DemoUser1MediaID, DemoUser2MediaID, DemoUser3MediaID, FSServerID, MacAddress, RecCreateTempName, forumServerIP } from '../Dictionary/CommonStrings';
import { toast, ToastContainer } from 'react-toastify';
import PullToRefresh from 'react-simple-pull-to-refresh';
import 'react-toastify/dist/ReactToastify.css';
import { RecruitedConsolidationReportGenration } from "../../services/LoginLogoutService";


export default function Video() {
  const [videoData, setVideoData] = useState();
  const [isRefreshing, setRefreshing] = useState(true);
  const [scroll, isScroll] = useState(false);
  // const [Filtered, showFiltered] = useState(true);
  let showFiltered = true
  const history = useHistory();

  const findIndexByMediaID = async(decodeReport, targetMediaID) => {
    if(decodeReport && targetMediaID != null){
      let jsonArray = decodeReport
      for (let i = 0; i < jsonArray.length; i++) {
        if (jsonArray[i].mediaID === targetMediaID) {
          return i; // Return the index of the matching object
        }
      }
    }
  return -1; // Return -1 if the targetMediaID is not found in the array
};
const toggle = () => {
  isScroll(!scroll);
} 
const loadOlderVideo = () => {
  setRefreshing(true);
  let isRecruitedVideos = false
  isRecruitedVideos = localStorage.getItem('RecruitedVideo')
  if(isRecruitedVideos == "true"){
    toast.success(objectNullandUndefined('230'));
  }else{
    const videodata = localStorage.getItem("UserVideoData");
    const ForumId = JSON.parse(videodata);
    // localStorage.removeItem(ForumId+"LastePlayedMID");
    localStorage.removeItem(ForumId+"viewedMediaIds");
    window.location.reload()
  }
  setRefreshing(false);
}

  useEffect(() => {
    let demo = localStorage.getItem('Demo', 'true');
    const videoArr = [];
   if(demo === "true"){
    const feedJson = {
      "companyName": "@DemoUser1",
      "Jd": "{\"MediaID\":\"656030ef2f6cad64bc6b57ef\",\"PaymentId\":\"NotPaid\"}",
      "jType": "8f035b28-f9e2-4034-959d-dc2886c4e262",
      "mediaID": DemoUser1MediaID,
      "ForumID": "f7783ed-b88c-47bc-84f3-f07cb0f5c47a",
      "MobileNumber": "27434312507796236942774766",
      "id": 0
    }
    let decodedreport = feedJson;
    videoArr.push(decodedreport)
    const feedJson1 = {
      "companyName": "@DemoUser2",
      "Jd": "{\"MediaID\":\"656030ef2f6cad64bc6b57ef\",\"PaymentId\":\"NotPaid\"}",
      "jType": "8f035b28-f9e2-4034-959d-dc2886c4e262",
      "mediaID": DemoUser2MediaID,
      "ForumID": "f7783ed-b88c-47bc-84f3-f07cb0f5c47a",
      "MobileNumber": "27434312507796236942774766",
      "id": 0
    }
    let decodedreport1 = feedJson1;
    videoArr.push(decodedreport1)
    const feedJson2 = {
      "companyName": "@DemoUser3",
      "Jd": "{\"MediaID\":\"656030ef2f6cad64bc6b57ef\",\"PaymentId\":\"NotPaid\"}",
      "jType": "8f035b28-f9e2-4034-959d-dc2886c4e262",
      "mediaID": DemoUser3MediaID,
      "ForumID": "f7783ed-b88c-47bc-84f3-f07cb0f5c47a",
      "MobileNumber": "27434312507796236942774766",
      "id": 0
    }
    let decodedreport2 = feedJson2;
    videoArr.push(decodedreport2)
    setVideoData(videoArr);
    localStorage.setItem('Demo', 'false');
   }else{
    localStorage.setItem('RecruitedVideo',false)
    showFiltered = true
    ConsolidatedReportGeneration();
   }
  
  }, []);

  const isPullable = () =>{
    setRefreshing(!isRefreshing)
  }

  return (
    <div>
      <main className="main">
        {videoData && (
           <PullToRefresh onRefresh={loadOlderVideo} isPullable={isRefreshing}>
          <RecruitContainer videos={videoData} isPullable={isPullable} ConsolidatedReportGeneration = {ConsolidatedReportGeneration} scroll={scroll} toggle={toggle}/>
          </PullToRefresh>
        )}
      </main>
      <ToastContainer position="top-center" hideProgressBar />
    </div>
  );

  async function ConsolidatedReportGeneration() {
    console.log("called")
    let mediaID1
    try{
      const videodata = localStorage.getItem("UserVideoData");
      const user = JSON.parse(videodata);
      let userData = JSON.parse(localStorage.getItem('userData'))
      for (let i = 0; i < userData.length; i++) {
        const countData = userData[i];
        let countForumID = countData["ForumID"]
        if(countForumID === user){
          countData["Counts"] = 0;
          countData["BridgeIDs"] = [];
          userData[i] = countData
        }
      }
    localStorage.setItem('userData',JSON.stringify(userData))
    }catch(err){

    }
    try {
      mediaID1 = await ArchiveConsolidatedReportGeneration();
    } catch (e) {
      mediaID1 = [];
    }
    const videodata = localStorage.getItem("UserVideoData");
    if(videodata != null){
      const user = JSON.parse(videodata);
      const data = localStorage.getItem("userProfile");
      let decoded = base64_decode(data);
      const usere = JSON.parse(decoded);
      const inputData = {};
      const outputDat = {};
      const temFIJson = {};
      const tempList = [];
      const oFlowNameWithID = CommonForumReport
      tempList.push(oFlowNameWithID);
      temFIJson[user] = tempList;
      const oTempInFoCo = { isDeleted: { $ne: true } };
      const tempFloIn = {};
      tempFloIn[oFlowNameWithID] = oTempInFoCo;
      inputData[user] = tempFloIn;
      const oTempOFoCo = { _id: 0 };
      const tempFliOt = {};
      tempFliOt[oFlowNameWithID] = oTempOFoCo;
      outputDat[user] = tempFliOt;
      const videoArr = [];
      const videoArr1 = [];
      let reportJson = {
        MACAddress: usere["MACAddress"],
        SessionID: usere["SessionID"],
        FlowNameIDList: temFIJson,
        ForumIDList: [user],
        InputData: inputData,
        OutputData: outputDat,
        ServerID: FSServerID,
      };
      const dataForum = new FormData();
      dataForum.append("Data", JSON.stringify(reportJson));
      let report = await axios({
        method: "post",
        url: forumServerIP + "reportGenerationForMultiplInputs",
        data: dataForum,
        headers: { "Content-Type": "multipart/form-data" },
      })
        .then(async function (response) {
          let sessionout = response.data;
          if (sessionout["ErrorCode"] === 1074) {
            toast.error((objectNullandUndefined('221')));
            setTimeout(() => {
              localStorage.clear();
              sessionStorage.clear();
              history.push('/');
              window.location.reload(false);
            }, 3000)
          }
          let response1 = response.data["ErrorMessage"][0];
          let ForumID = Object.keys(response1);
          let fidKey = response1[ForumID];
          let FID = Object.keys(fidKey);
          const userDatas = fidKey[FID];
          localStorage.setItem('recruitedforumid', ForumID)
          let oldData = localStorage.getItem(ForumID+"LastcandidateReport")
          if(oldData == null || oldData == undefined){
            localStorage.setItem(ForumID+"LastcandidateReport",JSON.stringify(userDatas))
          }
          // if (userDatas.length === 0) {
          //   alert(objectNullandUndefined('66'));
          //   history.push('/Notification');
          // }
          for (let i = 0; i < userDatas.length; i++) {
            const eachData = userDatas[i];
            const bridgeID = eachData["BridgeID"];
            const mediaID = eachData["MediaID"];
            const userName = eachData["UserName"];
            const mobileNum = eachData["MobileNumber"];
            const profile = eachData[RecCreateTempName+"_User_Main-d219b924-c3cd-437c-99b7-32c61d46c263_2"];
            const paid = base64_decode(eachData[RecCreateTempName+"_User_Main-d219b924-c3cd-437c-99b7-32c61d46c263_4"])
            let appliedTime = eachData['Time']
            let enddate = JSON.parse(appliedTime);
            const date = new Date(enddate * 1000);
            const options = {
                timeZone: 'Asia/Kolkata',
                hour: '2-digit',
                minute: '2-digit',
                year: 'numeric',
                month: 'short',
                day: 'numeric',
            };
            let Time = date.toLocaleString('en-IN', options);
            let profilepic = base64_decode(profile);
            let fullName = JSON.parse(profilepic)
            let name = fullName['FullName'] ?? "Jack Doe"
            if (mediaID.length > 0) {
              let mediaData = Object.keys(mediaID);
              let mediaKey =
                mediaID[mediaData][RecCreateTempName+"_User_Main-d219b924-c3cd-437c-99b7-32c61d46c263_6"];
              if(paid === "true"){
              const feedJson = {
                companyName: userName,
                Jd: profilepic,
                jType: bridgeID,
                mediaID: mediaKey,
                ForumID: ForumID[0],
                MobileNumber: mobileNum,
                id: i,
                AppliedTime:Time,
                FullName:name
              };
              let decodedreport = feedJson;
              videoArr.push(decodedreport);
              }else{
                const feedJson = {
                  companyName: userName,
                  Jd: profilepic,
                  jType: bridgeID,
                  mediaID: mediaKey,
                  ForumID: ForumID[0],
                  MobileNumber: mobileNum,
                  id: i,
                  AppliedTime:Time,
                  FullName:name
                };
                let decodedreport = feedJson;
                videoArr1.push(decodedreport);
              }
            }
          }
          let filteredVideoArray = [];
          if (mediaID1.length == 0) {
            // filteredVideoArray = videoArr;
              if(videoArr.length == 0 && videoArr1.length > 0){
                // filteredVideoArray = 
                filteredVideoArray = videoArr1.filter(item => !mediaID1.some(id => id === item.mediaID));
              }else if(videoArr1.length == 0 && videoArr.length > 0){
                // filteredVideoArray = videoArr
                filteredVideoArray = videoArr.filter(item => !mediaID1.some(id => id === item.mediaID));
              }else if(videoArr1.length == 0 && videoArr.length == 0){
                let data1 =  localStorage.getItem('recruitedforumid')
                let data = await RecruitedConsolidationReportGenration(data1)
                if(data.length!=0){
                  localStorage.setItem('nullvalue','null');
                  setVideoData(data)
                }else{
                  toast.warning(objectNullandUndefined('66'));
                  setTimeout(() => {
                    history.push('/Notification');
                  }, 2000)
                 return
                }
                
              }else if(videoArr1.length > 0 || videoArr.length > 0){
                filteredVideoArray = videoArr.concat(videoArr1)
                filteredVideoArray = filteredVideoArray.filter(item => !mediaID1.some(id => id === item.mediaID));
              }
          } else {
            // filteredVideoArray = videoArr.filter(item => !mediaID1.some(id => id === item.mediaID));
            if(videoArr1.length > 0 || videoArr.length > 0){
              filteredVideoArray = videoArr.concat(videoArr1)
              filteredVideoArray = filteredVideoArray.filter(item => !mediaID1.some(id => id === item.mediaID));
            }
          }
  
          // if (filteredVideoArray === 0) {
          //   toast("No videos have been submitted", {
          //     position: "top-center",
          //     autoClose: 3000, // Close after 3 seconds
          //     onClose: () => {
          //       history.push('/Notification');
          //     }
          //   });
          // }
          const ShowRecruitedCandidates = JSON.parse(localStorage.getItem('ShowRecruitedCandidates'));
          if (filteredVideoArray == 0 || userDatas.length === 0 || ShowRecruitedCandidates === true) {
            localStorage.removeItem('ShowRecruitedCandidates');
            let data1 =  localStorage.getItem('recruitedforumid')
            let data = await RecruitedConsolidationReportGenration(data1)
            if(data.length!=0){
              localStorage.setItem('nullvalue','null');
              toast.success(objectNullandUndefined('230'));
              setVideoData(data)
            }else{
              toast.warning(objectNullandUndefined('66'));
              setTimeout(() => {
                history.push('/Notification');
              }, 2000)
            }
          } else {
            const videodata = localStorage.getItem("UserVideoData");
            const ForumId = JSON.parse(videodata);
            let ViewedMediaIdsString = localStorage.getItem(ForumId+"viewedMediaIds")
            let ViewedMediaIds = [];
            if(ViewedMediaIdsString != null){
              ViewedMediaIds = JSON.parse(ViewedMediaIdsString)
            }
            let decodeReport = filteredVideoArray
            let oldData = localStorage.getItem(ForumId+"LastcandidateReport")
            if(oldData!=null){
              oldData = JSON.parse(oldData)
              if(showFiltered){
                if (oldData.length != filteredVideoArray.length){
                  localStorage.setItem(ForumId+"LastcandidateReport",JSON.stringify(filteredVideoArray))
                  showFiltered = false
                }else{
                  showFiltered = false
                }
                if(ViewedMediaIds.length == 0){
                  setVideoData(decodeReport);
                  isScroll(false)
                }else{
                  let newfilteredList = decodeReport.filter(item => !ViewedMediaIds.includes(item.mediaID));
                  if(newfilteredList.length > 0){
                    setVideoData(newfilteredList);
                    isScroll(true)
                    // toast.success(objectNullandUndefined('216'), {
                    //   position: toast.POSITION.TOP_CENTER,
                    //   autoClose: 2000, // 3 seconds
                    //   hideProgressBar: false,
                    // });
                  }else{
                    setVideoData([filteredVideoArray[filteredVideoArray.length - 1]]);
                    isScroll(false)
                  }
                }
              }else{
                if (oldData.length != filteredVideoArray.length){
                  localStorage.setItem(ForumId+"LastcandidateReport",JSON.stringify(filteredVideoArray))
                  let newfilteredList = decodeReport.filter(item => !ViewedMediaIds.includes(item.mediaID));
                  if(newfilteredList.length > 0){
                    setVideoData(newfilteredList);
                    isScroll(true)
                  }else{
                    setVideoData([filteredVideoArray[filteredVideoArray.length - 1]]);
                    isScroll(false)
                  }
                  showFiltered = false
                }else{
                  showFiltered = false
                }
              }
            }else{
              setVideoData(decodeReport);
              showFiltered = false
            }
            
            // below lines is for continue to play from last played video.
            // let storedMediaId = localStorage.getItem(ForumId+"LastePlayedMID");
            // let i = await findIndexByMediaID(decodeReport,storedMediaId)
            // console.log("stored index",i);
            // if(i == null || i == "NaN" || i == -1){
            //   i = 0;
            // }else{
            //   i = parseInt(i);
            // }
            // let LastPlayedArry = filteredVideoArray.slice(i)
            // setVideoData(LastPlayedArry);
            localStorage.setItem('nullvalue','null1');
          }
        })
        .catch(function (error) {
          console.log(error);
        });
        return report;
    }else{
      const videoArr = [];
      const feedJson = {
        "companyName": "@DemoUser1",
        "Jd": "{\"MediaID\":\"656030ef2f6cad64bc6b57ef\",\"PaymentId\":\"NotPaid\"}",
        "jType": "8f035b28-f9e2-4034-959d-dc2886c4e262",
        "mediaID": DemoUser1MediaID,
        "ForumID": "f7783ed-b88c-47bc-84f3-f07cb0f5c47a",
        "MobileNumber": "27434312507796236942774766",
        "id": 0
      }
      let decodedreport = feedJson;
      videoArr.push(decodedreport);
      const feedJson1 = {
        "companyName": "@DemoUser2",
        "Jd": "{\"MediaID\":\"656030ef2f6cad64bc6b57ef\",\"PaymentId\":\"NotPaid\"}",
        "jType": "8f035b28-f9e2-4034-959d-dc2886c4e262",
        "mediaID": DemoUser2MediaID,
        "ForumID": "f7783ed-b88c-47bc-84f3-f07cb0f5c47a",
        "MobileNumber": "27434312507796236942774766",
        "id": 0
      }
      let decodedreport1 = feedJson1;
      videoArr.push(decodedreport1)
      const feedJson2 = {
        "companyName": "@DemoUser3",
        "Jd": "{\"MediaID\":\"656030ef2f6cad64bc6b57ef\",\"PaymentId\":\"NotPaid\"}",
        "jType": "8f035b28-f9e2-4034-959d-dc2886c4e262",
        "mediaID": DemoUser3MediaID,
        "ForumID": "f7783ed-b88c-47bc-84f3-f07cb0f5c47a",
        "MobileNumber": "27434312507796236942774766",
        "id": 0
      }
      let decodedreport2 = feedJson2;
      videoArr.push(decodedreport2)
      setVideoData(videoArr);
    }
  }
  async function ArchiveConsolidatedReportGeneration() {
    const jsonData = localStorage.getItem('forumdata');
    const userData = JSON.parse(jsonData);
    const forumId = userData.ForumID;
    const Id = base64_encode(forumId);
    const data = localStorage.getItem("userProfile");
    let decoded = base64_decode(data);
    const userDetails = JSON.parse(decoded);
    const inputData = {};
    const outputDat = {};
    const temFIJson = {};
    const tempList = [];
    const oFlowNameWithID = ArchiveForumReport
    tempList.push(oFlowNameWithID);
    temFIJson[forumId] = tempList;
    const oTempInFoCo = { isDeleted: { $ne: true } };
    const tempFloIn = {};
    tempFloIn[oFlowNameWithID] = oTempInFoCo;
    inputData[forumId] = tempFloIn;
    const oTempOFoCo = { _id: 0 };
    const tempFliOt = {};
    tempFliOt[oFlowNameWithID] = oTempOFoCo;
    outputDat[forumId] = tempFliOt;
    const videoArr = [];
    const mediaArr = [];
    let reportJson = {
      MACAddress: userDetails["MACAddress"],
      SessionID: userDetails["SessionID"],
      FlowNameIDList: temFIJson,
      ForumIDList: [forumId],
      InputData: inputData,
      OutputData: outputDat,
      ServerID: FSServerID,
    };
    const dataForum = new FormData();
    dataForum.append("Data", JSON.stringify(reportJson));
    let report = await axios({
      method: "post",
      url: forumServerIP + "reportGenerationForMultiplInputs",
      data: dataForum,
      headers: { "Content-Type": "multipart/form-data" },
    })
      .then(async function (response) {
        let sessionout = response.data;
        if (sessionout["ErrorCode"] === 1074) {
          toast.error((objectNullandUndefined('221')));
          setTimeout(() => {
            localStorage.clear();
            sessionStorage.clear();
            history.push('/');
            window.location.reload(false);
          }, 3000)
        }
        let response1 = response.data["ErrorMessage"][0]
        let ForumID = Object.keys(response1)
        let fidKey = response1[ForumID]
        let FID = Object.keys(fidKey)
        let userDatas = fidKey[FID]

        if (userDatas.length === 0) {
          mediaArr = [];
          toast.warning(objectNullandUndefined('66'));
          setTimeout(() => {
            history.push('/Notification');
          }, 2000)
        }
        for (let i = 0; i < userDatas.length; i++) {
          const eachData = userDatas[i]
          const bridgeID = eachData["BridgeID"]
          const data = eachData["ArchiveTemp_ArchiveVideo_Main-839837f8-217b-45a4-9f47-3e4fe8975a98_4"]
          const mobileNum = eachData["MobileNumber"]
          const id = base64_decode(data);
          const userData = JSON.parse(id);
          let mediaId = userData.MediaID;
          let userName = userData.CompanyName;

          const feedJson = {
            "companyName": userName,
            "Jd": '',
            "jType": bridgeID,
            "mediaID": mediaId,
            "ForumID": ForumID,
            "MobileNumber": mobileNum
          }
          let decodedreport = feedJson;
          videoArr.push(decodedreport)
        }
        for (let i = 0; i < videoArr.length; i++) {
          let id = videoArr[i].mediaID;
          mediaArr.push(id);

        }

      })
      .catch(function (response) {
        // console.log(response);
      });
    return mediaArr
  };

};