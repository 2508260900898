import React, { useState, useEffect ,useRef} from 'react';
import { decode as base64_decode, encode as base64_encode } from 'base-64';
import { forumDetails } from '../../services/LoginLogoutService';
import Hook from '../Hook';
import { bridgeSendingByUser } from '../../services/LoginLogoutService';
import uuid from 'react-uuid';
import CircularProgress from '@material-ui/core/CircularProgress';
import Modal from '@material-ui/core/Modal';
import { useHistory } from 'react-router-dom';
import { objectNullandUndefined } from '../Dictionary/Dictionary';
import { ToastContainer, toast } from 'react-toastify'; import 'react-toastify/dist/ReactToastify.css';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import { FlexboxGrid } from 'rsuite';
import './PriorityList.css';
import Multiselect from 'multiselect-react-dropdown';
import DeleteIcon from '@material-ui/icons/Delete';
import Button from '@material-ui/core/Button';
import { Card } from 'react-bootstrap';
import { userJoinToForum } from '../../services/LoginLogoutService';
import {exitForum, fetchForumInfo } from '../../services/LoginLogoutService';
import { CAServerID, CandidateFilterFlow, CandidateFilterFlowID, CommonForumFlow, CommonForumID, FSServerID, MacAddress } from '../Dictionary/CommonStrings';
let JobCategoriesObject = {}
export default function PriorityList()  {
  const myUuid = uuid();
  const history = useHistory();
  const [jobCategories, setJobCatogary] = useState([]);
  const jobCategory1 = useRef();
  const jobCategory2 = useRef();
  const jobCategory3 = useRef();
  // const [showModel, setIshowModel] = useState(false);
  const [showDeleteModel1, setIsshowDeleteModel1] = useState(false);
  const [showDeleteModel2, setIsshowDeleteModel2] = useState(false);
  const [showDeleteModel3, setIsshowDeleteModel3] = useState(false);
  const [showAddModel1, setIsshowAddModel1] = useState(false);
  const [showAddModel2, setIsshowAddModel2] = useState(false);
  const [showAddModel3, setIsshowAddModel3] = useState(false);
  const [showEmptySaveModel, setshowEmptySaveModel] = useState(false);
  const [isLoading, setIsLoding] = useState(true);
  const [loading, setLoading] = useState(false);
  let CandidateIntrestString = localStorage.getItem('CandidateIntres');
  let CandidateIntrest = []
  if(CandidateIntrestString != null || CandidateIntrestString != undefined){
    CandidateIntrest = JSON.parse(CandidateIntrestString);
    let SelctedLanguageIntrestList = []
    for (let index = 0; index < CandidateIntrest.length; index++) {
      let element = CandidateIntrest[index];
      let item = objectNullandUndefined(element);
      if(item == undefined || item == null){
        item = element
      }
      SelctedLanguageIntrestList.push(item)
    }
    CandidateIntrest = SelctedLanguageIntrestList
  }
  const [CandidateIntrestList, setCandidateIntrestList] = useState(CandidateIntrest);
  const [selectedOptions1, setSelectedOptions1] = useState(CandidateIntrestList[0]);
  const [selectedOptions2, setSelectedOptions2] = useState(CandidateIntrestList[1]);
  const [selectedOptions3, setSelectedOptions3] = useState(CandidateIntrestList[2]);
  const [previousOptions1, setpreviousOptions1] = useState(CandidateIntrestList[0]);
  const [previousOptions2, setpreviousOptions2] = useState(CandidateIntrestList[1]);
  const [previousOptions3, setpreviousOptions3] = useState(CandidateIntrestList[2]);

  useEffect(() => {
    // fetchForumInfo()
    locationCall()
  }, []);
  const locationCall = async () => {
    localStorage.setItem("isSaved",false);
    let userDetails = localStorage.getItem('userProfile');
    let decoded = base64_decode(userDetails)
    userDetails = JSON.parse(decoded);
    let tempJson = {
      "MACAddress": userDetails['MACAddress'],
      "ForumID": CommonForumID,
      "SessionID": userDetails['SessionID'],
      "ServerID": CAServerID
    }
    let flowsCommon = await forumDetails(tempJson);
    if (flowsCommon["ErrorCode"] === 1074) {
      toast.error((objectNullandUndefined('221')));
      setTimeout(() => {
        localStorage.clear();
        sessionStorage.clear();
        history.push('/');
        window.location.reload(false);
      }, 3000)
    }
    let commonFlows = flowsCommon['ErrorMessage']['ForumDataArray']
    if(flowsCommon['ErrorCode'] == 1074){
      toast.warning(objectNullandUndefined("56"))
      localStorage.clear();
      sessionStorage.clear();
      history.push('/');
      window.location.reload(false);
    }
    if (commonFlows.length > 0) {
      for (let i = 0; i < commonFlows.length; i++) {
        let recForumData = commonFlows[i]
        let forumKey = Object.keys(recForumData)
        if (forumKey[0] === CommonForumID) {
          let commonUserFlows = recForumData[CommonForumID]['NewForumJsonData']
          let parsedCommonFlow = commonUserFlows["UserFlow"]
          let commonTempName = commonUserFlows["Template"]
          let reportFlowID_JobCategories = parsedCommonFlow[commonTempName + '_JobCategories']
          let formats_JobCategories = reportFlowID_JobCategories["Formats"][0]
          let Flows_JobCategories = parsedCommonFlow[formats_JobCategories]
          let locationKeys_JobCategories = Flows_JobCategories['row1'][2]
          let JobCategories = locationKeys_JobCategories["Options"]
          let JobCategoriesList = Object.keys(JobCategories)
          let locationKeys_JobCategoriesID = Flows_JobCategories['row2'][4]
          let JobCategoriesID = locationKeys_JobCategoriesID["Options"]
          let JobCategoriesListID = Object.keys(JobCategoriesID)
          let IndexForumIdObject = {}
          for(let j = 0 ; j < JobCategoriesListID.length;j++){
            IndexForumIdObject[JobCategoriesListID[j]] = JobCategoriesID[JobCategoriesListID[j]];
          }
          localStorage.setItem("IndexForumIdObject",JSON.stringify(IndexForumIdObject));
          JobCategoriesList = await ChangeLanguage(JobCategoriesList)
          JobCategoriesList = await removeOptionFromList(JobCategoriesList,selectedOptions1);
          JobCategoriesList = await removeOptionFromList(JobCategoriesList,selectedOptions2);
          JobCategoriesList = await removeOptionFromList(JobCategoriesList,selectedOptions3);
          setJobCatogary(JobCategoriesList)
          setIsLoding(false)
        }
      }
    }
  }

  const ChangeLanguage = async (JobCategoriesList) =>{
    let selectedlanguageCategoryList = []
    for (let index = 0; index < JobCategoriesList.length; index++) {
        let englishCategory = JobCategoriesList[index];
        let selectedlanguageCategory = objectNullandUndefined(englishCategory)
        if(selectedlanguageCategory == undefined || selectedlanguageCategory == null){
            selectedlanguageCategory = englishCategory
        }
        JobCategoriesObject[selectedlanguageCategory] = englishCategory
        selectedlanguageCategoryList.push(selectedlanguageCategory)
    }
    return selectedlanguageCategoryList
  }

  async function removeOptionFromList(list,option){
    if(list == null || option == null || option == undefined){
      return list;
    }
    const newlist = list.filter(item => item !== option);
    return newlist
  }

  async function onSelect1(selectedList, selectedItem) {
    setIsshowAddModel1(true)
    if(!jobCategories.includes(selectedOptions1) && selectedOptions1 != undefined){
      jobCategories.push(selectedOptions1);
    }
    setSelectedOptions1(selectedItem)
    setpreviousOptions1(selectedOptions1)
    const updatedAvailableOptions = await removeOptionFromList(jobCategories,selectedItem)
    setJobCatogary(updatedAvailableOptions);
  }

  async function onSelect2(selectedList, selectedItem) {
    setIsshowAddModel2(true,selectedItem)
    if(!jobCategories.includes(selectedOptions2) && selectedOptions2 != undefined){
      jobCategories.push(selectedOptions2);
    }
    setSelectedOptions2(selectedItem)
    setpreviousOptions2(selectedOptions2)
    const updatedAvailableOptions = await removeOptionFromList(jobCategories,selectedItem)
    setJobCatogary(updatedAvailableOptions);
  }

  async function onSelect3(selectedList, selectedItem) {
    setIsshowAddModel3(true,selectedItem)
    if(!jobCategories.includes(selectedOptions3) && selectedOptions3 != undefined){
      jobCategories.push(selectedOptions3);
    }
    setSelectedOptions3(selectedItem)
    setpreviousOptions3(selectedOptions3)
    const updatedAvailableOptions = await removeOptionFromList(jobCategories,selectedItem)
    setJobCatogary(updatedAvailableOptions);
  }

  const callExitForum = async (ForumID) => {
    let id = ForumID
    let data3 = await exitForum(id);
    if (data3["ErrorCode"] === 1074) {
      toast.error((objectNullandUndefined('221')));
      setTimeout(() => {
        localStorage.clear();
        sessionStorage.clear();
        history.push('/');
        window.location.reload(false);
      }, 3000)
    }
    if(data3['ErrorCode'] == 1030){
      return data3['ErrorCode']
    }else{
      toast.error(objectNullandUndefined('56'));
    }
  }

  const addUserToIndexForum = async (ForumId) =>{
    let userDetails = localStorage.getItem('userProfile');
        let decoded = base64_decode(userDetails);
        const userData = JSON.parse(decoded);
        let jsonObject = {
            "MACAddress":userData.MACAddress,
            "ForumID": ForumId,
            "SessionID": userData.SessionID,
            "Owner": userData.UserName,
            "OwnerMobileNumber": userData.MobileNumber,
            'ServerID': FSServerID,
        }
        let data = await userJoinToForum(jsonObject);
        if (data["ErrorCode"] === 1074) {
          toast.error((objectNullandUndefined('221')));
          setTimeout(() => {
            localStorage.clear();
            sessionStorage.clear();
            history.push('/');
            window.location.reload(false);
          }, 3000)
        }
        if(data['ErrorCode'] == 1035 || data['ErrorCode'] == 1010){
          return data['ErrorCode'];
        }else{
          toast.error(objectNullandUndefined('56'));
          return  null
        }
        
  }

  // async function HandleCandidateIntrest() {
  //   setLoading(true);
  //   let newCandidateIntrestList = [];
  //   let IndexForumIdObject = localStorage.getItem('IndexForumIdObject');
  //   IndexForumIdObject = JSON.parse(IndexForumIdObject);
  //   setIshowModel(false)
  //   if(!CandidateIntrestList.includes(selectedOptions1) && selectedOptions1 != undefined){
  //     if(IndexForumIdObject != null || IndexForumIdObject != undefined){
  //       let respone = await  addUserToIndexForum(IndexForumIdObject[selectedOptions1])
  //       if(respone == 1035 || respone == 1010){
  //         newCandidateIntrestList.push(selectedOptions1);
  //       }
  //     }
  //   }
  //   if(!CandidateIntrestList.includes(selectedOptions2) && selectedOptions2 != undefined){
  //     if(IndexForumIdObject != null || IndexForumIdObject != undefined){
  //       let respone = await addUserToIndexForum(IndexForumIdObject[selectedOptions2])
  //       if(respone == 1035 || respone == 1010){
  //         newCandidateIntrestList.push(selectedOptions2);
  //       }
  //     }
  //   }
  //   if(!CandidateIntrestList.includes(selectedOptions3) && selectedOptions3 != undefined){
  //     if(IndexForumIdObject != null || IndexForumIdObject != undefined){
  //       let respone = await addUserToIndexForum(IndexForumIdObject[selectedOptions3])
  //       if(respone == 1035 || respone == 1010){
  //         newCandidateIntrestList.push(selectedOptions3);
  //       }
  //     }
  //   }
  //   let newCandidateIntrestList1 = CandidateIntrestList
  //   if(selectedOptions1 != undefined){
  //     newCandidateIntrestList1[0] = selectedOptions1
  //   }
  //   if(selectedOptions2 != undefined){
  //     newCandidateIntrestList1[1] = selectedOptions2
  //   }
  //   if(selectedOptions3 != undefined){
  //     newCandidateIntrestList1[2] = selectedOptions3
  //   }
  //   let stringnewCandidateIntrestList = JSON.stringify(newCandidateIntrestList1)
  //   localStorage.setItem('CandidateIntres',stringnewCandidateIntrestList)
  //   toast.success(objectNullandUndefined('69'));
  //   setLoading(false);
  // };

  async function HandleDeleteIntrest(index) {
    setLoading(true);
    let IndexForumIdObject = localStorage.getItem('IndexForumIdObject');
    IndexForumIdObject = JSON.parse(IndexForumIdObject);
    if(index == '1'){
      setIsshowDeleteModel1(false)
      let selectedOptions11 = JobCategoriesObject[selectedOptions1]
      if(CandidateIntrestList.includes(selectedOptions1)){
        let response = await callExitForum(IndexForumIdObject[selectedOptions11]);
        if(response == 1030){
          let newCandidateIntrestList = CandidateIntrestList.filter((item) => item != selectedOptions1);
          setCandidateIntrestList(newCandidateIntrestList)
          setSelectedOptions1(newCandidateIntrestList[0]);
          let EnglishSavedIntrestList = []
          for (let index = 0; index < newCandidateIntrestList.length; index++) {
            const element = newCandidateIntrestList[index];
            let item = JobCategoriesObject[element]
            EnglishSavedIntrestList.push(item)
          }
          newCandidateIntrestList = EnglishSavedIntrestList;
          let stringnewCandidateIntrestList = JSON.stringify(newCandidateIntrestList)
          localStorage.setItem('CandidateIntres',stringnewCandidateIntrestList)
          toast.success(objectNullandUndefined('69'));
        }
      }else{
        setSelectedOptions1(undefined)
      }
      if(!jobCategories.includes(selectedOptions1)){
        jobCategories.push(selectedOptions1);
      }
      setJobCatogary(jobCategories);
      jobCategory1.current.resetSelectedValues();
    }
    else if(index == '2'){
      setIsshowDeleteModel2(false)
      let selectedOptions22 = JobCategoriesObject[selectedOptions2]
      if(CandidateIntrestList.includes(selectedOptions2)){
        let response = await callExitForum(IndexForumIdObject[selectedOptions22]);
        if(response == 1030){
          let newCandidateIntrestList = CandidateIntrestList.filter((item) => item != selectedOptions2);
          setCandidateIntrestList(newCandidateIntrestList)
          setSelectedOptions2(newCandidateIntrestList[1]);
          let EnglishSavedIntrestList = []
          for (let index = 0; index < newCandidateIntrestList.length; index++) {
            const element = newCandidateIntrestList[index];
            let item = JobCategoriesObject[element]
            EnglishSavedIntrestList.push(item)
          }
          newCandidateIntrestList = EnglishSavedIntrestList
          let stringnewCandidateIntrestList = JSON.stringify(newCandidateIntrestList)
          localStorage.setItem('CandidateIntres',stringnewCandidateIntrestList)
          toast.success(objectNullandUndefined('69'));
        }
      }else{
        setSelectedOptions2(undefined)
      }
      if(!jobCategories.includes(selectedOptions2)){
        jobCategories.push(selectedOptions2);
      }
      setJobCatogary(jobCategories);
      jobCategory2.current.resetSelectedValues();
    }
    else if(index == '3'){
      setIsshowDeleteModel3(false)
      let selectedOptions33 = JobCategoriesObject[selectedOptions3]
      if(CandidateIntrestList.includes(selectedOptions3)){
        let response = await callExitForum(IndexForumIdObject[selectedOptions33]);
        if(response == 1030){
          let newCandidateIntrestList = CandidateIntrestList.filter((item) => item != selectedOptions3);
          setCandidateIntrestList(newCandidateIntrestList)
          setSelectedOptions3(newCandidateIntrestList[2]);
          let EnglishSavedIntrestList = []
          for (let index = 0; index < newCandidateIntrestList.length; index++) {
            const element = newCandidateIntrestList[index];
            let item = JobCategoriesObject[element]
            EnglishSavedIntrestList.push(item)
          }
          newCandidateIntrestList = EnglishSavedIntrestList
          let stringnewCandidateIntrestList = JSON.stringify(newCandidateIntrestList)
          localStorage.setItem('CandidateIntres',stringnewCandidateIntrestList)
          toast.success(objectNullandUndefined('69'));
        }
      }else{
        setSelectedOptions3(undefined)
      }
      if(!jobCategories.includes(selectedOptions3)){
        jobCategories.push(selectedOptions3);
      }
      setJobCatogary(jobCategories);
      
      jobCategory3.current.resetSelectedValues();
    }
    setLoading(false);
    setTimeout(() => {
      window.location.reload();
    }, 1000);
  };

  async function bridgeSending(selectedJobCategoryList,selectedLocation) {
    let commonForumTemp = localStorage.getItem("commonUserFlows")
    if(commonForumTemp == null){
      let userProfile = localStorage.getItem("userProfile");
      let userDetails = JSON.parse(base64_decode(userProfile));
      let sessionID = userDetails["SessionID"];
      let MacAddress = userDetails["MACAddress"];
      let tempJson = {
        "MACAddress": MacAddress,
        "ForumID": CommonForumID,
        "SessionID": sessionID,
        "ServerID": CAServerID
      }
      let flowsCommon = await forumDetails(tempJson)
      let commonFlows = flowsCommon['ErrorMessage']['ForumDataArray']
      if (commonFlows.length > 0) {
        for (let i = 0; i < commonFlows.length; i++) {
          let recForumData = commonFlows[i]
          let forumKey = Object.keys(recForumData)
          if (forumKey[0] === CommonForumID) {
            let commonUserFlows = recForumData[CommonForumID]['NewForumJsonData']
            localStorage.setItem("commonUserFlows", JSON.stringify(commonUserFlows))
            commonForumTemp = JSON.stringify(commonUserFlows)
          }
        }
      }
    }
    let parsedFlow = JSON.parse(commonForumTemp)
    let parsedCommonFlow = parsedFlow["UserFlow"]
    let commonTempName = parsedFlow["Template"]
    let filterFlowID = parsedCommonFlow[commonTempName + '_CandidateFilter']
  try {
    let uuid = myUuid
  
    let userDetails = localStorage.getItem('userProfile');
    userDetails = base64_decode(userDetails);
    userDetails = JSON.parse(userDetails);
    let sessionID = userDetails.SessionID;
    let macAddress = userDetails.MACAddress;
    let flowName = commonTempName + '_CandidateFilter';;
    let fID = filterFlowID['FID']
    let tempJson = {
      ForumID: CommonForumID,
      SessionID: sessionID,
      MACAddress: macAddress,
      Time: '6516516156161',
      ScheduledDateTime: 'now',
      ScheduledBoolean: 0,
      FlowID: flowName,
      EnableChat: 0,
      FlowType: 'Custom',
      BridgeForward: 0,
      TemplateID: parsedFlow["TemplateID"],
      TextCount: 6,
      ImageCount: 0,
      InvoiceID: '',
      DocumentCount: 0,
      User: true,
      VideoCount: 0,
      ServerID: FSServerID,
      ReplyBridgeID: '',
      HiddenFlow: false,
      BilledForum: '',
      TempBridgeId: uuid,
      FID: fID,
      SentTo: '0',
    };
    
    let data1 = {
      'FilterType': (selectedJobCategoryList),
      'CityType': (selectedLocation)
    }

    let jsonData = {
      [CommonForumFlow + '_CandidateFilter_Main-88f212c1-b826-490c-a351-ff093f97b87b_2']: base64_encode(JSON.stringify(data1)),
    };
    tempJson[flowName] = jsonData;
    const data = new FormData();
    data.append('Data', JSON.stringify(tempJson));
    let bridgeData = await bridgeSendingByUser(data);
    if (bridgeData["ErrorCode"] === 1074) {
      toast.error((objectNullandUndefined('221')));
      setTimeout(() => {
        localStorage.clear();
        sessionStorage.clear();
        history.push('/');
        window.location.reload(false);
      }, 3000)
    }
  } catch (err) {
    return;
  }
  }

  async function handelSave() {
    let CandidateIntrestString = localStorage.getItem('CandidateIntres');
    if(CandidateIntrestString != null || CandidateIntrestString != undefined){
      CandidateIntrest = JSON.parse(CandidateIntrestString);
      let selectedLocation =  localStorage.getItem('selectedLocation');
      if(selectedLocation == null || selectedLocation == undefined){
        selectedLocation = 'Bangalore'
      }
      localStorage.setItem("isSaved",true);
      bridgeSending(CandidateIntrest,selectedLocation);
      localStorage.setItem("MediaIDS",[]);
      history.push("/ShortContainer");
    }else{
      // setshowEmptySaveModel(true);
      toast.dismiss()
      toast.error(objectNullandUndefined('261'));
    }
    
  };

  async function HandleEmptySavedIntres(){
    setshowEmptySaveModel(false);
    toast.info("you will be navigated to job filter selction screen");
    localStorage.setItem("MediaIDS",[]);
    setTimeout(() => {
      history.push("/JobLocationFilter");
    }, 2000);
  };

  async function HandleAddIntrest(index) {
    setLoading(true);
    let IndexForumIdObject = localStorage.getItem('IndexForumIdObject');
    IndexForumIdObject = JSON.parse(IndexForumIdObject);
    if(index == '1'){
      setIsshowAddModel1(false)
      let previousOptions11 = JobCategoriesObject[previousOptions1]
      let selectedOptions11 = JobCategoriesObject[selectedOptions1]
      if(previousOptions1 != undefined){
        if(!CandidateIntrestList.includes(selectedOptions1)){
          let repose1 = await callExitForum(IndexForumIdObject[previousOptions11])
          if(repose1 == 1030){
            let response = await addUserToIndexForum(IndexForumIdObject[selectedOptions11]);
            if(response == 1035 || response == 1010){
              let newCandidateIntrestList = CandidateIntrestList;
              for(let index = 0;index < newCandidateIntrestList.length ; index++){
                if(newCandidateIntrestList[index] == previousOptions1){
                  newCandidateIntrestList[index] = selectedOptions1;
                }
              }
              setCandidateIntrestList(newCandidateIntrestList)
              setSelectedOptions1(newCandidateIntrestList[0]);
              let EnglishSavedIntrestList = []
              for (let index = 0; index < newCandidateIntrestList.length; index++) {
                const element = newCandidateIntrestList[index];
                let item = JobCategoriesObject[element]
                EnglishSavedIntrestList.push(item)
              }
              newCandidateIntrestList = EnglishSavedIntrestList
              let stringnewCandidateIntrestList = JSON.stringify(newCandidateIntrestList)
              localStorage.setItem('CandidateIntres',stringnewCandidateIntrestList)
              toast.success(objectNullandUndefined('69'));
            }
          }
        }
      }else{
        let response = await addUserToIndexForum(IndexForumIdObject[selectedOptions11]);
        if(response == 1035 || response == 1010){
          let newCandidateIntrestList = [];
          if(CandidateIntrestList.length > 0){
            newCandidateIntrestList = CandidateIntrestList;
            for(let index = 0;index < newCandidateIntrestList.length ; index++){
              if(newCandidateIntrestList[index] == previousOptions1){
                newCandidateIntrestList[index] = selectedOptions1;
              }
            }
            setCandidateIntrestList(newCandidateIntrestList)
          }
          else{
            newCandidateIntrestList.push(selectedOptions1);
          }
          setSelectedOptions1(newCandidateIntrestList[0]);
          let EnglishSavedIntrestList = []
          for (let index = 0; index < newCandidateIntrestList.length; index++) {
            const element = newCandidateIntrestList[index];
            let item = JobCategoriesObject[element]
            EnglishSavedIntrestList.push(item)
          }
          newCandidateIntrestList = EnglishSavedIntrestList
          let stringnewCandidateIntrestList = JSON.stringify(newCandidateIntrestList)
          localStorage.setItem('CandidateIntres',stringnewCandidateIntrestList)
          toast.success(objectNullandUndefined('69'));
        }
      }
    }
    else if(index == '2'){
      setIsshowAddModel2(false)
      let previousOptions22 = JobCategoriesObject[previousOptions2]
      let selectedOptions22 = JobCategoriesObject[selectedOptions2]
      if(previousOptions2 != undefined){
        if(!CandidateIntrestList.includes(selectedOptions2)){
          let repose1 = await callExitForum(IndexForumIdObject[previousOptions22])
          if(repose1 == 1030){
            let response = await addUserToIndexForum(IndexForumIdObject[selectedOptions22]);
            if(response == 1035 || response == 1010){
              let newCandidateIntrestList = CandidateIntrestList;
              for(let index = 0;index < newCandidateIntrestList.length ; index++){
                if(newCandidateIntrestList[index] == previousOptions2){
                  newCandidateIntrestList[index] = selectedOptions2;
                }
              }
              setCandidateIntrestList(newCandidateIntrestList)
              setSelectedOptions2(newCandidateIntrestList[1]);
              let EnglishSavedIntrestList = []
              for (let index = 0; index < newCandidateIntrestList.length; index++) {
                const element = newCandidateIntrestList[index];
                let item = JobCategoriesObject[element]
                EnglishSavedIntrestList.push(item)
              }
              newCandidateIntrestList = EnglishSavedIntrestList
              let stringnewCandidateIntrestList = JSON.stringify(newCandidateIntrestList)
              localStorage.setItem('CandidateIntres',stringnewCandidateIntrestList)
              toast.success(objectNullandUndefined('69'));
            }
          }
        }
      }else{
        let response = await addUserToIndexForum(IndexForumIdObject[selectedOptions22]);
        if(response == 1035 || response == 1010){
          let newCandidateIntrestList = [];
          if(CandidateIntrestList.length > 1){
            newCandidateIntrestList = CandidateIntrestList;
            for(let index = 0;index < newCandidateIntrestList.length ; index++){
              if(newCandidateIntrestList[index] == previousOptions2){
                newCandidateIntrestList[index] = selectedOptions2;
              }
            }
            setCandidateIntrestList(newCandidateIntrestList)
          }
          else{
            newCandidateIntrestList = CandidateIntrestList;
            newCandidateIntrestList.push(selectedOptions2);
          }
          setSelectedOptions2(newCandidateIntrestList[1]);
          let EnglishSavedIntrestList = []
          for (let index = 0; index < newCandidateIntrestList.length; index++) {
            const element = newCandidateIntrestList[index];
            let item = JobCategoriesObject[element]
            EnglishSavedIntrestList.push(item)
          }
          newCandidateIntrestList = EnglishSavedIntrestList
          let stringnewCandidateIntrestList = JSON.stringify(newCandidateIntrestList)
          localStorage.setItem('CandidateIntres',stringnewCandidateIntrestList)
          toast.success(objectNullandUndefined('69'));
        }
      }
    }
    else if(index == '3'){
      setIsshowAddModel3(false)
      let previousOptions33 = JobCategoriesObject[previousOptions3]
      let selectedOptions33 = JobCategoriesObject[selectedOptions3]
      if(previousOptions3 != undefined){
        if(!CandidateIntrestList.includes(selectedOptions3)){
          let repose1 = await callExitForum(IndexForumIdObject[previousOptions33])
          if(repose1 == 1030){
            let response = await addUserToIndexForum(IndexForumIdObject[selectedOptions33]);
            if(response == 1035 || response == 1010){
              let newCandidateIntrestList = CandidateIntrestList;
              for(let index = 0;index < newCandidateIntrestList.length ; index++){
                if(newCandidateIntrestList[index] == previousOptions3){
                  newCandidateIntrestList[index] = selectedOptions3;
                }
              }
              setCandidateIntrestList(newCandidateIntrestList)
              setSelectedOptions3(newCandidateIntrestList[2]);
              let EnglishSavedIntrestList = []
              for (let index = 0; index < newCandidateIntrestList.length; index++) {
                const element = newCandidateIntrestList[index];
                let item = JobCategoriesObject[element]
                EnglishSavedIntrestList.push(item)
              }
              newCandidateIntrestList = EnglishSavedIntrestList
              let stringnewCandidateIntrestList = JSON.stringify(newCandidateIntrestList)
              localStorage.setItem('CandidateIntres',stringnewCandidateIntrestList)
              toast.success(objectNullandUndefined('69'));
            }
          }
        }
      }else{
        let response = await addUserToIndexForum(IndexForumIdObject[selectedOptions33]);
        if(response == 1035 || response == 1010){
          let newCandidateIntrestList = [];
          if(CandidateIntrestList.length > 2){
            newCandidateIntrestList = CandidateIntrestList;
            for(let index = 0;index < newCandidateIntrestList.length ; index++){
              if(newCandidateIntrestList[index] == previousOptions3){
                newCandidateIntrestList[index] = selectedOptions3;
              }
            }
            setCandidateIntrestList(newCandidateIntrestList)
          }
          else{
            newCandidateIntrestList = CandidateIntrestList;
            newCandidateIntrestList.push(selectedOptions3);
          }
          setSelectedOptions3(newCandidateIntrestList[2]);
          let EnglishSavedIntrestList = []
          for (let index = 0; index < newCandidateIntrestList.length; index++) {
            const element = newCandidateIntrestList[index];
            let item = JobCategoriesObject[element]
            EnglishSavedIntrestList.push(item)
          }
          newCandidateIntrestList = EnglishSavedIntrestList
          let stringnewCandidateIntrestList = JSON.stringify(newCandidateIntrestList)
          localStorage.setItem('CandidateIntres',stringnewCandidateIntrestList)
          toast.success(objectNullandUndefined('69'));
        }
      }

    }
    setLoading(false);
    setTimeout(() => {
      window.location.reload();
    }, 1000);
  };

  const classes = useStyles();
  return (
    <>
      <Hook
        short={'INTERESTS'}
      />
        <ToastContainer position="top-center" autoClose={2000} hideProgressBar />
        {/* {showModel === true && (
          <div className="transparent-overlay1">
            <Card className="popup0">
              <div style={{ textAlign: 'center', paddingTop: '20px' }}>
                <p style={{ fontSize: 'large', color: 'black', overflow: "hidden", padding: '10px 20px 10px 20px' }}>
                  {objectNullandUndefined('253')}
                </p>
              </div>
              <div className='Loginbutton'>
                <Button
                  variant="text"
                  onClick={() => setIshowModel(false)}
                  style={{ fontSize: '16px', width: '30%', textTransform: 'none',color:'blue' }}
                >
                  {objectNullandUndefined('16')}
                </Button>
                <Button
                  variant="text"
                  onClick={() => HandleCandidateIntrest()}
                  style={{ fontSize: '16px', width: '30%', textTransform: 'none' ,color:'blue'}}
                >
                  {objectNullandUndefined('48')}
                </Button>
              </div>
            </Card>
          </div>
        )} */}
        {showDeleteModel1 === true && (
          <div className="transparent-overlay1">
            <Card className="popup0">
              <div style={{ textAlign: 'center', paddingTop: '20px' }}>
                <p style={{ fontSize: 'large', color: 'black', overflow: "hidden", padding: '10px 20px 10px 20px' }}>
                  {objectNullandUndefined('253')}
                </p>
              </div>
              <div className='Loginbutton'>
                <Button
                  variant="text"
                  onClick={() => setIsshowDeleteModel1(false)}
                  style={{ fontSize: '16px', width: '30%', textTransform: 'none',color:'blue' }}
                >
                  {objectNullandUndefined('16')}
                </Button>
                <Button
                  variant="text"
                  onClick={() => HandleDeleteIntrest('1')}
                  style={{ fontSize: '16px', width: '30%', textTransform: 'none' ,color:'blue'}}
                >
                  {objectNullandUndefined('256')}
                </Button>
              </div>
            </Card>
          </div>
        )}
        {showDeleteModel2 === true && (
          <div className="transparent-overlay1">
            <Card className="popup0">
              <div style={{ textAlign: 'center', paddingTop: '20px' }}>
                <p style={{ fontSize: 'large', color: 'black', overflow: "hidden", padding: '10px 20px 10px 20px' }}>
                  {objectNullandUndefined('253')}
                </p>
              </div>
              <div className='Loginbutton'>
                <Button
                  variant="text"
                  onClick={() => setIsshowDeleteModel2(false)}
                  style={{ fontSize: '16px', width: '30%', textTransform: 'none',color:'blue' }}
                >
                  {objectNullandUndefined('16')}
                </Button>
                <Button
                  variant="text"
                  onClick={() => HandleDeleteIntrest('2')}
                  style={{ fontSize: '16px', width: '30%', textTransform: 'none' ,color:'blue'}}
                >
                  {objectNullandUndefined('256')}
                </Button>
              </div>
            </Card>
          </div>
        )}
        {showDeleteModel3 === true && (
          <div className="transparent-overlay1">
            <Card className="popup0">
              <div style={{ textAlign: 'center', paddingTop: '20px' }}>
                <p style={{ fontSize: 'large', color: 'black', overflow: "hidden", padding: '10px 20px 10px 20px' }}>
                  {objectNullandUndefined('253')}
                </p>
              </div>
              <div className='Loginbutton'>
                <Button
                  variant="text"
                  onClick={() => setIsshowDeleteModel3(false)}
                  style={{ fontSize: '16px', width: '30%', textTransform: 'none',color:'blue' }}
                >
                  {objectNullandUndefined('16')}
                </Button>
                <Button
                  variant="text"
                  onClick={() => HandleDeleteIntrest('3')}
                  style={{ fontSize: '16px', width: '30%', textTransform: 'none' ,color:'blue'}}
                >
                  {objectNullandUndefined('256')}
                </Button>
              </div>
            </Card>
          </div>
        )}
        {showAddModel1 === true && (
          <div className="transparent-overlay1">
            <Card className="popup0">
              <div style={{ textAlign: 'center', paddingTop: '20px' }}>
                <p style={{ fontSize: 'large', color: 'black', overflow: "hidden", padding: '10px 20px 10px 20px' }}>
                  {objectNullandUndefined('253')}
                </p>
              </div>
              <div className='Loginbutton'>
                <Button
                  variant="text"
                  onClick={() => {
                    if(!jobCategories.includes(selectedOptions1) && selectedOptions1 != undefined){
                      jobCategories.push(selectedOptions1);
                    }
                    setSelectedOptions1(previousOptions1);
                    setIsshowAddModel1(false);}}
                  style={{ fontSize: '16px', width: '30%', textTransform: 'none',color:'blue' }}
                >
                  {objectNullandUndefined('16')}
                </Button>
                <Button
                  variant="text"
                  onClick={() => HandleAddIntrest('1')}
                  style={{ fontSize: '16px', width: '30%', textTransform: 'none' ,color:'blue'}}
                >
                  {objectNullandUndefined('256')}
                </Button>
              </div>
            </Card>
          </div>
        )}
        {showAddModel2 === true && (
          <div className="transparent-overlay1">
            <Card className="popup0">
              <div style={{ textAlign: 'center', paddingTop: '20px' }}>
                <p style={{ fontSize: 'large', color: 'black', overflow: "hidden", padding: '10px 20px 10px 20px' }}>
                  {objectNullandUndefined('253')}
                </p>
              </div>
              <div className='Loginbutton'>
                <Button
                  variant="text"
                  onClick={() => {
                    if(!jobCategories.includes(selectedOptions2) && selectedOptions2 != undefined){
                      jobCategories.push(selectedOptions2);
                    }
                    setSelectedOptions2(previousOptions2);
                    setIsshowAddModel2(false);}}
                  style={{ fontSize: '16px', width: '30%', textTransform: 'none',color:'blue' }}
                >
                  {objectNullandUndefined('16')}
                </Button>
                <Button
                  variant="text"
                  onClick={() => HandleAddIntrest('2')}
                  style={{ fontSize: '16px', width: '30%', textTransform: 'none' ,color:'blue'}}
                >
                  {objectNullandUndefined('256')}
                </Button>
              </div>
            </Card>
          </div>
        )}
        {showAddModel3 === true && (
          <div className="transparent-overlay1">
            <Card className="popup0">
              <div style={{ textAlign: 'center', paddingTop: '20px' }}>
                <p style={{ fontSize: 'large', color: 'black', overflow: "hidden", padding: '10px 20px 10px 20px' }}>
                  {objectNullandUndefined('253')}
                </p>
              </div>
              <div className='Loginbutton'>
                <Button
                  variant="text"
                  onClick={() => {
                    if(!jobCategories.includes(selectedOptions3) && selectedOptions3 != undefined){
                      jobCategories.push(selectedOptions3);
                    }
                    setSelectedOptions3(previousOptions3);
                    setIsshowAddModel3(false);}}
                  style={{ fontSize: '16px', width: '30%', textTransform: 'none',color:'blue' }}
                >
                  {objectNullandUndefined('16')}
                </Button>
                <Button
                  variant="text"
                  onClick={() => HandleAddIntrest('3')}
                  style={{ fontSize: '16px', width: '30%', textTransform: 'none' ,color:'blue'}}
                >
                  {objectNullandUndefined('256')}
                </Button>
              </div>
            </Card>
          </div>
        )}
        {showEmptySaveModel === true && (
          <div className="transparent-overlay1">
            <Card className="popup0">
              <div style={{ textAlign: 'center', paddingTop: '20px' }}>
                <p style={{ fontSize: 'large', color: 'black', overflow: "hidden", padding: '10px 20px 10px 20px' }}>
                  {"Are you sure you want to remove all saved intrest?"}
                </p>
              </div>
              <div className='Loginbutton'>
                <Button
                  variant="text"
                  onClick={() => {
                    setshowEmptySaveModel(false);}}
                  style={{ fontSize: '16px', width: '30%', textTransform: 'none',color:'blue' }}
                >
                  {objectNullandUndefined('16')}
                </Button>
                <Button
                  variant="text"
                  onClick={() => HandleEmptySavedIntres()}
                  style={{ fontSize: '16px', width: '30%', textTransform: 'none' ,color:'blue'}}
                >
                  {objectNullandUndefined('256')}
                </Button>
              </div>
            </Card>
          </div>
        )}
        <div className={classes.roo}>
            <div className={classes.containe}>
              <div className="header" >
                <p className="bold">{objectNullandUndefined('263')}</p>
              </div>
              <div className='option'>
                <div className={classes.lists}>
                  <p style={{fontWeight:'bold',marginBottom:'5px'}}>{objectNullandUndefined('257')}</p>
                  <DeleteIcon
                    className={classes.removeIcon}
                    onClick={() => setIsshowDeleteModel1(true)}
                  />
                </div>
                <Multiselect
                  placeholder ={objectNullandUndefined('93')}
                  id="jobCategory"
                  value={[selectedOptions1]}
                  ref={jobCategory1}
                  label="Job Category"
                  displayValue="key"
                  isObject={false}
                  onSelect={onSelect1}
                  showCheckbox = {false}
                  options={jobCategories}
                  selectionLimit={1}
                  showArrow = {true}
                  // emptyRecordMsg = {objectNullandUndefined('194')}
                  emptyRecordMsg = {objectNullandUndefined('254')}
                  loading = {isLoading}
                  singleSelect
                  selectedValues={[selectedOptions1|| objectNullandUndefined('93')]}
                />
              </div>

              <div className='option'>
                <div className={classes.lists}>
                  <p style={{fontWeight:'bold',marginBottom:'5px'}}>{objectNullandUndefined('258')}</p>
                  <DeleteIcon
                    className={classes.removeIcon}
                    onClick={() => setIsshowDeleteModel2(true)}
                  />
                </div>
                <Multiselect
                  placeholder ={objectNullandUndefined('93')}
                  id="jobCategory"
                  ref={jobCategory2}
                  value={[selectedOptions2]}
                  label="Job Category"
                  displayValue="key"
                  isObject={false}
                  onSelect={onSelect2}
                  showCheckbox = {false}
                  options={jobCategories}
                  selectionLimit={1}
                  showArrow = {true}
                  // emptyRecordMsg = {objectNullandUndefined('194')}
                  emptyRecordMsg = {objectNullandUndefined('254')}
                  loading = {isLoading}
                  singleSelect
                  selectedValues={[selectedOptions2 || objectNullandUndefined('93')]}
                />
              </div>

              <div className='option'> 
                <div className={classes.lists}>
                  <p style={{fontWeight:'bold',marginBottom:'5px'}}>{objectNullandUndefined('259')}</p>
                  <DeleteIcon
                    className={classes.removeIcon}
                    onClick={() => setIsshowDeleteModel3(true)}
                  />
                </div>
                <div className="multiselect-container">
                <Multiselect
                  placeholder ={objectNullandUndefined('93')}
                  id="jobCategory"
                  ref={jobCategory3}
                  value={[selectedOptions3]}
                  label="Job Category"
                  displayValue="key"
                  isObject={false}
                  onSelect={onSelect3}
                  showCheckbox = {false}
                  options={jobCategories}
                  selectionLimit={1}
                  showArrow = {true}
                  // emptyRecordMsg = {objectNullandUndefined('194')}
                  emptyRecordMsg = {objectNullandUndefined('254')}
                  loading = {isLoading}
                  singleSelect
                  selectedValues={[selectedOptions3 || objectNullandUndefined('93')]}
                />
              </div>
              </div>

              <Button variant="contained" color="primary" className={classes.button} onClick={handelSave}>
                {objectNullandUndefined('255')}
              </Button>

            </div>
        </div>
        <Modal open={loading} className="loaderContainer2">
          <div>
            <CircularProgress className="loader2" size={40} thickness={4} />
          </div>
        </Modal>
    </>
  );
};

const useStyles = makeStyles((theme) => ({
  button: {
    marginTop: '10px',
    backgroundColor: '#2BAE66FF',
    '&:hover': {
      backgroundColor: '#5FC98BFF', // Lighter shade of the color on hover
    },
  },
  roo: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    minHeight: '100vh',
    backgroundColor: 'black',
  },
  containe: {
    display: 'flex',
    flexDirection: 'column',
    padding: '2em',
    justifyContent: 'space-evenly',
    backgroundColor: 'white',
    border: '2px solid black',
    borderRadius: '10px',
    width: '70%',
    height: '75vh',
    maxWidth: '400px',
    margin: 'auto',
    [theme.breakpoints.up('md')]: {
      width: '60%',
    },
    overflow:'scroll',
    '&:first-child': {
      paddingTop: 0, // Remove the top padding for the first child
      
    },
  },
  removeIcon: {
    cursor: 'pointer',
    color: 'red',
  },
  lists: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-start',
    justifyContent: 'space-between'},
}));