import React, { useState, useEffect, useRef } from 'react';
import './Filter.css';
import { bridgeSendingByUser } from '../../services/LoginLogoutService';
import { decode as base64_decode, encode as base64_encode } from 'base-64';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import uuid from 'react-uuid';
import { useHistory } from 'react-router-dom';
import { CandidateFilterFlow, CandidateFilterFlowID, CommonForumFlow, CommonForumID, FSServerID, MacAddress } from '../Dictionary/CommonStrings';
import { objectNullandUndefined } from '../Dictionary/Dictionary';

const Dropdown = ({ options }) => {
  const history = useHistory();
  const [isOpen, setIsOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState('');
  const [selectedOptionToSend, setSelectedOptionToSend] = useState('');
  const [searchTerm, setSearchTerm] = useState('');
  const dropdownRef = useRef(null);
  const myUuid = uuid();

  const notify = () => toast(objectNullandUndefined("58"));
  const toggleDropdown = () => {
    setIsOpen(!isOpen);
    if (!isOpen) {
      setSearchTerm('');
    }
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };

    document.addEventListener('click', handleClickOutside);

    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, []);
  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
  };

  const filteredOptions = options.filter((option) =>
  option.label.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const handleOptionClick = (option) => {
    setSelectedOption(option.id);
    setIsOpen(false);
    setSelectedOptionToSend(option.id)
    notify()
    // bridgeSending();
  };

  useEffect(() => {
    if (selectedOptionToSend) {
      bridgeSending();
    }
  }, [selectedOptionToSend]);

  async function bridgeSending() {
    let commonForumTemp = localStorage.getItem("commonUserFlows")
    let parsedFlow = JSON.parse(commonForumTemp)
    let parsedCommonFlow = parsedFlow["UserFlow"]
    let commonTempName = parsedFlow["Template"]
    let filterFlowID = parsedCommonFlow[commonTempName + '_CandidateFilter']
    try {
      let uuid = myUuid

      let userDetails = localStorage.getItem('userProfile');
      userDetails = base64_decode(userDetails);
      userDetails = JSON.parse(userDetails);
      let sessionID = userDetails.SessionID;
      let macAddress = userDetails.MACAddress;
      let flowName = commonTempName + '_CandidateFilter';
      let fID = filterFlowID['FID']
      let location = localStorage.getItem('selectedLocation');
      let tempJson = {
        ForumID: CommonForumID,
        SessionID: sessionID,
        MACAddress: macAddress,
        Time: '6516516156161',
        ScheduledDateTime: 'now',
        ScheduledBoolean: 0,
        FlowID: flowName,
        EnableChat: 0,
        FlowType: 'Custom',
        BridgeForward: 0,
        TemplateID: parsedFlow["TemplateID"],
        TextCount: 6,
        ImageCount: 0,
        InvoiceID: '',
        DocumentCount: 0,
        User: true,
        VideoCount: 0,
        ServerID: FSServerID,
        ReplyBridgeID: '',
        HiddenFlow: false,
        BilledForum: '',
        TempBridgeId: uuid,
        FID: fID,
        SentTo: '0',
      };
      
      let data1 = {
        'FilterType': (selectedOptionToSend),
        'CityType': (location)

      }
      let jsonData = {};
      let filterFlow = CommonForumFlow + '_CandidateFilter_Main-88f212c1-b826-490c-a351-ff093f97b87b_2'
      jsonData[filterFlow] = base64_encode(JSON.stringify(data1))
      tempJson[flowName] = jsonData;
      const data = new FormData();
      data.append('Data', JSON.stringify(tempJson));
      let bridgeData = await bridgeSendingByUser(data);
      if (bridgeData["ErrorCode"] === 1074) {
        toast.error((objectNullandUndefined('221')));
        setTimeout(() => {
          localStorage.clear();
          sessionStorage.clear();
          history.push('/');
          window.location.reload(false);
        }, 3000)
      }
    } catch (err) {
      return;
    }
  }



  return (
    <div className="dropdown" ref={dropdownRef}>
      <button className="dropdown-icon" onClick={() => toggleDropdown()} >
        <FilterAltIcon style={{ color: 'black',fontSize:'1.3em',marginTop:'13px',marginLeft:'-100px' }} /><p style={{ color: 'white', fontSize: '16px', padding: '10%', paddingBlockStart: '50%',marginTop:'-14px' }}></p>
      </button>
      {isOpen && (
        <ul
          style={{
            marginLeft:'-90px',
            marginTop:'40px',
            position: 'absolute',
            padding: 0,
            backgroundColor: 'black',
            border: '1px solid white',
            borderTop: 'none',
            overflowY: 'auto',
            width: '365px',
            height:'620px',
            zIndex:'999'
          }}
        >
          <li
            style={{
              padding: '4px',
              cursor: 'pointer',
              borderBottom: '1px solid #333',
              textAlign: 'center',
              fontSize: '16px',
              fontWeight: 'bold',
              marginBottom: '10px',
              color: '#fff',
            }}
          >
            <input
              type="text"
              placeholder="Search"
              value={searchTerm}
              onChange={handleSearchChange}
              style={{
                padding: '4px',
                cursor: 'pointer',
                textAlign: 'center',
                fontSize: '16px',
                fontWeight: 'bold',
                marginBottom: '10px',
                width: '100%',
                height: '45%',
              }}
            />
          </li>
          {filteredOptions.map((option) => (
            <li key={option.id} onClick={() => handleOptionClick(option)}
              style={{
                padding: '10px',
                cursor: 'pointer',
                borderBottom: '1px solid lightgray',
                textAlign: 'center',
                fontSize: '16px',
                fontWeight: 'bold',
                marginBottom: '10px',
                color: '#fff',
              }}
              onMouseEnter={(e) => {
                e.target.style.backgroundColor = 'lightgray';
              }}
              onMouseLeave={(e) => {
                e.target.style.backgroundColor = 'inherit';
              }}
            >
              {option.label}
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

export default Dropdown;
