import React, { useState, useEffect, useRef } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import TextInput from '@material-ui/core/TextField';
import Container from '@material-ui/core/Container';
import { Card } from '@material-ui/core';
import { downloadProfilePhoto, clientSync, updateProfile, fetchOldData, forumDetails, fetchForumInfo, DeleteForum } from '../../services/LoginLogoutService';
import {
  Dialog,
  List,
  ListItem,
  ListItemText,
  DialogTitle,
  Divider,
  ListItemAvatar,
  Avatar,
  Button,
  DialogActions
} from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';
import PhotoCameraIcon from '@mui/icons-material/PhotoCamera';
import InputAdornment from '@material-ui/core/InputAdornment';
import { decode as base64_decode, encode as base64_encode } from 'base-64';
import { Alert, useAccordionButton } from 'react-bootstrap';
import Modal from '@material-ui/core/Modal';
import CircularProgress from '@material-ui/core/CircularProgress';
import { objectNullandUndefined } from '../Dictionary/Dictionary';
import CloseIcon from '@mui/icons-material/Close';
import defaultPhoto from '../../assets/img/default.jpeg'
import Hook from '../Hook';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { useHistory } from 'react-router-dom';
import { deleteUserAccount } from '../../services/LoginLogoutService';
import { CAServerID, FSServerID, LLServerID, MacAddress, loginServerIP } from '../Dictionary/CommonStrings';
import { ToastContainer, toast } from 'react-toastify'; import 'react-toastify/dist/ReactToastify.css';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
export default function Account() {
  const history = useHistory();
  const [username, setUserName] = useState('');
  const [firstname, setFirstName] = useState('');
  const [lastname, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [pic, setPic] = useState('');
  const [key, setKey] = useState('');
  const [isUserNameEditing, setIsUserNameEditing] = useState(false);
  const [isFirstNameEditing, setIsFirstNameEditing] = useState(false);
  const [isLastNameEditing, setIsLastNameEditing] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);
  const [file, setFile] = useState(null);
  const [loading, setLoading] = useState(false);
  const [loader, setLoader] = useState(false);
  const [profilepic, setprofilepic] = useState();
  const [menuOpt, setMenuOpt] = useState(null);
  const fileInputRef = useRef(null);
  const [open, setOpen] = useState(false);
  const [forumNames, setForumNames] = useState([]);

  useEffect(() => {
    const handleServiceWorkerMessage = (event) => {
      if (event.data.type === 'navigate') {
        history.push('/Notification');
      }
    };

    navigator.serviceWorker.addEventListener('message', handleServiceWorkerMessage);

    return () => {
      navigator.serviceWorker.removeEventListener('message', handleServiceWorkerMessage);
    };
  }, []);
  
  function getRandomColor() {
    const letters = '0123456789ABCDEF';
    let color = '#';
    for (let i = 0; i < 6; i++) {
      color += letters[Math.floor(Math.random() * 16)];
    }
    return color;
  }
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClickCancel = () => {
    setOpen(false);
    setForumNames([])
  }

  const handleClose = () => {
    setOpen(false);
    setForumNames([])
    history.push('/Notification');
  };


  useEffect(() => {
    const fetchData = async () => {

      try {
        const jsonData = localStorage.getItem('userProfile');
        let decoded = base64_decode(jsonData);
        const userData = JSON.parse(decoded);
        console.log('this is userdata',userData.MACAddress);
        setUserName(userData.UserName);
        setFirstName(userData.FirstName);
        setLastName(userData.LastName);
        setprofilepic(userData.UserProfilePicture);
      } catch (e) {
        console.log('Error fetching data');
      }
    };
    fetchData();
  }, []);
  useEffect(() => {
    let data1 = localStorage.getItem('image')
    if (data1 != null) {
      setSelectedImage(data1)
    }
    else {
      const DownloadProfilePicture = () => {
        const userDetails = localStorage.getItem('userProfile');
        let decoded = base64_decode(userDetails);
        let userDetail = JSON.parse(decoded);
        let mediaID = userDetail['UserProfilePicture'];
        document.getElementById('profileImage').src = defaultPhoto;
        let jsonData = {
          "SessionID": userDetail['SessionID'],
          "MACAddress": userDetail['MACAddress'],
          "MediaID": mediaID,
          "ServerID": LLServerID
        }
        downloadProfilePhoto(jsonData).then(response => {
          if (response.length != 0) {
            let sourc = document.getElementById('profileImage');
            if (sourc != null) {
              sourc.src = window.URL.createObjectURL(new Blob([response]));
            }
          }
        });
      }
      DownloadProfilePicture();

    }

  }, []);

  useEffect(() => {
    const fetchEmail = async () => {
      try {
        const jsonData = localStorage.getItem('userEmail');
        let decoded = base64_decode(jsonData);
        const usere = JSON.parse(decoded);
        setEmail(usere.UserName);
      } catch (e) {
        console.log('Error fetching data');
      }
    };
    fetchEmail();
  }, []);
  const retrive = () => {
    const data = localStorage.getItem('recruitValue')
    if (data == "true") {
      setKey(true);
    }
    if (data == "false") {
      setKey(false);
    }
  }
  useEffect(() => {
    retrive();
  }, []);
  const handleUserNameEdit = () => {
    setIsUserNameEditing(true);
  };

  const handleUserNameChange = (event) => {
    const inputValue = event.target.value;
    if (inputValue.startsWith('@') && !inputValue.includes(' ') && /^[a-zA-Z0-9@]+$/.test(inputValue)) {
      setUserName(inputValue);
    } else {
    }
  };


  const handleFirstNameEdit = () => {
    setIsFirstNameEditing(true);
  };

  const handleFirstNameChange = (event) => {
    const inputValue = event.target.value;
    if (inputValue && !/^[a-zA-Z]/.test(inputValue.charAt(0))) {
      toast.warning(objectNullandUndefined('160'))
      event.preventDefault();
    } else {
      setFirstName(inputValue);
    }
  };

  const handleLastNameEdit = () => {
    setIsLastNameEditing(true);
  };

  const handleLastNameChange = (event) => {
    const inputValue = event.target.value;
    if (inputValue && !/^[a-zA-Z]/.test(inputValue.charAt(0))) {
      toast.warning(objectNullandUndefined('160'));
      event.preventDefault();
    } else {
      setLastName(inputValue);
    }
  };
  const handleImageUpload = (event) => {
    const file = event.target.files[0];
    const fileSizeInKB = file.size / 1024;
    if (fileSizeInKB < 2000) {
      setFile(file);
      const reader = new FileReader();

      reader.onload = async (e) => {
        localStorage.setItem('image', e.target.result);
        setSelectedImage(e.target.result);
      }
      if (file) {
        reader.readAsDataURL(file);
      }
    }
    else {
      toast.error(objectNullandUndefined('146'));
    }


  };
  const Imagelibrary = () => {
    fileInputRef.current.click();
    setLoader(false);
  }

  const Cancel = () => {
    setLoader(false);

  }
  const handleOpenMenu = (event) => {
    setMenuOpt(event.currentTarget);
  };
  const handleCloseMenu = () => {
    setMenuOpt(null);
  };
  const handleDeleteClick = async (option) => {
    setLoading(true);
    handleCloseMenu();
    const confirmed = window.confirm(objectNullandUndefined('224'));
    let data = await fetchForumInfo();
    const jsonData = localStorage.getItem('forumdata1');
    const userData = JSON.parse(jsonData);
    if (userData) {
      let deleteResponse = await DeleteForum(userData)
      if (deleteResponse["ErrorCode"] === 1074) {
        toast.error((objectNullandUndefined('221')));
        setTimeout(() => {
          localStorage.clear();
          sessionStorage.clear();
          history.push('/');
          window.location.reload(false);
        }, 3000)
      }
    }
    if (confirmed) {
      let userDetails = localStorage.getItem('userProfile');
      userDetails = base64_decode(userDetails);
      userDetails = JSON.parse(userDetails);
      let deleteJSON = {
        "SessionID": userDetails['SessionID'],
        "MACAddress": userDetails['MACAddress'],
        "DeleteAccount": "True",
        "ServerID": LLServerID
      }
      const accountDelete = await deleteUserAccount(deleteJSON)
      if (accountDelete["ErrorCode"] === 1074) {
        toast.error((objectNullandUndefined('221')));
        setTimeout(() => {
          localStorage.clear();
          sessionStorage.clear();
          history.push('/');
          window.location.reload(false);
        }, 3000)
      }
      if (accountDelete["ErrorCode"] === 1128) {
        toast.success((objectNullandUndefined('227')));
        setTimeout(() => {
          localStorage.clear();
          sessionStorage.clear();
          history.push('/');
          window.location.reload(false);
          setLoading(false);
        }, 3000)
      }
      else if (accountDelete["ErrorCode"] === 1143) {
        let delForums = accountDelete["ErrorMessage"]
        for (let i = 0; i < delForums.length; i++) {
          let deleteForumID = delForums[i]
          let tempJson = {
            "MACAddress": userDetails['MACAddress'],
            "ForumID": deleteForumID,
            "SessionID": userDetails['SessionID'],
            "ServerID": CAServerID
          };
          let forumIDs = await forumDetails(tempJson)
          if (forumIDs["ErrorCode"] === 1074) {
            toast.error((objectNullandUndefined('221')));
            setTimeout(() => {
              localStorage.clear();
              sessionStorage.clear();
              history.push('/');
              window.location.reload(false);
            }, 3000)
          }
          if (forumIDs["ErrorCode"] === 1172) {
            let forumNames1 = forumIDs["ErrorMessage"]["ForumDataArray"][0][deleteForumID]["ForumDetails"]["ForumName"]
            if (forumNames1 == "Archive492000" && delForums.length == 1) {
              let deleteForumID = delForums[0];
              let deletearchiveForum = await DeleteForum(deleteForumID)
              if (deletearchiveForum['ErrorCode'] == 1130 || deletearchiveForum['ErrorCode'] == 1032) {
                let deleteAccount  =  await deleteUserAccount(deleteJSON)
                if (deleteAccount["ErrorCode"] === 1074) {
                  toast.error((objectNullandUndefined('221')));
                  setTimeout(() => {
                    localStorage.clear();
                    sessionStorage.clear();
                    history.push('/');
                    window.location.reload(false);
                  }, 3000)
                }
                if (deleteAccount["ErrorCode"] === 1128) {
                  toast.success((objectNullandUndefined('227')));
                  setTimeout(() => {
                    localStorage.clear();
                    sessionStorage.clear();
                    history.push('/');
                    window.location.reload(false);
                    setLoading(false);
                  }, 3000)
                }
              }
            }
            else{
              setForumNames((prevArray) => [...prevArray, forumNames1]);
            }
          }

        }
        handleClickOpen()
        setLoading(false);
        // let forumIDs = await forumDetails(tempJson)
        // let item = localStorage.getItem('userData');
        // let parsedData = JSON.parse(item)
        // const names = parsedData.map((forum) => forum.ForumName);
        // setForumNames(names)
        // handleClickOpen()
        // setLoading(false);
      }
      else {
        toast.warning((objectNullandUndefined('56')));
        setLoading(false);
      }
    }
    setLoading(false);
  };
  const handleCameraClick = () => {
    setLoader(true);
  };
  const UpdateProfile = async (rempvepic) => {
    const userDetails = localStorage.getItem('userProfile');
    let userDetails1 = base64_decode(userDetails);
    let userDetails2 = JSON.parse(userDetails1)
    if (file === null && userDetails2['FirstName'] === firstname && userDetails2['LastName'] === lastname && rempvepic != true) {
      setIsFirstNameEditing(false)
      setIsLastNameEditing(false)
      toast.warning(objectNullandUndefined('154'));

    }
    else {
      if (firstname.length > 0 && lastname.length > 0) {
        if (firstname.length < 33 && lastname.length < 33) {

          setLoading(true);
         
          const userDetails = localStorage.getItem('userProfile');
          let userDetails1 = base64_decode(userDetails);
          let userDetails2 = JSON.parse(userDetails1)
          // if(userDetails2['FirstName'] === firstname && userDetails2['LastName'] === lastname )
          let sessionID = userDetails2['SessionID'];
          let macAddress = userDetails2['MACAddress'];
          let tempJson = {
            SessionID: sessionID,
            MACAddress: macAddress,
            FirstName: firstname,
            LastName: lastname,
            Device: false,
            UserPictureRemoved: rempvepic,
            ServerID: LLServerID,
          }

          const data = new FormData();
          data.append('Data', JSON.stringify(tempJson));
          data.append("UserProfilePicture", file);
          let profiledata = await updateProfile(data);
          if (profiledata["ErrorCode"] === 1074) {
            toast.error((objectNullandUndefined('221')));
            setTimeout(() => {
              localStorage.clear();
              sessionStorage.clear();
              history.push('/');
              window.location.reload(false);
            }, 3000)
          }
          if (profiledata['ErrorCode'] == 1006) {
            userDetails2['FirstName'] = firstname;
            userDetails2['LastName'] = lastname;
            localStorage.setItem(
              'userProfile',
              base64_encode(JSON.stringify(userDetails2))
            );

          }
          setIsLastNameEditing(false)
          setIsFirstNameEditing(false)
          toast.success(objectNullandUndefined('94'));
          setLoading(false);
          setLoader(false);
          if (rempvepic === true) {
            let data1 = defaultPhoto;
            localStorage.setItem('image', data1);
            window.location.reload();
          }


        }
        else {
          toast.warning(objectNullandUndefined('147'));
        }
      }
      else {
        toast.warning(objectNullandUndefined('148'));
      }

    }

  }
  const UpdateCandidate = async (removepic) => {
    const userDetails = localStorage.getItem('userProfile');
    let userDetails1 = base64_decode(userDetails);
    let userDetails2 = JSON.parse(userDetails1)
    if (file === null && userDetails2['FirstName'] === firstname && userDetails2['LastName'] === lastname && removepic != true) {
      setIsFirstNameEditing(false)
      setIsLastNameEditing(false)
      toast.warning(objectNullandUndefined('154'));
    }
    else {
      if (firstname.length > 0 && lastname.length > 0) {
        if (firstname.length < 33 && lastname.length < 33) {

          setLoading(true);
     
          const userDetails = localStorage.getItem('userProfile');
          let userDetails1 = base64_decode(userDetails);
          let userDetails2 = JSON.parse(userDetails1)
          let sessionID = userDetails2['SessionID'];
          let macAddress = userDetails2['MACAddress'];
          let tempJson = {
            SessionID: sessionID,
            MACAddress: macAddress,
            FirstName: firstname,
            LastName: lastname,
            Device: false,
            UserPictureRemoved: removepic,
            ServerID: LLServerID,
          }
          const data = new FormData();
          data.append('Data', JSON.stringify(tempJson));
          data.append("UserProfilePicture", file);


          let profiledata = await updateProfile(data);
          if (profiledata["ErrorCode"] === 1074) {
            toast.error((objectNullandUndefined('221')));
            setTimeout(() => {
              localStorage.clear();
              sessionStorage.clear();
              history.push('/');
              window.location.reload(false);
            }, 3000)
          }
          if (profiledata['ErrorCode'] == 1006) {
            userDetails2['FirstName'] = firstname;
            userDetails2['LastName'] = lastname;
            localStorage.setItem(
              'userProfile',
              base64_encode(JSON.stringify(userDetails2))
            );

          }
          setIsLastNameEditing(false)
          setIsFirstNameEditing(false)
          toast.success(objectNullandUndefined('94'));
          // setTimeout(() => {
          //   history.push('/ShortContainer');
          // }, 2000)
          setLoading(false);
          setLoader(false);
          if (removepic === true) {
            let data1 = defaultPhoto;
            localStorage.setItem('image', data1);
            window.location.reload();
          }

        } else {
          toast.warning(objectNullandUndefined('147'));

        }

      } else {
        toast.warning(objectNullandUndefined('148'));

      }

    }

  }


  const Username = async () => {
    const userDetails = localStorage.getItem('userProfile');
    let userDetails1 = base64_decode(userDetails);
    let userDetails2 = JSON.parse(userDetails1)
    if (userDetails2['UserName'] === username) {
      toast.warning(objectNullandUndefined('154'));
    } else {
      if (username.length >= 4 && username.length <= 33) {
        if (/^[a-zA-Z]{3}/.test(username.substring(1, 4))) {
          setIsUserNameEditing(false);
          setLoading(true);
       
          let userDetails = localStorage.getItem('userProfile');
          let userDetails1 = base64_decode(userDetails);
          let userDetails2 = JSON.parse(userDetails1)
          let sessionID = userDetails2['SessionID'];
          let macAddress = userDetails2['MACAddress'];
          const data = {
            'SessionID': sessionID,
            'MACAddress': macAddress,
            'ServerID': LLServerID,
            'UserName': username,
          };
          const response = await fetch(loginServerIP + 'checkUserAlias', {
            method: 'POST',
            headers: {
              Accept: 'application/json',
              'Content-Type': 'application/json',
            },
            body: JSON.stringify(data),
          });
          const responseData = await response.json();
          if (responseData['ErrorCode'] == 1015) {
            const tempJson = {
              'SessionID': sessionID,
              'MACAddress': macAddress,
              'Device': false,
              'ServerID': LLServerID,
              'UserName': username,
            };
            const response = await fetch(loginServerIP + 'updateUserAlias', {
              method: 'POST',
              headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
              },
              body: JSON.stringify(tempJson),
            });
            const responseData = await response.json();
            // Alert.alert('User Name Updated successfully');
            if (responseData['ErrorCode'] == 1017) {
              userDetails2['UserName'] = username;
              localStorage.setItem(
                'userProfile',
                base64_encode(JSON.stringify(userDetails2)),
              );
              toast.success(objectNullandUndefined('145'));
              setLoading(false);


            }
            if (responseData['ErrorCode'] == 1072) {
              toast.warning(objectNullandUndefined('162'));
              setLoading(false);
              setIsUserNameEditing(true);
            }
          }
          else {
            const jsonData = localStorage.getItem('userProfile');
            let decoded = base64_decode(jsonData);
            const userData = JSON.parse(decoded);
            toast.warning(objectNullandUndefined('164'));
            setLoading(false);
            setUserName(userData.UserName);

          }

        }
        else {
          toast.warning(objectNullandUndefined('178'));
        }

      }
      else {
        toast.warning(objectNullandUndefined('150'));
      }


    }

  };

  const classes = useStyles();
  if (key === true) {
    return (
      <>      <Hook
        short={'ACCOUNT'}
      />
        <ToastContainer position="top-center" autoClose={2000} hideProgressBar />

        <div className={classes.roo}>
          <Container className={classes.containe}>
            <Tooltip title="Delete Account">
              <IconButton
                onClick={handleOpenMenu}
                style={{
                  position: 'relative',
                  color: '#000000',
                  top: -10,
                  left: 170,
                }}
              >
                <MoreVertIcon style={{ fontSize: '32px' }} />
              </IconButton>
            </Tooltip>
            <Menu
              id="menu"
              anchorEl={menuOpt}
              open={Boolean(menuOpt)}
              onClose={handleCloseMenu}
              PaperProps={{
                style: {
                  marginLeft: '-80px',
                },
              }}
            >
              <MenuItem onClick={() => handleDeleteClick('Delete Account')}>{objectNullandUndefined('226')}</MenuItem>
              {/* <MenuItem onClick={() => handleDeleteClick('Option 2')}>Option 2</MenuItem>
              <MenuItem onClick={() => handleDeleteClick('Option 3')}>Option 3</MenuItem> */}
            </Menu>
            <Dialog open={open}>
              <DialogTitle>{objectNullandUndefined('225')}</DialogTitle>
              <List>
                {forumNames.map((item, index) => (
                  <div key={index}>
                    <ListItem className={classes.listItem} >
                      <ListItemAvatar style={{ textAlign: 'center' }}>
                        <Avatar style={{ backgroundColor: getRandomColor() }}>{item.charAt(0)}</Avatar>
                      </ListItemAvatar>
                      <ListItemText primary={item} />
                    </ListItem>
                    {index < forumNames.length - 1 && <Divider style={{ borderWidth: '1px', backgroundColor: 'rgba(0, 0, 0, 5.12)' }} />}
                  </div>
                ))}
              </List>
              <DialogActions>
                <Button onClick={handleClickCancel} color="primary">
                  {objectNullandUndefined('16')}
                </Button>
                <Button onClick={handleClose} color="primary">
                  {objectNullandUndefined('196')}
                </Button>
              </DialogActions>
            </Dialog>

            <label htmlFor="avatarInput">
              <input
                id="avatarInput"
                type="file"
                accept="image/*"
                capture="camera"
                onChange={handleImageUpload}
                ref={fileInputRef}
                style={{ display: 'none' }}
              />
              <img
                id="profileImage"
                src={selectedImage}
                onError={(e) => {
                  e.target.src = defaultPhoto;
                }}
                className={classes.avata} />
              <div>
                <button onClick={handleCameraClick} className={classes.camerabutton}>
                  <PhotoCameraIcon style={{ color: 'black', marginLeft: '130px', marginTop: '-70px', fontSize: '33px' }} />
                </button>
              </div>
            </label>

            <Typography variant="h6" color="textPrimary" component="div" className={classes.textFiel1}>
              {objectNullandUndefined('71')}
            </Typography>
            <TextField
              type="text"
              placeholder={objectNullandUndefined('71')}
              value={username}
              onChange={handleUserNameChange}
              disabled={!isUserNameEditing}
              className={classes.text}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    {isUserNameEditing ? (
                      <button className={classes.updateButton3} onClick={Username}>
                        {objectNullandUndefined('96')}
                      </button>
                    ) : (
                      <EditIcon className={classes.editIcon} onClick={handleUserNameEdit} />
                    )}
                  </InputAdornment>
                ),
              }} />

            <Typography variant="h6" color="textPrimary" component="div" className={classes.textFiel2}>
              {objectNullandUndefined('97')}
            </Typography>
            <TextField
              type="text"
              placeholder={objectNullandUndefined('97')}
              value={firstname}
              onChange={handleFirstNameChange}
              disabled={!isFirstNameEditing}
              className={classes.text}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    {isFirstNameEditing ? null : (
                      <EditIcon className={classes.editIcon} onClick={handleFirstNameEdit} />
                    )}
                  </InputAdornment>
                ),
              }} />
            <Typography variant="h6" color="textPrimary" component="div" className={classes.textFiel2}>
              {objectNullandUndefined('98')}
            </Typography>
            <TextField
              type="text"
              placeholder={objectNullandUndefined('98')}
              value={lastname}
              onChange={handleLastNameChange}
              disabled={!isLastNameEditing}
              className={classes.text}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    {isLastNameEditing ? null : (
                      <EditIcon className={classes.editIcon} onClick={handleLastNameEdit} />
                    )}
                  </InputAdornment>
                ),
              }} />


            <Typography variant="h6" color="textPrimary" component="div" className={classes.textFiel2}>
              {objectNullandUndefined('88')}
            </Typography>
            <TextField
              type="email"
              placeholder={objectNullandUndefined('88')}
              value={email}
              onChange={(event) => setEmail(event.target.value)}
              InputProps={{
                readOnly: true,
              }}
              className={classes.text} />
            <div className={classes.updateButtonContainer}>
              <button className={classes.updateButton} onClick={() => {
                UpdateProfile();
              }}>
                {objectNullandUndefined('96')}
              </button>
              <Modal open={loading} className={classes.loaderContainer}>
                <div>
                  <CircularProgress className={classes.loader} size={40} thickness={3} />
                </div>
              </Modal>
            </div>
          </Container>
          {loader === true && (
            <div className={classes.popup1}>

              <div style={{ textAlign: 'center', position: 'absolute', marginLeft: '25%' }}>
                <p style={{ fontSize: '25px', color: 'black' }}>
                  {objectNullandUndefined('93')}
                </p>
              </div>

              <div style={{ marginLeft: '90%', marginTop: '2%', position: 'absolute' }}>
                <CloseIcon
                  style={{
                    fontSize: 25,
                    color: 'rgb(234, 67, 53)',
                    cursor: 'pointer',
                  }}
                  onClick={() => {
                    Cancel();
                  }} />
              </div>

              <button style={{ height: '20%', marginLeft: '5%' }}
                className={classes.imagelibrary}
                onClick={() => {
                  Imagelibrary();

                }}
              >
                {objectNullandUndefined('151')}
              </button>

              <button style={{ height: '20%', marginLeft: '10%' }}
                className={classes.remove}
                onClick={() => {
                  UpdateProfile(true);
                }}
              >
                {objectNullandUndefined('152')}

              </button>


              {/* {profilepic === "" && (
                <button style={{ height: '20%', marginLeft: '10%' }}
                  className={classes.remove}
                  onClick={() => {
                    toast.warning(objectNullandUndefined('169'));
                  }}
                >
                  {objectNullandUndefined('152')}

                </button>

              )} */}

            </div>
          )}
        </div>
      </>
    );
  }
  else {
    return (
      <>
        <Hook
          short={'ACCOUNT'}
        />
        <ToastContainer position="top-center" autoClose={2000} hideProgressBar />
        <div className={classes.roo}>
          <Container className={classes.containe}>
            <Tooltip title="Delete Account">
              <IconButton
                onClick={handleOpenMenu}
                style={{
                  position: 'relative',
                  color: '#000000',
                  top: -10,
                  left: 170,
                }}
              >
                <MoreVertIcon style={{ fontSize: '32px' }} />
              </IconButton>
            </Tooltip>
            <Menu
              id="menu"
              anchorEl={menuOpt}
              open={Boolean(menuOpt)}
              onClose={handleCloseMenu}
              PaperProps={{
                style: {
                  marginLeft: '-80px',
                },
              }}
            >
              <MenuItem onClick={() => handleDeleteClick('Delete Account')}>{objectNullandUndefined('226')}</MenuItem>
              {/* <MenuItem onClick={() => handleDeleteClick('Option 2')}>Option 2</MenuItem>
              <MenuItem onClick={() => handleDeleteClick('Option 3')}>Option 3</MenuItem> */}
            </Menu>
            <Dialog open={open}>
              <DialogTitle>{objectNullandUndefined('225')}</DialogTitle>
              <List>
                {forumNames.map((item, index) => (
                  <div key={index}>
                    <ListItem className={classes.listItem} >
                      <ListItemAvatar style={{ textAlign: 'center' }}>
                        <Avatar style={{ backgroundColor: getRandomColor() }}>{item.charAt(0)}</Avatar>
                      </ListItemAvatar>
                      <ListItemText primary={item} />
                    </ListItem>
                    {index < forumNames.length - 1 && <Divider style={{ borderWidth: '1px', backgroundColor: 'rgba(0, 0, 0, 5.12)' }} />}
                  </div>
                ))}
              </List>
              <Typography variant="body2" color="#000000" style={{ margin: '16px',fontSize: '1rem' }}>
                <span style={{ color: '#8B0000',fontSize: '1.5rem' }}>*</span>{objectNullandUndefined("228")}
              </Typography>
              <DialogActions>
                <Button onClick={handleClickCancel} color="primary">
                  {objectNullandUndefined('16')}
                </Button>
                <Button onClick={handleClickCancel} color="primary">
                  {objectNullandUndefined('196')}
                </Button>
              </DialogActions>
            </Dialog>
            <label htmlFor="avatarInput">
              <input
                id="avatarInput"
                type="file"
                accept="image/*"
                capture="camera"
                onChange={handleImageUpload}
                ref={fileInputRef}
                style={{ display: 'none' }}
              />
              <img
                id="profileImage"
                src={selectedImage}
                onError={(e) => {
                  e.target.src = defaultPhoto;
                }}
                className={classes.avata} />
              <div>
                <button onClick={handleCameraClick} className={classes.camerabutton}>
                  <PhotoCameraIcon style={{ color: 'black', marginLeft: '130px', marginTop: '-70px', fontSize: '33px' }} />
                </button>
              </div>
            </label>

            <Typography variant="h6" color="textPrimary" component="div" className={classes.textFiel2}>
              {objectNullandUndefined('71')}
            </Typography>
            <TextField
              type="text"
              placeholder={objectNullandUndefined('71')}
              value={username}
              onChange={handleUserNameChange}
              disabled={!isUserNameEditing}
              className={classes.text1}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    {isUserNameEditing ? (
                      <button className={classes.updateButton2} onClick={Username}>
                        {objectNullandUndefined('96')}
                      </button>
                    ) : (
                      <EditIcon className={classes.editIcon} onClick={handleUserNameEdit} />
                    )}
                  </InputAdornment>
                ),
              }} />

            <Typography variant="h6" color="textPrimary" component="div" className={classes.textFiel2}>
              {objectNullandUndefined('97')}
            </Typography>
            <TextField
              type="text"
              placeholder={objectNullandUndefined('97')}
              value={firstname}
              onChange={handleFirstNameChange}
              disabled={!isFirstNameEditing}
              className={classes.text1}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    {isFirstNameEditing ? null : (
                      <EditIcon className={classes.editIcon} onClick={handleFirstNameEdit} />
                    )}
                  </InputAdornment>
                ),
              }} />
            <Typography variant="h6" color="textPrimary" component="div" className={classes.textFiel2}>
              {objectNullandUndefined('98')}
            </Typography>
            <TextField
              type="text"
              placeholder={objectNullandUndefined('98')}
              value={lastname}
              onChange={handleLastNameChange}
              disabled={!isLastNameEditing}
              className={classes.text1}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    {isLastNameEditing ? null : (
                      <EditIcon className={classes.editIcon} onClick={handleLastNameEdit} />
                    )}
                  </InputAdornment>
                ),
              }} />


            <Typography variant="h6" color="textPrimary" component="div" className={classes.textFiel2}>
              {objectNullandUndefined('88')}
            </Typography>
            <TextField
              type="email"
              placeholder={objectNullandUndefined('88')}
              value={email}
              onChange={(event) => setEmail(event.target.value)}
              InputProps={{
                readOnly: true,
              }}
              className={classes.text1} />
            <div className={classes.updateButtonContainer}>
              <button className={classes.updateButton1} onClick={() => {
                UpdateCandidate();
              }}>
                {objectNullandUndefined('96')}
              </button>

              <Modal open={loading} className={classes.loaderContainer}>
                <div>
                  <CircularProgress className={classes.loader} size={40} thickness={3} />
                </div>
              </Modal>
            </div>
          </Container>
          {loader === true && (
            <div className={classes.popup1}>

              <div style={{ textAlign: 'center', position: 'absolute', marginLeft: '25%' }}>
                <p style={{ fontSize: '25px', color: 'black' }}>
                  {objectNullandUndefined('153')}
                </p>
              </div>

              <div style={{ marginLeft: '90%', marginTop: '2%', position: 'absolute' }}>
                <CloseIcon
                  style={{
                    fontSize: 25,
                    color: '#2BAE66FF',
                    cursor: 'pointer',
                  }}
                  onClick={() => {
                    Cancel();
                  }} />
              </div>

              <button style={{ height: '20%', marginLeft: '5%' }}
                className={classes.imagelibrarycandidate}
                onClick={() => {
                  Imagelibrary();

                }}
              >
                {objectNullandUndefined('151')}
              </button>


              <button style={{ height: '20%', marginLeft: '10%' }}
                className={classes.removecandidate}
                onClick={() => {
                  UpdateCandidate(true);
                }}
              >
                {objectNullandUndefined('152')}

              </button>


              {/* {profilepic === "" && (
                <button style={{ height: '20%', marginLeft: '10%' }}
                  className={classes.removecandidate}
                  onClick={() => {
                    toast.warning(objectNullandUndefined('163'));
                  }}
                >
                  {objectNullandUndefined('152')}

                </button>

              )} */}

            </div>

          )}
        </div></>
    );

  }
};


const useStyles = makeStyles((theme) => ({
  loaderContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100vh',
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
  },
  loader: {
    color: '#fff',
  },
  roo: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    minHeight: '93vh',
    backgroundColor: 'black',
  },
  containe: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '2em',
    backgroundColor: 'white',
    border: '2px solid black',
    borderRadius: '10px',
    width: '70%',
    maxWidth: '400px',
    margin: 'auto',
    [theme.breakpoints.up('md')]: {
      width: '60%',
    },
  },
  avata: {
    width: '170px',
    height: '170px',
    borderRadius: '50%',
    border: '2px solid black',
    marginTop: '1em',
  },
  text: {
    width: '100%',
    marginTop: '-0.2em',
    '& .MuiInputBase-root': {
      fontSize: '1.375em',
      fontWeight: 'bold',
      color: 'rgb(234,67,53)',
      backgroundColor: 'white',
      borderRadius: '4px',
      borderWidth: '5px',
      borderColor: 'gray',
    },

  },
  text1: {
    width: '100%',
    marginTop: '-0.2em',
    '& .MuiInputBase-root': {
      fontSize: '1.375em',
      fontWeight: 'bold',
      color: '#2BAE66FF',
      backgroundColor: 'white',
      borderRadius: '4px',
      borderWidth: '5px',
      borderColor: 'gray',
    },

  },
  textFiel1: {
    width: '100%',
    marginTop: '14px',
    '& .MuiInputBase-root': {
      fontSize: '1.375em',
      fontWeight: 'bold',
      color: 'red',
      backgroundColor: 'white',
      borderRadius: '4px',
      borderWidth: '5px',
      borderColor: 'gray',
    },
  },
  textFiel2: {
    width: '100%',
    marginTop: '3px',
    '& .MuiInputBase-root': {
      fontSize: '1.375em',
      fontWeight: 'bold',
      color: 'red',
      backgroundColor: 'white',
      borderRadius: '4px',
      borderWidth: '5px',
      borderColor: 'gray',
    },
  },
  editIcon: {
    top: '-29px',
    left: '140px',
    cursor: 'pointer',
  },
  camerabutton: {
    background: 'none',
    border: 'none',
    cursor: 'pointer',
    padding: '0',
    marginLeftleft: '50px',
  },

  cameraIcon: {
    width: '24px',
    height: '24px',
    marginLeftleft: '50px',
  },
  updateButtonContainer: {
    display: 'flex',
    justifycontent: 'center',
    marginTop: '20px'
  },
  updateButton: {
    height: '40px',
    width: '150px',
    border: 0,
    fontSize: '19px',
    borderRadius: 7,
    color: 'black',
    backgroundColor: 'rgb(234,67,53)'
  },
  updateButton1: {
    height: '40px',
    width: '150px',
    border: 0,
    fontSize: '19px',
    borderRadius: 7,
    color: 'black',
    backgroundColor: '#2BAE66FF'
  },
  updateButton2: {
    border: 0,
    borderRadius: 7,
    color: 'black',
    fontSize: '16px',
    height: '29px',
    backgroundColor: '#2BAE66FF'
  },
  updateButton3: {
    border: 0,
    borderRadius: 7,
    color: 'black',
    fontSize: '16px',
    height: '29px',
    backgroundColor: 'rgb(234,67,53)'
  },
  popup1: {
    position: 'absolute',
    backgroundColor: 'lightgrey',
    width: '25%',
    height: '25%',
    '@media (max-width: 1000px)': {
      width: '38%',
      height: '25%',
    },
    '@media (max-width: 786px)': {
      width: '50%',
      height: '30%',
    },
    '@media (max-width: 770px)': {
      width: '45%',
      height: '35%',
    },
  },
  imagelibrary: {
    width: '40%',
    backgroundColor: 'rgb(234,67,53)',
    border: 'none',
    marginTop: '25%',
    borderRadius: '4px',
    '@media (max-width: 768px)': {
      width: '60%',
    },
  },
  remove: {
    width: '40%',
    backgroundColor: 'rgb(234,67,53)',
    border: 'none',
    marginTop: '25%',
    borderRadius: '4px',
    '@media (max-width: 768px)': {
      width: '60%',
    },
  },
  imagelibrarycandidate: {
    width: '40%',
    backgroundColor: '#2BAE66FF',
    border: 'none',
    marginTop: '25%',
    borderRadius: '4px',
    '@media (max-width: 768px)': {
      width: '60%',
    },
  },
  removecandidate: {
    width: '40%',
    backgroundColor: '#2BAE66FF',
    border: 'none',
    marginTop: '25%',
    borderRadius: '4px',
    '@media (max-width: 768px)': {
      width: '60%',
    },

  },

}));
