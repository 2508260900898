import { LLServerID, FSServerID, CAServerID, loginServerIP, forumServerIP, caServerIP, fireBaseRestApi, CommonForumFlow, RecCreateTempName, ChatFlowID, RecUserFlow } from '../components/Dictionary/CommonStrings';
import axios from 'axios';
import uuid from 'react-uuid';
import { decode as base64_decode, encode as base64_encode } from 'base-64';
import { CommonForumID, CommonForumTemp, MacAddress,ArchiveForumReport,RecruitedCandidateForumReport,RecruitFlowReport } from "../components/Dictionary/CommonStrings";
import utf8 from 'utf8';
import { objectNullandUndefined } from '../components/Dictionary/Dictionary';
import { RecruiterForumTemp,CommonForumReport } from '../components/Dictionary/CommonStrings';
import { ArchiveTemp,IndexTemplateID } from '../components/Dictionary/CommonStrings';
export const loginWithGoogleAndFB = async (loginJson) => {
  const response = await fetch(loginServerIP + 'bridgeWeblGFBSignIn', {
    method: 'POST',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(loginJson)
  });
  return await response.json();
}

export const signInUsingGoogle = async (formData) => {
  return axios({
    method: "post",
    url: loginServerIP + 'googleLogin',
    data: formData,
    headers: { "Content-Type": "multipart/form-data" },
  })
    .then(function (response) {
      return response.data;
    })
    .catch(function (response) {
      console.log(response);
    });
}
export const downloadProfilePhoto = (jsonData) => {
  return axios({
    method: "POST",
    url: loginServerIP + 'getProfilePictureUsingMediaId',
    data: jsonData,
    headers: {
      'Content-Type': 'application/image'
    },
    responseType: 'blob',
  })
    .then(function (response) {
      return response.data;
    })
    .catch(function (response) {
      console.log(response);
    });
}

export const deleteUserAccount = async (formData) => {
  return axios({
    method: "post",
    url: loginServerIP + 'deleteUserAccount',
    data: formData,
    headers: { "Content-Type": 'application/image' },
  })
    .then(function (response) {
      return response.data;
    })
    .catch(function (error) {
      console.log(error);
    });
}

export const downloadMedia = (jsonData) => {
  return axios({
    method: "POST",
    url: forumServerIP + 'getSelectedForumMedia',
    data: jsonData,
    headers: {
      'Content-Type': 'application/image'
    },
    responseType: 'blob',
  })
    .then((response) => {
      return response.data;
    })
    .catch(function (response) {
      console.log(response);
    });
}



// export const generateReport = (Json) => {
//     return axios(forumServerIP + 'reportGeneration', {
//         method: 'POST',
//         headers: {
//             'Accept': 'application/json',
//             'Content-Type': 'application/json',
//         },
//         Json
//     })
//         .then(response => {
//             return response.json();
//         });
// }
export const generateReport = async (formData) => {
  return axios({
    method: "post",
    url: forumServerIP + 'reportGenerationForMultiplInputs',
    data: formData,
    headers: { "Content-Type": "multipart/form-data" },
  })
    .then(function (response) {
      return response.data;
    })
    .catch(function (response) {
      console.log(response);
    });
}
export const oneToOne = async formData => {
  return axios({
    method: 'post',
    url: forumServerIP + 'oneToOneChat',
    data: formData,
    headers: { 'Content-Type': 'multipart/form-data' },
  })
    .then(function (response) {
      return response.data;
    })
    .catch(function (response) {
      console.log(response);
    });
};

// export const clientSync = async (forumData) =>{
//   return axios({
//     method: "post",
//     url: forumServerIP + 'clientSync',
//     data: forumData,
//     headers: {'Content-Type': 'application/json'},
//     })

//     .then(function (response){
//       return response.data;
//     })
//     .catch(function(response){
//       console.log(response);
//     })
//   }

export const clientSync = json => {
  return fetch(forumServerIP + 'clientSync', {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(json),
  }).then(response => {
    // console.log(response);
    return response.json();
  })
};

export const fetchOldData = async () => {
  const userProfile = localStorage.getItem("userProfile")
  let userDetails = JSON.parse(base64_decode(userProfile))
  const data = {
    'SessionID': userDetails["SessionID"],
    'MACAddress': userDetails["MACAddress"],
    'ServerID': LLServerID
  };
  const response = await fetch(loginServerIP + 'fetchUserAllData', {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  });
  const responseData = await response.json();
  return responseData;
};
export const userJoinToForum = async jsonObject => {
  return fetch(forumServerIP + 'userJoinToForum', {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(jsonObject),
  }).then(response => {
    return response.json();
  });
};


export const fetchForumInfo = async () => {
  try {
    let CountObject = {}
    let item1 = JSON.parse(localStorage.getItem('userData'))
    if(item1 !== null && item1.length > 0){
      for (let index = 0; index < item1.length; index++) {
        const element = item1[index];
        let count =  element['Counts'] ?? 0
        CountObject[element['ForumID']] = count
      }
    }
    const userProfile = localStorage.getItem("userProfile")
    let userDetails = JSON.parse(base64_decode(userProfile))
    const response = await fetchOldData();
    let isRecuiter = localStorage.getItem('recruitValue')
    const forumDetailsArray = [];
    const forumDataArray = response["ForumDataArray"];
    if (forumDataArray !== undefined) {
      for (let i = 0; i < forumDataArray.length; i++) {
        const forumID = Object.keys(forumDataArray[i])[0];
        const template = forumDataArray[i][forumID]["NewForumJsonData"]["TemplateID"];
        const forumDetails = forumDataArray[i][forumID]["ForumDetails"];
        if (template === RecruiterForumTemp) {
          const admin = forumDetails["ForumAdmins"];
          const user = forumDetails["ForumUsers"];
          if (isRecuiter === "true" && admin.includes(userDetails["MobileNumber"])) {
            const forumName = forumDetails["ForumName"];
            const forumDescription = forumDetails["ForumDescription"];
            const forumDetail = {
              ForumID: forumID,
              ForumName: forumName,
              ForumDescription: forumDescription,
              Template: template,
              Counts: CountObject[forumID],
              BridgeIDs: []
            };
            forumDetailsArray.push(forumDetail);

          }
          else if (isRecuiter === "false" && user.includes(userDetails["MobileNumber"])) {
            try {
              const message = localStorage.getItem('userMessage');
              const recMessage = JSON.parse(message)
              const forumName = forumDetails["ForumName"]
              let receiverNotification = "Under Review";
              let epochTime = [];
              let oldForumdata = await getLasTfiftydata(forumID)
              if (oldForumdata['ErrorCode'] == 1053) {
              //   let userBridges = oldForumdata['ErrorMessage']['User']
              //   userBridges = userBridges.reverse();
              //   for (let bridge = 0; bridge < userBridges.length; bridge++) {
              //     let dataJson = userBridges[bridge]
              //     let keys = Object.keys(dataJson)
              //     if (keys[0] === 'Bridge') {
              //       const bridgeData = dataJson['Bridge'];
              //       if (bridgeData['ReplyBridgeID'] != "") {
              //         if (bridgeData['TemplateID'] == RecruiterForumTemp) {
              //           if (userDetails['MobileNumber'] == bridgeData['WhisperReceiverMobileNumber']) {
              //             // receiverNotification = base64_decode(bridgeData[RecCreateTempName+'_RecruiterMessage'][RecCreateTempName+'_RecruiterMessage_Main-6b141153-d01e-4749-9ddb-5542358d0ba4_2']);
              //             try {
              //               let decodedMessage = base64_decode(bridgeData[RecCreateTempName+'_RecruiterMessage'][RecCreateTempName+'_RecruiterMessage_Main-6b141153-d01e-4749-9ddb-5542358d0ba4_2']);
              //              let receiverNotification1 = utf8.decode(decodedMessage)
              //               receiverNotification.push(receiverNotification1)
              //               let time = (bridgeData['Time']);
                            
              //               epochTime.push(time)
              //             } catch {
              //               receiverNotification = base64_decode(bridgeData[RecCreateTempName+'_RecruiterMessage'][RecCreateTempName+'_RecruiterMessage_Main-6b141153-d01e-4749-9ddb-5542358d0ba4_2'])
                            
              //             }
              //           }
              //         }
              //       }
              //     }
              //   }
              // }
              let dataList = oldForumdata["ErrorMessage"]['User']
              let messageDisplayList = []
              for (let index = 0; index < dataList.length; index++) {
                  let singleMessage = {}
                  const element = dataList[index]['Bridge'];
                  if(element['FlowID'] === ChatFlowID){
                  singleMessage['BridgeID'] = element['BridgeID']
                  singleMessage['MobileNumber'] = element['MobileNumber']
                  let textMessage = '';
                  try{
                      textMessage = decodeURIComponent(escape(base64_decode(element[ChatFlowID][RecCreateTempName+'_Chat_Main-ff165321-0b90-4d07-917c-da5d002f11ba_2'])))
                  }catch(err){
                      textMessage = utf8.decode(element[RecCreateTempName+'_Chat_Main-ff165321-0b90-4d07-917c-da5d002f11ba_2'])
                  }
                  singleMessage['textMessage'] = textMessage
                  const date = new Date(element['Time'] * 1000);
                  const options = {
                      timeZone: 'Asia/Kolkata',
                      hour: '2-digit',
                      minute: '2-digit',
                      year: 'numeric',
                      month: 'short',
                      day: 'numeric',
                  };
                  let Time = date.toLocaleString('en-IN', options)
                  singleMessage['Time'] = Time
                  singleMessage['UserName'] = element['UserName']
                  messageDisplayList.push(singleMessage)
                  }else if(element['FlowID'] === RecUserFlow){
                    let mediaId = element['MediaID']
                    if (mediaId.length > 0) {
                      mediaId = element['MediaID'][0][RecCreateTempName + '_User_Main-d219b924-c3cd-437c-99b7-32c61d46c263_6']
                    }
                    localStorage.setItem(element['ForumID']+"myvideoMediaID",mediaId)
                  }
              }
              console.log(messageDisplayList);
              let reverseList = messageDisplayList.reverse();
               localStorage.setItem(forumID+"_"+userDetails['MobileNumber'],JSON.stringify(reverseList))
               localStorage.setItem("bridgeFetchCount",JSON.stringify(reverseList.length));
              if(reverseList.length > 0){
                receiverNotification = reverseList[reverseList.length - 1]['textMessage']
              }
            }
            let CountObject = {}
            let item1 = JSON.parse(localStorage.getItem('userData'))
            if(item1 !== null && item1.length > 0){
              for (let index = 0; index < item1.length; index++) {
                const element = item1[index];
                let count =  element['Counts'] ?? 0
                CountObject[element['ForumID']] = count
              }
            }
              const forumDetail = {
                ForumID: forumID,
                ForumName: forumName,
                ForumDescription: receiverNotification,
                Template: "",
                Counts: CountObject[forumID],
                BridgeIDs: [],
                EpochTime:[epochTime]
              }
              if (recMessage != null) {
                let keys = Object.keys(recMessage)
                if (keys.length != 0) {
                  if (forumID === recMessage['forumID']) {
                    forumDetail.ForumDescription = recMessage['msg']
                    // forumDetail.Counts = 1;
                  }
                }
                forumDetailsArray.push(forumDetail);
              } else {
                forumDetailsArray.push(forumDetail);
              }
            } catch (e) {
              const forumName = forumDetails["ForumName"]
              let oldForumdata = await getLasTfiftydata(forumID)
              const forumDetail = {
                ForumID: forumID,
                ForumName: forumName,
                ForumDescription: "Under Review",
                Template: "",
                Counts: 0,
                BridgeIDs: []
              }
              forumDetailsArray.push(forumDetail);
            }
          }
        }
        if (template == ArchiveTemp) {
          const admin = forumDetails["ForumAdmins"];
          const user = forumDetails["ForumUsers"];
          if (isRecuiter == "true" && admin.includes(userDetails["MobileNumber"])) {
            const forumName = forumDetails["ForumName"];
            const forumDescription = forumDetails["ForumDescription"];
            const forumDetail = {
              ForumID: forumID,
              ForumName: forumName,
              ForumDescription: forumDescription,
              Template: template,
              Counts: 0,
              BridgeIDs: []
            };
            if (forumDetail.ForumName == 'Archive492000') {
              localStorage.setItem('forumdata', JSON.stringify(forumDetail));
              localStorage.setItem('forumdata1', JSON.stringify(forumDetail.ForumID));
              localStorage.setItem(
                'Olddata', 'true'
              );
            }


          }
        }
      }
      let item1 = JSON.parse(localStorage.getItem('userData'))
        if(item1 != null){
          let notificaionList = []
          for (let i = 0; i < item1.length; i++) {
            if(Object.keys(item1[i]).includes('status')){
              notificaionList.push(item1[i])
            }
          }
          let newforumDetailsArray  = [...notificaionList,...forumDetailsArray]
          localStorage.setItem('userData', JSON.stringify(newforumDetailsArray));
        }else{
          localStorage.setItem('userData', JSON.stringify(forumDetailsArray));
        }

    }
  } catch (error) {
    console.error(error);
  }
};

export const fetchForumInfo1 = async () => {
  try {
    let CandiadateIntrest = [];
    const userProfile = localStorage.getItem("userProfile")
    let userDetails = JSON.parse(base64_decode(userProfile))
    const response = await fetchOldData();
    let isRecuiter = localStorage.getItem('recruitValue')
    const forumDetailsArray = [];
    const forumDataArray = response["ForumDataArray"];
    if (forumDataArray !== undefined) {
      for (let i = 0; i < forumDataArray.length; i++) {
        const forumID = Object.keys(forumDataArray[i])[0];
        const template = forumDataArray[i][forumID]["NewForumJsonData"]["TemplateID"];
        const forumDetails = forumDataArray[i][forumID]["ForumDetails"];
        if(template == IndexTemplateID){
          let forumName1 = forumDetails["ForumName"];
          CandiadateIntrest.push(forumName1);
        }
      }
      let stringnewCandidateIntrestList = JSON.stringify(CandiadateIntrest)
      localStorage.setItem('CandidateIntres',stringnewCandidateIntrestList)
      return CandiadateIntrest;
    }
  } catch (error) {
    console.error(error);
  }
};

export const DeleteForum = async (forumID) => {
  const userProfile = localStorage.getItem("userProfile")
  let userDetails = JSON.parse(base64_decode(userProfile))
  const data = {
    'SessionID': userDetails["SessionID"],
    'MACAddress': userDetails["MACAddress"],
    'ServerID': FSServerID,
    'ForumID': forumID,
  };
  const response = await fetch(forumServerIP + 'deleteForum', {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  });
  const responseData = await response.json();
  fetchForumInfo()
  return responseData;
};
export async function getLasTfiftydata(forumid) {
  const userProfile = localStorage.getItem("userProfile")
  let userDetails = JSON.parse(base64_decode(userProfile))
  let tempJson = {
    "MACAddress": userDetails["MACAddress"],
    "SessionID": userDetails["SessionID"],
    "ForumID": forumid,
    "UserDataLastCount": 0,
    "UserDataFetchCount": 20,
    "AdminDataLastCount": 0,
    "AdminDataFetchCount": 20,
    'ServerID': FSServerID
  }
  const response = await fetch(forumServerIP + 'clientSyncForumBridgesPagination', {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(tempJson),
  });
  const responseData = await response.json();
  return responseData;
}

export async function saveLocationToUserProfile(selectedLocation) {
  const userProfile = localStorage.getItem("userProfile")
  let userDetails = JSON.parse(base64_decode(userProfile))
  let tempJson = {
    'ServerID':LLServerID,
    'SessionID': userDetails["SessionID"],
    'MACAddress': userDetails["MACAddress"],
    "ThemeColorName": selectedLocation,
    "ThemeColorCode": '#008080',
  }
  const response = await fetch(loginServerIP + 'updateThemeColor', {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(tempJson),
  });
  const responseData = await response.json();
  return responseData;
}

export const bridgeSendingByUser = async formData => {
  const URL = forumServerIP + 'saveForumBridges2'
  const xhr = new XMLHttpRequest();
  xhr.open('POST', URL);
  xhr.send(formData);
  xhr.onreadystatechange = e => {
    if (xhr.readyState !== 4) {
      return;
    }
    if (xhr.status === 200) {
      fetchForumInfo()
    } else {
      console.log('error', xhr.responseText);
    }
  };
};
export const updateProfile = async formData => {
  return axios({
    method: 'post',
    url: loginServerIP + 'updateUserProfile',
    data: formData,
    headers: { 'Content-Type': 'multipart/form-data' },
  })
    .then(function (response) {
      return response.data;
    })
    .catch(function (response) {
      console.log(response);
    });
}
export async function getLasTfiftydata1(forumid) {
  const userProfile = localStorage.getItem("userProfile")
  let userDetails = JSON.parse(base64_decode(userProfile))
  let tempJson = {
    "MACAddress":userDetails["MACAddress"],
    "SessionID": userDetails["SessionID"],
    "ForumID": forumid,
    "UserDataLastCount": 0,
    "UserDataFetchCount": 10,
    "AdminDataLastCount": 0,
    "AdminDataFetchCount": 0,
    'ServerID': FSServerID
    
  }
  const response = await fetch(forumServerIP + 'clientSyncForumBridgesPagination', {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(tempJson),
  });
  const responseData = await response.json();
  return responseData;
}

export async function getLasTfiftydata2(forumid) {
  const userProfile = localStorage.getItem("userProfile")
  let userDetails = JSON.parse(base64_decode(userProfile))
  let tempJson = {
    "MACAddress":userDetails["MACAddress"],
    "SessionID": userDetails["SessionID"],
    "ForumID": forumid,
    "UserDataLastCount": 0,
    "UserDataFetchCount": Number.MAX_SAFE_INTEGER,
    "AdminDataLastCount": 0,
    "AdminDataFetchCount": 0,
    'ServerID': FSServerID
    
  }
  const response = await fetch(forumServerIP + 'clientSyncForumBridgesPagination', {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(tempJson),
  });
  const responseData = await response.json();
  return responseData;
}

export async function exitForum(forumid) {
  const userProfile = localStorage.getItem("userProfile");
  let userDetails = JSON.parse(base64_decode(userProfile));
  let tempJson = {
    "MACAddress": userDetails["MACAddress"],
    "SessionID": userDetails["SessionID"],
    "ForumID": forumid,
    "MobileNumber": userDetails["MobileNumber"],
    "Owner": userDetails["UserName"],
    "DeleteAccount": false,
    "ServerID": FSServerID
  }
  const response = await fetch(forumServerIP + 'exitForum', {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(tempJson),
  });
  const responseData = await response.json();
  return responseData;
}

export const forumDetails = async jsonLang => {
  return fetch(caServerIP + 'getRequestedForumDetails', {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(jsonLang),
  }).then(response => {
    return response.json();
  });
}

export async function handleLanguage() {
  const userProfile = localStorage.getItem("userProfile");
  let userDetails = JSON.parse(base64_decode(userProfile));
  let tempJson = {
    "MACAddress": userDetails["MACAddress"],
    "ForumID": CommonForumID,
    "SessionID": userDetails["SessionID"],
    "ServerID": CAServerID
  }
  let language = await forumDetails(tempJson)
  let dataLang = language['ErrorMessage']['ForumDataArray']
  if (dataLang.length > 0) {
    for (let i = 0; i < dataLang.length; i++) {
      let forumDataLang = dataLang[i]
      let langKey = Object.keys(forumDataLang)
      if (langKey[0] === CommonForumID) {
        let languagess = forumDataLang[CommonForumID]['NewForumJsonData']['Language']
        localStorage.setItem('Languages', JSON.stringify(languagess))
      }
    }
  }
}

export const DeleteBridge = async json => {
  const response = await fetch(forumServerIP + 'deleteForumBridge', {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(json),
  });
  return await response.json();

}
export const modifyDeletedBridge = async json => {
  const response = await fetch(forumServerIP + 'modifyDeletedForumBridge', {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(json),
  });
  return await response.json();
}
export const GenerateApi = async shareLink => {
  let package_name = "com.cosmitude.prescreener"
  let bundleId = "org.reactjs.native.example.PreScreenerM"
  let json = {
    "dynamicLinkInfo": {
      "domainUriPrefix": "https://prescreenerm.page.link",
      "link": shareLink,
      "androidInfo": {
        "androidPackageName": package_name
      },
      "iosInfo": {
        "iosBundleId": bundleId
      },
    }
  }
  const response = await fetch(fireBaseRestApi, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(json),
  });
  return await response.json();
}

export const paymentBridge = async (message) => {
  const myUuid = uuid();
  let commonForumTemp = localStorage.getItem("commonUserFlows")
  let parsedFlow = JSON.parse(commonForumTemp)
  let parsedCommonFlow = parsedFlow["UserFlow"]
  let commonTempName = parsedFlow["Template"]
  let reportFlowID = parsedCommonFlow[commonTempName + '_Rpay']
  try {
    let uuid = myUuid;
   
    const userProfile = localStorage.getItem("userProfile");
    let userDetails = JSON.parse(base64_decode(userProfile));
    let sessionID = userDetails["SessionID"];
    let macAddress = userDetails["MACAddress"];
    let flowName = commonTempName + '_Rpay';
    let fID = reportFlowID["FID"];
    let tempJson = {
      ForumID: CommonForumID,
      SessionID: sessionID,
      MACAddress: macAddress,
      Time: '6516516156161',
      ScheduledDateTime: 'now',
      ScheduledBoolean: 0,
      FlowID: flowName,
      EnableChat: 0,
      FlowType: 'Custom',
      BridgeForward: 0,
      TemplateID: parsedFlow["TemplateID"],
      TextCount: 6,
      ImageCount: 0,
      InvoiceID: '',
      DocumentCount: 0,
      User: true,
      VideoCount: 0,
      ReplyBridgeID: '',
      ServerID: FSServerID,
      HiddenFlow: false,
      BilledForum: '',
      TempBridgeId: uuid,
      FID: fID,
      SentTo: '0',

    };
    let jsonData = {
      [CommonForumFlow + "_Rpay_Main-daa0e776-693d-4d68-8315-cbbe0b5c720b_2"]: base64_encode(message),
    };
    tempJson[flowName] = jsonData;
    const data = new FormData();
    data.append('Data', JSON.stringify(tempJson));
    let bridgeData = await bridgeSendingByUser(data);
  } catch (err) {
    return;
  }
}

export const LogoSpinner = () => {
  return (
    <div className='PSLoader'>
      <div>
        <img
          className="loading-logo"
          src="/logo.png"
          style={{ height: 300 }}
        />
      </div>
      <div>
        <p className='Loading'>{objectNullandUndefined('199')}</p>
      </div>
    </div>
  )
}

export async function commonForumFlows() {
  let userProfile = localStorage.getItem("userProfile");
  let userDetails = JSON.parse(base64_decode(userProfile));
  let sessionID = userDetails["SessionID"];
  let MacAddress = userDetails["MACAddress"];
  let tempJson = {
    "MACAddress": MacAddress,
    "ForumID": CommonForumID,
    "SessionID": sessionID,
    "ServerID": CAServerID
  }
  let flowsCommon = await forumDetails(tempJson)
  let commonFlows = flowsCommon['ErrorMessage']['ForumDataArray']
  if (commonFlows.length > 0) {
    for (let i = 0; i < commonFlows.length; i++) {
      let recForumData = commonFlows[i]
      let forumKey = Object.keys(recForumData)
      if (forumKey[0] === CommonForumID) {
        let commonUserFlows = recForumData[CommonForumID]['NewForumJsonData']
        localStorage.setItem("commonUserFlows", JSON.stringify(commonUserFlows))
        if (localStorage.getItem("recruitValue") === false) {
          paymentBridge("Hello Recruiter")
        } else {
          paymentBridge('Hello Candidate')
        }
      }
    }
  }
  return true;
}
export const languageList = async listJSON => {
  const response = await fetch(forumServerIP + 'getLanguageListFromForum', {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(listJSON),
  });
  return await response.json();
}

export async function langListCall() {
  let userProfile = localStorage.getItem("userProfile");
  let userDetails = JSON.parse(base64_decode(userProfile));
  let sessionID = userDetails["SessionID"];
  let MacAddress = userDetails["MACAddress"];
  let listJSON = {
    "ServerID": FSServerID,
    "MACAddress": MacAddress,
    "ForumID": CommonForumID,
    "SessionID": sessionID,
  }
  let listLangs = await languageList(listJSON)
  let languages = listLangs['Languages']
  localStorage.setItem("LanguageLists", JSON.stringify(languages))
}

export const languageLiterals = async langJson => {
  const response = await fetch(forumServerIP + 'getSelectedLanguageMetaFromForum', {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(langJson),
  });
  return await response.json();
}

export const saveLanguageToProfile = async langJson => {
  const response = await fetch(forumServerIP + 'getSelectedBridgeLanguageMeta', {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(langJson),
  });
  return await response.json();
}

export async function ArchiveConsolidatedReportGeneration(id) {
  const jsonData =  localStorage.getItem('forumdata');
  const userData = JSON.parse(jsonData);
  const forumId = userData.ForumID
  let Id = "";
  if (id != 'null') {
    Id = base64_encode(id);
  }
  const data = localStorage.getItem("userProfile");
  let decoded = base64_decode(data);
  const userDetails = JSON.parse(decoded);
  const inputData = {};
  const outputDat = {};
  const temFIJson = {};
  const tempList = [];
  const oFlowNameWithID = ArchiveForumReport;
  tempList.push(oFlowNameWithID);
  temFIJson[forumId] = tempList;
  const oTempInFoCo = { isDeleted: { $ne: true } };
  if (Id != "") {
    oTempInFoCo["ArchiveTemp_ArchiveVideo_Main-839837f8-217b-45a4-9f47-3e4fe8975a98_2"] = Id;
  }
  const tempFloIn = {};
  tempFloIn[oFlowNameWithID] = oTempInFoCo;
  inputData[forumId] = tempFloIn;
  const oTempOFoCo = { _id: 0 };
  const tempFliOt = {};
  tempFliOt[oFlowNameWithID] = oTempOFoCo;
  outputDat[forumId] = tempFliOt;
  const videoArr = [];
  let reportJson = {
      MACAddress: userDetails["MACAddress"],
      SessionID: userDetails["SessionID"],
      FlowNameIDList: temFIJson,
      ForumIDList: [forumId],
      InputData: inputData,
      OutputData: outputDat,
      ServerID: FSServerID,
  };
  const dataForum = new FormData();
  dataForum.append("Data", JSON.stringify(reportJson));
  let report = await axios({
      method: "post",
      url: forumServerIP + "reportGenerationForMultiplInputs",
      data: dataForum,
      headers: { "Content-Type": "multipart/form-data" },
  })
      .then(async function (response) {   
        // console.log('resposce',response)    
          let response1 = response.data["ErrorMessage"][0]
          let ForumID = Object.keys(response1)
          let fidKey = response1[ForumID]
          let FID = Object.keys(fidKey)
          let userDatas = fidKey[FID]

          if (Id != "") {
            const data = localStorage.getItem("userProfile");
            let decoded = base64_decode(data);
            const userDetails = JSON.parse(decoded);
            for (let i = 0; i < userDatas.length; i++) {
              const eachData = userDatas[i]
              let bridgeID1 = eachData["BridgeID"]
              let decodedreport = bridgeID1;       
            const adminflow = "ArchiveTemp_ArchiveVideo_57dcb8b3-d2e3-6ff9-d465-90cdbf706840_Admin";
            const forumId1 = forumId;
            let JsonObject = {
              "ForumID": forumId1,
              "BridgeID": decodedreport,
              "FlowID": adminflow,
              "MACAddress": userDetails["MACAddress"],
              'SessionID': userDetails["SessionID"],
              'ServerID': FSServerID,
            }
            const response = await fetch(forumServerIP + 'deleteForumBridge', {
              method: 'POST',
              headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
              },
              body: JSON.stringify(JsonObject),
            });
            const responseData = await response.json();
            console.log(responseData);
          }
        }else{
          for (let i = 0; i < userDatas.length; i++) {
            const eachData = userDatas[i]
            const bridgeID = eachData["BridgeID"]
            const data = eachData["ArchiveTemp_ArchiveVideo_Main-839837f8-217b-45a4-9f47-3e4fe8975a98_4"]
            const id = base64_decode(data);
            const userData = JSON.parse(id);
            let mediaId = userData.MediaID;
            let userName = userData.CompanyName;
            let profilepic = userData.Profilepic;
            let forumID = userData.ForumID;
            let mobileNum = userData.mobileNumber;
            let fullname = userData.FullName;
            let Forumname = ""
            try{
              Forumname = userData['forumData']['ForumName']
            }catch(err){
              if (userData && userData.hasOwnProperty('ForumName')) {
                Forumname = userData.ForumName
              } else {
                Forumname = ""
              }
            }
                const feedJson = {
                    "companyName": userName,
                    "Jd": profilepic,
                    "jType": bridgeID,
                    "mediaID": mediaId,
                    "ForumID": ForumID,
                    "MobileNumber": mobileNum,
                    "DeleteforumID" : forumID,
                    "ForumName":Forumname,
                    "FullName":fullname ??"Jack Doe"
                }
                let decodedreport = feedJson;
                // console.log('report json',decodedreport);
                videoArr.push(decodedreport)

        }
        }
      })
      .catch(function (response) {
          // console.log(response);
      });
  return videoArr
};

export async function RecruitedConsolidationReportGenration(forumId) {
  const data = localStorage.getItem("userProfile");
  let decoded = base64_decode(data);
  const userDetails = JSON.parse(decoded);
  const inputData = {};
  const outputDat = {};
  const temFIJson = {};
  const tempList = [];
  const oFlowNameWithID = RecruitedCandidateForumReport;
  tempList.push(oFlowNameWithID);
  temFIJson[forumId] = tempList;
  const oTempInFoCo = { isDeleted: { $ne: true } };
  const tempFloIn = {};
  tempFloIn[oFlowNameWithID] = oTempInFoCo;
  inputData[forumId] = tempFloIn;
  const oTempOFoCo = { _id: 0 };
  const tempFliOt = {};
  tempFliOt[oFlowNameWithID] = oTempOFoCo;
  outputDat[forumId] = tempFliOt;
  const videoArr = [];
  let reportJson = {
      MACAddress: userDetails["MACAddress"],
      SessionID: userDetails["SessionID"],
      FlowNameIDList: temFIJson,
      ForumIDList: [forumId],
      InputData: inputData,
      OutputData: outputDat,
      ServerID: FSServerID,
  };
  const dataForum = new FormData();
  dataForum.append("Data", JSON.stringify(reportJson));
  let report = await axios({
      method: "post",
      url: forumServerIP + "reportGenerationForMultiplInputs",
      data: dataForum,
      headers: { "Content-Type": "multipart/form-data" },
  })
      .then(async function (response) {     
          let response1 = response.data["ErrorMessage"][0]
          let ForumID = Object.keys(response1)
          let fidKey = response1[ForumID]
          let FID = Object.keys(fidKey)
          let userDatas = fidKey[FID]
       
          for (let i = 0; i < userDatas.length; i++) {
            const eachData = userDatas[i]
            const bridgeID = eachData["BridgeID"]
            const data = eachData[RecCreateTempName+"_RecruitedCandidate_Main-345bd685-8f77-47c4-ac6c-824a6b3cb791_4"]
            const id = base64_decode(data);
            const userData = JSON.parse(id);
            let mediaId = userData.MediaID;
            let userName = userData.CompanyName;
            let profilepic = userData.Profilepic;
            let forumID = userData.ForumID;
            let mobileNum = userData.mobileNumber
                const feedJson = {
                    "companyName": userName,
                    "Jd": profilepic,
                    "jType": bridgeID,
                    "mediaID": mediaId,
                    "ForumID": ForumID[0],
                    "MobileNumber": mobileNum,
                    "DeleteforumID" : forumID,
                }
                let decodedreport = feedJson;
                videoArr.push(decodedreport)

        }
      })
      .catch(function (response) {
      });
  return videoArr
};

export async function RecruitedConsolidationReportGenrationCount(forumId) {
  const data = localStorage.getItem("userProfile");
  let decoded = base64_decode(data);
  const userDetails = JSON.parse(decoded);
  const inputData = {};
  const outputDat = {};
  const temFIJson = {};
  const tempList = [];
  const oFlowNameWithID = RecruitedCandidateForumReport;
  tempList.push(oFlowNameWithID);
  temFIJson[forumId] = tempList;
  const oTempInFoCo = { isDeleted: { $ne: true } };
  const tempFloIn = {};
  tempFloIn[oFlowNameWithID] = oTempInFoCo;
  inputData[forumId] = tempFloIn;
  const oTempOFoCo = { _id: 0 };
  const tempFliOt = {};
  tempFliOt[oFlowNameWithID] = oTempOFoCo;
  outputDat[forumId] = tempFliOt;
  const videoArr = [];
  let userDatas = 0;
  let reportJson = {
      MACAddress: userDetails["MACAddress"],
      SessionID: userDetails["SessionID"],
      FlowNameIDList: temFIJson,
      ForumIDList: [forumId],
      InputData: inputData,
      OutputData: outputDat,
      ServerID: FSServerID,
  };
  const dataForum = new FormData();
  dataForum.append("Data", JSON.stringify(reportJson));
  let report = await axios({
      method: "post",
      url: forumServerIP + "reportGenerationForMultiplInputs",
      data: dataForum,
      headers: { "Content-Type": "multipart/form-data" },
  })
      .then(async function (response) {     
          let response1 = response.data["ErrorMessage"][0]
          let ForumID = Object.keys(response1)
          let fidKey = response1[ForumID]
          let FID = Object.keys(fidKey)
          userDatas = fidKey[FID]
          localStorage.setItem(ForumID+"RecruitedAnalytics",JSON.stringify(userDatas))
      })
      .catch(function (response) {
      });
  return userDatas.length
};

export async function AppliedConsolidationReportGenrationCount(forumId) {
  const data = localStorage.getItem("userProfile");
  let decoded = base64_decode(data);
  const userDetails = JSON.parse(decoded);
  const inputData = {};
  const outputDat = {};
  const temFIJson = {};
  const tempList = [];
  const oFlowNameWithID = CommonForumReport;
  tempList.push(oFlowNameWithID);
  temFIJson[forumId] = tempList;
  const oTempInFoCo = {};
  const tempFloIn = {};
  tempFloIn[oFlowNameWithID] = oTempInFoCo;
  inputData[forumId] = tempFloIn;
  const oTempOFoCo = { _id: 0 };
  const tempFliOt = {};
  tempFliOt[oFlowNameWithID] = oTempOFoCo;
  outputDat[forumId] = tempFliOt;
  let userDatas = 0;
  let reportJson = {
      MACAddress: userDetails["MACAddress"],
      SessionID: userDetails["SessionID"],
      FlowNameIDList: temFIJson,
      ForumIDList: [forumId],
      InputData: inputData,
      OutputData: outputDat,
      ServerID: FSServerID,
  };
  const dataForum = new FormData();
  dataForum.append("Data", JSON.stringify(reportJson));
  let report = await axios({
      method: "post",
      url: forumServerIP + "reportGenerationForMultiplInputs",
      data: dataForum,
      headers: { "Content-Type": "multipart/form-data" },
  })
      .then(async function (response) {     
          let response1 = response.data["ErrorMessage"][0]
          let ForumID = Object.keys(response1)
          let fidKey = response1[ForumID]
          let FID = Object.keys(fidKey)
          userDatas = fidKey[FID]
          localStorage.setItem(ForumID+"AppliedAnalytics",JSON.stringify(userDatas))
      })
      .catch(function (response) {
      });
  return userDatas.length
};

export async function underManualReviewConsolidationReportGenrationCount(forumId) {
  const data = localStorage.getItem("userProfile");
  let decoded = base64_decode(data);
  const userDetails = JSON.parse(decoded);
  const inputData = {};
  const outputDat = {};
  const temFIJson = {};
  const tempList = [];
  const oFlowNameWithID = CommonForumReport;
  tempList.push(oFlowNameWithID);
  temFIJson[forumId] = tempList;
  const oTempInFoCo = { isDeleted: { $eq: true } };
  const tempFloIn = {};
  tempFloIn[oFlowNameWithID] = oTempInFoCo;
  inputData[forumId] = tempFloIn;
  const oTempOFoCo = { _id: 0 };
  const tempFliOt = {};
  tempFliOt[oFlowNameWithID] = oTempOFoCo;
  outputDat[forumId] = tempFliOt;
  let userDatas = 0;
  let reportJson = {
      MACAddress: userDetails["MACAddress"],
      SessionID: userDetails["SessionID"],
      FlowNameIDList: temFIJson,
      ForumIDList: [forumId],
      InputData: inputData,
      OutputData: outputDat,
      ServerID: FSServerID,
  };
  const dataForum = new FormData();
  dataForum.append("Data", JSON.stringify(reportJson));
  let report = await axios({
      method: "post",
      url: forumServerIP + "reportGenerationForMultiplInputs",
      data: dataForum,
      headers: { "Content-Type": "multipart/form-data" },
  })
      .then(async function (response) {     
          let response1 = response.data["ErrorMessage"][0]
          let ForumID = Object.keys(response1)
          let fidKey = response1[ForumID]
          let FID = Object.keys(fidKey)
          userDatas = fidKey[FID]
      
      })
      .catch(function (response) {
      });
  return userDatas.length
};

export async function endDateReport(forumId) {
  const data = localStorage.getItem("userProfile");
  let decoded = base64_decode(data);
  const userDetails = JSON.parse(decoded);
  const inputData = {};
  const outputDat = {};
  const temFIJson = {};
  const tempList = [];
  const oFlowNameWithID = RecruitFlowReport;
  tempList.push(oFlowNameWithID);
  temFIJson[forumId] = tempList;
  const oTempInFoCo = {};
  const tempFloIn = {};
  tempFloIn[oFlowNameWithID] = oTempInFoCo;
  inputData[forumId] = tempFloIn;
  const oTempOFoCo = { _id: 0 };
  const tempFliOt = {};
  tempFliOt[oFlowNameWithID] = oTempOFoCo;
  outputDat[forumId] = tempFliOt;
  let userDatas = 0;
  let Time = ""
  let reportJson = {
      MACAddress: userDetails["MACAddress"],
      SessionID: userDetails["SessionID"],
      FlowNameIDList: temFIJson,
      ForumIDList: [forumId],
      InputData: inputData,
      OutputData: outputDat,
      ServerID: FSServerID,
  };
  const dataForum = new FormData();
  dataForum.append("Data", JSON.stringify(reportJson));
  let report = await axios({
      method: "post",
      url: forumServerIP + "reportGenerationForMultiplInputs",
      data: dataForum,
      headers: { "Content-Type": "multipart/form-data" },
  })
      .then(async function (response) {     
          let response1 = response.data["ErrorMessage"][0]
          let ForumID = Object.keys(response1)
          let fidKey = response1[ForumID]
          let FID = Object.keys(fidKey)
          userDatas = fidKey[FID]

          if (userDatas.length !== 0) {
            for (let i = 0; i < userDatas.length; i++) {
              const eachData = userDatas[i]
                let companyName1 = utf8.decode(base64_decode(eachData[RecCreateTempName+"_Recruiter_Main-5fbc611c-ba6c-49d8-a3b1-a36a1c807fbb_8"]))
                const date = new Date(companyName1 * 1000);
                const options = {
                  timeZone: 'Asia/Kolkata',
                  hour: '2-digit',
                  minute: '2-digit',
                  year: 'numeric',
                  month: 'short',
                  day: 'numeric',
                };
                Time = date.toLocaleString('en-IN', options)
              }
            }
      })
      .catch(function (response) {
      });
  return Time
};