import "./RecruitmentVideoUpload.css";
import React from "react";
import { forumDetails } from "../../services/LoginLogoutService";
import { useEffect, useRef, useState } from "react";
import { decode as base64_decode, encode as base64_encode } from 'base-64';
import axios from 'axios';
import { useHistory } from "react-router-dom";
import uuid from 'react-uuid';
import Fab from '@material-ui/core/Fab';
import UploadSharpIcon from '@mui/icons-material/UploadSharp';
import CameraAltIcon from '@mui/icons-material/CameraAlt';
import { useReactMediaRecorder } from "react-media-recorder";
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import Autocomplete from '@mui/material/Autocomplete';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import dayjs from 'dayjs';
import Webcam from 'react-webcam';
import { Container } from 'react-bootstrap';
import { Button } from "@mui/material";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { objectNullandUndefined } from "../Dictionary/Dictionary";
import { CircularProgress } from '@material-ui/core';
import { getLasTfiftydata, bridgeSendingByUser, fetchForumInfo, clientSync } from "../../services/LoginLogoutService";
import { Card, CardContent } from '@material-ui/core';
import { ArchiveTemp, ArchiveTempName, CommonForumID,CAServerID, CommonForumRecCreate, CommonForumRecCreateID, FSServerID, MacAddress, RecCreateTempName, RecruitCreateFlow, RecruitCreateFlowID, RecruiterForumTemp, forumServerIP,CommonForumTemp, CommonForumFlow, restoreBotPhoneNumber, requestChange } from "../Dictionary/CommonStrings";
import DialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { Dialog, DialogActions, DialogContent, DialogContentText } from '@mui/material';
import utf8 from 'utf8';
import Hook from "../Hook";
import { RecUserFlow } from "../Dictionary/CommonStrings";
import ReactGA from 'react-ga';
import AddIcon from '@mui/icons-material/Add';
import Modal from '@material-ui/core/Modal';
import { makeStyles } from '@material-ui/core/styles';

function Recruiter() {
  const history = useHistory();

  const [name, setName] = useState('');
  const [description, setDescription] = useState('');
  const [selectedValue, setSelectedValue] = useState(null);
  const [file, setFile] = useState('');
  const [videoUrl, setVideoUrl] = useState('');
  const [isPlaying, setIsPlaying] = useState(false);
  const videoRef = useRef(null);
  const [date, setDate] = useState('');
  const [selectCity, setSelectedCity] = React.useState(null)
  const myUuid = uuid();
  const [loading, setLoading] = useState(false);
  const [loading1, setLoading1] = useState(false);
  const [isRecording, setIsRecording] = React.useState(false);
  const [location, setlocation] = React.useState(false);
  const [catagory, setcatogary] = React.useState(false);
  const { status, startRecording, stopRecording, mediaBlobUrl } =
    useReactMediaRecorder({ video: true,mimeType: "video/mp4" });
  const [value, setValue] = useState(null);
  const webcamRef = useRef(null);
  const [paidPop, setPaidPop] = React.useState(false)
  const [logout, setlogout] = React.useState(false)
  const [showPermissionDialog, setShowPermissionDialog] = React.useState(false);
  const [blobUrl, setBlobUrl] = React.useState()
  const [locationdata, setLocation] = useState('');    
  const [timeoutId, setTimeoutId] = useState(null);   
       
  
  let isPaid = false;

  const [jobCategories, setJobCatogary] = useState([]);
  const [options, setJobObjCatogary] = useState([]);
  const [citiesO, setLocationsCatogary] = useState([]);
  const [cities, setLocationsOBJCatogary] = useState([]);

  useEffect(() => {
    const handleServiceWorkerMessage = (event) => {
      if (event.data.type === 'navigate') {
        history.push('/Notification');
      }
    };

    navigator.serviceWorker.addEventListener('message', handleServiceWorkerMessage);

    return () => {
      navigator.serviceWorker.removeEventListener('message', handleServiceWorkerMessage);
    };
  }, []);
  useEffect(()=>{
  ReactGA.pageview(window.location.pathname)
  },[])
  // const interval = setInterval(() => {
  //   clientSyncAdmin();
  // }, 10000);

  // const clientSyncAdmin = async () => {
  //   let userDetails = localStorage.getItem('userProfile');
  //   let decoded = base64_decode(userDetails)
  //   userDetails = JSON.parse(decoded);
  //   let clientSyncJson = {
  //     SessionID: userDetails.SessionID,
  //     MACAddress: MacAddress,
  //     ServerID: FSServerID,
  //   };
  //   let data = await clientSync(clientSyncJson);
  //   let dataArr = data["ErrorMessage"]['User']
  //   let dataAdminArr = data["ErrorMessage"]['Admin']
  //   if (dataArr.length > 0) {
  //     for (let i = 0; i < dataArr.length; i++) {
  //       let dataJson = data["ErrorMessage"]['User'][i]
  //       let keys = Object.keys(dataJson)
  //       if (keys[0] === 'Bridge') {
  //         const bridgeData = dataJson['Bridge'];
  //         let flowID = bridgeData['FlowID']
  //         if (flowID === CommonForumFlow + "_Data2") {
  //           let data = bridgeData[CommonForumFlow + "_Data2"]
  //           let jobSeeker = base64_decode(data['PreScreenerTemplate_Data2_Main-19ae7edb-d2ea-4a00-865a-711709c736b8_2'])
  //           if (jobSeeker === "Recruiter Pay Status") {
  //             let jobSeekerPayment = base64_decode(data[CommonForumFlow + "_Data2_Main-19ae7edb-d2ea-4a00-865a-711709c736b8_4"])
  //             localStorage.setItem("AdminPayment", jobSeekerPayment)
  //           }
  //         }
  //       }
  //     }
  //   }
  //   else if(dataAdminArr.length > 0){
  //     for (let i = 0; i < dataAdminArr.length; i++) {
  //       let adminJSON = dataAdminArr[i]
  //       let keys = Object.keys(adminJSON)
  //       if (keys[0] === 'Bridge') {
  //         const bridgeData = adminJSON['Bridge'];
  //         let flowID = bridgeData['FlowID']
  //         if (flowID === RecUserFlow) {
  //           let forumID = bridgeData["ForumID"]
  //           let bridgeID = bridgeData["BridgeID"]
  //           let userData = JSON.parse(localStorage.getItem('userData'))
  //           for (let i = 0; i < userData.length; i++) {
  //             const countData = userData[i];
  //             let countForumID = countData["ForumID"]
  //             let countBridgeArr = countData["BridgeIDs"]
  //             if(forumID === countForumID){
  //               if(!countBridgeArr.includes(bridgeID)){
  //                 countBridgeArr.push(bridgeID)
  //                 countData['Counts'] = countBridgeArr.length
  //                 userData[i] = countData
  //             }}}
  //             localStorage.setItem('userData',JSON.stringify(userData))

  //         }
  //       }
  //     }
  //   }
  // }


  // const handleFileChange = (event) => {
  //   setFile(event.target.files[0]);
  //   console.log('this is that', event.target.files[0])
  // };

  const handleFileChange = (event) => {
    const selectedFile = event.target.files[0];
    if (selectedFile) {
      if (selectedFile.type === 'video/mp4') {
        if (selectedFile.size <= 64 * 1024 * 1024) {
          setFile(selectedFile);
          localStorage.setItem('isWebRecorded',JSON.stringify(false));
        } else {
          toast.warning(objectNullandUndefined('189'));
        }
      } else {
        toast.warning(objectNullandUndefined('190'));
      }
    }
  };

  const handleCancel = () => {
    const confirmed = window.confirm(objectNullandUndefined('191'));
    if (confirmed) {
      localStorage.setItem('isWebRecorded',JSON.stringify(true));
      setVideoUrl(null);
      setBlobUrl(null);
      setFile(null);
      document.getElementById('upload-video').value = '';
      clearTimeout(timeoutId);
    }
  };
  useEffect(() => {
    if (mediaBlobUrl) {
      setVideoUrl(mediaBlobUrl);
      setBlobUrl(mediaBlobUrl)
    }
  }, [mediaBlobUrl]);

  useEffect(() => {
    if (file) {
      const videoObjectUrl = URL.createObjectURL(file);
      setVideoUrl(videoObjectUrl);
    }
  }, [file]);


  const today = dayjs();
  const tomorrow = today.add(1, 'day').startOf('day');
  const checkDate = tomorrow.unix()

  const handleVideoClick = () => {
    if (isPlaying) {
      videoRef.current.pause();
    } else {
      videoRef.current.play();
    }
    setIsPlaying(!isPlaying);
  };

  const handleRecording = async () => {
    try {
      await navigator.mediaDevices.getUserMedia({ video: true, audio: true });
      if (status === 'idle' || status === 'stopped') {
        let count = 2;
        const toastId = toast.info(`Your recording starts in ${count}...`, {
          position: toast.POSITION.TOP_CENTER,
          autoClose: false,
          hideProgressBar: true,
          closeButton: false,
          progressClassName: 'toast-progress',
          toastClassName: 'toast-info',
          bodyClassName: 'toast-body',
        });

        const countdownInterval = setInterval(() => {
          count--;
          if (count === 0) {
            toast.update(toastId, {
              render: 'Recording in progress...',
              autoClose: 500,
            });
            clearInterval(countdownInterval);
            const newTimeoutId = setTimeout(() => {
              stopRecording(); // Stop recording after 60 seconds
              setIsRecording(false);
            }, 60000);
            setTimeoutId(newTimeoutId);
          } else {
            toast.update(toastId, {
              render: `Your recording starts in ${count}...`,
            });
          }
        }, 1000);
        startRecording();
        setIsRecording(true);
      } else {
        clearTimeout(timeoutId);
        stopRecording();
        setIsRecording(false);
      }
    } catch (error) {
      setShowPermissionDialog(true);
    }
  };

  const closePermissionDialog = () => {
    setShowPermissionDialog(false);
  };

  function containsSpecialCharacters(str) {
    const regex = /[^\w@\u0080-\uFFFF. ]/;
    return regex.test(str);
  }

  const theme = createTheme({
    palette: {
      secondary: {
        main: '#f40606',
        contrastText: '#fff',
      },
    },
  });
  const handleSubmit = async () => {
    let recruiterPay = localStorage.getItem("AdminPayment")
    if (!selectedValue) {
      toast.error(objectNullandUndefined('102'));
      return;
    }

    if (!selectCity) {
      toast.error(objectNullandUndefined('103'));
      return;
    }

    if (!date) {
      toast.error(objectNullandUndefined('104'));
      return;
    }

    if (date < checkDate) {
      toast.error(objectNullandUndefined('192'))
      return
    }

    if (!name) {
      toast.error(objectNullandUndefined('105'));
      return;
    }
    if(containsSpecialCharacters(name)){
      toast.error(objectNullandUndefined('287'));
      return;
    }
    

    if (!description) {
      toast.error(objectNullandUndefined('106'));
      return;
    }

    if(containsSpecialCharacters(description)){
      toast.error(objectNullandUndefined('288'));
      return;
    }


    if (/^\s*$/.test(name)) {
      toast.error(objectNullandUndefined('176'));
      return;
    }

    if (/^\s*$/.test(description)) {
      toast.error(objectNullandUndefined('177'));
      return;
    }

    if (!(blobUrl || file)) {
      toast.error(objectNullandUndefined('101'));
      return;
    }

    if (recruiterPay === "true") {
      setPaidPop(!paidPop)
    } else {
      setLoading(true);
      ForumCreating();
      setName('');
      setDescription('');
      setDate('');
      setVideoUrl('');
    }
  };
  // const handleSubmit = () => {
  //   setlogout(true);
  // }
  const handleClose = () => {
    setPaidPop(!paidPop)
  }

  const handlePaid = () => {
    isPaid = true;
    setPaidPop(!paidPop)
    setLoading(true);
    ForumCreating();
    setName('');
    setDescription('');
    setDate('');
    setVideoUrl('');
  }

  const handleFree = () => {
    isPaid = false;
    setPaidPop(!paidPop)
    setLoading(true);
    ForumCreating();
    setName('');
    setDescription('');
    setDate('');
    setVideoUrl('');
  }

  useEffect(() => {
    locationCall()
  }, []);

  const locationCall = async () => {
    // let commonUserFlows = await AsyncStorage.getItem('commonUserFlows');
    // commonUserFlows = JSON.parse(commonUserFlows)
    // // let JobCategoriesList = [];
    // if(commonUserFlows != null){
    //       let parsedCommonFlow = commonUserFlows["UserFlow"]
    //       let commonTempName = commonUserFlows["Template"]

    //       let reportFlowID_JobCategories = parsedCommonFlow[commonTempName + '_JobCategories']
    //       let formats_JobCategories = reportFlowID_JobCategories["Formats"][0]
    //       let Flows_JobCategories = parsedCommonFlow[formats_JobCategories]
    //       let key_JobCategories = Object.keys(Flows_JobCategories)
    //       let locationKeys_JobCategories = Flows_JobCategories[key_JobCategories][2]
    //       let JobCategories = locationKeys_JobCategories["Options"]
    //       jobCategories = Object.keys(JobCategories)
    //       console.log(jobCategories)
    //       jobs = updateJobsObject(jobCategories)
    //       console.log("jobs",jobs)
    // }
    // else{
      setLoading(true);
    let userDetails = localStorage.getItem('userProfile');
    let decoded = base64_decode(userDetails)
    userDetails = JSON.parse(decoded);
    let tempJson = {
      "MACAddress": userDetails['MACAddress'],
      "ForumID": CommonForumID,
      "SessionID": userDetails['SessionID'],
      "ServerID": CAServerID
    }
    let flowsCommon = await forumDetails(tempJson);
    if (flowsCommon["ErrorCode"] === 1074) {
      toast.error((objectNullandUndefined('221')));
      setTimeout(() => {
        localStorage.clear();
        sessionStorage.clear();
        history.push('/');
        window.location.reload(false);
      }, 3000)
    }
    setLoading(false);
    let commonFlows = flowsCommon['ErrorMessage']['ForumDataArray']
    if(flowsCommon['ErrorCode'] == 1074){
      toast.warning(objectNullandUndefined("56"))
      localStorage.clear();
      sessionStorage.clear();
      history.push('/');
      window.location.reload(false);
    }
    if (commonFlows.length > 0) {
      for (let i = 0; i < commonFlows.length; i++) {
        let recForumData = commonFlows[i]
        let forumKey = Object.keys(recForumData)
        if (forumKey[0] === CommonForumID) {
          let commonUserFlows = recForumData[CommonForumID]['NewForumJsonData']
          let parsedCommonFlow = commonUserFlows["UserFlow"]
          let commonTempName = commonUserFlows["Template"]

          let reportFlowID = parsedCommonFlow[commonTempName + '_Locations']
          let formats = reportFlowID["Formats"][0]
          let locationFlows = parsedCommonFlow[formats]
          let key = Object.keys(locationFlows)
          let locationKeys = locationFlows[key][2]
          let locations1 = locationKeys["Options"]
          let locationList = Object.keys(locations1)
          setLocationsCatogary(locationList)
          let val1 = await updateLocJobsObject(locationList)
          setLocationsOBJCatogary(val1)

          let reportFlowID_JobCategories = parsedCommonFlow[commonTempName + '_JobCategories']
          let formats_JobCategories = reportFlowID_JobCategories["Formats"][0]
          let Flows_JobCategories = parsedCommonFlow[formats_JobCategories]
          let locationKeys_JobCategories = Flows_JobCategories['row1'][2]
          let JobCategories = locationKeys_JobCategories["Options"]
          let JobCategoriesList = Object.keys(JobCategories)
          console.log("job lint in filter",JobCategoriesList)
          setJobCatogary(JobCategoriesList)
          let val = await updateJobsObject(JobCategoriesList)
          setJobObjCatogary(val)
          // locations.push(locationList)
        }
      }
      }
    }
  // }

  async function addBotToRecruiterForumAndSendBridge(response){
    let userDetails = localStorage.getItem('userProfile');
    userDetails = base64_decode(userDetails);
    userDetails = JSON.parse(userDetails);
    let userData = JSON.parse(localStorage.getItem('userData'))
    let addForumID = response.data["ErrorMessage"]["ForumID"]
    let addForum = response.data["ErrorMessage"]["ForumName"]
    let addDescription = response.data["ErrorMessage"]["ForumDescription"]
    const forumDetail = {
      ForumID: addForumID,
      ForumName: addForum,
      ForumDescription: addDescription,
      Template: "",
      Counts:0,
      BridgeIDs:[]
    }
    userData.push(forumDetail)
    localStorage.setItem('userData', JSON.stringify(userData));
    let userBot = [];
    userBot.push(restoreBotPhoneNumber);
    let tempBotJson = {
      SessionID: userDetails.SessionID,
      MACAddress: userDetails.MACAddress,
      Owner: userDetails.UserName,
      AddAdminsToForum: userBot,
      ServerID: FSServerID,
      UnBlockUsers: true,
      ForumID: response.data.ErrorMessage.ForumID,
      Creator: ['False'],
      Modifier: ['True'],
      Approver: ['True'],
      Priority: [0],
      Forum: true,
      ChatForum: false,
    };
    axios({
      method: 'post',
      url: forumServerIP + 'addingForumAdminsExternally',
      data: tempBotJson,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    })
      .then(function (responsebot) {
        console.log('bot added', responsebot.data);
        bridgeSending(response.data.ErrorMessage.ForumID);
      })
      .catch(function (responsebot) {
        console.log(responsebot);
      });
  }

  async function updateJobsObject(JobCategoriesList){
    let newJobsIndexObj = []
    for (let i = 0; i < JobCategoriesList.length; i++) {
      let obj = {}
      obj['id'] = JobCategoriesList[i];
      obj['label'] = objectNullandUndefined(String(JobCategoriesList[i]))
      if(obj['label'] == undefined){
        obj['label'] = JobCategoriesList[i];
      }
      newJobsIndexObj.push(obj)
    }
    let obj1 = {}
    let labeldata = objectNullandUndefined('239')
    if(labeldata == null || labeldata == undefined){
      labeldata = "REQUEST NEW JOB TYPE"
    }
    let labeldata1 = labeldata.toLowerCase().replace(/\b\w/g, c => c.toUpperCase());

    obj1['id'] = "Request Addition";
    obj1['label'] = labeldata1
    newJobsIndexObj.push(obj1)
   return newJobsIndexObj;
  }

  async function updateLocJobsObject(JobCategoriesList){
    let newJobsIndexObj = []
    for (let i = 0; i < JobCategoriesList.length; i++) {
      let obj = {}
      obj['id'] = JobCategoriesList[i];
      obj['label'] = objectNullandUndefined(String(JobCategoriesList[i]))
      if(obj['label'] == undefined){
        obj['label'] = JobCategoriesList[i];
      }
      newJobsIndexObj.push(obj)
    }
    let obj1 = {}
    let labeldata = objectNullandUndefined('241')
    if(labeldata == null || labeldata == undefined){
      labeldata = "REQUEST NEW LOCATION"
    }
    let labeldata1 = labeldata.toLowerCase().replace(/\b\w/g, c => c.toUpperCase());
    obj1['id'] = "Request Addition";
    obj1['label'] = labeldata1
    newJobsIndexObj.push(obj1)
   return newJobsIndexObj;
  }
  // const options = [
  //   {id: "Software Engineer", label:objectNullandUndefined('24')},
  //   {id: "Human Resource Manager", label: objectNullandUndefined('27')},
  //   {id: "Quality Control Analyst", label:objectNullandUndefined('39')},
  //   { id: "Sales Representative", label: objectNullandUndefined('30') },
  //   { id: "Operations Manager", label: objectNullandUndefined('31') },
  //   { id: "Nurse", label: objectNullandUndefined('32') },
  //   { id: "Physical Therapist", label: objectNullandUndefined('33') },
  //   { id: "Delivery Partner", label: objectNullandUndefined('37') },
  //   { id: "Supply Chain Manager", label: objectNullandUndefined('38') },
  //   { id: "Domestic Worker", label: objectNullandUndefined('40') },
  //   { id: "Chef", label: objectNullandUndefined('41') },
  //   { id: "Construction Worker", label: objectNullandUndefined('42') },
  //   { id: "Agriculture Worker", label: objectNullandUndefined('43') },
  //   { id: "Garments Worker", label: objectNullandUndefined('44') },
  //   // {id: "Digital Marketing Intern", label:objectNullandUndefined('179')},
  //   // {id: "Content Creator", label: objectNullandUndefined('180')}
  //   // { id: "Testing Forum", label: "Testing Forum" }
  // ]

  // const cities = [
  //   { id: 'Ahmedabad', label: objectNullandUndefined('72') },
  //   { id: 'Bangalore', label: objectNullandUndefined('73') },
  //   { id: 'Chennai', label: objectNullandUndefined('74') },
  //   { id: 'Davangere', label: objectNullandUndefined('75') },
  //   { id: 'Delhi', label: objectNullandUndefined('76') },
  //   { id: 'Gurgaon', label: objectNullandUndefined('77') },
  //   { id: 'Hubli', label: objectNullandUndefined('78') },
  //   { id: 'Hyderabad', label: objectNullandUndefined('79') },
  //   { id: 'Jaipur', label: objectNullandUndefined('80') },
  //   { id: 'Kanpur', label: objectNullandUndefined('81') },
  //   { id: 'Kolkata', label: objectNullandUndefined('82') },
  //   { id: 'Mangalore', label: objectNullandUndefined('83') },
  //   { id: 'Mumbai', label: objectNullandUndefined('84') },
  //   { id: 'Panji', label: objectNullandUndefined('85') },
  //   { id: 'Pune', label: objectNullandUndefined('86') },
  // ]
  async function ForumCreating() {
    let userDetails = localStorage.getItem('userProfile');
    userDetails = base64_decode(userDetails);
    userDetails = JSON.parse(userDetails);
    let users = [];
    users.push(userDetails.MobileNumber);
    let tempJsonData = {
      SessionID: userDetails.SessionID,
      MACAddress: userDetails.MACAddress,
      AddUsers: '',
      CloneTemplateFromForum: false,
      UPI: 'UPI',
      TemplateName: RecCreateTempName,
      ForumName: name,
      TemplateID: RecruiterForumTemp,
      ForumDomain: 'others',
      ChatForum: false,
      Owner: userDetails.UserName,
      ForumDescription: description,
      Time: '1643620059',
      ForumType: 'Public',
      ServerID: FSServerID,
    };
    const dataForum = new FormData();
    dataForum.append('Data', JSON.stringify(tempJsonData));
    axios({
      method: 'post',
      url: forumServerIP + 'newForum',
      data: dataForum,
      headers: { 'Content-Type': 'multipart/form-data' },
    })
      .then(function (response) {
        if (response.data.ErrorCode === 1011) {
          addBotToRecruiterForumAndSendBridge(response)
        } else if (response.data.ErrorCode === 1008){
          setLoading(false);
          setTimeout(() => {
            toast.error(objectNullandUndefined('179'));
          }, 100)
        }
        else {
          setLoading(false);
          setTimeout(() => {
            toast.error(objectNullandUndefined('111'));
          }, 100)
          return;
        }

      })
      .catch(function (response) {
        console.log(response);
      });
  }
  async function bridgeSending(forumID) {
    try {
      let isWebRecorded = localStorage.getItem('isWebRecorded');
      let isWebm = true
      if(isWebRecorded !== null && isWebRecorded !== undefined && isWebRecorded === 'false'){
        isWebm = false;
      }
      let info;
      let encodedDescription;
      let uuid = myUuid;
    
      let userDetails = localStorage.getItem('userProfile');
      userDetails = base64_decode(userDetails);
      userDetails = JSON.parse(userDetails);
      let sessionID = userDetails.SessionID;
      let macAddress = userDetails.MACAddress;
      let flowName = RecCreateTempName + '_Recruiter';
      let fID = RecruitCreateFlowID;
      let tempJson = {
        ForumID: forumID,
        SessionID: sessionID,
        MACAddress: macAddress,
        Time: '6516516156161',
        ScheduledDateTime: 'now',
        ScheduledBoolean: 0,
        FlowID: flowName,
        EnableChat: 0,
        FlowType: 'Custom',
        BridgeForward: 0,
        TemplateID: RecruiterForumTemp,
        TextCount: 6,
        ImageCount: 0,
        InvoiceID: '',
        DocumentCount: 0,
        User: false,
        VideoCount: 0,
        ServerID: FSServerID,
        ReplyBridgeID: '',
        HiddenFlow: false,
        BilledForum: '',
        TempBridgeId: uuid,
        FID: fID,
        SentTo: '0',
      };

      let data1 = {
        "CompanyName": name,
        "MediaID": userDetails['UserProfilePicture'],
        "City": selectCity
      }
      try {
        const utf8EncodedName = utf8.encode(data1.CompanyName);
        info = {
          ...data1,
          "CompanyName": utf8EncodedName,
          "ToBeConverted":isWebm
        };
        let utfCoded = utf8.encode(description);
        encodedDescription = base64_encode(utfCoded)
      } catch (error) {
        info = data1;
        encodedDescription = base64_encode(description)
      }
      let flows = flowName + "_Main-5fbc611c-ba6c-49d8-a3b1-a36a1c807fbb"
      let jsonData = {};
      jsonData[flows + "_2"] = base64_encode(JSON.stringify(info));
      jsonData[flows + "_4"] = encodedDescription;
      jsonData[flows + "_6"] = base64_encode(selectedValue);
      jsonData[flows + "_8"] = base64_encode(date);
      tempJson[flowName] = jsonData;
      const data = new FormData();
      data.append('Data', JSON.stringify(tempJson));

      if (typeof blobUrl === 'string') {
        const response = await fetch(blobUrl);
        const blob = await response.blob();
        const file = new File([blob], 'video.mp4',{type:blob.type});
        data.append(flows + "_10", file);
      } else {
        data.append(flows + "_10", file);
      }

      const URL = forumServerIP + 'saveForumBridges2';
      const xhr = new XMLHttpRequest();
      xhr.open('POST', URL);
      xhr.send(data);
      xhr.onreadystatechange = async (e) => {
        if (xhr.readyState !== 4) {
          return;
        }
        if (xhr.status === 200) {
          // console.log('success', xhr.responseText);
          let errorCode = JSON.parse(xhr.responseText)
          localStorage.setItem('isWebRecorded',JSON.stringify(true));
          if (errorCode['ErrorCode'] === 1024) {
            let lastBridge = await getLasTfiftydata(forumID)
            if (lastBridge["ErrorCode"] === 1074) {
              toast.error((objectNullandUndefined('221')));
              setTimeout(() => {
                localStorage.clear();
                sessionStorage.clear();
                history.push('/');
                window.location.reload(false);
              }, 3000)
            }
            if (lastBridge['ErrorCode'] === 1053) {
              let dataArr = lastBridge['ErrorMessage']
              let bridgeInfo = dataArr['Admin'][0]['Bridge']
              let mediaKey = bridgeInfo['MediaID']
              if (mediaKey != 0) {
                let recJson = {
                  'ForumInfo': bridgeInfo[RecCreateTempName + '_Recruiter'],
                  'ForumID': bridgeInfo['ForumID'],
                  'MediaID': bridgeInfo['MediaID'],
                }
                let recJson1 = base64_encode(JSON.stringify(recJson))
                commonBridge(recJson1)
              }
            }
          }
        } else {
          console.log('error', xhr.responseText);
          bridgeSending();
        }
      };
      toast.success(objectNullandUndefined('107'))
      fetchForumInfo();
      setTimeout(() => {
        history.push("/Notification");
      }, 5000)
    } catch (err) {
      return;
    }
  }

  async function commonBridge(recJson1) {
    let commonForumTemp =localStorage.getItem("commonUserFlows")
    let parsedFlow = JSON.parse(commonForumTemp)
    let parsedCommonFlow = parsedFlow["UserFlow"]
    let commonTempName = parsedFlow["Template"]
    let reportFlowID = parsedCommonFlow[commonTempName + '_RecruitmentCreate']
    try {
      let uuid = myUuid

      let userDetails = localStorage.getItem('userProfile');
      userDetails = base64_decode(userDetails);
      userDetails = JSON.parse(userDetails);
      let sessionID = userDetails.SessionID;
      let macAddress =  userDetails.MACAddress;
      let flowName = commonTempName + '_RecruitmentCreate';
      let fID = reportFlowID["FID"]
      let tempJson = {
        ForumID: CommonForumID,
        SessionID: sessionID,
        MACAddress: macAddress,
        Time: '6516516156161',
        ScheduledDateTime: 'now',
        ScheduledBoolean: 0,
        FlowID: flowName,
        EnableChat: 0,
        FlowType: 'Custom',
        BridgeForward: 0,
        TemplateID: parsedFlow["TemplateID"],
        TextCount: 6,
        ImageCount: 0,
        InvoiceID: '',
        DocumentCount: 0,
        User: true,
        VideoCount: 0,
        ServerID: FSServerID,
        ReplyBridgeID: '',
        HiddenFlow: false,
        BilledForum: '',
        TempBridgeId: uuid,
        FID: fID,
        SentTo: '0',
      };
      let jsonData = {
        [CommonForumFlow + '_RecruitmentCreate_Main-cade135a-1011-48b9-8b29-1107c1cae267_2']: recJson1,
      };
      tempJson[flowName] = jsonData;
      const data = new FormData();
      data.append('Data', JSON.stringify(tempJson));
      let bridgeData = await bridgeSendingByUser(data);
      if (bridgeData["ErrorCode"] === 1074) {
        toast.error((objectNullandUndefined('221')));
        setTimeout(() => {
          localStorage.clear();
          sessionStorage.clear();
          history.push('/');
          window.location.reload(false);
        }, 3000)
      }
      setLoading(false);
    } catch (err) {
      return;
    }
  }

  const handleDateChange = (date) => {
    setValue(date);
    const unixTime = dayjs(date).unix();
    const originalDate = new Date(unixTime * 1000);
    const newDate = new Date(originalDate);
    newDate.setHours(newDate.getHours() + 23);
    newDate.setMinutes(newDate.getMinutes() + 59);
    newDate.setSeconds(newDate.getSeconds() + 59);
    newDate.setMilliseconds(newDate.getMilliseconds() + 999);
    const newUnixTime = Math.floor(newDate.getTime() / 1000);
    setDate(newUnixTime)
  };
  const handleChange = (event, value) => {
    if(value?.id == 'Request Addition'){
      handleRequestcategory()
    }else{
      setSelectedValue(value?.id || null);
    }
  };

  const handleCity = (event, value) => {
    if(value?.id == 'Request Addition'){
      handleRequestlocation()
    }else{
      setSelectedCity(value?.id || null);
    }
  };

  const handleNameChange = (event) => {
    let inputValue = event.target.value;
    
    // Block spaces for the first character
    if (inputValue.length === 1 && inputValue.trim() === '') {
      inputValue = '';
    }

    // Limit the input to a maximum length of 33 characters
    if (inputValue.length <= 33) {
      setName(inputValue);
    }
  };
  const handleRequestlocation = () => {
    setlocation(true);
    setLocation(null);
  };
  const handleRequestcategory = () => {
    setcatogary(true);
    setLocation(null);
  };
  const handleLocationChange = (event) => {
    setLocation(event.target.value);
  };

  const locationBridgesending = async () => {
    if(locationdata && !(/^\s*$/.test(locationdata))){
      try {
        if(catagory){
          setcatogary(false);
        }else{
          setlocation(false);
        }
        setLoading1(true);
        let uuid = myUuid;
        let userDetails = localStorage.getItem('userProfile');
        let userDetails1 = JSON.parse(base64_decode(userDetails));
        let flowName = requestChange;
        let fID = 'c127584d-b290-4b99-2370-6e711416031d';
        let tempJson = {
          ForumID: CommonForumID,
          SessionID: userDetails1['SessionID'],
          MACAddress: userDetails1['MACAddress'],
          Time: '6516516156161',
          ScheduledDateTime: 'now',
          ScheduledBoolean: 0,
          FlowID: flowName,
          EnableChat: 0,
          FlowType: 'Custom',
          BridgeForward: 0,
          TemplateID: CommonForumTemp,
          TextCount: 6,
          ImageCount: 0,
          InvoiceID: '',
          DocumentCount: 0,
          User: true,
          VideoCount: 0,
          ServerID: FSServerID,
          ReplyBridgeID: '',
          HiddenFlow: false,
          BilledForum: '',
          TempBridgeId: uuid,
          FID: fID,
          SentTo: '1',
        };
        let info = locationdata;
        let jsonData = {
          [CommonForumFlow + "_RequestChange_Main-50d98f69-3894-4f0f-9faf-659b837e92c3_2"]: base64_encode(info)
        };
        tempJson[flowName] = jsonData;
        const dataForum = new FormData();
        dataForum.append('Data', JSON.stringify(tempJson));
        const URL = forumServerIP + 'saveForumBridges2'
        const xhr = new XMLHttpRequest();
        xhr.open('POST', URL);
        xhr.send(dataForum);
        xhr.onreadystatechange = e => {
          if (xhr.readyState !== 4) {
            return;
          }
          if (xhr.status === 200) {
            let errorCode = JSON.parse(xhr.responseText)
            setLocation(null);
            setLoading1(false);
            if(errorCode['ErrorCode'] == 1060){
              if(catagory){
                toast.success(objectNullandUndefined('242'));
              }else{
                toast.success(objectNullandUndefined('243'));
              }            
            }else{
              toast.warning(objectNullandUndefined('56'));
            }
          } else {
            setLocation(null);
            setLoading1(false);
            toast.warning(objectNullandUndefined('56'));
          }
        }
      } catch (err) {
        setLoading1(false);
        toast.warning(objectNullandUndefined('56'));
        return;
      }
    }else{
      if(location){
        toast.warning(objectNullandUndefined('245'))
      }else{
        toast.warning(objectNullandUndefined('244'))
      }
      console.log('hiiii');
    }

    }
    const classes = useStyles();
  return (
    <ThemeProvider theme={theme}>
        <Modal open={loading1} className={classes.loaderContainer}>
                <div>
                  <CircularProgress className={classes.loader} size={40} thickness={3} />
                </div>
              </Modal>
        {catagory === true && (
      <div className="transparent-overlay3">
   
        <Card className="requestcard">
        <div>
          <div style={{ textAlign: 'center', paddingTop: '20px' }}>
            <p style={{ fontSize: 'large', color: 'black', overflow: "hidden", padding: '10px 20px 10px 20px' }}>
            {objectNullandUndefined('239')}
            </p>
          </div>
          <div style={{ textAlign: 'center',marginTop:'-35px' }}>
          <TextField
            label={objectNullandUndefined('244')}
            variant="outlined"
            style={{ width: '65%', marginTop: '20px' }}
            value={locationdata} 
            onChange={handleLocationChange} 
          />
        </div>
          <div className='Loginbutton3'>
            <Button
              onClick={() => setcatogary(false)}
              style={{ fontSize: '16px', width: '25%',backgroundColor:'rgb(234, 67, 53)',color:'black',borderRadius:'5px',height:'40px' }}
            >
              {objectNullandUndefined('16')}
            </Button>
            <Button
              variant="text"
              onClick={() => locationBridgesending()}
              style={{ fontSize: '16px', width: '25%',backgroundColor:'rgb(234, 67, 53)',color:'black',borderRadius:'5px',height:'40px'}}
            >
              {objectNullandUndefined('240')}
            </Button>
          </div>
          </div>
        </Card>     
      </div>
    )}
     {location === true && (
      <div className="transparent-overlay3">
   
        <Card className="requestcard">
        <div>
          <div style={{ textAlign: 'center', paddingTop: '20px' }}>
            <p style={{ fontSize: 'large', color: 'black', overflow: "hidden", padding: '10px 20px 10px 20px' }}>
            {objectNullandUndefined('241')}
            </p>
          </div>
          <div style={{ textAlign: 'center',marginTop:'-35px' }}>
          <TextField
            label={objectNullandUndefined('245')}
            variant="outlined"
            style={{ width: '65%', marginTop: '20px' }}
            value={locationdata} 
            onChange={handleLocationChange} 
          />
        </div>
          <div className='Loginbutton3'>
            <Button
              onClick={() => setlocation(false)}
              style={{ fontSize: '16px', width: '25%',backgroundColor:'rgb(234, 67, 53)',color:'black',borderRadius:'5px',height:'40px' }}
            >
              {objectNullandUndefined('16')}
            </Button>
            <Button
              variant="text"
              onClick={() => locationBridgesending()}
              style={{ fontSize: '16px', width: '25%',backgroundColor:'rgb(234, 67, 53)',color:'black',borderRadius:'5px',height:'40px'}}
            >
              {objectNullandUndefined('240')}
            </Button>
          </div>
          </div>
        </Card>     
      </div>
    )}
      {loading ? (
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            height: '100vh',
            top: '55%',
            position: 'relative',
            backgroundColor:'black'
          }}
        >
          <CircularProgress size={60} />
          {/* <span style={{ marginLeft: '10px', fontSize: '20px' }}>{objectNullandUndefined('127')}</span> */}
        </div>
      ) : (
        <>
          <ToastContainer position="top-center" hideProgressBar />
          <Hook
            short={'RECRUITMENT'}
          />

          <div className="custom-div">
            <Container style={{ border: '2px solid black', borderRadius: '5px', width: '90%', backgroundColor: '#f1f1f1' }}>
              <Card style={{ margin: 'auto', width: '90%', height: '100%', backgroundColor: '#f1f1f1' }}>
                <CardContent style={{ border: '2px solid black', borderRadius: '5px' }}>
                  <Grid container spacing={2} style={{}}>
                    <Grid item xs={12} md={6}>
                      <Grid container spacing={2} alignItems="center">
                        <Grid item xs={12}>
                          {videoUrl ? (
                            <div>
                              <div style={{ position: 'relative', paddingBottom: '40%' }}>
                                <video
                                  src={videoUrl}
                                  ref={videoRef}
                                  onClick={handleVideoClick}
                                  style={{
                                    position: 'absolute',
                                    top: 40,
                                    left: 0,
                                    width: '100%',
                                    height: '145%',
                                    left: '5%',
                                    objectFit: 'contain',
                                  }}
                                  autoPlay />
                              </div>
                              <button
                                className="recVideoCancel"
                                onClick={handleCancel}
                              >
                                {objectNullandUndefined('16')}
                              </button>
                            </div>
                          ) : (
                            <Grid style={{ marginLeft: '41%', marginTop: '20%' }}>
                              {isRecording ? (
                                <>
                                  <Webcam audio={false} ref={webcamRef} style={{ width: '150%', height: '200%', maxHeight: '200%', transform: 'translate(-25%, -5%)' }} />
                                  <Fab
                                    size="large"
                                    component="span"
                                    aria-label="stop"
                                    variant="extended"
                                    onClick={handleRecording}
                                    style={{ color: 'black', backgroundColor: 'rgb(234,67,53)', position: 'sticky', bottom: 0, marginTop: '-5%' }}
                                  >
                                    {objectNullandUndefined('128')}
                                  </Fab>
                                </>
                              ) : (
                                <Fab
                                  size="large"
                                  component="span"
                                  aria-label="add"
                                  variant="extended"
                                  onClick={handleRecording}
                                  style={{ width: '200px', position: 'sticky', bottom: 0, backgroundColor: 'rgb(234,67,53)', color: 'black' }}
                                  disabled={isRecording || isPlaying}
                                > {objectNullandUndefined('59')}
                                  <div style={{ fontSize: '40px' }}>
                                    <CameraAltIcon fontSize="inherit" />
                                  </div>
                                </Fab>
                              )}
                            </Grid>
                          )}
                        </Grid>
                        <Grid item style={{ marginLeft: '40%', marginTop: '15%' }}>
                          <label htmlFor="upload-video">
                            <input
                              style={{ display: 'none' }}
                              accept=".mp4"
                              id="upload-video"
                              name="upload-video"
                              type="file"
                              onChange={handleFileChange}
                              required
                              disabled={isRecording || isPlaying} />
                            {!videoUrl && !isRecording && (
                              <Fab
                                size="large"
                                component="span"
                                aria-label="add"
                                variant="extended"
                                style={{ width: '200px', position: 'sticky', top: 0, backgroundColor: 'rgb(234,67,53)', color: 'black' }}
                                disabled={isRecording || isPlaying}
                              >{objectNullandUndefined('60')}
                                <div style={{ fontSize: '45px' }}>
                                  <UploadSharpIcon fontSize="inherit" />
                                </div>
                              </Fab>
                            )}
                          </label>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <Box sx={{ px: [2, 4, 10], pb: 2, pt: [2, 4] }}>
                      <div style={{ display: 'flex' }}>
                      {/* <div className="Iconstyle" style={{ borderColor: '#6c757d', borderStyle: 'solid', borderWidth: '1px',justifyContent:"center",alignItems:'center' }} onClick={handleRequestcategory}>
                           <AddIcon style={{ color: 'black',fontSize:'30px' }} />
                           </div> */}
                        <Autocomplete
                          required
                          value={options.find(option => option.id === selectedValue) || null}
                          onChange={handleChange}
                          options={options}
                          // noOptionsText={objectNullandUndefined('193')}
                          noOptionsText={objectNullandUndefined('254')}
                          sx={{ width: '100%' }}
                          getOptionLabel={(option) => option.label}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label={objectNullandUndefined('52')}
                              variant="outlined"
                              sx={{ width: '100%' }} />
                          )} />
                         
                           </div>
                        <br />
                        <div style={{ display: 'flex' }}>
                        {/* <div className="Iconstyle" style={{ borderColor: '#6c757d', borderStyle: 'solid', borderWidth: '1px',justifyContent:"center",alignItems:'center' }} onClick={handleRequestlocation}>
                           <AddIcon style={{ color: 'black',fontSize:'30px' }} />
                           </div> */}
                        <Autocomplete
                          required
                          value={cities.find(option => option.id === selectCity) || null}
                          onChange={handleCity}
                          options={cities}
                          // noOptionsText={objectNullandUndefined('194')}
                          noOptionsText={objectNullandUndefined('254')}
                          sx={{ width: '100%' }}
                          getOptionLabel={(city) => city.label}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label={objectNullandUndefined('53')}
                              variant="outlined"
                              sx={{ width: '100%' }} />
                          )} />
                          </div>
                        <br />
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <DatePicker
                            label={objectNullandUndefined('7')}
                            value={value}
                            sx={{ width: '100%' }}
                            onChange={handleDateChange}
                            minDate={tomorrow}
                            renderInput={(params) => <TextField {...params} disabled />}
                            format="DD/MM/YYYY"
                          />
                        </LocalizationProvider>
                        <TextField
                          // required
                          margin="normal"
                          sx={{ width: '100%' }}
                          id="companyName"
                          label={objectNullandUndefined('6')}
                          value={name}
                          inputProps={{ maxLength: 33 }}
                          onChange={handleNameChange} />
                        <TextField
                          // required
                          margin="normal"
                          sx={{ width: '100%' }}
                          multiline
                          rows={3}
                          label={objectNullandUndefined('8')}
                          id="description"
                          value={description}
                          inputProps={{ maxLength: 100 }}
                          onChange={(event) => setDescription(event.target.value)} />
                        <button
                          style={{ backgroundColor: 'rgb(234,67,53)', border: 'none', borderRadius: '5px', width: '100%', height: '50%', padding: '8px' }}
                          onClick={handleSubmit}
                        >
                          {objectNullandUndefined('9')}
                        </button>
                      </Box>
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
            </Container>

            {/* Payment option checking Dialog box */}
            <Dialog
              open={paidPop}
              onClose={handleClose}
              maxWidth="xl"
              style={{ marginRight: '5%' }}
            >
              <IconButton aria-label="close" onClick={handleClose} sx={{ position: 'absolute', right: 8, top: 8 }}>
                <CloseIcon />
              </IconButton>
              <DialogContent>
                <DialogContentText>
                  {objectNullandUndefined('141')}
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <button
                  onClick={handleFree}
                  className="dialog-button1"
                >
                  {objectNullandUndefined('137')}
                </button>
                <button
                  onClick={handlePaid}
                  className="dialog-button1"
                >
                  {objectNullandUndefined('142')}
                </button>
              </DialogActions>
            </Dialog>

            {/* Camera Permission Checking Dialog box */}

            <Dialog open={showPermissionDialog} onClose={closePermissionDialog}>
              <DialogContent>
                <DialogContentText>
                  {objectNullandUndefined('195')}
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button onClick={closePermissionDialog} autoFocus>
                  {objectNullandUndefined('196')}
                </Button>
              </DialogActions>
            </Dialog>
          </div></>
      )}
                  
    </ThemeProvider >
  );
}
const useStyles = makeStyles((theme) => ({
  loaderContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100vh',
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    zIndex:9999,
  },
  loader: {
    color: '#fff',
  },

}));
export default Recruiter;



