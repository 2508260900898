import React, { useState, useEffect ,useRef} from 'react';
import './JobLocationFilter.css';
import { useHistory } from 'react-router-dom';
import Avatar from '@material-ui/core/Avatar';
import { objectNullandUndefined } from '../Dictionary/Dictionary';
import { bridgeSendingByUser, forumDetails } from '../../services/LoginLogoutService';
import { decode as base64_decode, encode as base64_encode } from 'base-64';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css'; 
import { CAServerID, CandidateFilterFlow, CandidateFilterFlowID, CommonForumFlow, CommonForumID, FSServerID, MacAddress } from '../Dictionary/CommonStrings';
import uuid from 'react-uuid';
import { logOutFromSystem } from '../../App';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import Multiselect from 'multiselect-react-dropdown';
import { Margin } from '@mui/icons-material';
import { userJoinToForum,saveLocationToUserProfile } from '../../services/LoginLogoutService';
const JobLocationFilter = () => {
  const history = useHistory();
  const [selectedJobCategory, setSelectedJobCategory] = useState('');
  const [selectedLocation, setSelectedLocation] = useState('');
  const myUuid = uuid();
  const [locations, setLocationsCatogary] = useState([]); 
  const [jobCategories, setJobCatogary] = useState([]);
  const jobCategory = useRef();
  const jobLocation = useRef();
  const [isLoading, setIsLoding] = useState(true);
  const [selectedJobCategoryList, setSelectedJobCategoryList] = useState([]);
  useEffect(() => {
    
    locationCall()
  }, []);

  // const jobCategories = [
  //   // objectNullandUndefined('23'),
  //   objectNullandUndefined('24'),
  //   // objectNullandUndefined('25'),
  //   // objectNullandUndefined('26'),
  //   objectNullandUndefined('27'),
  //   // objectNullandUndefined('28'),
  //   // objectNullandUndefined('29'),
  //   objectNullandUndefined('30'),
  //   objectNullandUndefined('31'),
  //   objectNullandUndefined('32'),
  //   objectNullandUndefined('33'),
  //   // objectNullandUndefined('34'),
  //   // objectNullandUndefined('35'),
  //   // objectNullandUndefined('36'),
  //   objectNullandUndefined('37'),
  //   objectNullandUndefined('38'),
  //   objectNullandUndefined('39'),
  //   objectNullandUndefined('40'),
  //   objectNullandUndefined('41'),
  //   objectNullandUndefined('42'),
  //   objectNullandUndefined('43'),
  //   objectNullandUndefined('44'),
  //   // objectNullandUndefined('181'),
  //   // objectNullandUndefined('180')
  //   // "Testing Forum"
  // ];

  // const notify = () => toast("Please wait videos are loading",);


  // const locations = [
  //   objectNullandUndefined('72'),
  //   objectNullandUndefined('73'),
  //   objectNullandUndefined('74'),
  //   objectNullandUndefined('75'),
  //   objectNullandUndefined('76'),
  //   objectNullandUndefined('77'),
  //   objectNullandUndefined('78'),
  //   objectNullandUndefined('79'),
  //   objectNullandUndefined('80'),
  //   objectNullandUndefined('81'),
  //   objectNullandUndefined('82'),
  //   objectNullandUndefined('83'),
  //   objectNullandUndefined('84'),
  //   objectNullandUndefined('85'),
  //   objectNullandUndefined('86'),
  // ];
  const locationCall = async () => {
    let userDetails = localStorage.getItem('userProfile');
    let decoded = base64_decode(userDetails)
    userDetails = JSON.parse(decoded);
    let tempJson = {
      "MACAddress": userDetails['MACAddress'],
      "ForumID": CommonForumID,
      "SessionID": userDetails['SessionID'],
      "ServerID": CAServerID
    }
    let flowsCommon = await forumDetails(tempJson);
    if (flowsCommon["ErrorCode"] === 1074) {
      toast.error((objectNullandUndefined('221')));
      setTimeout(() => {
        localStorage.clear();
        sessionStorage.clear();
        history.push('/');
        window.location.reload(false);
      }, 3000)
    }
    let commonFlows = flowsCommon['ErrorMessage']['ForumDataArray']
    if(flowsCommon['ErrorCode'] == 1074){
      toast.warning(objectNullandUndefined("56"))
      localStorage.clear();
      sessionStorage.clear();
      history.push('/');
      window.location.reload(false);
    }
    if (commonFlows.length > 0) {
      for (let i = 0; i < commonFlows.length; i++) {
        let recForumData = commonFlows[i]
        let forumKey = Object.keys(recForumData)
        if (forumKey[0] === CommonForumID) {
          let commonUserFlows = recForumData[CommonForumID]['NewForumJsonData']
          let parsedCommonFlow = commonUserFlows["UserFlow"]
          let commonTempName = commonUserFlows["Template"]
          let reportFlowID = parsedCommonFlow[commonTempName + '_Locations']
          let formats = reportFlowID["Formats"][0]
          let locationFlows = parsedCommonFlow[formats]
          let key = Object.keys(locationFlows)
          let locationKeys = locationFlows[key][2]
          let locations1 = locationKeys["Options"]
          let locationList = Object.keys(locations1)
          setLocationsCatogary(locationList)
          localStorage.setItem("locationList",JSON.stringify(locationList))

          let reportFlowID_JobCategories = parsedCommonFlow[commonTempName + '_JobCategories']
          let formats_JobCategories = reportFlowID_JobCategories["Formats"][0]
          let Flows_JobCategories = parsedCommonFlow[formats_JobCategories]
          let locationKeys_JobCategories = Flows_JobCategories['row1'][2]
          let JobCategories = locationKeys_JobCategories["Options"]
          let JobCategoriesList = Object.keys(JobCategories)
          let val = await updateJobsObject(JobCategoriesList)
          localStorage.setItem("JobCategoriesList",JSON.stringify(val))
          let locationKeys_JobCategoriesID = Flows_JobCategories['row2'][4]
          let JobCategoriesID = locationKeys_JobCategoriesID["Options"]
          let JobCategoriesListID = Object.keys(JobCategoriesID)
          let IndexForumIdObject = {}
          for(let j = 0 ; j < JobCategoriesListID.length;j++){
            IndexForumIdObject[JobCategoriesListID[j]] = JobCategoriesID[JobCategoriesListID[j]];
          }
          localStorage.setItem("IndexForumIdObject",JSON.stringify(IndexForumIdObject));
          setIsLoding(false)
          setJobCatogary(JobCategoriesList)
          // locations.push(locationList)
        }
      }
    }
  }

  async function updateJobsObject(JobCategoriesList){
    let newJobsIndexObj = []
    for (let i = 0; i < JobCategoriesList.length; i++) {
      let obj = {}
      obj['id'] = JobCategoriesList[i];
      obj['label'] = objectNullandUndefined(String(JobCategoriesList[i]))
      if(obj['label'] == undefined){
        obj['label'] = JobCategoriesList[i];
      }
      newJobsIndexObj.push(obj)
    }
   return newJobsIndexObj;
  }
  const handleSubmit = () => {
    localStorage.setItem('selectedLocation', selectedLocation);

    if (selectedJobCategoryList.length != 0 && selectedLocation) {
      saveLocationToUserProfile(selectedLocation)
      bridgeSending()
      addAsUserToIndexForum(selectedJobCategoryList)
      history.push("/ShortContainer");  
    } else {
      alert(objectNullandUndefined('114'));
    }
  };

  const addUser= async (ForumId) =>{
    let userDetails = localStorage.getItem('userProfile');
        let decoded = base64_decode(userDetails);
        const userData = JSON.parse(decoded);
        let jsonObject = {
            "MACAddress":userData.MACAddress,
            "ForumID": ForumId,
            "SessionID": userData.SessionID,
            "Owner": userData.UserName,
            "OwnerMobileNumber": userData.MobileNumber,
            'ServerID': FSServerID,
        }
        let data = await userJoinToForum(jsonObject);
        if (data["ErrorCode"] === 1074) {
          toast.error((objectNullandUndefined('221')));
          setTimeout(() => {
            localStorage.clear();
            sessionStorage.clear();
            history.push('/');
            window.location.reload(false);
          }, 3000)
        }
        if(data['ErrorCode'] == 1035){
          return data['ErrorCode'];
        }else{
          toast.error(objectNullandUndefined('56'));
          return null
        }
        
  }

    async function addAsUserToIndexForum(selectedJobCategoryList){
      let IndexForumIdObject = localStorage.getItem('IndexForumIdObject');
      IndexForumIdObject = JSON.parse(IndexForumIdObject);
      for(let i = 0 ;i < selectedJobCategoryList.length;i++){
        let responce = await addUser(IndexForumIdObject[selectedJobCategoryList[i]])
      }
    }
    async function bridgeSending() {
      let commonForumTemp = localStorage.getItem("commonUserFlows")
      let parsedFlow = JSON.parse(commonForumTemp)
      let parsedCommonFlow = parsedFlow["UserFlow"]
      let commonTempName = parsedFlow["Template"]
      let filterFlowID = parsedCommonFlow[commonTempName + '_CandidateFilter']
    try {
      let uuid = myUuid
    
      let userDetails = localStorage.getItem('userProfile');
      userDetails = base64_decode(userDetails);
      userDetails = JSON.parse(userDetails);
      let sessionID = userDetails.SessionID;
      let macAddress = userDetails.MACAddress;
      let flowName = commonTempName + '_CandidateFilter';;
      let fID = filterFlowID['FID']
      let tempJson = {
        ForumID: CommonForumID,
        SessionID: sessionID,
        MACAddress: macAddress,
        Time: '6516516156161',
        ScheduledDateTime: 'now',
        ScheduledBoolean: 0,
        FlowID: flowName,
        EnableChat: 0,
        FlowType: 'Custom',
        BridgeForward: 0,
        TemplateID: parsedFlow["TemplateID"],
        TextCount: 6,
        ImageCount: 0,
        InvoiceID: '',
        DocumentCount: 0,
        User: true,
        VideoCount: 0,
        ServerID: FSServerID,
        ReplyBridgeID: '',
        HiddenFlow: false,
        BilledForum: '',
        TempBridgeId: uuid,
        FID: fID,
        SentTo: '0',
      };
      
      let data1 = {
        'FilterType': (selectedJobCategoryList),
        'CityType': (selectedLocation)

      }

      let jsonData = {
        [CommonForumFlow + '_CandidateFilter_Main-88f212c1-b826-490c-a351-ff093f97b87b_2']: base64_encode(JSON.stringify(data1)),
      };
      tempJson[flowName] = jsonData;
      const data = new FormData();
      data.append('Data', JSON.stringify(tempJson));
      let bridgeData = await bridgeSendingByUser(data);
      if (bridgeData["ErrorCode"] === 1074) {
        toast.error((objectNullandUndefined('221')));
        setTimeout(() => {
          localStorage.clear();
          sessionStorage.clear();
          history.push('/');
          window.location.reload(false);
        }, 3000)
      }
    } catch (err) {
      return;
    }
  }
  
   function onSelect(selectedList, selectedItem) {
    setSelectedJobCategoryList(selectedList)
    let selectedListString = JSON.stringify(selectedList)
    localStorage.setItem('CandidateIntres',selectedListString);
    }
    function onSelectLoction(selectedList, selectedItem) {
      setSelectedLocation(selectedItem)
      }

    function onRemove(selectedList, removedItem) {
      setSelectedJobCategoryList(selectedList)
      let selectedListString = JSON.stringify(selectedList)
      localStorage.setItem('CandidateIntres',selectedListString);
    }
  const handleCancel = () => {
    jobCategory.current.resetSelectedValues();
    jobLocation.current.resetSelectedValues();
    setSelectedJobCategory('');
    setSelectedLocation('');
  };
  const HandleLogOut = async () => {
    let res = await logOutFromSystem();
    localStorage.clear();
    sessionStorage.clear();
    history.push('/');
    window.location.reload(false);

  };

  return (
    <><NavBar>
    </NavBar><><div className="container5">
    </div>
    <div className="container4">
          <h1>{objectNullandUndefined('123')}</h1>

          {/* <div className="job">
            <FilterDropdown
              label="Job Category"
              options={jobCategories}
              selectedOption={selectedJobCategory}
              onSelect={setSelectedJobCategory} />
          </div> */}

          <div className='job'>
            <p style={{fontWeight:'bold',marginBottom:'5px'}}>Job Category</p>
          <Multiselect
            placeholder ={objectNullandUndefined('93')}
            id="jobCategory"
            ref={jobCategory}
            label="Job Category"
            displayValue="key"
            isObject={false}
            onSelect={onSelect}
            onRemove={onRemove}
            showCheckbox = {true}
            options={jobCategories}
            selectionLimit={3}
            showArrow = {true}
            // emptyRecordMsg = {objectNullandUndefined('194')}
            emptyRecordMsg = {objectNullandUndefined('2544')}
            loading = {isLoading}
          />
          </div>

          {/* <div className="location">
            <FilterDropdown
              label="Location"
              options={locations}
              selectedOption={selectedLocation}
              onSelect={setSelectedLocation} />
          </div> */}

          <div className="location">
          <p style={{fontWeight:'bold',marginBottom:'5px'}}>Location</p>
          <Multiselect
            placeholder ={objectNullandUndefined('93')}
            id="jobLocation"
            ref={jobLocation}
            displayValue="key"
            isObject={false}
            onSelect={onSelectLoction}
            showCheckbox = {false}
            options={locations}
            loading = {isLoading}
            selectionLimit={1}
            closeOnSelect = {true}
            showArrow = {true}
          />
          </div>

          <div className="buttons2">

            <button onClick={handleCancel}>{objectNullandUndefined('166')}</button>
            <button onClick={handleSubmit}>{objectNullandUndefined('9')}</button>
          </div>
          <p className="back-icon"  onClick={HandleLogOut}>
                  <ArrowBackIcon style={{ color: 'black', fontSize: '2rem' }} />
                </p>
        </div></></>
      
 
  );
};

const FilterDropdown = ({ label, options, selectedOption, onSelect }) => {
  return (
    <div className="dropdown1">
      <label className="dropdown1-label">{label}</label>
      <select className="dropdown1-select" value={selectedOption} onChange={(e) => onSelect(e.target.value)}>
        <option value="">{objectNullandUndefined('93')}</option>
        {options.map((option, index) => (
          <option key={index} value={option}>
            {option}
          </option>
        ))}
      </select>
    </div>
  );
};
const NavBar = (props) => {
  return (
      <div>
      <nav className="navigation">
          <a className="brand-name">
              <h2>Prescreener</h2>
          </a>
          <nav style={{position:"absolute",top:"25%",right:"20px"}}>
          <a href="https://prescreener.in/#/" target="_blank" rel="noopener noreferrer" style={{ color: 'inherit' }}>
                    <Avatar sx={{ width: 56, height: 56 }} style={{ marginRight: "0" }} alt="" src="/logo.png" />
                    </a>
          </nav>
      </nav>
      <nav className="nav2">
            <h6 style={{ backgroundColor: 'lightgrey', width: '100%', textAlign: 'center' }}>
              <a href="https://cosmitude.com" target="_blank" rel="noopener noreferrer" style={{ color: 'inherit' }}>
              By Cosmitude Softwares Private Limited
              </a>
            </h6>
          </nav>
      </div>
  )
}

export default JobLocationFilter;
