import React, { useState, useEffect, useRef } from 'react';
import './Filter.css';
import { oneToOne } from '../../services/LoginLogoutService';
import { decode as base64_decode, encode as base64_encode } from 'base-64';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import { MacAddress } from '../Dictionary/CommonStrings';
const DropdownFilter = ({ options,props }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState('');
  const [selectedOptionToSend, setSelectedOptionToSend] = useState('');
  const [searchTerm, setSearchTerm] = useState('');
  const dropdownRef = useRef(null);



  const toggleDropdown = () => {
    setIsOpen(!isOpen);
    if (!isOpen) {
      setSearchTerm('');
    }
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };

    document.addEventListener('click', handleClickOutside);

    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, []);
  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
  };

  const filteredOptions = options.filter((option) =>
    option.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const handleOptionClick = (option) => {
    setSelectedOption(option);
    setIsOpen(false);
    // console.log('Selected option:', option);
    setSelectedOptionToSend(option)
    oneToOneChat();
  };
  async function oneToOneChat() {
    props.history.push("/ShortContainer");
    let userDetails = localStorage.getItem('userProfile');
    let decoded = base64_decode(userDetails);
    userDetails = JSON.parse(decoded);
    let json = {
      MobileNumber: userDetails.MobileNumber,
      MACAddress: userDetails.MACAddress,
      ForumID: '274343bc055e83e7a14ebeaa33761390e136b0',
      SessionID: userDetails.SessionID,
      ScheduledDateTime: 'now',
      ScheduledBoolean: false,
      EnableChat: false,
      FlowType: 'Custom',
      BridgeForward: false,
      User: false,
      Time: '6516516156161',
      TempBridgeId: '1aba11e9-45cf-a1ea-833e-30b29d1aa46b',
      ReachedServer: 'False',
      UserType: 'SELF',
      ReplyBridgeID: '',
      FlowID: 'Chat_Talk',
      Chat_Talk: { Chat_Talk_Sample_2: base64_encode(selectedOptionToSend) },
      ReceiverMobileNumber: '274343bc055e83e7a14ebeaa33761390e136b0',
      MediaType: '0',
      UserUUID: '21682d86-3d27-47e2-9eeb-1214403430a6',
      UserName: 'SELF',
      ServerID: '5a32b54c-51ff-51c1-16cf-76939f188068',
    };
    const data = new FormData();
    data.append('Data', JSON.stringify(json));
    let reportData = await oneToOne(data);
    // console.log(reportData);
  }
  useEffect(() => {
    if (selectedOptionToSend) {
      oneToOneChat();
    }
  }, [selectedOptionToSend]);

  return (
    <div className="dropdown" ref={dropdownRef}>
      <button className="dropdown-icon" onClick={() => toggleDropdown()} >
        <FilterAltIcon style={{ color: 'white',marginLeft:'330px',fontSize:'1.3em',marginTop:'-4px' }} /><p style={{ color: 'white', fontSize: '16px', padding: '10%', paddingBlockStart: '50%',marginTop:'-14px' }}></p>
      </button>
      {isOpen && (
        <ul
          style={{
            // marginLeft:'-100px',
            position: 'absolute',
            // top: '180%',
            height:'600%',
            padding: 0,
            backgroundColor: 'black',
            border: '1px solid white',
            borderTop: 'none',
            overflowY: 'auto',
            width: '100%',
            zIndex:'999'
          }}
        >
          <li
            style={{
              padding: '4px',
              cursor: 'pointer',
              borderBottom: '1px solid #333',
              textAlign: 'center',
              fontSize: '16px',
              fontWeight: 'bold',
              marginBottom: '10px',
              color: '#fff',
            }}
          >
            <input
              type="text"
              placeholder="Search"
              value={searchTerm}
              onChange={handleSearchChange}
              style={{
                padding: '4px',
                cursor: 'pointer',
                textAlign: 'center',
                fontSize: '16px',
                fontWeight: 'bold',
                marginBottom: '10px',
                width: '100%',
                height: '45%',
              }}
            />
          </li>
          {filteredOptions.map((option) => (
            <li key={option} onClick={() => handleOptionClick(option)}
              style={{
                padding: '10px',
                cursor: 'pointer',
                borderBottom: '1px solid lightgray',
                textAlign: 'center',
                fontSize: '16px',
                fontWeight: 'bold',
                marginBottom: '10px',
                color: '#fff',
              }}
              onMouseEnter={(e) => {
                e.target.style.backgroundColor = 'lightgray';
              }}
              onMouseLeave={(e) => {
                e.target.style.backgroundColor = 'inherit';
              }}
            >
              {option}
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

export default DropdownFilter;
