
import { getMessaging, getToken, onMessage } from 'firebase/messaging';
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';
import { decode as base64_decode, encode as base64_encode } from 'base-64';

const firebaseConfig = {
  apiKey: "AIzaSyC2dn6GYGu3Z6rvvS6kyGhMNa-EUkdwGeI",
  authDomain: "prescreener-c092f.firebaseapp.com",
  projectId: "prescreener-c092f",
  storageBucket: "prescreener-c092f.appspot.com",
  messagingSenderId: "533531884352",
  appId: "1:533531884352:web:d813f8fab0ac188ff518a5",
  measurementId: "G-T9Q96BF6PR"
};
const app = firebase.initializeApp(firebaseConfig);
const messaging = getMessaging(app);
const db = app.firestore();
const auth = firebase.auth();
const provider = new firebase.auth.GoogleAuthProvider();

export { auth, provider };

export default db;




export const requestForToken = () => {
  console.log('Requesting User Permission……');
  Notification.requestPermission().then((permission) => {
    if (permission === "granted") {
      console.log("Notification User Permission Granted.");
      return getToken(messaging, { vapidKey : "BPQwT_A8ADsBP3cgqiumiy0LsrBDgxz_1q-YxqF3pjHMibwTuBCL9Qd6fB2PxSfWS9MYTurxo32-3bj_1dwWRUM"})
        .then((currentToken) => {
          if (currentToken) {
            localStorage.setItem('fcmtoken', base64_encode(JSON.stringify(currentToken)));
            console.log('Client Token: ', currentToken);
          } else {
            console.log('Failed to generate the registration token.');
          }
        })
        .catch((err) => {
          console.log('An error occurred when requesting to receive the token.', err);
        });
    } else {
      console.log("User Permission Denied.");
      localStorage.setItem('fcmtoken', null);
    }
  })
}

// export const onMessageListener = () =>
//   new Promise((resolve) => {
//     onMessage(messaging, (payload) => {
//       resolve(payload);
      
//     });
//   });



