import "../User/User.css"
import React from "react";
import { useEffect,useState } from "react";
import { decode as base64_decode, encode as base64_encode } from 'base-64';
import { fetchForumInfo, forumDetails, userJoinToForum } from "../../services/LoginLogoutService";
import Short from "../Short";
import { Route, useLocation } from "react-router-dom";
import Fab from '@material-ui/core/Fab';
import CameraAltIcon from '@mui/icons-material/CameraAlt';
import { useReactMediaRecorder } from "react-media-recorder";
import Webcam from 'react-webcam';
import { Card, Container, Button } from "react-bootstrap";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import uuid from 'react-uuid';
import { CircularProgress } from '@material-ui/core';
import { objectNullandUndefined } from "../Dictionary/Dictionary";
import { CAServerID, FSServerID, MacAddress, RecCreateTempName, RecUserFlow, RecUserFlowID, RecruiterForumTemp, forumServerIP } from "../Dictionary/CommonStrings";
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Slide } from '@mui/material';
import axios from "axios";
import { makeStyles } from '@material-ui/core/styles';
import Tooltip from '@mui/material/Tooltip';
import { useHistory } from 'react-router-dom';
import utf8 from 'utf8';

const useStyles = makeStyles((theme) => ({
    dialog: {
        maxWidth: '90%',
        [theme.breakpoints.up('sm')]: {
            maxWidth: '30%',
            position: 'absolute',
            left: '10%',
            top: '10%',
            transform: 'translate(85%, 0%)',
        },
    },
}));


const dialogStyles = {
    maxWidth: '90%',
};

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});
function User() {
    const [videoUrl, setVideoUrl] = React.useState('');
    const videoRef = React.useRef(null);
    const [loading, setLoading] = React.useState(false);
    const [isPlaying, setIsPlaying] = React.useState(false);
    const { status, startRecording, stopRecording, mediaBlobUrl } =
        useReactMediaRecorder({ video: true });
    const location = useLocation();
    const [isRecording, setIsRecording] = React.useState(false);
    // let forumId = location.state?.forumId;
    // let forumName = location.state?.forumName;
    const webcamRef = React.useRef(null);
    const history = useHistory();
    const myUuid = uuid();
    const [paidPop, setPaidPop] = React.useState(false)
    const [showPermissionDialog, setShowPermissionDialog] = React.useState(false);
    const [blobUrl, setBlobUrl] = React.useState()
    let isPaid = false;
    let PaymentId = 'NotPaid'
    const classes = useStyles();
    const [timeoutId, setTimeoutId] = useState(null);
    const [forumId, setforumId] = useState("");
    const [forumName, setforumName] = useState("");

    useEffect(() => {
        let forumId = localStorage.getItem('candidateSelectedToApplyForumID')
        setforumId(forumId);
        let forumName = localStorage.getItem('candidateSelectedToApplyForumName')
        setforumName(forumName)
        const handleServiceWorkerMessage = (event) => {
          if (event.data.type === 'navigate') {
            history.push('/Notification');
          }
        };
    
        navigator.serviceWorker.addEventListener('message', handleServiceWorkerMessage);
    
        return () => {
          navigator.serviceWorker.removeEventListener('message', handleServiceWorkerMessage);
        };
      }, []);

    const uploadVideo = async () => {
        let candidatePay = localStorage.getItem("candidatePayment")
        if (!blobUrl) {
            toast.warning(objectNullandUndefined('115'));
            return;
        }
        if (candidatePay == null || candidatePay == undefined || candidatePay === "true") {
            setPaidPop(!paidPop)
        } else {
            isPaid = false
            setLoading(true);
            const jsonData = localStorage.getItem('userProfile');
            let decoded = base64_decode(jsonData)
            const userData = JSON.parse(decoded);

            let jsonObject = {
                "MACAddress": userData.MACAddress,
                "ForumID": forumId,
                "SessionID": userData.SessionID,
                "Owner": userData.UserName,
                "OwnerMobileNumber": userData.MobileNumber,
                'ServerID': FSServerID,
            }
            let data = await userJoinToForum(jsonObject);
            if (data["ErrorCode"] === 1074) {
                toast.error((objectNullandUndefined('221')));
                setTimeout(() => {
                  localStorage.clear();
                  sessionStorage.clear();
                  history.push('/');
                  window.location.reload(false);
                }, 3000)
              }
            let tempJson = {
                "MACAddress": userData['MACAddress'],
                "ForumID": forumId,
                "SessionID": userData['SessionID'],
                "ServerID": CAServerID
            }
            let forumIDs = await forumDetails(tempJson)
            if (forumIDs["ErrorCode"] === 1074) {
                toast.error((objectNullandUndefined('221')));
                setTimeout(() => {
                  localStorage.clear();
                  sessionStorage.clear();
                  history.push('/');
                  window.location.reload(false);
                }, 3000)
              }
            let candidateFlowData = forumIDs['ErrorMessage']['ForumDataArray']
            if (candidateFlowData.length > 0) {
                for (let i = 0; i < candidateFlowData.length; i++) {
                    let candidateForumData = candidateFlowData[i]
                    let forumKey = Object.keys(candidateForumData)
                    if (forumKey[0] === forumId) {
                        let candidateFlows = candidateForumData[forumId]['NewForumJsonData']["UserFlow"]
                        localStorage.setItem("UserTempFlows", JSON.stringify(candidateFlows))
                    }
                }
            }
            if (data['ErrorCode'] === 1035) {
                setTimeout(() => {
                    bridgeSending();
                }, 100)

            } else if (data['ErrorCode'] === 1010) {
                toast.warning(objectNullandUndefined('54'))
                history.push("/ShortContainer");
            }
            else {
                toast.error(objectNullandUndefined('56'), {
                    position: toast.POSITION.TOP_CENTER,
                    autoClose: 5000,
                    hideProgressBar: true,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
                setLoading(false);
            }
        }
    };

    const handleClose = () => {
        setPaidPop(!paidPop)
    }

    const handleFree = async () => {
        isPaid = false
        setPaidPop(!paidPop)
        setLoading(true);
        const jsonData = localStorage.getItem('userProfile');
        let decoded = base64_decode(jsonData)
        const userData = JSON.parse(decoded);
        let jsonObject = {
            "MACAddress": userData["MACAddress"],
            "ForumID": forumId,
            "SessionID": userData["SessionID"],
            "Owner": userData["UserName"],
            "OwnerMobileNumber": userData["MobileNumber"],
            'ServerID': FSServerID,
        }
        let data = await userJoinToForum(jsonObject);
        if (data["ErrorCode"] === 1074) {
            toast.error((objectNullandUndefined('221')));
            setTimeout(() => {
              localStorage.clear();
              sessionStorage.clear();
              history.push('/');
              window.location.reload(false);
            }, 3000)
          }
        let tempJson = {
            "MACAddress": userData['MACAddress'],
            "ForumID": forumId,
            "SessionID": userData['SessionID'],
            "ServerID": CAServerID
        }
        let forumIDs = await forumDetails(tempJson)
        if (forumIDs["ErrorCode"] === 1074) {
            toast.error((objectNullandUndefined('221')));
            setTimeout(() => {
              localStorage.clear();
              sessionStorage.clear();
              history.push('/');
              window.location.reload(false);
            }, 3000)
          }
        let candidateFlowData = forumIDs['ErrorMessage']['ForumDataArray']
        if (candidateFlowData.length > 0) {
            for (let i = 0; i < candidateFlowData.length; i++) {
                let candidateForumData = candidateFlowData[i]
                let forumKey = Object.keys(candidateForumData)
                if (forumKey[0] === forumId) {
                    let candidateFlows = candidateForumData[forumId]['NewForumJsonData']["UserFlow"]
                    localStorage.setItem("UserTempFlows", JSON.stringify(candidateFlows))
                }
            }
        }
        if (data['ErrorCode'] === 1035) {
            setTimeout(() => { bridgeSending() }, 100)

        } else if (data['ErrorCode'] === 1010) {
            toast.warning(objectNullandUndefined('54'));
            setTimeout(() => {
                history.push("/ShortContainer");
            }, 3000)
        } else {
            toast.warning((objectNullandUndefined('56')));
            setTimeout(() => {
                history.push("/ShortContainer");
            }, 3000)
        }
    }


    const handlePaid = async () => {
        isPaid = true
        setPaidPop(!paidPop)
        if (!mediaBlobUrl) {
            toast.warning(objectNullandUndefined('115'));
            return;
        }
        setLoading(true);
        const jsonData = localStorage.getItem('userProfile');
        let decoded = base64_decode(jsonData)
        const userData = JSON.parse(decoded);
        let jsonObject = {
            "MACAddress": userData["MACAddress"],
            "ForumID": forumId,
            "SessionID": userData["SessionID"],
            "Owner": userData["UserName"],
            "OwnerMobileNumber": userData["MobileNumber"],
            'ServerID': FSServerID,
        }
        let data = await userJoinToForum(jsonObject);
        if (data["ErrorCode"] === 1074) {
            toast.error((objectNullandUndefined('221')));
            setTimeout(() => {
              localStorage.clear();
              sessionStorage.clear();
              history.push('/');
              window.location.reload(false);
            }, 3000)
          }
        let tempJson = {
            "MACAddress": userData['MACAddress'],
            "ForumID": forumId,
            "SessionID": userData['SessionID'],
            "ServerID": CAServerID
        }
        let forumIDs = await forumDetails(tempJson)
        if (forumIDs["ErrorCode"] === 1074) {
            toast.error((objectNullandUndefined('221')));
            setTimeout(() => {
              localStorage.clear();
              sessionStorage.clear();
              history.push('/');
              window.location.reload(false);
            }, 3000)
          }
        let candidateFlowData = forumIDs['ErrorMessage']['ForumDataArray']
        if (candidateFlowData.length > 0) {
            for (let i = 0; i < candidateFlowData.length; i++) {
                let candidateForumData = candidateFlowData[i]
                let forumKey = Object.keys(candidateForumData)
                if (forumKey[0] === forumId) {
                    let candidateFlows = candidateForumData[forumId]['NewForumJsonData']["UserFlow"]
                    localStorage.setItem("UserTempFlows", JSON.stringify(candidateFlows))
                }
            }
        }
        if (data['ErrorCode'] === 1035) {
            setTimeout(() => { bridgeSending() }, 100)

        } else if (data['ErrorCode'] === 1010) {
            toast.warning(objectNullandUndefined('54'));
            setTimeout(() => {
                history.push("/ShortContainer");
            }, 3000)
        } else {
            toast.warning(objectNullandUndefined('56'));
            setTimeout(() => {
                history.push("/ShortContainer");
            }, 3000)
        }
    }


    React.useEffect(() => {
        if (mediaBlobUrl) {
            setVideoUrl(mediaBlobUrl);
            setBlobUrl(mediaBlobUrl)
        }
    }, [mediaBlobUrl]);

    const handleCancel = () => {
        const confirmed = window.confirm(objectNullandUndefined('191'));
        if (confirmed) {
            setVideoUrl(null);
            clearTimeout(timeoutId);
        }
    };


    const handleVideoClick = () => {
        if (isPlaying) {
            videoRef.current.pause();
        } else {
            videoRef.current.play();
        }
        setIsPlaying(!isPlaying);
    };

    const handleRecording = async () => {
        try {
            await navigator.mediaDevices.getUserMedia({ video: true, audio: true });
            if (status === 'idle' || status === 'stopped') {
                let count = 2;
                const toastId = toast.info(`Your recording starts in ${count}...`, {
                    position: toast.POSITION.TOP_CENTER,
                    autoClose: false,
                    hideProgressBar: true,
                    closeButton: false,
                    progressClassName: 'toast-progress',
                    toastClassName: 'toast-info',
                    bodyClassName: 'toast-body',
                });

                const countdownInterval = setInterval(() => {
                    count--;
                    if (count === 0) {
                        toast.update(toastId, {
                            render: 'Recording in progress...',
                            autoClose: 3000,
                        });
                        clearInterval(countdownInterval);
                        const newTimeoutId = setTimeout(() => {
                            stopRecording(); // Stop recording after 60 seconds
                            setIsRecording(false);
                        }, 60000);
                        setTimeoutId(newTimeoutId);
                    } else {
                        toast.update(toastId, {
                            render: `Your recording starts in ${count}...`,
                        });
                    }
                }, 1000);
                startRecording();
                setIsRecording(true);
            } else {
                clearTimeout(timeoutId);
                stopRecording();
                setIsRecording(false);
            }
        } catch (error) {
            setShowPermissionDialog(true);
        }
    };

    const closePermissionDialog = () => {
        setShowPermissionDialog(false);
    };
    function loadScript(src) {
        return new Promise((resolve) => {
            const script = document.createElement("script");
            script.src = src;
            script.onload = () => {
                resolve(true);
            };
            script.onerror = () => {
                resolve(false);
            };
            document.body.appendChild(script);
        });
    }
    async function displayRazorpay() {
        const res = await loadScript(
            "https://checkout.razorpay.com/v1/checkout.js"
        );

        if (!res) {
            alert("Razorpay SDK failed to load. Are you online?");
            return;
        }
        // creating a new order
        const result = await axios.post("https://pay.prescreener.in/order");
        // const result = await axios.post("http://34.93.177.110:3000/order");
        console.log(result)

        // if (!result) {
        //     alert("Server error. Are you online?");
        //     return;
        // }

        // Getting the order details back
        const { amount, id: order_id, currency, key_id } = result.data;
        // const order_id = "order_MeYds8znYjfy70"

        const options = {
            key: key_id, // Enter the Key ID generated from the Dashboard
            amount: amount,
            currency: currency,
            name: "Cosmitude Softwares Private Limited",
            description: "Priority pay",
            // image: { logo },
            order_id: order_id,
            handler: async function (response) {
                const data = {
                    orderCreationId: order_id,
                    razorpayPaymentId: response.razorpay_payment_id,
                    razorpayOrderId: response.razorpay_order_id,
                    razorpaySignature: response.razorpay_signature,
                };
                handlePaid()
                PaymentId = data.razorpay_payment_id
            },
            prefill: {
                name: "",
                email: "cosmitude@cosmitude.com",
                contact: "8050396072",
            },
            notes: {
                address: "Cosmitude Softwares",
            },
            theme: {
                color: "#61dafb",
            },
        };

        const paymentObject = new window.Razorpay(options);
        paymentObject.open();
    }
    async function bridgeSending() {
        let candidateApplyFlow = localStorage.getItem("UserTempFlows")
        let userParsedFlow = JSON.parse(candidateApplyFlow)
        let userCreateFlowName = userParsedFlow[RecCreateTempName + '_User']
        try {
            let userDetailsArr = JSON.parse(localStorage.getItem('userData'))
            let uuid = myUuid
            
            let userDetails = localStorage.getItem('userProfile');
            userDetails = base64_decode(userDetails);
            userDetails = JSON.parse(userDetails)
            let sessionID = userDetails.SessionID;
            let macAddress = userDetails.MACAddress;
            let flowName = RecCreateTempName + '_User';;
            let fID = userCreateFlowName['FID']

            let FirstName = userDetails['FirstName'] ?? "Jack";
            let LastName = userDetails['LastName'] ?? "Doe";
            let FullName = FirstName+" "+LastName;
            let tempJson = {
                ForumID: forumId,
                SessionID: sessionID,
                MACAddress: macAddress,
                Time: '6516516156161',
                ScheduledDateTime: 'now',
                ScheduledBoolean: 0,
                FlowID: flowName,
                EnableChat: 0,
                FlowType: 'Custom',
                BridgeForward: 0,
                TemplateID: RecruiterForumTemp,
                TextCount: 6,
                ImageCount: 0,
                InvoiceID: '',
                DocumentCount: 0,
                User: true,
                VideoCount: 0,
                ServerID: FSServerID,
                ReplyBridgeID: '',
                HiddenFlow: false,
                BilledForum: '',
                TempBridgeId: uuid,
                FID: fID,
                SentTo: '0',
            };
            const utf8EncodedName = utf8.encode(forumName);
            let data1 = {
                "MediaID": userDetails['UserProfilePicture'],
                "PaymentId": PaymentId,
                "CompanyName":utf8EncodedName,
                "ToBeConverted":true,
                "FullName":FullName ?? "Jack Doe"

            }
            let jsonData = {
                [RecCreateTempName+'_User_Main-d219b924-c3cd-437c-99b7-32c61d46c263_2']: base64_encode(JSON.stringify(data1)),
                [RecCreateTempName+'_User_Main-d219b924-c3cd-437c-99b7-32c61d46c263_4']: base64_encode(isPaid ? "true" : "false"),
            };
            tempJson[flowName] = jsonData;
            const data = new FormData();
            data.append('Data', JSON.stringify(tempJson));
            const response = await fetch(blobUrl);
            const blob = await response.blob();
            const file = new File([blob], 'video.mp4');
            data.append(RecCreateTempName+'_User_Main-d219b924-c3cd-437c-99b7-32c61d46c263_6', file);

            const URL = forumServerIP + 'saveForumBridges2'
            const xhr = new XMLHttpRequest();
            xhr.open('POST', URL);
            xhr.send(data);
            xhr.onreadystatechange = async e => {
                if (xhr.readyState !== 4) {
                    return;
                }
                if (xhr.status === 200) {
                    let errorCode = JSON.parse(xhr.responseText)
                    PaymentId = "NotPaid"
                    if (errorCode['ErrorCode'] === 1060) {
                        const userDetail = {
                            ForumID: forumId,
                            ForumName: forumName,
                            ForumDescription: "Under Review",
                            Template: ""
                        }
                        userDetailsArr.push(userDetail)
                        localStorage.setItem('userData', JSON.stringify(userDetailsArr));
                    }
                    fetchForumInfo()
                } else {
                    console.log('error', xhr.responseText);
                    bridgeSending();
                }
            }
            toast.success(objectNullandUndefined('133'));
            setTimeout(() => {
                history.push("/ShortContainer");
            }, 3000)
        } catch (err) {
            return;
        }

    };
    const cardStyle = {
        top: 100,
        border: "0px",
        flex: 1,
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        display: "flex",
        backgroundColor: "transparent"
    };
    return (
        <div style={{ backgroundColor: 'black' }}>
            <Container
                className="d-flex justify-content-center align-items-center"
                style={{ height: "100vh", backgroundColor: 'black' }}>
                <ToastContainer position="top-center" autoClose={2000} hideProgressBar />
                <Card
                    style={{
                        paddingTop: '1%',
                        height: "100%",
                        width: "60%",
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        alignItems: "center",
                        backgroundColor: '#bababa'
                    }}
                >
                    <h2 className="heading">{objectNullandUndefined('59')}</h2>
                    <Card.Body>
                        <Route path="/Short" component={Short} />
                        <div>
                            {isRecording ? (
                                <>
                                    <Webcam audio={false} ref={webcamRef} style={{ width: '92%', height: 'auto', paddingLeft: '9%' }} />
                                    <button
                                        className="recording"
                                        onClick={handleRecording}>
                                        {objectNullandUndefined('128')}
                                    </button>
                                </>
                            ) : !videoUrl && (
                                <Fab
                                    color="primary"
                                    size="large"
                                    component="span"
                                    aria-label="add"
                                    variant="extended"
                                    onClick={handleRecording}
                                    disabled={isRecording}
                                    style={{ position: 'relative', top: '50%', transform: 'translateY(450%)' }}>

                                    <div >
                                        <CameraAltIcon style={{ fontSize: "40px" }} fontSize="inherit" />
                                    </div>
                                </Fab>
                            )}
                            {videoUrl && (
                                <div
                                    style={{
                                        width: "100%",
                                        height: "50%",
                                        display: "flex",
                                        justifyContent: "center",
                                        alignItems: "center",
                                        flexDirection: "column",
                                    }}
                                >
                                    {!loading && (
                                        <video
                                            ref={videoRef}
                                            src={videoUrl}
                                            onClick={handleVideoClick}
                                            autoPlay
                                            controls
                                            style={{
                                                height: 'auto',
                                                width: '90%',
                                                objectFit: "contain",
                                            }}
                                        />
                                    )}
                                    <div style={{ display: "flex", justifyContent: "space-between", width: "90%" }}>
                                        {!loading && (status === "idle" || status === "stopped") && (
                                            <React.Fragment>
                                                <button className="videoCancel" onClick={handleCancel} disabled={loading}>
                                                    {objectNullandUndefined('16')}
                                                </button>
                                                <Dialog
                                                    open={paidPop}
                                                    TransitionComponent={Transition}
                                                    // keepMounted
                                                    onClose={handleClose}
                                                    fullWidth
                                                    maxWidth="false"
                                                    PaperProps={{
                                                        style: {
                                                            ...dialogStyles,
                                                            maxHeight: '90%',
                                                            borderRadius: '5px',
                                                        },
                                                    }}
                                                    BackdropProps={{
                                                        style: {
                                                            backgroundColor: 'rgba(0, 0, 0, 0)',
                                                        },
                                                    }}
                                                    className={classes.dialog}
                                                >
                                                    <DialogTitle style={{ backgroundColor: '#2BAE66', color: '#0C0705', fontWeight: 'bold' }}>{objectNullandUndefined('136')}
                                                    <Tooltip title="Payment will ensure recruiters see your video earlier than free video submissions in a parallel queue">
                                                        <IconButton
                                                            color="inherit"
                                                            aria-label="info"
                                                            sx={{
                                                                position: 'relative',
                                                                right: '1%',
                                                                top: '7.5%',
                                                                color: 'rgba(0, 0, 0, 0.87)',
                                                                fontSize: '8px'
                                                            }}
                                                        >
                                                            <InfoOutlinedIcon />
                                                        </IconButton>
                                                        </Tooltip>
                                                        <IconButton
                                                            edge="end"
                                                            color="inherit"
                                                            onClick={handleClose}
                                                            aria-label="close"
                                                            sx={{
                                                                position: 'absolute',
                                                                right: '5%',
                                                                top: '5%',
                                                                color: 'rgba(0, 0, 0, 0.87)',
                                                            }}
                                                        >
                                                            <CloseIcon />
                                                        </IconButton>
                                                    </DialogTitle>
                                                    <DialogContent>
                                                        <div style={{ alignContent: 'center', marginTop: '10%', fontFamily: 'Helvetica Bold', fontWeight: 'bold', fontSize: '18px' }}>
                                                            <div style={{ justifyContent: 'center', display: 'flex', }}>
                                                                <Button
                                                                    variant="contained"
                                                                    color="primary"
                                                                    onClick={handleFree}
                                                                    style={{
                                                                        backgroundColor: '#ADD8E6',
                                                                        border: 'none',
                                                                        fontWeight: 'bold',
                                                                        borderRadius: '5px',
                                                                        padding: '10px 20px',
                                                                        width: '120px',
                                                                        margin: '10px',
                                                                    }}
                                                                >
                                                                    {objectNullandUndefined('137')}
                                                                </Button>
                                                                <Button
                                                                    variant="contained"
                                                                    color="secondary"
                                                                    onClick={displayRazorpay}
                                                                    style={{
                                                                        backgroundColor: '#FFA836',
                                                                        border: 'none',
                                                                        fontWeight: 'bold',
                                                                        borderRadius: '5px',
                                                                        padding: '10px 20px',
                                                                        width: '120px',
                                                                        margin: '10px',
                                                                    }}
                                                                >
                                                                    {objectNullandUndefined('138')}
                                                                </Button>
                                                            </div>
                                                        </div>
                                                    </DialogContent>
                                                </Dialog>
                                                <button className="uploadUserButton" onClick={uploadVideo} disabled={loading}>
                                                    {objectNullandUndefined('9')}
                                                </button>
                                            </React.Fragment>
                                        )}
                                        {loading && (
                                            <div
                                                style={{
                                                    position: 'absolute',
                                                    top: '50%',
                                                    left: '50%',
                                                    transform: 'translate(-50%, -50%)',
                                                }}
                                            >
                                                <CircularProgress />
                                            </div>
                                        )}
                                    </div>
                                </div>
                            )}
                        </div>
                    </Card.Body>
                </Card>
            </Container>

            <Dialog open={showPermissionDialog} onClose={closePermissionDialog}>
                <DialogContent>
                    <DialogContentText>
                        {objectNullandUndefined('195')}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={closePermissionDialog} autoFocus>
                        {objectNullandUndefined('196')}
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
    // Custom CSS class for the wide dialog

}
export default User;