import { useEffect, useRef, useState, Alert,TextField } from "react";
import React from "react";
import axios from 'axios';
import { decode as base64_decode, encode as base64_encode } from 'base-64';
import { List, ListItem, Divider, ListItemText, ListItemAvatar, Avatar, Typography, ListItemButton, Card } from '@mui/material';
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import Groups3Icon from '@mui/icons-material/Groups3';
import Video from '../Admin/Video';
import { useHistory } from 'react-router-dom';
import * as XLSX from 'xlsx';
import { DeleteForum, fetchForumInfo, getLasTfiftydata1, generateReport, userJoinToForum, GenerateApi, ArchiveConsolidatedReportGeneration, getLasTfiftydata2,RecruitedConsolidationReportGenrationCount } from "../../services/LoginLogoutService";
import "./Notification.css"
import Hook from '../Hook';
import { CircularProgress } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';
import Candidatevideo from "./Candidatevideo";
import uuid from 'react-uuid';
import { objectNullandUndefined } from "../Dictionary/Dictionary";
import { CAServerID, ChatFID, ChatFlowID, CommonForumFlow, CommonForumID, FSServerID, MacAddress, RecCreateTempName, RecMessageFlow, RecruitDeleteFlow, RecruitDeleteFlowID, RecruitFlowReport, RecruitedCandidateForumReport, RecruitedCandidateTempFlow, RecruiterForumTemp, ShareLinkReportFlow, caServerIP, chatReportid, forumServerIP } from "../Dictionary/CommonStrings";
import isUrl from 'is-url';
import CloseIcon from '@mui/icons-material/Close';
import { toast, ToastContainer } from 'react-toastify';
import ShareIcon from '@mui/icons-material/Share';
import MessageIcon from '@mui/icons-material/Message';
import MessageIcons from '@mui/icons-material/MarkUnreadChatAlt';
import './QRCodePopup.css'; // Create this CSS file for styling
import QRCode from 'qrcode.react';
import utf8 from 'utf8';
import { RecUserFlow } from "../Dictionary/CommonStrings";
import { clientSync,AppliedConsolidationReportGenrationCount,underManualReviewConsolidationReportGenrationCount,endDateReport } from "../../services/LoginLogoutService";
import { red } from "@mui/material/colors";
import { light } from "@mui/material/styles/createPalette";
// import MenuIcon from '@mui/icons-material/Menu';
import MenuIcon from '@mui/icons-material/List';
import { event } from "react-ga";
// import ReactGA from 'react-ga';

export default function Notification() {
  // Add event listener for storage change
  const isRecuiter = localStorage.getItem('recruitValue');
  const [loading, setLoading] = useState(true);
  const [forumDetailsArr, setForumDetailsArr] = useState([]);
  const [RecruitedcandidateLists, setRecruitedcandidateLists] = useState([]);
  const [popupVisible, setPopupVisible] = useState(false);
  const [displayCandidateChatBox, setdisplayCandidateChatBox] = useState(false);
  const [isSearchOpen, setisSearchOpen] = useState(false);
  const [isSearchLoading, setisSearchLoading] = useState(false);
  const [loadingItemId, setLoadingItemId] = useState("");
  const [popupVisible2, setPopupVisible2] = useState(false);
  const [SelectedForumName, setSelectedForumName] = useState({});
  const [popupVisible1, setPopupVisible1] = useState('');
  const [epochtime, setEpochtime] = useState('');
  const [isOpen, setIsOpen] = useState(false);
  const history = useHistory();
  const [ForumData, setForumID] = useState("");
  const [forum, setForum] = useState("");
  const [open, setOpen] = React.useState(false);
  const [QrCodeLink, setQrcode] = useState('');
  const [message, setMessage] = useState('');
  const inputRef = useRef(null);
  const inputRefrec = useRef(null);
  const searchRef = useRef(null);
  const [SelectedCandidate, setSelectedCandidate] = useState({});
  const [SelectedCandidateMessages, setSelectedCandidateMessages] = useState([]);
  const [MobileNumber, setMobileNumber] = useState('');
  const [SelectedRecruterForumNameByCandidate, setSelectedRecruterForumNameByCandidate] = useState({});
  const [ShowMenuPopList,setShowMenuPopList] = useState(false);
  const [SelectedForumDetailsFromList, setSelectedForumDetailsFromList] = useState("");
  const [RecruitedCandidateCount, setRcount] = useState("");
  const [TotalAppliedCount, setTotalAppliedCount] = useState("");
  const [UnderReviewCount, setUnderReviewCount] = useState("");
  const [EndTime, setEndTime] = useState("");
  const [loadingItemId2, setLoadingItemId2] = useState("");
  const [isMenuLoading, setisMenuLoading] = useState(false);


  const toggleModal = () => {
    setShowMenuPopList(true);
  };

  const handleClose1 = (e) => {
    if (e.target === e.currentTarget) {
      setShowMenuPopList(false);
    }
  };

  const ConvertUnixTimeToString = (Time)=>{
    const date = new Date(Time * 1000);
    const options = {
      timeZone: 'Asia/Kolkata',
      hour: '2-digit',
      minute: '2-digit',
      year: 'numeric',
      month: 'short',
      day: 'numeric',
    };
    let time = date.toLocaleString('en-IN', options)
    return time
  }


  const DownloadAnalytics = (Forumn) => {
    let data1 = JSON.parse(localStorage.getItem(Forumn.ForumID+"AppliedAnalytics"))
    let data2 = JSON.parse(localStorage.getItem(Forumn.ForumID+"RecruitedAnalytics"))
    let data3 = []
    let SlCount = 1
    data3.push({})
    for (let index = 0; index < data1.length; index++) {
      const element = data1[index];
      let AppliedObject = {}
      AppliedObject['SL'] = SlCount++;
      AppliedObject['Status'] = "Applied"
      AppliedObject['UserName'] = element["UserName"]
      let Time = ConvertUnixTimeToString(element['Date'])
      AppliedObject['Time'] = Time
      data3.push(AppliedObject)
    }
    data3.push({})

    for (let index = 0; index < data2.length; index++) {
      const element = data2[index];
      const data = element[RecCreateTempName+"_RecruitedCandidate_Main-345bd685-8f77-47c4-ac6c-824a6b3cb791_4"]
      const id = base64_decode(data);
      const userData = JSON.parse(id);
      let AppliedObject = {}
      AppliedObject['SL'] = SlCount++;
      AppliedObject['Status'] = "Recruited"
      AppliedObject['UserName'] = userData["CompanyName"]
      let Time = ConvertUnixTimeToString(element['Date'])
      AppliedObject['Time'] = Time
      data3.push(AppliedObject)
    }

    let FIlename = Forumn['ForumName']+"_Analytics"
    const ws = XLSX.utils.json_to_sheet(data3);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Sheet1");
    XLSX.writeFile(wb, FIlename+'.xlsx');
    console.log(data1 + '-------'+data2)
  };

  useEffect(() => {
    const intervalId = setInterval(() => {
      const CandidateSideChatOpenIs = localStorage.getItem('CandidateSideChatOpenIs');
      const currentForumId = localStorage.getItem('CandidateSideCurrentForum');
      if(CandidateSideChatOpenIs === currentForumId+"_Chat" && isRecuiter === 'false'){
        // const storedData = localStorage.getItem(currentForumId+"MessagesList")
        // if(storedData !== null){
        //   if(storedData.length > SelectedCandidateMessages.length)
        //   setSelectedCandidateMessages(JSON.parse(storedData))
        // }
        const storedData = localStorage.getItem(currentForumId + "MessagesList");
        if (storedData !== null) {
            const parsedData = JSON.parse(storedData);
            let Count = localStorage.getItem(currentForumId+"MessagesListLength")
            if (parsedData.length > Count) {
                localStorage.setItem(currentForumId+"MessagesListLength",JSON.stringify(parsedData.length))
                setSelectedCandidateMessages(parsedData);
            }
        }
      }
    }, 10000); // 10000 milliseconds = 10 seconds

    // Clean up the interval when the component unmounts
    return () => {
      clearInterval(intervalId);
    };
  }, []);

  function epoch(date) {
    return Date.parse(date) / 1000;
  }

  const [showButton, setShowButton] = useState(false);

  useEffect(() => {
    function handleScroll() {
      if (window.pageYOffset === 0) {
        setShowButton(true);
      } else {
        setShowButton(false);
      }
    }

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  function scrollToTop() {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }

  const messagesRef = useRef();

  useEffect(() => {
    // Scroll to the last message when the component mounts or when messages change
    scrollToLastMessage();
  }, [SelectedCandidateMessages]);

  const scrollToLastMessage = () => {
    // Ensure messagesRef is not null before accessing its properties
    if (messagesRef.current) {
      // Set scrollTop to the height of the scrollHeight, ensuring the last message is visible
      messagesRef.current.scrollTop = messagesRef.current.scrollHeight;
    }
  };

  const popupRef = useRef(null);

  useEffect(() => {
    function handleClickOutside(event) {
      if (popupRef.current && !popupRef.current.contains(event.target)) {
        // Clicked outside the popupChat, call your method here
        yourMethod();
      }
    }

    // Add event listener when the component mounts
    document.addEventListener('click', handleClickOutside);

    // Cleanup the event listener when the component unmounts
    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, []);

  function yourMethod() {
    // Your method implementation here
    console.log('Clicked outside popupChat');
    localStorage.setItem('RecruiterSideChatOpenIs','')
    localStorage.setItem('RecruiterSideForumOpenIs','')
    setPopupVisible2(false);

  }
  const searchRecruitedCandidates = () =>{
    setisSearchOpen(true)
    let searchData = searchRef.current.value;
    if(searchData === ""){
      setisSearchOpen(false)
      return
    }
    setRecruitedcandidateLists(prevState => {
      // Create a Set to keep track of unique BridgeIDs
      const uniqueBridgeIDs = new Set();
    
      // Filter out duplicates based on BridgeID
      let filteredState = prevState.filter(item => {
        let fullName =  item.FullName ?? "";
        if(item.CompanyName == searchData || fullName.toLowerCase().includes(searchData.toLowerCase())){
          return true;
        } // Exclude duplicates
        return false;
      });
      if(filteredState.length < 1){
        let noUserDisplayData = {}
        noUserDisplayData['CompanyName'] = "User Not Found."
        noUserDisplayData['mobileNumber'] = "User Not Found."
        noUserDisplayData['ForumID'] = "0"
        filteredState.push(noUserDisplayData)
        setSelectedCandidate(noUserDisplayData)
        setSelectedCandidateMessages([])
      }
      // else if(filteredState.length == 1){ //uncomment to display searched user message.
      //   setSelectedCandidate(filteredState[0])
      //   DisplayMessages(filteredState[0]['ForumID'],filteredState[0])
      // }
      // setSelectedCandidateMessages([])
      return filteredState;
    });
    searchRef.current.value = searchData;
    localStorage.setItem('SearchedData',searchData)
  }

  const FetchAllCandidatesList = () =>{
    setisSearchOpen(false)
    let forumnId = SelectedForumName;
    FetchAllRecruitedCandidateListAndFirstCandidateText(forumnId['ForumID'])
  }
  


  const handleSendClick = () => {
    const inputValue = inputRef.current.value;
    if(inputValue === '' || inputValue === null || inputValue.trim() === ''){
      toast.error((objectNullandUndefined('276')));
      inputRef.current.value = '';
    }else{
      sendReplyToRecruiter(inputValue)
      inputRef.current.value = '';
    }
  };

  const handleSendClickRec = () => {
    const inputValue = inputRefrec.current.value;
    if(inputValue == '' || inputValue == null || inputValue.trim() === ''){
      toast.error((objectNullandUndefined('276')));
      inputRefrec.current.value = '';
    }else{
      sendReplyToCandidate(inputValue)
      inputRefrec.current.value = '';
    }
  };

  const sendReplyToRecruiter = async(message) =>{
    try {

      let encodedRecMessage;
      let encodedMobileNumber
      let uuid = myUuid;     
      const data = localStorage.getItem("userProfile");
      let decoded = base64_decode(data);
      const userDetails = JSON.parse(decoded);
      console.log(SelectedForumName)
      let singleMessage = {}
      singleMessage['BridgeID'] = uuid
      singleMessage['MobileNumber'] = userDetails['MobileNumber']
      let textMessage = message;
      // try{
      //   textMessage = base64_decode(element[ChatFlowID][RecCreateTempName+'_Chat_Main-ff165321-0b90-4d07-917c-da5d002f11ba_2'])
      // }catch(err){
      //   textMessage = utf8.decode(element[RecCreateTempName+'_Chat_Main-ff165321-0b90-4d07-917c-da5d002f11ba_2'])
      // }
      singleMessage['textMessage'] = textMessage
      const date = new Date('6516516156161' * 1000);
      const options = {
        timeZone: 'Asia/Kolkata',
        hour: '2-digit',
        minute: '2-digit',
        year: 'numeric',
        month: 'short',
        day: 'numeric',
      };
      let Time = ""
      singleMessage['Time'] = objectNullandUndefined("174")
      singleMessage['UserName'] = userDetails['UserName']
      setSelectedCandidateMessages(prevState => [...prevState, singleMessage]);
      let sessionID = userDetails.SessionID;
      let macAddress =userDetails.MACAddress;
      let MobileNumber = userDetails.MobileNumber;
      let flowName = ChatFlowID;
      let fID = ChatFID;
      let tempJson = {
        ForumID: SelectedForumName['ForumID'],
        SessionID: sessionID,
        MACAddress: macAddress,
        Time: '6516516156161',
        ScheduledDateTime: 'now',
        ScheduledBoolean: 0,
        FlowID: flowName,
        EnableChat: 0,
        FlowType: 'Custom',
        BridgeForward: 0,
        TemplateID: RecruiterForumTemp,
        TextCount: 6,
        ImageCount: 0,
        InvoiceID: '',
        DocumentCount: 0,
        User: true,
        VideoCount: 0,
        ServerID: FSServerID,
        ReplyBridgeID: '',
        HiddenFlow: false,
        BilledForum: '',
        TempBridgeId: uuid,
        FID: fID,
        SentTo: '0',
      };
      try {
        encodedMobileNumber = base64_encode(MobileNumber)
        encodedRecMessage = base64_encode(message)
      } catch (error) {
        let utfMessage = utf8.encode(message)
        encodedRecMessage = base64_encode(utfMessage)
      }
      let jsonData = {
        [RecCreateTempName+"_Chat_Main-ff165321-0b90-4d07-917c-da5d002f11ba_2"]: encodedRecMessage,
        [RecCreateTempName+"_Chat_Main-ff165321-0b90-4d07-917c-da5d002f11ba_4"]: encodedMobileNumber,
      };
      tempJson[flowName] = jsonData;
      const dataForum = new FormData();
      dataForum.append('Data', JSON.stringify(tempJson));
      let res = await axios({
        method: "post",
        url: forumServerIP + 'saveForumBridges2',
        data: dataForum,
        headers: { "Content-Type": "multipart/form-data" },
      })
        .then(function (response) {
          if (response['data']["ErrorCode"] === 1074) {
            toast.warning(objectNullandUndefined("56"))
            setTimeout(() => {
              localStorage.clear();
              sessionStorage.clear();
              history.push('/');
              window.location.reload(false);
            }, 3000)
            setLoading(false)
          }else if(response['data']["ErrorCode"] === 1130){
            let storedMessageString = localStorage.getItem(SelectedForumName['ForumID']+"MessagesList")
            if(storedMessageString !== null && storedMessageString !== undefined){
              let storedMessagelist = JSON.parse(storedMessageString)
              if(storedMessagelist.length > 0){
                setSelectedCandidateMessages(prevState =>{
                  console.log(prevState)
                  const epochTime = Date.now(); // Get current epoch time in milliseconds
                  const date = new Date(epochTime); // Convert epoch time to Date object
                  const options = {
                      timeZone: 'Asia/Kolkata',
                      hour: '2-digit',
                      minute: '2-digit',
                      year: 'numeric',
                      month: 'short',
                      day: 'numeric',
                  };
                  let Time = date.toLocaleString('en-IN', options);

                  const newList = prevState
                    .filter((item, index, self) => {
                      // Keep only the first occurrence of each BridgeID
                      return index === self.findIndex(t => t.BridgeID === item.BridgeID);
                    })
                    .map(item => {
                      if (item.BridgeID === uuid) {
                        singleMessage = item;
                        singleMessage['BridgeID'] = response['data']["ErrorMessage"]
                        singleMessage['Time'] = objectNullandUndefined("271")
                        return singleMessage;
                      } else {
                        // Keep the other items unchanged
                        return item;
                      }
                    });
                    localStorage.setItem(SelectedForumName['ForumID']+"MessagesList", JSON.stringify(newList))
                  return newList;
                })
              }
            }
          }else {
            let storedMessageString = localStorage.getItem(SelectedForumName['ForumID']+"MessagesList")
            if(storedMessageString !== null && storedMessageString !== undefined){
              let storedMessagelist = JSON.parse(storedMessageString)
              if(storedMessagelist.length > 0){
                setSelectedCandidateMessages(prevState =>{
                  console.log(prevState)
                  const epochTime = Date.now(); // Get current epoch time in milliseconds
                  const date = new Date(epochTime); // Convert epoch time to Date object
                  const options = {
                      timeZone: 'Asia/Kolkata',
                      hour: '2-digit',
                      minute: '2-digit',
                      year: 'numeric',
                      month: 'short',
                      day: 'numeric',
                  };
                  let Time = date.toLocaleString('en-IN', options);

                  const newList = prevState
                    .filter((item, index, self) => {
                      // Keep only the first occurrence of each BridgeID
                      return index === self.findIndex(t => t.BridgeID === item.BridgeID);
                    })
                    .map(item => {
                      if (item.BridgeID === uuid) {
                        singleMessage = item;
                        singleMessage['BridgeID'] = response['data']["ErrorMessage"]
                        singleMessage['Time'] = Time
                        return singleMessage;
                      } else {
                        // Keep the other items unchanged
                        return item;
                      }
                    });
                    localStorage.setItem(SelectedForumName['ForumID']+"MessagesList", JSON.stringify(newList))
                  return newList;
                })
              }
            }




            // FetchChatMessage(SelectedForumName);
          }
        })
        return res;
    } catch (err) {
      return;
    }
  }

  const sendReplyToCandidate = async(message) =>{
    try {
      let encodedRecMessage;
      let encodedMobileNumber
      let uuid = myUuid;     
      const data = localStorage.getItem("userProfile");
      let decoded = base64_decode(data);
      const userDetails = JSON.parse(decoded);
      let sessionID = userDetails.SessionID;
      let macAddress =userDetails.MACAddress;
      let flowName = ChatFlowID;
      let fID = ChatFID;
      let singleMessage = {}
      singleMessage['BridgeID'] = uuid
      singleMessage['MobileNumber'] = userDetails['MobileNumber']
      singleMessage['textMessage'] = message
      singleMessage['Time'] = objectNullandUndefined("174")
      singleMessage['UserName'] = userDetails['UserName']
      setSelectedCandidateMessages(prevState => [...prevState, singleMessage]);
      let tempJson = {
        ForumID: SelectedForumName['ForumID'],
        SessionID: sessionID,
        MACAddress: macAddress,
        Time: '6516516156161',
        ScheduledDateTime: 'now',
        ScheduledBoolean: 0,
        FlowID: flowName,
        EnableChat: 0,
        FlowType: 'Custom',
        BridgeForward: 0,
        TemplateID: RecruiterForumTemp,
        TextCount: 1,
        ImageCount: 0,
        InvoiceID: '',
        DocumentCount: 0,
        User: true,
        VideoCount: 0,
        ReplyBridgeID: '',
        ServerID: CAServerID,
        HiddenFlow: false,
        BilledForum: '',
        TempBridgeId: uuid,
        FID: fID,
        "WhisperToUser": true,
        "WhisperReceiver": MobileNumber,
        SentTo: '3',
      };
      try {
        encodedMobileNumber = base64_encode(MobileNumber)
        encodedRecMessage = base64_encode(message)
      } catch (error) {
        let utfMessage = utf8.encode(message)
        encodedRecMessage = base64_encode(utfMessage)
      }
      let jsonData = {
        [RecCreateTempName+"_Chat_Main-ff165321-0b90-4d07-917c-da5d002f11ba_2"]: encodedRecMessage,
        [RecCreateTempName+"_Chat_Main-ff165321-0b90-4d07-917c-da5d002f11ba_4"]: encodedMobileNumber,
      };
      tempJson[flowName] = jsonData;
      const dataForum = new FormData();
      dataForum.append('Data', JSON.stringify(tempJson));
      let res = await axios({
        method: "post",
        url: caServerIP + "bridgeWhisperSending",
        data: dataForum,
        headers: { "Content-Type": "multipart/form-data" },
      })
        .then(function (response) {
          // console.log(response);
          if (response['data']["ErrorCode"] === 1074) {
            toast.warning(objectNullandUndefined("56"))
            setTimeout(() => {
              localStorage.clear();
              sessionStorage.clear();
              history.push('/');
              window.location.reload(false);
            }, 3000)
            setLoading(false)
          }else if(response['data']['ErrorCode'] === 1031){
            setSelectedCandidateMessages(prevState => {
              const newList = prevState
              .map(item => {
                  // Check if the BridgeID matches the condition
                  if (item.BridgeID === uuid) {
                      let data = item;
                      data['Time'] = objectNullandUndefined(219)
                      return data;
                  } else {
                  // Keep the other items unchanged
                  return item;
                  }
              });
              return newList;
          });
          } 
          // else {
          //   let data = {}
          //   data['mobileNumber'] = MobileNumber
          //   DisplayMessages(SelectedForumName['ForumID'],data)
          //   return response.data;
          // }
        })
        return res;
    } catch (err) {
      return;
    }
  }

  const RecruitedcandidateList = async(forumID)=>{
    let userDetails = localStorage.getItem('userProfile');
    userDetails = base64_decode(userDetails);
    userDetails = JSON.parse(userDetails);
    const inputData = {};
    const outputDat = {};
    const temFIJson = {};
    const tempList = [];
    const oFlowNameWithID = RecruitedCandidateForumReport;
    tempList.push(oFlowNameWithID);
    temFIJson[forumID] = tempList;
    const oTempInFoCo = { isDeleted: { $ne: true } };
    const tempFloIn = {};
    tempFloIn[oFlowNameWithID] = oTempInFoCo;
    inputData[forumID] = tempFloIn;
    const oTempOFoCo = { _id: 0 };
    const tempFliOt = {};
    tempFliOt[oFlowNameWithID] = oTempOFoCo;
    outputDat[forumID] = tempFliOt;
    let reportJson = {
      MACAddress: userDetails.MACAddress,
      SessionID: userDetails.SessionID,
      FlowNameIDList: temFIJson,
      ForumIDList: [forumID],
      InputData: inputData,
      OutputData: outputDat,
      ServerID: FSServerID,
    };
    const dataForum = new FormData();
    dataForum.append("Data", JSON.stringify(reportJson));
    let response = await generateReport(dataForum);
    if (response["ErrorCode"] === 1074) {
      toast.warning(objectNullandUndefined("56"))
      setTimeout(() => {
        localStorage.clear();
        sessionStorage.clear();
        history.push('/');
        window.location.reload(false);
      }, 3000)
      setLoading(false)
    } else {
      let ErrorMessageList = response["ErrorMessage"]
      let recruitedCandidateData = []
      let rListCount = JSON.parse(localStorage.getItem(forumID+"RecruitedCandidateListNotificationCount"))
      for (let index = 0; index < ErrorMessageList.length; index++) {
        const element = ErrorMessageList[index];
        let list = element[forumID][RecruitedCandidateForumReport]
        for (let innerIndex = 0; innerIndex < list.length; innerIndex++) {
          let data = list[innerIndex][RecCreateTempName+'_RecruitedCandidate_Main-345bd685-8f77-47c4-ac6c-824a6b3cb791_4']
          let decoded = base64_decode(data)
          let recruitedUserData = JSON.parse(decoded);
          if(rListCount !== null){
            recruitedUserData['NotificationCount'] = rListCount[forumID+"_"+recruitedUserData['mobileNumber']+"Count"]
          }
          recruitedCandidateData.push(recruitedUserData)
        }
      }
      return(recruitedCandidateData)
    }
  }


  const SelectedCandidateMessageReport = async(forumId,mobileNumber)=>{
    let forumID = forumId;
    if(Array.isArray(forumID)){
      forumID = forumID[0];
    }

    let encodedMobileNumber = base64_encode(mobileNumber)
    let userDetails = localStorage.getItem('userProfile');
    userDetails = base64_decode(userDetails);
    userDetails = JSON.parse(userDetails);
    const inputData = {};
    const outputDat = {};
    const temFIJson = {};
    const tempList = [];
    const oFlowNameWithID = chatReportid;
    tempList.push(oFlowNameWithID);
    temFIJson[forumID] = tempList;
    const oTempInFoCo = { isDeleted: { $ne: true } };
    oTempInFoCo[RecCreateTempName+"_Chat_Main-ff165321-0b90-4d07-917c-da5d002f11ba_4"] = encodedMobileNumber
    const tempFloIn = {};
    tempFloIn[oFlowNameWithID] = oTempInFoCo;
    inputData[forumID] = tempFloIn;
    const oTempOFoCo = { _id: 0 };
    const tempFliOt = {};
    tempFliOt[oFlowNameWithID] = oTempOFoCo;
    outputDat[forumID] = tempFliOt;
    let reportJson = {
      MACAddress: userDetails.MACAddress,
      SessionID: userDetails.SessionID,
      FlowNameIDList: temFIJson,
      ForumIDList: [forumID],
      InputData: inputData,
      OutputData: outputDat,
      ServerID: FSServerID,
    };
    const dataForum = new FormData();
    dataForum.append("Data", JSON.stringify(reportJson));
    let response = await generateReport(dataForum);
    if (response["ErrorCode"] === 1074) {
      toast.warning(objectNullandUndefined("56"))
      setTimeout(() => {
        localStorage.clear();
        sessionStorage.clear();
        history.push('/');
        window.location.reload(false);
      }, 3000)
      setLoading(false)
    } else {
      let dataList = response["ErrorMessage"][0][forumID][chatReportid]
      let messageDisplayList = []
      for (let index = 0; index < dataList.length; index++) {
        let singleMessage = {}
        const element = dataList[index];
        singleMessage['BridgeID'] = element['BridgeID']
        singleMessage['MobileNumber'] = element['MobileNumber']
        let textMessage = '';
        if(element.hasOwnProperty(RecCreateTempName + '_Chat_Main-ff165321-0b90-4d07-917c-da5d002f11ba_2')){
          try{
            textMessage = decodeURIComponent(escape(base64_decode(element[RecCreateTempName+'_Chat_Main-ff165321-0b90-4d07-917c-da5d002f11ba_2'])))
          }catch(err){
            textMessage = utf8.decode(element[RecCreateTempName+'_Chat_Main-ff165321-0b90-4d07-917c-da5d002f11ba_2'])
          }
        }else{
          continue
        }
        singleMessage['textMessage'] = textMessage
        const date = new Date(element['Date'] * 1000);
        const options = {
          timeZone: 'Asia/Kolkata',
          hour: '2-digit',
          minute: '2-digit',
          year: 'numeric',
          month: 'short',
          day: 'numeric',
        };
        let Time = date.toLocaleString('en-IN', options)
        singleMessage['Time'] = Time
        singleMessage['UserName'] = element['UserName']
        messageDisplayList.push(singleMessage)
      }
      // for (let index = 0; index < 20; index++) {
      //   messageDisplayList.push(messageDisplayList[0])
      // }
      console.log(messageDisplayList);
      localStorage.setItem(forumID+"_"+mobileNumber,JSON.stringify(messageDisplayList))
      return messageDisplayList
      }
      // return(recruitedCandidateData)
  }

  const DisplayMessages = async(forumID,userData) =>{
    let userDetails = localStorage.getItem('userProfile');
    userDetails = base64_decode(userDetails);
    userDetails = JSON.parse(userDetails);
    let mobileNumber = userData['mobileNumber']
    if(userDetails['MobileNumber'] === mobileNumber){
      alert(objectNullandUndefined('284'));
      setSelectedCandidateMessages([])
      return;
    }else{
      let rListCount = JSON.parse(localStorage.getItem(forumID+"RecruitedCandidateListNotificationCount"))
      if(rListCount === null){
        rListCount = {}
      }
      rListCount[forumID+"_"+mobileNumber+"Count"] = 0
      localStorage.setItem(forumID+"RecruitedCandidateListNotificationCount",JSON.stringify(rListCount))
      setRecruitedcandidateLists(prevState =>{
        const newList = prevState
                        .filter((item, index, self) => {
                          // Keep only the first occurrence of each BridgeID
                          return index === self.findIndex(t => t.mobileNumber === item.mobileNumber);
                        })
                        .map(item => {
                          // Check if the BridgeID matches the condition
                          if (item.mobileNumber === mobileNumber) {
                            // Replace the matching item with the new singleMessage
                            let singleMessage = item
                            singleMessage['NotificationCount'] = 0
                            return singleMessage;
                          } else {
                            return item;
                          }
                        });
                    
                      return newList;
      })
      setMobileNumber(userData['mobileNumber'])
      let messagesList = [];
      localStorage.setItem('RecruiterSideChatOpenIs',forumID+"_"+mobileNumber);
      localStorage.setItem('RecruiterSideForumOpenIs',forumID);
      let StoredMessageString = localStorage.getItem(forumID+"_"+mobileNumber)
      if(StoredMessageString !== null && StoredMessageString !== undefined){
        let StoredMessageList = JSON.parse(StoredMessageString)
        if(StoredMessageList.length > 0){
            setSelectedCandidateMessages(prevState => [...StoredMessageList])
        }else{
          messagesList = await SelectedCandidateMessageReport(forumID,mobileNumber)
          setMobileNumber(userData['mobileNumber'])
          if(messagesList.length > 0){
            setSelectedCandidateMessages(prevState => [...messagesList])
          }
        }
      }else{
        messagesList = await SelectedCandidateMessageReport(forumID,mobileNumber)
        setMobileNumber(userData['mobileNumber'])
        if(messagesList.length > 0){
          setSelectedCandidateMessages(prevState => [...messagesList])
        }
      }
    }
  }
  
  const FetchAllRecruitedCandidateListAndFirstCandidateText = async(forumID) =>{
    let list = JSON.parse(localStorage.getItem("userData"))
    let newList = list.map(item =>{
      if(item['ForumID'] === forumID){
        item['NotificationCount'] = 0;
        return item;
      }else{
        return item;
      }
    })
    localStorage.setItem("userData",JSON.stringify(newList))
    let List = await RecruitedcandidateList(forumID);
    // for (let index = 0; index < 20; index++) {
    //   List.push(List[0])
    // }
    if(List.length > 0){
      setPopupVisible2(true)
      setRecruitedcandidateLists(List)
      setSelectedCandidate(List[0])
      DisplayMessages(forumID,List[0])
    }else{
      toast.error((objectNullandUndefined('231')), {
        autoClose: 3000,
      });
      console.log("No candidate has been recruited yet.")
    }
    
  }

  useEffect(() => {
    const convertEpochToDateTime = (epoch) => {
      const date = new Date(epoch * 1000);
      const options = {
        timeZone: 'Asia/Kolkata',
        hour: '2-digit',
        minute: '2-digit',
        year: 'numeric',
        month: 'short',
        day: 'numeric',
      };
      console.log(date.toLocaleString('en-IN', options))
    };
    convertEpochToDateTime(1697522287)
  }, []);
  useEffect(() => {
    const fetchData = async () => {
      let data = await fetchForumInfo();
      console.log(data);
      const newData = localStorage.getItem('userData');
      const newUserData = JSON.parse(newData);
    };
    fetchData();

    const handleServiceWorkerMessage = (event) => {
      if (event.data.type === 'navigate') {
        history.push('/Notification');
      }
    };

    navigator.serviceWorker.addEventListener('message', handleServiceWorkerMessage);

    return () => {
      navigator.serviceWorker.removeEventListener('message', handleServiceWorkerMessage);
    };
  }, []);


  useEffect(() => {
    if (localStorage.getItem('userProfile') === null) {
      history.push('/Login');
    }
    fetchForum();
    const interval = setInterval(() => {
      let data = localStorage.getItem("recruitValue");
      if (localStorage.getItem("recruitValue") === "true") {
        clientSyncAdmin();
        // console.log("call")
      }
    }, 10000);
    // ReactGA.pageview(window.location.pathname)
  }, [])
  const NavigateToCreateRec = async(item) => {
    deleteNotification(item);
    history.push('/Recruiter');
  }
  const deleteNotification = async(item) => {
    let userData = JSON.parse(localStorage.getItem('userData'));
      for (let i = 0; i < userData.length; i++) {
        if( item.BridgeID == userData[i]['BridgeID']){
          userData.splice(i,1);
        }
      }
      localStorage.setItem('userData', JSON.stringify(userData))
      setForumDetailsArr(userData)
  }

  const getCount = async (processedBridge, bridgeID) => {
    return processedBridge.filter(item => item === bridgeID).length;
  };

  const clientSyncAdmin = async () => {
    let userDetails = localStorage.getItem('userProfile');
    let decoded = base64_decode(userDetails)
    userDetails = JSON.parse(decoded);
    let clientSyncJson = {
      SessionID: userDetails.SessionID,
      MACAddress: userDetails.MACAddress,
      ServerID: FSServerID,
    };
    let data = await clientSync(clientSyncJson);
    if (data["ErrorCode"] === 1074) {
      toast.error((objectNullandUndefined('221')));
      setTimeout(() => {
        localStorage.clear();
        sessionStorage.clear();
        history.push('/');
        window.location.reload(false);
      }, 3000)
    }
    let processedIDs = [];
    let dataArr = data["ErrorMessage"]['User']
    let dataAdminArr = data["ErrorMessage"]['Admin']
    console.log(dataArr.length,"--",dataAdminArr.length)
    if (dataArr.length > 0) {
      for (let i = 0; i < dataArr.length; i++) {
        let dataJson = data["ErrorMessage"]['User'][i]
        let keys = Object.keys(dataJson)
        if (keys[0] === 'Bridge') {
          const bridgeData = dataJson['Bridge'];
          let bridgeID = bridgeData['BridgeID']
          if (!processedIDs.includes(bridgeID)) {
            processedIDs.push(bridgeID);
            let flowID = bridgeData['FlowID']
            if (flowID === CommonForumFlow + "_Data2") {
              let data = bridgeData[CommonForumFlow + "_Data2"]
              let jobSeeker = base64_decode(data[CommonForumFlow + '_Data2_Main-19ae7edb-d2ea-4a00-865a-711709c736b8_2'])
              if (jobSeeker === "Recruiter Pay Status") {
                let jobSeekerPayment = base64_decode(data[CommonForumFlow + "_Data2_Main-19ae7edb-d2ea-4a00-865a-711709c736b8_4"])
                localStorage.setItem("AdminPayment", jobSeekerPayment)
              }
            }
            else if(flowID === CommonForumFlow + "_NotifyUser"){
              let forumID = bridgeData["ForumID"]
              let bridgeID = bridgeData["BridgeID"]
              let data = bridgeData[flowID]
              let status = base64_decode(data[CommonForumFlow + '_NotifyUser_Main-3696a421-97d2-407d-add3-15a07edcfb10_2'])
              let RequestDatas = base64_decode(data[CommonForumFlow + '_NotifyUser_Main-3696a421-97d2-407d-add3-15a07edcfb10_4'])
              let type = base64_decode(data[CommonForumFlow + '_NotifyUser_Main-3696a421-97d2-407d-add3-15a07edcfb10_6'])
              let getUserItem = localStorage.getItem('userData')
              let userData = JSON.parse(getUserItem)
              let RequestData = RequestDatas
              data = RequestData
              let isCandidate = ""
              if(status === "Under Manual Review" || status === "Profinity Passed" || status === "Profinity Failed"){
                RequestData = JSON.parse(RequestDatas)
                data = RequestData['CompanyName']['CompanyName']
                isCandidate = RequestData['isCandidate']
              } 
              let notify = {}
              notify['status'] = status
              notify['RequestData'] = data
              notify['type'] = type
              notify['BridgeID'] = bridgeID
              notify['isCandidate'] = isCandidate
              let notifyExists = userData.some(item => item.BridgeID === notify.BridgeID);
              let newList = []
              if (!notifyExists) {
                  newList = [notify, ...userData];
                  localStorage.setItem('userData', JSON.stringify(newList))
                  setForumDetailsArr(newList)
              }
            }
            else if(flowID === ChatFlowID){
              let element = bridgeData
              let singleMessage = {}
              singleMessage['BridgeID'] = element['BridgeID']
              singleMessage['MobileNumber'] = element['MobileNumber']
              let textMessage = '';
              try{
                textMessage = base64_decode(element[ChatFlowID][RecCreateTempName+'_Chat_Main-ff165321-0b90-4d07-917c-da5d002f11ba_2'])
              }catch(err){
                textMessage = utf8.decode(element[ChatFlowID][RecCreateTempName+'_Chat_Main-ff165321-0b90-4d07-917c-da5d002f11ba_2'])
              }
              singleMessage['textMessage'] = textMessage
              const date = new Date(element['Time'] * 1000);
              const options = {
                timeZone: 'Asia/Kolkata',
                hour: '2-digit',
                minute: '2-digit',
                year: 'numeric',
                month: 'short',
                day: 'numeric',
              };
              let Time = date.toLocaleString('en-IN', options)
              singleMessage['Time'] = Time
              singleMessage['UserName'] = element['UserName']
              console.log(singleMessage)
              let candidatesideOpenChat = localStorage.getItem('CandidateSideChatOpenIs')
              if(candidatesideOpenChat === element['ForumID']+"_Chat"){
                if(element['MobileNumber'] == userDetails['MobileNumber']){
                  setSelectedCandidateMessages(prevState => {
                    // Create a new array by mapping over the previous state
                    const newList = prevState
                      .filter((item, index, self) => {
                        // Keep only the first occurrence of each BridgeID
                        return index === self.findIndex(t => t.BridgeID === item.BridgeID);
                      })
                      .map(item => {
                        // Check if the BridgeID matches the condition
                        if (item.BridgeID === element['TempBridgeId']) {
                          // Replace the matching item with the new singleMessage
                          return singleMessage;
                        } else {
                          // Keep the other items unchanged
                          return item;
                        }
                      });
                  
                    return newList;
                  });
                }else{
                  setSelectedCandidateMessages(prevState => {
                    // Create a Set to keep track of unique BridgeIDs
                    const uniqueBridgeIDs = new Set();
                  
                    // Filter out duplicates based on BridgeID
                    const filteredState = prevState.filter(item => {
                      if (!uniqueBridgeIDs.has(item.BridgeID)) {
                        uniqueBridgeIDs.add(item.BridgeID);
                        return true; // Include the first occurrence
                      }
                      return false; // Exclude duplicates
                    });
                  
                    if (!uniqueBridgeIDs.has(singleMessage.BridgeID)) {
                      return [...filteredState, singleMessage];
                    }
                  
                    return filteredState;
                  });
                }
              }
            }
          }
        }
        else if (keys[0] === 'UserProfileUpdated') {
          let userDetails1 = localStorage.getItem('userProfile');
          let decoded = base64_decode(userDetails1)
          let userDetails2 = JSON.parse(decoded);
          let profilepicture = dataJson['UserProfileUpdated']['UserProfilePicture']
          userDetails2['UserProfilePicture'] = profilepicture;
          localStorage.removeItem('userProfile')
          localStorage.setItem('userProfile', base64_encode(JSON.stringify(userDetails2)));
        }
      }
    }
    else if (dataAdminArr.length > 0) {
      for (let i = 0; i < dataAdminArr.length; i++) {
        let adminJSON = dataAdminArr[i]
        let keys = Object.keys(adminJSON)
        if (keys[0] === 'Bridge') {
          const bridgeData = adminJSON['Bridge'];
          let bridgeID = bridgeData['BridgeID']
          if (!processedIDs.includes(bridgeID)) {
            processedIDs.push(bridgeID);
            let flowID = bridgeData['FlowID']
            if (flowID === RecUserFlow) {
              let forumID = bridgeData["ForumID"]
              let bridgeID = bridgeData["BridgeID"]
              let userData = JSON.parse(localStorage.getItem('userData'))
              for (let i = 0; i < userData.length; i++) {
                const countData = userData[i];
                let countForumID = countData["ForumID"]
                let countBridgeArr = countData["BridgeIDs"]
                if (forumID === countForumID) {
                  let count =  countData['Counts'] ?? 0
                  if (!countBridgeArr.includes(bridgeID)) {
                    countBridgeArr.push(bridgeID)
                    countData['Counts'] = count + 1
                    userData[i] = countData
                  }
                }
              }
              setForumDetailsArr(userData)
              localStorage.setItem('userData', JSON.stringify(userData))

            }
            else if(flowID === ChatFlowID){
              let element = bridgeData
              let singleMessage = {}
              singleMessage['BridgeID'] = element['BridgeID']
              singleMessage['MobileNumber'] = element['MobileNumber']
              let textMessage = '';
              let userMobileNumber = '';
              try{
                userMobileNumber = base64_decode(element[ChatFlowID][RecCreateTempName+'_Chat_Main-ff165321-0b90-4d07-917c-da5d002f11ba_4'])
                textMessage =  decodeURIComponent(escape(base64_decode(utf8.decode(element[ChatFlowID][RecCreateTempName+'_Chat_Main-ff165321-0b90-4d07-917c-da5d002f11ba_2']))))
              }catch(err){
                userMobileNumber = utf8.decode(element[ChatFlowID][RecCreateTempName+'_Chat_Main-ff165321-0b90-4d07-917c-da5d002f11ba_4'])
                textMessage = base64_decode(element[ChatFlowID][RecCreateTempName+'_Chat_Main-ff165321-0b90-4d07-917c-da5d002f11ba_2'])
              }
              singleMessage['textMessage'] = textMessage
              const date = new Date(element['Time'] * 1000);
              const options = {
                timeZone: 'Asia/Kolkata',
                hour: '2-digit',
                minute: '2-digit',
                year: 'numeric',
                month: 'short',
                day: 'numeric',
              };
              let Time = date.toLocaleString('en-IN', options)
              singleMessage['Time'] = Time
              singleMessage['UserName'] = element['UserName']
              console.log(singleMessage)

              let OldMessage = localStorage.getItem(bridgeData['ForumID']+"_"+userMobileNumber)
              if(OldMessage !== null && OldMessage !== undefined){
                let OldMessageList = JSON.parse(OldMessage);
                const newList = [...OldMessageList]
                if (!OldMessageList.some(message => message.BridgeID === singleMessage.BridgeID)) {
                  newList.push(singleMessage);
                }
                localStorage.setItem(bridgeData['ForumID']+"_"+userMobileNumber,JSON.stringify(newList));
                let RecSideopenChat = localStorage.getItem('RecruiterSideChatOpenIs');
                let data = {};
                data['CompanyName'] = element['UserName'];
                data['ForumID']= bridgeData['ForumID']
                data['MediaID']= ""
                data['mobileNumber'] = userMobileNumber
                if(RecSideopenChat == bridgeData['ForumID']+"_"+userMobileNumber){
                  DisplayMessages(bridgeData['ForumID'],data)
                  // if(element['MobileNumber'] == userDetails['MobileNumber']){
                  //   setSelectedCandidateMessages(prevState => {
                  //     // Create a new array by mapping over the previous state
                  //     const newList = prevState
                  //       .filter((item, index, self) => {
                  //         // Keep only the first occurrence of each BridgeID
                  //         return index === self.findIndex(t => t.BridgeID === item.BridgeID);
                  //       })
                  //       .map(item => {
                  //         // Check if the BridgeID matches the condition
                  //         if (item.BridgeID === element['TempBridgeId']) {
                  //           // Replace the matching item with the new singleMessage
                  //           return singleMessage;
                  //         } else {
                  //           // Keep the other items unchanged
                  //           return item;
                  //         }
                  //       });
                    
                  //     return newList;
                  //   });
                  // }else{
                  //   setSelectedCandidateMessages(prevState => {
                  //     // Create a Set to keep track of unique BridgeIDs
                  //     const uniqueBridgeIDs = new Set();
                    
                  //     // Filter out duplicates based on BridgeID
                  //     const filteredState = prevState.filter(item => {
                  //       if (!uniqueBridgeIDs.has(item.BridgeID)) {
                  //         uniqueBridgeIDs.add(item.BridgeID);
                  //         return true; // Include the first occurrence
                  //       }
                  //       return false; // Exclude duplicates
                  //     });
                    
                  //     if (!uniqueBridgeIDs.has(singleMessage.BridgeID)) {
                  //       return [...filteredState, singleMessage];
                  //     }
                    
                  //     return filteredState;
                  //   });
                  // }
                }
                else{
                  let list = JSON.parse(localStorage.getItem(bridgeData['ForumID']+"RecruitedCandidateListNotificationCount"))
                  if(list === null){
                    let List = {}
                    List[bridgeData['ForumID']+"_"+userMobileNumber+"Count"] = 1;
                    localStorage.setItem(bridgeData['ForumID']+"RecruitedCandidateListNotificationCount",JSON.stringify(List))
                  }else{
                    list[bridgeData['ForumID']+"_"+userMobileNumber+"Count"] = 1;
                    localStorage.setItem(bridgeData['ForumID']+"RecruitedCandidateListNotificationCount",JSON.stringify(list))
                  }
                  let RecruiterSideForumOpenIs = localStorage.getItem('RecruiterSideForumOpenIs');
                  if(RecruiterSideForumOpenIs === bridgeData['ForumID']){
                    let RecruitedCandidateList = await RecruitedcandidateList(bridgeData['ForumID']);
                    setRecruitedcandidateLists(RecruitedCandidateList)
                  }else{
                    let list = JSON.parse(localStorage.getItem("userData"))
                    let newList = list.map(item =>{
                      if(item['ForumID'] === bridgeData['ForumID']){
                        item['NotificationCount'] = 1;
                        return item;
                      }else{
                        return item;
                      }
                    })
                    localStorage.setItem("userData",JSON.stringify(newList))
                  }
                }
                //else increase count notification tab and usertab;
                // else{
                //   let List = []
                //   let RecruitedCandidateList = await AsyncStorage.getItem(bridgeData['ForumID']+'RecruitedCandidateList')
                //   if(RecruitedCandidateList !== null && RecruitedCandidateList !== undefined){
                //       List = JSON.parse(RecruitedCandidateList)
                //       console.log(List)
                //       let newRecruitedCandidateList = List
                //                     .map(item => {
                //                       if(item['CompanyName'] == bridgeData['UserName']){
                //                         let count = item['NotificationCount'] + 1;
                //                         item['NotificationCount'] = count
                //                         return item;
                //                       }else{
                //                         return item;
                //                       }
                //                     }
                //                   )
                //       await AsyncStorage.setItem(bridgeData['ForumID']+'RecruitedCandidateList',JSON.stringify(newRecruitedCandidateList))
                //   }
                //   let getUserItem = await AsyncStorage.getItem('userData')
                //   let userData = JSON.parse(getUserItem)
                //   console.log(userData)
                //   let newuserData = userData
                //                     .map(item => {
                //                       if(item['ForumID'] == bridgeData['ForumID']){
                //                         let count = 0
                //                         count = item['NotificationCount'] + 1;
                //                         if(isNaN(count)){
                //                           count = 1;
                //                         }
                //                         item['NotificationCount'] = count
                //                         return item;
                //                       }else{
                //                         return item;
                //                       }
                //                     }
                //                   )
                //     await AsyncStorage.setItem('userData',JSON.stringify(newuserData))
                //     let countNotification = {}
                //     if(newuserData !==null && newuserData.length > 0){
                //       for (let index = 0; index < newuserData.length; index++) {
                //         const element = newuserData[index];
                //         countNotification[element['ForumID']] = element['NotificationCount'];
                //       }
                //       await AsyncStorage.setItem('countNotification',JSON.stringify(countNotification));
                //     }
                //     setForumDetailsArr(newuserData)
                // }

              }else{
                //else increase count notification tab and usertab; 
              }            
            }
          }
        }
      }
    }
  }
  const myUuid = uuid();
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  // useEffect(() => {
  //   // fetchForumInfo()
  // }, []);

  async function ConsolidatedReportGeneration(forumID, type) {
    if(type !== "delete"){
      setLoadingItemId(forumID);
      setisSearchLoading(true);
    }
    let userDetails = localStorage.getItem('userProfile');
    userDetails = base64_decode(userDetails);
    userDetails = JSON.parse(userDetails);
    const inputData = {};
    const outputDat = {};
    const temFIJson = {};
    const tempList = [];
    const oFlowNameWithID = RecruitFlowReport;
    tempList.push(oFlowNameWithID);
    temFIJson[forumID] = tempList;
    const oTempInFoCo = { isDeleted: { $ne: true } };
    const tempFloIn = {};
    tempFloIn[oFlowNameWithID] = oTempInFoCo;
    inputData[forumID] = tempFloIn;
    const oTempOFoCo = { _id: 0 };
    const tempFliOt = {};
    tempFliOt[oFlowNameWithID] = oTempOFoCo;
    outputDat[forumID] = tempFliOt;
    let reportJson = {
      MACAddress: userDetails.MACAddress,
      SessionID: userDetails.SessionID,
      FlowNameIDList: temFIJson,
      ForumIDList: [forumID],
      InputData: inputData,
      OutputData: outputDat,
      ServerID: FSServerID,
    };
    const dataForum = new FormData();
    dataForum.append("Data", JSON.stringify(reportJson));
    let response = await generateReport(dataForum);

    if (response["ErrorCode"] === 1074) {
      toast.warning(objectNullandUndefined("56"))
      setTimeout(() => {
        localStorage.clear();
        sessionStorage.clear();
        history.push('/');
        window.location.reload(false);
      }, 3000)
      setLoading(false)
    } else {
      let response1 = response["ErrorMessage"][0]
      let ForumID = Object.keys(response1)
      let fidKey = response1[ForumID]
      let FID = Object.keys(fidKey)
      let userDatas = fidKey[FID]
      if (userDatas.length !== 0) {
        for (let i = 0; i < userDatas.length; i++) {
          const eachData = userDatas[i]
          if (type == "delete") {
            const jobType = base64_decode(eachData[RecCreateTempName+"_Recruiter_Main-5fbc611c-ba6c-49d8-a3b1-a36a1c807fbb_6"])
            deleteBridge(jobType, forumID)
          } else {
            let MediaIDs = eachData['MediaID'][0]
            let mediaId = MediaIDs[RecCreateTempName+'_Recruiter_Main-5fbc611c-ba6c-49d8-a3b1-a36a1c807fbb_10']
            let companyName = utf8.decode(base64_decode(eachData[RecCreateTempName+"_Recruiter_Main-5fbc611c-ba6c-49d8-a3b1-a36a1c807fbb_2"]))
            let data = JSON.parse(companyName);
            let companyName1 = data.CompanyName;
            let CompanyName = encodeURIComponent(companyName1);
            let descriptio = utf8.decode(base64_decode(eachData[RecCreateTempName+"_Recruiter_Main-5fbc611c-ba6c-49d8-a3b1-a36a1c807fbb_4"]))
            let description = encodeURIComponent(descriptio);
            let city = data.City;
            generatLink(forumID, mediaId, CompanyName, city,description)
            setLoading(false)
          }
        }
      } else {
        if (type == "delete") {
          deleteForumCall(forumID)
          setLoading(false)
          toast.success(objectNullandUndefined('61'))
        } else {
          alert("Unable to share, Recruitment expired")
          setisSearchLoading(false);
        }
      }
    }
  };
  async function generateReportForsharesataus(forumID) {
    let userDetails = localStorage.getItem('userProfile');
    userDetails = base64_decode(userDetails);
    userDetails = JSON.parse(userDetails);
    const inputData = {};
    const outputDat = {};
    const temFIJson = {};
    const tempList = [];
    const oFlowNameWithID = ShareLinkReportFlow;
    tempList.push(oFlowNameWithID);
    temFIJson[forumID] = tempList;
    const oTempInFoCo = { isDeleted: { $ne: true } };
    let shared = base64_encode(forumID+"_Share")
    oTempInFoCo[RecMessageFlow+"_Main-6b141153-d01e-4749-9ddb-5542358d0ba4_2"] = shared
    const tempFloIn = {};
    tempFloIn[oFlowNameWithID] = oTempInFoCo;
    inputData[forumID] = tempFloIn;
    const oTempOFoCo = { _id: 0 };
    const tempFliOt = {};
    tempFliOt[oFlowNameWithID] = oTempOFoCo;
    outputDat[forumID] = tempFliOt;
    let reportJson = {
      MACAddress: userDetails.MACAddress,
      SessionID: userDetails.SessionID,
      FlowNameIDList: temFIJson,
      ForumIDList: [forumID],
      InputData: inputData,
      OutputData: outputDat,
      ServerID: FSServerID,
    };
    const dataForum = new FormData();
    dataForum.append("Data", JSON.stringify(reportJson));
    let response = await generateReport(dataForum);

    if (response["ErrorCode"] === 1074) {
      toast.warning(objectNullandUndefined("56"))
      setTimeout(() => {
        localStorage.clear();
        sessionStorage.clear();
        history.push('/');
        window.location.reload(false);
      }, 3000)
      setLoading(false)
    } else {
      let response1 = response["ErrorMessage"][0][forumID][ShareLinkReportFlow]
      if(response1.length >= 1){
        localStorage.setItem(forumID+"IsSharable","true");
        return true;
      }else{
        return false;
      }
    }
  }

  async function checkStatus(forumID) {
    let IsSharable = localStorage.getItem(forumID+"IsSharable");
    if(IsSharable !== null){
      if(IsSharable === "true"){
        setisSearchLoading(false);
        return true;
      }else{
        setisSearchLoading(false);
        return await generateReportForsharesataus(forumID)
      }
    }else{
      setisSearchLoading(false);
      return await generateReportForsharesataus(forumID)
    }
  };


  const generatLink = async (ForumIDs, mediaIDs, companyData,City, descriptio) => {
    setLoading(true)
    let CheckforProfinityStatus = await checkStatus(ForumIDs)
    if (CheckforProfinityStatus === true){
      let ForumID = ForumIDs
      let companyName = companyData
      let mediaID = mediaIDs
      let description = descriptio
      let city = City
      let baseUrl = window.location.origin
      let fallback_link = "https://web.prescreener.in/"
      // let fallback_link = "http://localhost:3000/"
      const shareLink = `${fallback_link}?FiD=${ForumID}&cN=${companyName}&dT=${description}&cY=${city}&ViD=${mediaID}&/ShortContainer`;
      let firebaseLink = await GenerateApi(shareLink)
      let fireBaseLink = firebaseLink.shortLink
      await navigator.clipboard.writeText(fireBaseLink)
        .then(() => {
          // console.log('Link copied to clipboard:', shareLink);
          alert("Link copied to clipBoard")
          setIsOpen(true);
          setQrcode(fireBaseLink)
          QRCodePopup()
        })
        .catch(error => {
          console.error('Error copying to clipboard:', error);
          // Handle clipboard copy error here
        });
    }else{
      // toast.error((objectNullandUndefined('272')));
      alert(objectNullandUndefined('272'))
      // return;
    }
    setLoading(false)
  };
  async function deleteBridge(jobType, forumID) {
    let data = await ArchiveConsolidatedReportGeneration(forumID);
    let CommonForumTemp = localStorage.getItem("commonUserFlows")
    let parsedFlow = JSON.parse(CommonForumTemp)
    let parsedCommonFlow = parsedFlow["UserFlow"]
    let commonTempName = parsedFlow["Template"]
    let deleteFlowID = parsedCommonFlow[commonTempName + '_DeleteRecruitment']
    try {
      let uuid = myUuid

      let userDetails = localStorage.getItem('userProfile');
      userDetails = base64_decode(userDetails);
      userDetails = JSON.parse(userDetails);
      let sessionID = userDetails.SessionID;
      let macAddress = userDetails.MACAddress;
      let flowName = commonTempName + '_DeleteRecruitment';;
      let fID = deleteFlowID["FID"]
      let tempJson = {
        ForumID: CommonForumID,
        SessionID: sessionID,
        MACAddress: macAddress,
        Time: '6516516156161',
        ScheduledDateTime: 'now',
        ScheduledBoolean: 0,
        FlowID: flowName,
        EnableChat: 0,
        FlowType: 'Custom',
        BridgeForward: 0,
        TemplateID: parsedFlow["TemplateID"],
        TextCount: 6,
        ImageCount: 0,
        InvoiceID: '',
        DocumentCount: 0,
        User: true,
        VideoCount: 0,
        ServerID: FSServerID,
        ReplyBridgeID: '',
        HiddenFlow: false,
        BilledForum: '',
        TempBridgeId: uuid,
        FID: fID,
        SentTo: '0',
      };
      let deleteJson = {
        'FilterType': (jobType),
        'DeleteForumId': (forumID)

      }
      let jsonData = {}
      let deleteTemp = CommonForumFlow + '_DeleteRecruitment_Main-57a419e2-c004-4434-82a7-311cfd7283ca_2'
      jsonData[deleteTemp] = base64_encode(JSON.stringify(deleteJson))
      tempJson[flowName] = jsonData;
      const data = new FormData();
      data.append('Data', JSON.stringify(tempJson));
      const URL = forumServerIP + 'saveForumBridges2'
      const xhr = new XMLHttpRequest();
      xhr.open('POST', URL);
      xhr.send(data);
      xhr.onreadystatechange = async e => {
        if (xhr.readyState !== 4) {
          return;
        }
        if (xhr.status === 200) {
          // console.log('success', xhr.responseText);
          let errorCode = JSON.parse(xhr.responseText)
          if (errorCode['ErrorCode'] === 1060) {
            deleteForumCall(forumID)
            toast.success(objectNullandUndefined('61'))
          }
        } else {
          console.log('error', xhr.responseText);
        }
      };
    } catch (err) {
      return;
    }
  }
  async function deleteForumCall(forumID) {
  let deleteResponse = await DeleteForum(forumID)
  if (deleteResponse["ErrorCode"] === 1074) {
    toast.error((objectNullandUndefined('221')));
    setTimeout(() => {
      localStorage.clear();
      sessionStorage.clear();
      history.push('/');
      window.location.reload(false);
    }, 3000)
  }
  if (deleteResponse['ErrorCode'] == 1130 || deleteResponse['ErrorCode'] == 1032) {
    let deletedForum = deleteResponse["ErrorMessage"]["ForumDeleted"]["ForumID"]
    let item1 = JSON.parse(localStorage.getItem('userData'));
    for (let i = 0; i < item1.length; i++) {
      let deleteForum = item1[i]["ForumID"]
      if (deletedForum === deleteForum) {
        item1.splice(i, 1);
        break;
      }
    }
    setForumDetailsArr(item1)
    setTimeout(() => {
      setLoading(false);
    }, 2000)
    setLoading(false);
  } else {
    toast.warning(objectNullandUndefined("56"))
    setLoading(false)
  }
  }
  // function AlertDialog() {
  //   return (
  //     <div>
  //       <Dialog
  //         open={open}
  //         onClose={handleClose}
  //         aria-labelledby="alert-dialog-title"
  //         aria-describedby="alert-dialog-description"
  //       >
  //         <DialogTitle id="alert-dialog-title">
  //           {"Restart or Continue"}
  //         </DialogTitle>
  //         <DialogContent>
  //           <DialogContentText id="alert-dialog-description">
  //           {objectNullandUndefined('129')}
  //           </DialogContentText>
  //         </DialogContent>
  //         <DialogActions>
  //           <Button onClick={async () => {
  //             // handleClose()
  //             Details()
  //           }}>{objectNullandUndefined('64')}</Button>
  //           <Button onClick={async () => {
  //             // handleClose()
  //             Details()
  //           }} >
  //           {objectNullandUndefined('65')}
  //           </Button>
  //         </DialogActions>
  //       </Dialog>
  //     </div>
  //   );
  // }
  async function fetchForum() {
    const isRecuiter = localStorage.getItem('recruitValue');
    if (isRecuiter === 'true') {
      let item = localStorage.getItem('userData');
      if (item != null && item.length > 0) {
        const userData = JSON.parse(item);
        setForumDetailsArr(userData);
        setLoading(false);
      } else {
        fetchForumInfo().then(() => {
          const newData = localStorage.getItem('userData');
          const newUserData = JSON.parse(newData);
          setForumDetailsArr(newUserData);
          setLoading(false);
        });
      }
    } else {
      let item = localStorage.getItem('userData');
      // console.log('this is user notification', item);
      if (item != null) {
        const userData = JSON.parse(item);
        // console.log('this is new form data', userData);
        setForumDetailsArr(userData);
        setLoading(false);
      } else {
        fetchForumInfo().then(() => {
          const newData = localStorage.getItem('userData');
          const newUserData = JSON.parse(newData);
          // console.log('this is new form data', newUserData);
          setForumDetailsArr(newUserData);
          setLoading(false);
        });
      }
    }
  }

  const CloseMessage = (forumID) => {
    setPopupVisible(false);
    try {
      let userData = JSON.parse(localStorage.getItem('userData'))
      for (let i = 0; i < userData.length; i++) {
        const countData = userData[i];
        let countForumID = countData["ForumID"]
        if (countForumID === forumID) {
          countData["Counts"] = 0;
          countData["BridgeIDs"] = [];
          userData[i] = countData
        }
      }
      setForumDetailsArr(userData)
      localStorage.setItem('userData', JSON.stringify(userData))
    } catch (err) {

    }
  }

  const Details = (forumId) => {
    localStorage.removeItem('ShowRecruitedCandidates');
    handleClose()
    const isRecuiter = localStorage.getItem('recruitValue');
    let data = forumId.ForumName;
    localStorage.setItem('ForumnameforArchive',data)
    if (isRecuiter === 'true') {
      localStorage.setItem('UserVideoData', JSON.stringify(forumId.ForumID));
      history.push('/video');
      Video();
      Hook();
    }
    // } else {
    //   window.alert(forumId.ForumName, forumId.ForumDescription, [
    //     { text: 'OK', onPress: () => setPopupVisible(false) }
    //   ], { cancelable: false });
    // }
  };

  const ShowRecruitedCandidates = (forumId,count) => {
    if(count > 0){
      handleClose()
      const isRecuiter = localStorage.getItem('recruitValue');
      localStorage.setItem('ShowRecruitedCandidatesvalue', "true")
      localStorage.setItem('ShowRecruitedCandidates',JSON.stringify(true));
      let data = forumId.ForumName;
      localStorage.setItem('ForumnameforArchive',data)
      if (isRecuiter === 'true') {
        localStorage.setItem('UserVideoData', JSON.stringify(forumId.ForumID));
        history.push('/video');
        Video();
        Hook();
      }
    }else{
      handleClose()
      toast.error((objectNullandUndefined('231')));
    }
    // } else {
    //   window.alert(forumId.ForumName, forumId.ForumDescription, [
    //     { text: 'OK', onPress: () => setPopupVisible(false) }
    //   ], { cancelable: false });
    // }
  };

  const handleVideoButtonPress = async (id) => {
    let mediaId = localStorage.getItem(id.ForumID+"myvideoMediaID")
    if(mediaId === null){
      let data = await getLasTfiftydata2(id.ForumID)
      if (data["ErrorCode"] === 1074) {
        toast.error((objectNullandUndefined('221')));
        setTimeout(() => {
          localStorage.clear();
          sessionStorage.clear();
          history.push('/');
          window.location.reload(false);
        }, 3000)
      }
      if (data['ErrorCode'] === 1053) {
        let dataArr = data["ErrorMessage"]['User']
        if (dataArr.length > 0) {
          for (let i = 0; i < dataArr.length; i++) {
            let bridgeInfo = dataArr[i]["Bridge"]
            mediaId = bridgeInfo['MediaID']
            if (mediaId.length > 0) {
              mediaId = bridgeInfo['MediaID'][0][RecCreateTempName+'_User_Main-d219b924-c3cd-437c-99b7-32c61d46c263_6']
              localStorage.setItem(bridgeInfo['ForumID']+"myvideoMediaID",mediaId)
              break;
            }
          }
        }
      }
    }
    // console.log('this is 50', data);
    let forumname = id.ForumName;
    let forumid = id.ForumID;

    let data1 = {
      mediaID: mediaId,
      Forumname: forumname,
      Forumid: forumid
    }
    localStorage.setItem('mediaID', JSON.stringify(data1));
    history.push('/Candidatevideo')
    Candidatevideo();
  };

  const recruiterViewMyVideo = async (id) =>{
    let ForumnIDForDetailsFetch = id
    let ForumDetails = {}
    let ForumnIDDetails = localStorage.getItem(ForumnIDForDetailsFetch+"RecruitedForumDetails");
    if(ForumnIDDetails == null || ForumnIDDetails == undefined){
      ForumDetails = await FetchForumDetailsUsingBridgeSentByRecruiter(ForumnIDForDetailsFetch)
    }else{
      ForumDetails = JSON.parse(ForumnIDDetails)
    }
    let data1 = {
      mediaID: ForumDetails['MediaID'],
      Forumname: ForumDetails['CompanyName'],
      Forumid: id
    }
    console.log(ForumDetails)
    localStorage.setItem('mediaID', JSON.stringify(data1));
    history.push('/Candidatevideo')
    Candidatevideo();
  }

  async function FetchForumDetailsUsingBridgeSentByRecruiter(forumID) {
    const userProfile = localStorage.getItem("userProfile")
    let userDetails = JSON.parse(base64_decode(userProfile))
    const inputData = {};
    const outputDat = {};
    const temFIJson = {};
    const tempList = [];
    const oFlowNameWithID = RecruitFlowReport;
    tempList.push(oFlowNameWithID);
    temFIJson[forumID] = tempList;
    const oTempInFoCo = { isDeleted: { $ne: true } };
    const tempFloIn = {};
    tempFloIn[oFlowNameWithID] = oTempInFoCo;
    inputData[forumID] = tempFloIn;
    const oTempOFoCo = { _id: 0 };
    const tempFliOt = {};
    tempFliOt[oFlowNameWithID] = oTempOFoCo;
    outputDat[forumID] = tempFliOt;
    let reportJson = {
      MACAddress: userDetails["MACAddress"],
      SessionID: userDetails["SessionID"],
      FlowNameIDList: temFIJson,
      ForumIDList: [forumID],
      InputData: inputData,
      OutputData: outputDat,
      ServerID: FSServerID,
    };
    const dataForum = new FormData();
    dataForum.append("Data", JSON.stringify(reportJson));
    let response = await generateReport(dataForum);
    if (response["ErrorCode"] === 1074) {
      toast.error((objectNullandUndefined('221')));
      setTimeout(() => {
        localStorage.clear();
        sessionStorage.clear();
        history.push('/');
        window.location.reload(false);
      }, 3000)
    }else{
      let ResponseData = response['ErrorMessage'][0][forumID][RecruitFlowReport][0];
      let RecruiterForumDescriptionData = {}
      RecruiterForumDescriptionData['MediaID'] = ResponseData['MediaID'][0][RecCreateTempName+'_Recruiter_Main-5fbc611c-ba6c-49d8-a3b1-a36a1c807fbb_10']
      let secondFlowData = JSON.parse(base64_decode(ResponseData[RecCreateTempName+'_Recruiter_Main-5fbc611c-ba6c-49d8-a3b1-a36a1c807fbb_2']))
      RecruiterForumDescriptionData['CompanyName'] = secondFlowData['CompanyName']
      RecruiterForumDescriptionData['Location'] = secondFlowData['City']
      RecruiterForumDescriptionData['JobDescription'] = base64_decode(ResponseData[RecCreateTempName+'_Recruiter_Main-5fbc611c-ba6c-49d8-a3b1-a36a1c807fbb_4'])
      RecruiterForumDescriptionData['JobType'] = base64_decode(ResponseData[RecCreateTempName+'_Recruiter_Main-5fbc611c-ba6c-49d8-a3b1-a36a1c807fbb_6'])
      localStorage.setItem(forumID+"RecruitedForumDetails",JSON.stringify(RecruiterForumDescriptionData))
      return RecruiterForumDescriptionData;
    }
  };

  const FetchChatMessage = async (forum) => {
    localStorage.setItem('CandidateSideCurrentForum',forum.ForumID)
    const userProfile = localStorage.getItem("userProfile")
    let userDetails = JSON.parse(base64_decode(userProfile))
    setMobileNumber(userDetails['MobileNumber'])
    let Forum = {
      'Name' :forum.ForumName,
      // 'Discription':forum.ForumDescription,
      'ForumID':forum.ForumID,
      'ForumName':forum.ForumName,
    }
    setSelectedForumName(Forum)
    setSelectedRecruterForumNameByCandidate(forum)
    localStorage.setItem('CandidateSideChatOpenIs',forum.ForumID+"_Chat")
    let storedMessageString = localStorage.getItem(forum.ForumID+"MessagesList")
    if(storedMessageString !== null && storedMessageString !== undefined){
      let storedMessagelist = JSON.parse(storedMessageString)
      if(storedMessagelist.length > 0){
        setSelectedCandidateMessages(storedMessagelist)
      }else{
        let data = await getLasTfiftydata1(forum.ForumID)
        if (data["ErrorCode"] === 1074) {
          toast.error((objectNullandUndefined('221')));
          setTimeout(() => {
            localStorage.clear();
            sessionStorage.clear();
            history.push('/');
            window.location.reload(false);
          }, 3000)
        }else{
          let dataList = data["ErrorMessage"]['User']
          let messageDisplayList = []
          for (let index = 0; index < dataList.length; index++) {
            let singleMessage = {}
            const element = dataList[index]['Bridge'];
            if(element['FlowID'] === ChatFlowID){
              singleMessage['BridgeID'] = element['BridgeID']
              singleMessage['MobileNumber'] = element['MobileNumber']
              let textMessage = '';
              try{
                textMessage =  decodeURIComponent(escape(base64_decode(utf8.decode(element[ChatFlowID][RecCreateTempName+'_Chat_Main-ff165321-0b90-4d07-917c-da5d002f11ba_2']))))
              }catch(err){
                textMessage = utf8.decode(element[RecCreateTempName+'_Chat_Main-ff165321-0b90-4d07-917c-da5d002f11ba_2'])
              }
              singleMessage['textMessage'] = textMessage
              const date = new Date(element['Time'] * 1000);
              const options = {
                timeZone: 'Asia/Kolkata',
                hour: '2-digit',
                minute: '2-digit',
                year: 'numeric',
                month: 'short',
                day: 'numeric',
              };
              let Time = date.toLocaleString('en-IN', options)
              singleMessage['Time'] = Time
              singleMessage['UserName'] = element['UserName']
              messageDisplayList.push(singleMessage)
            }
          }
          // for (let index = 0; index < 20; index++) {
          //   messageDisplayList.push(messageDisplayList[0])
          // }
          console.log(messageDisplayList);
          let List = messageDisplayList.reverse()
          setSelectedCandidateMessages(List)
          localStorage.setItem(forum.ForumID+"MessagesList",JSON.stringify(List))
        }
      }
    }else{
      let data = await getLasTfiftydata1(forum.ForumID)
      if (data["ErrorCode"] === 1074) {
        toast.error((objectNullandUndefined('221')));
        setTimeout(() => {
          localStorage.clear();
          sessionStorage.clear();
          history.push('/');
          window.location.reload(false);
        }, 3000)
      }else{
        let dataList = data["ErrorMessage"]['User']
        let messageDisplayList = []
        for (let index = 0; index < dataList.length; index++) {
          let singleMessage = {}
          const element = dataList[index]['Bridge'];
          if(element['FlowID'] === ChatFlowID){
            singleMessage['BridgeID'] = element['BridgeID']
            singleMessage['MobileNumber'] = element['MobileNumber']
            let textMessage = '';
            try{
              textMessage = decodeURIComponent(escape(base64_decode(element[ChatFlowID][RecCreateTempName+'_Chat_Main-ff165321-0b90-4d07-917c-da5d002f11ba_2'])))
            }catch(err){
              textMessage = utf8.decode(element[RecCreateTempName+'_Chat_Main-ff165321-0b90-4d07-917c-da5d002f11ba_2'])
            }
            singleMessage['textMessage'] = textMessage
            const date = new Date(element['Time'] * 1000);
            const options = {
              timeZone: 'Asia/Kolkata',
              hour: '2-digit',
              minute: '2-digit',
              year: 'numeric',
              month: 'short',
              day: 'numeric',
            };
            let Time = date.toLocaleString('en-IN', options)
            singleMessage['Time'] = Time
            singleMessage['UserName'] = element['UserName']
            messageDisplayList.push(singleMessage)
          }
        }
        // for (let index = 0; index < 20; index++) {
        //   messageDisplayList.push(messageDisplayList[0])
        // }
        console.log(messageDisplayList);
        let List = messageDisplayList.reverse()
        setSelectedCandidateMessages(List)
        localStorage.setItem(forum.ForumID+"MessagesList",JSON.stringify(List))
      }
    }
    let userData = JSON.parse(localStorage.getItem('userData'))
    for (let index = 0; index < userData.length; index++) {
      const element = userData[index];
      if(element['ForumID'] === forum.ForumID){
        userData[index]['Counts'] = 0;
      }
    }
    localStorage.setItem('userData',JSON.stringify(userData))
  };

  const FetchAllChatMessage = async () => {
    let forum = SelectedForumName
    const userProfile = localStorage.getItem("userProfile")
    let userDetails = JSON.parse(base64_decode(userProfile))
    setMobileNumber(userDetails['MobileNumber'])
    let Forum = {
      'Name' :forum.ForumName,
      // 'Discription':forum.ForumDescription,
      'ForumID':forum.ForumID,
      'ForumName':forum.ForumName,
    }
    setSelectedForumName(Forum)
    setSelectedRecruterForumNameByCandidate(forum)
    localStorage.setItem('CandidateSideChatOpenIs',forum.ForumID+"_Chat")
    let length = localStorage.getItem(forum.ForumID+"MessagesListLength")
    if(SelectedCandidateMessages.length == length){
      return;
    }
    let data = await getLasTfiftydata2(forum.ForumID)
    if (data["ErrorCode"] === 1074) {
      toast.error((objectNullandUndefined('221')));
      setTimeout(() => {
        localStorage.clear();
        sessionStorage.clear();
        history.push('/');
        window.location.reload(false);
      }, 3000)
    }else{
      let dataList = data["ErrorMessage"]['User']
      let messageDisplayList = []
      for (let index = 0; index < dataList.length; index++) {
        let singleMessage = {}
        const element = dataList[index]['Bridge'];
        if(element['FlowID'] === ChatFlowID){
          singleMessage['BridgeID'] = element['BridgeID']
          singleMessage['MobileNumber'] = element['MobileNumber']
          let textMessage = '';
          try{
            textMessage = decodeURIComponent(escape(base64_decode(element[ChatFlowID][RecCreateTempName+'_Chat_Main-ff165321-0b90-4d07-917c-da5d002f11ba_2'])))
          }catch(err){
            textMessage = utf8.decode(element[RecCreateTempName+'_Chat_Main-ff165321-0b90-4d07-917c-da5d002f11ba_2'])
          }
          singleMessage['textMessage'] = textMessage
          const date = new Date(element['Time'] * 1000);
          const options = {
            timeZone: 'Asia/Kolkata',
            hour: '2-digit',
            minute: '2-digit',
            year: 'numeric',
            month: 'short',
            day: 'numeric',
          };
          let Time = date.toLocaleString('en-IN', options)
          singleMessage['Time'] = Time
          singleMessage['UserName'] = element['UserName']
          messageDisplayList.push(singleMessage)
        }
      }
      // for (let index = 0; index < 20; index++) {
      //   messageDisplayList.push(messageDisplayList[0])
      // }
      console.log(messageDisplayList);
      let List = messageDisplayList.reverse()
      setSelectedCandidateMessages(List)
      localStorage.setItem(forum.ForumID+"MessagesList",JSON.stringify(List))
      localStorage.setItem(forum.ForumID+"MessagesListLength",JSON.stringify(List.length))
    }
  };
  const closeModal = () => {
    setPopupVisible(false);
  };


  if (loading) {
    return (
      <div className="loader">
        <ToastContainer position="top-center" hideProgressBar />
        <div className="loader-container">
          <CircularProgress style={{ color: "white" }} />
        </div>
      </div>
    );
  } else if (forumDetailsArr != null) {
    const isRecruiter = localStorage.getItem('recruitValue');
    return (
      <>
      <ToastContainer position="top-center" hideProgressBar />
        {isRecruiter === "true" && (
          <Hook
            short={'RECRUIT ALERT'}
          />
        )}
        {isRecruiter != "true" && (
          <Hook
            short={'NOTIFICATION'}
          />
        )}

        <div className="recr1">
          <Card style={{ width: '70%', marginLeft: '15%', marginTop: '10px', borderRadius: '10px' }}>
            <div className="hloo">

              <RenderList></RenderList>

            </div>
          </Card>
          <br></br>
          <br></br>
        </div></>
    );
  } else {
    if (localStorage.getItem('recruitValue') === 'true') {
      history.push('/Notification');
      return (
        <div className="recr">
          <p className="text1">
            {objectNullandUndefined('125')}
          </p>
        </div>
      );
    } else if (localStorage.getItem('recruitValue') === 'false') {
      return (
        <div className="recr">
          <p className="text1">
            {objectNullandUndefined('126')}
          </p>
        </div>
      );
    }
  }
 

  function closePopup() {
    setIsOpen(false);
  };

  function RenderList() {
    const handleListItemClick = () => {
      const isRecuiter = localStorage.getItem('recruitValue');
      if (isRecuiter === 'true') {
        localStorage.setItem('Demo', 'true');
        history.push('/video');
        Video();
        Hook();
      }
    };
    const isRecruiter = localStorage.getItem('recruitValue');
    return (

      <>
        {popupVisible2 === true && (
            <div className="transparent-overlay-chat">
              <div className="popupChat">
                <div className="popupChat-Container">
                  <div  className="Lists">
                    <div className="ListsConatiner">
                      <div className="ListTitle">
                        <div className="ListTitle1">
                          <Avatar alt="No Image" style={{
                            backgroundColor: 'black',
                          }}
                            variant="rounded">
                            <Groups3Icon></Groups3Icon>
                          </Avatar> 
                          {/* CHAT */}
                        </div>
                        <div className="ListTitle2">
                          <div>{SelectedForumName.Name}</div>
                          {/* <div>{SelectedForumName.Discription}</div> */}
                        </div>
                      </div>
                      <div className="search">
                          <input className="searchTab1" 
                          type="text"
                          ref={searchRef} 
                          placeholder={isSearchOpen ? localStorage.getItem('SearchedData') : "Search..."}
                          // onChange={searchRecruitedCandidates}
                          style={{width:'100%',height:'100%'}}
                          >
                          </input>
                        <div className="searchButton">
                          { !isSearchOpen ? (
                          <Avatar alt="No Image" style={{
                            // backgroundColor: 'black',
                            borderRadius:' 0 10px 10px 0'
                          }}
                          onClick={searchRecruitedCandidates}
                          >
                            <SearchOutlinedIcon></SearchOutlinedIcon>
                          </Avatar>
                          ):(
                            <Avatar alt="No Image" style={{
                            backgroundColor: 'red',
                            borderRadius:' 0 10px 10px 0'
                          }}
                          onClick={FetchAllCandidatesList}
                            >
                            <CloseOutlinedIcon></CloseOutlinedIcon>
                          </Avatar>)}
                          
                        </div>
                      </div>
                      <div className="ListBody">
                      {RecruitedcandidateLists.length !== 0 ? (
                          RecruitedcandidateLists.map((userdata) => {
                            let title = userdata.CompanyName
                            let title1 = userdata.FullName
                            // let symbol = userdata.CompanyName[-1]
                            let display = userdata.mobileNumber
                            let NotificationCount = 0
                            if(userdata.NotificationCount !== null && userdata.NotificationCount !== undefined){
                              NotificationCount = userdata.NotificationCount
                            }
                              return (
                                <>
                                  <ListItem className="listItemrec" style={{ padding: "0,0,0,0", overflow:'hidden',maxHeight:'100px', }} key={userdata.id} alignItems = "center">
                                    <ListItemAvatar>
                                      {/* <Avatar alt="Remy Sharp" src="/static/images/avatar/1.jpg" /> */}
                                      <Avatar alt="No Image" style={{
                                        backgroundColor: randomColor()
                                      }}>
                                      </Avatar>  
                                    </ListItemAvatar>
                                    <ListItemText
                                      style={{ marginLeft: '0px',overflowWrap:'break-word' }}
                                      primary={title}
                                      secondary={title1}
                                      onClick={() => {
                                        let data = {}
                                        data['CompanyName'] = title
                                        setSelectedCandidate(data)
                                        DisplayMessages(userdata['ForumID'],userdata)
                                        }
                                      }
                                      // secondary={ 
                                      //   <div style={{ width: '93%', wordWrap:'break-word' }}>
                                      //     <h6 style={{ color: 'black' }}>{display}</h6>
                                      //   </div>
                                      // } 
                                      />
                                  { NotificationCount > 0 ? (
                                        <div style={{ display:'flex',flexDirection:'row',alignSelf:'center',justifyContent:'flex-end',marginRight:10,color:'red'}}>
                                            <MessageIcon name="mark-chat-unread" size={30} color="red" /> 
                                        </div>
                                    ) : 
                                    (
                                        <div style={{display:'flex',flexDirection:'row',alignSelf:'center',justifyContent:'flex-end',marginRight:10}}>
                                            <MessageIcon name="chat-bubble" size={30} color="white" /> 
                                        </div>
                                    )} 
                                  </ListItem>
                                  <Divider style={{ borderWidth: '1px', borderColor: 'rgba(0, 0, 0, 5.12)' }} />
                                </>
                              );
                          })
                        ) : (
                          <ListItem alignItems="flex-start" className="listItem" onClick={handleListItemClick}>
                            {isRecruiter === "true" && (
                              <ListItemText primary={objectNullandUndefined('262')} />
                            )}
                            {isRecruiter != "true" && (
                              <ListItemText primary={"No Notification "} />
                            )}
                          </ListItem>
                        )}
                      </div>
                    </div>
                  </div>
                  <div  className="Chats">
                    <div className="close">
                      <CloseIcon onClick={() => {
                            localStorage.setItem('RecruiterSideChatOpenIs','')
                            localStorage.setItem('RecruiterSideForumOpenIs','')
                            setPopupVisible2(false)
                          }
                        }/>
                    </div>
                    <div className="chatTitle">
                      <div className="chatTitle1">
                          <Avatar alt="No Image" style={{
                                backgroundColor: 'black',
                              }}
                              variant="rounded">
                                {
                                  SelectedCandidate.CompanyName[1]
                                }
                          </Avatar>
                      </div>
                      <div className="chatTitle2">
                          {SelectedCandidate.CompanyName}
                      </div>

                    </div>
                    <div className="chatBody" ref={messagesRef}>
                    {SelectedCandidateMessages.length !== 0 ? (
                          SelectedCandidateMessages.map((userMessageData) => {
                            // let username = userMessageData.
                            let textMessage = userMessageData.textMessage
                            const urlRegex = /(https?:\/\/[^\s]+)/;
                            const match = textMessage.match(urlRegex);
                            let textBeforeLink;
                            let textAfterLink;
                            if(match){
                              textBeforeLink = textMessage.substring(0, match.index);
                              textAfterLink = textMessage.substring(match.index + match[0].length);
                            }
                            let symbol = userMessageData.UserName[1]
                            let BridgeId = userMessageData.BridgeID
                            let Time =  userMessageData.Time
                            if(userMessageData.MobileNumber == MobileNumber){
                              return (
                                <>
                                <div class="received-chats" style={{marginBottom: '1%'}}>
                                  <div class="received-chats-img" style={{float: 'left'}}>
                                    <Avatar alt="No Image" style={{backgroundColor: '#2bae66'}}>{symbol}</Avatar>
                                  </div>
                                  <div class="received-msg" style={{marginBottom:'3%'}}>
                                      <div class="received-msg-inbox">
                                      {match ?(<><p>{textBeforeLink}
                                                    <a
                                                      href={match[0]}
                                                      target="_blank"
                                                      rel="noopener noreferrer"
                                                      className="link"
                                                    >
                                                      {match[0]}
                                                    </a>
                                                    {textAfterLink}
                                                    </p>
                                                    </>
                                        ):(<p style={{marginBottom:'2px'}}> {textMessage}</p>)}
                                          <span class="time">{Time}</span>
                                      </div>
                                  </div>
                                </div>
                                </>
                              );
                            }else{
                              return (
                              <>
                                <div class="outgoing-chats">
                                    <div class="outgoing-chats-img" style={{float: 'right',marginLeft:'8px'}}>
                                      <Avatar alt="No Image" style={{backgroundColor: '#ea4336'}}>{symbol}</Avatar>
                                    </div>
                                    <div class="outgoing-msg">
                                        <div class="outgoing-chats-msg">
                                        {match ?(<><p>{textBeforeLink}
                                                    <a
                                                      href={match[0]}
                                                      target="_blank"
                                                      rel="noopener noreferrer"
                                                      className="link"
                                                    >
                                                      {match[0]}
                                                    </a>
                                                    {textAfterLink}
                                                    </p>
                                                    </>
                                        ):(<p style={{marginBottom:'2px'}}> {textMessage}</p>)}
                                            <span class="time">{Time} </span>
                                        </div>
                                    </div>
                                </div>
                              </>
                              );
                            }
                          })
                        ) : (
                          <ListItem alignItems="flex-start" className="listItem">
                            ...
                          </ListItem>
                        )}
                    </div>
                    <div className="chatSend">
                    <div style={{ display: 'flex', alignItems: 'center' ,justifyContent: 'space-evenly',}}>
                        <input
                          type="text"
                          ref={inputRefrec} 
                          placeholder="Type your message..."
                          // onChange={handleInputChange}
                          style={{
                            padding: '10px',
                            border: '1px solid #ccc',
                            borderRadius: '5px',
                            marginRight: '5px',
                            fontSize: '16px',
                            width:'90%',
                          }}
                          autoFocus
                        />
                        <button
                          onClick={handleSendClickRec}
                          style={{
                            padding: '10px',
                            backgroundColor: '#ea4336',
                            color: '#fff',
                            border: 'none',
                            borderRadius: '5px',
                            cursor: 'pointer',
                            fontSize: '16px',
                            width:'10%'
                          }}
                        >
                          &#10148;
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
        )}
        {displayCandidateChatBox === true &&(
                    <>
                      <div className="transparent-overlay-chat">
                        <div className="popupChat">
                            <div  className="CandidateChats">
                              <div className="CandidateChatTop">
                                <div className="Candidateclose">
                                  <CloseIcon onClick={() => {
                                        setdisplayCandidateChatBox(false)
                                        setPopupVisible(false);
                                        localStorage.setItem('CandidateSideChatOpenIs',"")
                                      }
                                    }/>
                                </div>
                                <div className="CandidatechatTitle">
                                  <div style={{padding:'25px'}}>
                                      <Avatar alt="No Image" style={{
                                            backgroundColor: 'black',
                                          }}
                                          variant="rounded">
                                            {/* {
                                              SelectedRecruterForumNameByCandidate.ForumName[1]
                                            } */}
                                      </Avatar>
                                  </div>
                                  <div style={{fontSize: '36px',textAlign: 'center'}}>
                                      {SelectedRecruterForumNameByCandidate.ForumName}
                                  </div>
                                </div>
                              </div>
                              <div className="CandidateChatBottom">
                                <div className="test">
                                <Button
                            variant="contained"
                            onClick={(event) => {
                              FetchAllChatMessage()
                            }}
                            style={{ backgroundColor: '#2BAE66FF', color: 'white', fontSize: '10px' }}
                          >
                            {'Load More'}
                          </Button>
                                </div>
                                <div className="CandidatechatBody" ref={messagesRef}>
                                {SelectedCandidateMessages.length !== 0 ? (
                                    SelectedCandidateMessages.map((userMessageData) => {
                                      // let username = userMessageData.
                                      let textMessage = userMessageData.textMessage
                                      const urlRegex = /(https?:\/\/[^\s]+)/;
                                      const match = textMessage.match(urlRegex);
                                      let textBeforeLink;
                                      let textAfterLink;
                                      if(match){
                                        textBeforeLink = textMessage.substring(0, match.index);
                                        textAfterLink = textMessage.substring(match.index + match[0].length);
                                      }
                                      let symbol = userMessageData.UserName[1]
                                      let BridgeId = userMessageData.BridgeID
                                      let Time =  userMessageData.Time
                                      if(userMessageData.MobileNumber != MobileNumber){
                                        return (
                                          <>
                                          <div class="received-chats" style={{marginBottom: '1%',paddingLeft:'10px'}}>
                                            <div class="received-chats-img" style={{float: 'left'}}>
                                              <Avatar alt="No Image" style={{backgroundColor: '#ea4336'}}>{symbol}</Avatar>
                                            </div>
                                            <div class="received-msg" style={{marginBottom:'3%'}}>
                                                <div class="received-msg-inbox">
                                                  {match ?(<><p>{textBeforeLink}
                                                    <a
                                                      href={match[0]}
                                                      target="_blank"
                                                      rel="noopener noreferrer"
                                                      className="link"
                                                    >
                                                      {match[0]}
                                                    </a>
                                                    {textAfterLink}
                                                    </p>
                                                    </>
                                        ):(<p style={{marginBottom:'2px'}}> {textMessage}</p>)}
                                                  
                                                    <span class="time" style={{color :'white'}}>{Time}</span>
                                                </div>
                                            </div>
                                          </div>
                                          </>
                                        );
                                      }else{
                                        return (
                                        <>
                                          <div class="outgoing-chats">
                                              <div class="outgoing-chats-img" style={{float: 'right',marginLeft:'8px'}}>
                                                <Avatar alt="No Image" style={{backgroundColor: '#2bae66'}}>{symbol}</Avatar>
                                              </div>
                                              <div class="outgoing-msg">
                                                  <div class="outgoing-chats-msg">
                                                  {match ?(<><p>{textBeforeLink}
                                                    <a
                                                      href={match[0]}
                                                      target="_blank"
                                                      rel="noopener noreferrer"
                                                      className="link"
                                                    >
                                                      {match[0]}
                                                    </a>
                                                    {textAfterLink}
                                                    </p>
                                                    </>
                                        ):(<p style={{marginBottom:'2px'}}> {textMessage}</p>)}
                                                      <span class="time" style={{color :'white'}}>{Time} </span>
                                                  </div>
                                              </div>
                                          </div>
                                        </>
                                        );
                                      }
                                    })
                                  ) : (
                                    <ListItem alignItems="flex-start" className="listItem">
                                      ...
                                    </ListItem>
                                  )}
                                </div>
                                <div className="CandidatechatSend">
                                <div style={{ display: 'flex', alignItems: 'center' ,justifyContent: 'space-evenly',width:'100%'}}>
                                    <input
                                      type="text"
                                      ref={inputRef} 
                                      placeholder="Type your message..."
                                      // onChange={handleInputChange}
                                      style={{
                                        padding: '10px',
                                        border: '1px solid #ccc',
                                        borderRadius: '5px',
                                        marginRight: '5px',
                                        fontSize: '16px',
                                        width:'90%',
                                        height:'100%'
                                      }}
                                      autoFocus
                                    />
                                    <button
                                      onClick={handleSendClick}
                                      style={{
                                        backgroundColor: '#2bae66',
                                        color: '#fff',
                                        border: 'none',
                                        borderRadius: '5px',
                                        cursor: 'pointer',
                                        fontSize: '20px',
                                        width:'10%',
                                        alignItems:'center',
                                        height:'100%'
                                      }}
                                    >
                                      &#10148;
                                      {/* Send */}
                                    </button>
                                  </div>
                                </div>
                              </div>
                            </div>
                          
                        </div>
                      </div>
                    </>
                  )}
        {forumDetailsArr.length !== 0 ? (
          forumDetailsArr.map((forum) => {
            let data = []
            let time = [0]
            for (let i = 0; i < popupVisible1.length; i++) {
              let data1 = popupVisible1[i];
              data.push(data1)
            }
            for (let i = 0; i < epochtime.length; i++) {
              const date = new Date(epochtime[i] * 1000);
              const options = {
                timeZone: 'Asia/Kolkata',
                hour: '2-digit',
                minute: '2-digit',
                year: 'numeric',
                month: 'short',
                day: 'numeric',
              };
              let Time = date.toLocaleString('en-IN', options)
              time.push(Time)
            }

            if (isRecruiter === "true") {
              let ForumDescription = ""
              let isLink = ""
              const status = forum.status
              let renderNotification = false
              if(status != null || status != undefined){
                renderNotification = true
                let randomColor = '#008000'
                let title =objectNullandUndefined('246')
                let symbol = '*'
                let display = objectNullandUndefined('248')+" " + forum.RequestData+" " + objectNullandUndefined('251')+" "+ forum.type+" " +objectNullandUndefined('249')
                if(forum.status == "Rejected"){
                  randomColor = '#FF0000'
                  symbol = "!";
                  title = objectNullandUndefined('247')
                  display = objectNullandUndefined('248')+" " + forum.RequestData+" " + objectNullandUndefined('251')+" "+ forum.type +" "+objectNullandUndefined('250')
                }else if(forum.status == "Under Manual Review"){
                  randomColor = '#3176FB'
                  symbol = "@";
                  title = objectNullandUndefined('272')
                  if(forum.isCandidate){
                    display = "Application for Recruitment"+` "` + forum.RequestData+`" ` + "is currently under manual review for profinity."
                  }else{
                    display = "Recruitment"+` "` + forum.RequestData+`" ` + "is currently under manual review for profinity."
                  }
                }else if(forum.status == "Profinity Passed"){
                  randomColor = '#008000'
                  symbol = "#";
                  title = objectNullandUndefined('246')
                  if(forum.isCandidate){
                    display = "Application for Recruitment"+` "` + forum.RequestData+`" ` + " has passed manual review for profinity and will propagated"
                  }else{
                    display = "Recruitment"+` "` + forum.RequestData+`" ` + " has passed manual review for profinity and will propagated"
                  }
                }else if(forum.status == "Profinity Failed"){
                  randomColor = '#FF0000'
                  symbol = "#";
                  title = objectNullandUndefined('247')
                  if(forum.isCandidate){
                    display = "Application for Recruitment"+` "` + forum.RequestData+`" ` + " has failed manual review for profinity and not will reach Recruiter."
                  }else{
                    display = "Recruitment"+` "` + forum.RequestData+`" ` + " has failed manual review for profinity and not will reach candidates."
                  }
                }
                return (
                  <>
                    <ListItem style={{ padding: "0,0,0,0" }} key={forum.id} alignItems="flex-start" className="listItem">
                      <ListItemAvatar>
                        {/* <Avatar alt="Remy Sharp" src="/static/images/avatar/1.jpg" /> */}
                        <Avatar alt="No Image" style={{
                          backgroundColor: randomColor
                        }}>
                          {
                            symbol
                          }
                        </Avatar>  
                      </ListItemAvatar>
                      <ListItemText
                        style={{ marginLeft: '20px' }}
                        primary={title}
                        onClick={() => {
                            if(forum.status == "Approved"){
                              NavigateToCreateRec(forum);
                            }else{
                              deleteNotification(forum)
                            }
                          }
                        }
                        secondary={ 
                          <div style={{ width: '93%', wordWrap: 'break-word' }}>
                            <h6 style={{ color: 'black' }}>{display}</h6>
                          </div>
                        } />
                      {isRecruiter === "true" && (
                        <>
                          <DeleteIcon
                            style={{ marginTop: "2%", marginRight: '3%' }}
                            onClick={async () => {
                              deleteNotification(forum)
                            }}
                          ></DeleteIcon>
                        </>
                      )}
                    </ListItem>
                    <Divider style={{ borderWidth: '1px', borderColor: 'rgba(0, 0, 0, 5.12)' }} />
                  </>
  
                );
              }else{
                ForumDescription = forum.ForumDescription;
                let NotificationCount = 0
                if(forum.NotificationCount !== null && forum.NotificationCount !== undefined){
                  NotificationCount = forum.NotificationCount
                }
                isLink = isUrl(ForumDescription);
                return (

                  <>
                    <ListItem style={{ padding: "0,0,0,0" }} key={forum.id} alignItems="center" className="listItem">
                      <ListItemAvatar>
                        {/* <Avatar alt="Remy Sharp" src="/static/images/avatar/1.jpg" /> */}
                        <Avatar alt="No Image" style={{
                          backgroundColor: randomColor()
                        }}>
                          {
                            forum.ForumName
                              .replace(/\s+/g, ' ')
                              .split(' ')
                              .filter(word => word.trim() !== '')
                              .slice(0, 2)
                              .map(word => word.charAt(0))
                              .join('').toUpperCase()
                          }
                        </Avatar>
                        {forum.Counts > 0 && forum.Counts <= 9 && (
                          <span class="badge badge-light" style={{ borderRadius: '50%', fontSize: "10px", backgroundColor: 'red' }}>{forum.Counts}</span>
                        )}
                        {forum.Counts > 9 && (
                          <span class="badge badge-light" style={{ borderRadius: '50%', fontSize: "10px", backgroundColor: 'red' }}>9+</span>
                        )}
  
                      </ListItemAvatar>
                      <ListItemText
                        style={{ marginLeft: '20px' }}
                        primary={forum.ForumName}
                        onClick={() => {
                          Details(forum);
                          if (isRecruiter === 'true') {
                            handleClickOpen();
                          }
                        }}
                        secondary={isLink ? (
                          <a href={ForumDescription} target="_blank" rel="noopener noreferrer">
                            {ForumDescription}
                          </a>
                        ) : (
                          <div style={{ width: '93%', wordWrap: 'break-word' }}>
                            <h6 style={{ color: 'black' }}>{ForumDescription}</h6>
                          </div>
  
                        )} />
                      {isRecruiter === "true" && (
                        <>
                          <QRCodePopup></QRCodePopup>
                          { NotificationCount > 0 ? (
                                        <MessageIcon
                                        onClick={async () => {
                                          let Forum = {
                                            'Name' :forum.ForumName,
                                            'Discription':forum.ForumDescription,
                                            'ForumID':forum.ForumID
                                          }
                                          setSelectedForumName(Forum)
                                          await FetchAllRecruitedCandidateListAndFirstCandidateText(forum.ForumID)
                                        }}
                                        style={{
                                          marginRight: '2%',
                                          marginTop: "2%",
                                          color:'red'
                                        }}>
                                      </MessageIcon>
                                    ) : 
                                    (
                                      <MessageIcon
                                      onClick={async () => {
                                        let Forum = {
                                          'Name' :forum.ForumName,
                                          'Discription':forum.ForumDescription,
                                          'ForumID':forum.ForumID
                                        }
                                        setSelectedForumName(Forum)
                                        await FetchAllRecruitedCandidateListAndFirstCandidateText(forum.ForumID)
                                      }}
                                      style={{
                                        marginRight: '2%',
                                        marginTop: "2%",
                                      }}>
                                    </MessageIcon>
                                    )} 

                          {isSearchLoading &&loadingItemId === forum.ForumID ? (
                            <CircularProgress size={23} style={{ color: "black",marginRight: '2%',
                            marginTop: "2%",}} />
                          ) : (
                          <ShareIcon
                            onClick={async () => {
                              await ConsolidatedReportGeneration(forum.ForumID, "share");
                            }}
                            style={{
                              marginRight: '2%',
                              marginTop: "2%",
                            }}>
                          </ShareIcon>
                          )}
                          <DeleteIcon
                            style={{ marginTop: "2%", marginRight: '3%' }}
                            onClick={async () => {
                              if (window.confirm(objectNullandUndefined('157'))) {
                                await ConsolidatedReportGeneration(forum.ForumID, "delete");
                                setLoading(true);
                              }
                            }}
                          ></DeleteIcon>
                          {/* <Button
                            variant="contained"
                            onClick={(event) => {
                              event.stopPropagation()
                              console.log("clicked")
                              recruiterViewMyVideo(forum.ForumID)
                              // handleVideoButtonPress(forum)
                            }}
                            style={{ backgroundColor: 'rgb(234,67,53)', color: 'white', fontSize: '10px',marginTop: "2%"}}
                          >
                            {objectNullandUndefined('155')}
                          </Button> */}
                          {isMenuLoading &&loadingItemId2 === forum.ForumID ? (
                            <CircularProgress size={23} style={{ color: "black",marginRight: '2%',
                            marginTop: "2%",}} />
                          ) : (
                          <MenuIcon
                            style={{ marginTop: "2%", marginRight: '0%',fontSize:30}}
                            onClick={async () => {
                              setLoadingItemId2(forum.ForumID);
                              setisMenuLoading(true);
                              setSelectedForumDetailsFromList(forum)
                              let Rcount = await RecruitedConsolidationReportGenrationCount(forum.ForumID)
                              let AppliedCount = await AppliedConsolidationReportGenrationCount(forum.ForumID)
                              let Enddate = await endDateReport(forum.ForumID)
                              setEndTime(Enddate)
                              // let ReviewCount = await underManualReviewConsolidationReportGenrationCount(forum.ForumID)
                              setRcount(Rcount)
                              setTotalAppliedCount(AppliedCount)
                              // setUnderReviewCount(ReviewCount)
                              setisMenuLoading(false);
                              toggleModal()
                            }}
                          ></MenuIcon>
                          )}
                        </>
                      )}
                    </ListItem>
                    <Divider style={{ borderWidth: '1px', borderColor: 'rgba(0, 0, 0, 5.12)' }} />
                  </>
  
                );
              }
              

            } else {
              let ForumDescription = ""
              let isLink = ""
              const status = forum.status
              let renderNotification = false
              if(status != null || status != undefined){
                renderNotification = true
                let randomColor = '#008000'
                let title =objectNullandUndefined('246')
                let symbol = '*'
                let display = objectNullandUndefined('248')+" " + forum.RequestData+" " + objectNullandUndefined('251')+" "+ forum.type+" " +objectNullandUndefined('249')
                if(forum.status == "Rejected"){
                  randomColor = '#FF0000'
                  symbol = "!";
                  title = objectNullandUndefined('247')
                  display = objectNullandUndefined('248')+" " + forum.RequestData+" " + objectNullandUndefined('251')+" "+ forum.type +" "+objectNullandUndefined('250')
                }else if(forum.status == "Under Manual Review"){
                  randomColor = '#3176FB'
                  symbol = "@";
                  title = objectNullandUndefined('272')
                  if(forum.isCandidate){
                    display = objectNullandUndefined('278')+` "` + forum.RequestData+`" ` + objectNullandUndefined('279')
                  }else{
                    display = objectNullandUndefined('280')+` "` + forum.RequestData+`" ` + objectNullandUndefined('279')
                  }
                }else if(forum.status == "Profinity Passed"){
                  randomColor = '#008000'
                  symbol = "#";
                  title = objectNullandUndefined('246')
                  if(forum.isCandidate){
                    display = objectNullandUndefined('278')+` "` + forum.RequestData+`" ` + objectNullandUndefined('281')
                  }else{
                    display = objectNullandUndefined('280')+` "` + forum.RequestData+`" ` + objectNullandUndefined('281')
                  }
                }else if(forum.status == "Profinity Failed"){
                  randomColor = '#FF0000'
                  symbol = "#";
                  title = objectNullandUndefined('247')
                  if(forum.isCandidate){
                    display = objectNullandUndefined('278')+` "` + forum.RequestData+`" ` + objectNullandUndefined('282')
                  }else{
                    display = objectNullandUndefined('280')+` "` + forum.RequestData+`" ` + objectNullandUndefined('283')
                  }
                }
                return (
                  <>
                    <ListItem style={{ padding: "0,0,0,0" }} key={forum.id} alignItems="flex-start" className="listItem">
                      <ListItemAvatar>
                        {/* <Avatar alt="Remy Sharp" src="/static/images/avatar/1.jpg" /> */}
                        <Avatar alt="No Image" style={{
                          backgroundColor: randomColor
                        }}>
                          {
                            symbol
                          }
                        </Avatar>  
                      </ListItemAvatar>
                      <ListItemText
                        style={{ marginLeft: '20px' }}
                        primary={title}
                        onClick={() => {
                            if(forum.status == "Approved"){
                              // NavigateToCreateRec(forum);
                              deleteNotification(forum)
                            }else{
                              deleteNotification(forum)
                            }
                          }
                        }
                        secondary={ 
                          <div style={{ width: '93%', wordWrap: 'break-word' }}>
                            <h6 style={{ color: 'black' }}>{display}</h6>
                          </div>
                        } />
                      {isRecruiter === "true" && (
                        <>
                          <DeleteIcon
                            style={{ marginTop: "2%", marginRight: '3%' }}
                            onClick={async () => {
                              deleteNotification(forum)
                            }}
                          ></DeleteIcon>
                        </>
                      )}
                    </ListItem>
                    <Divider style={{ borderWidth: '1px', borderColor: 'rgba(0, 0, 0, 5.12)' }} />
                  </>
                );
              }else{
              return (
                <>
                  <ListItem key={forum.id} alignItems="flex-start" className="listItem"
                  onClick={() => {
                    if(forum['ForumDescription'][0][0] === "Under Review" || forum['ForumDescription'][0] === "Under Review" || forum['ForumDescription'] === "Under Review"){
                      toast.info((objectNullandUndefined('272')));
                      return
                    }
                    FetchChatMessage(forum);
                    setdisplayCandidateChatBox(true)
                    setPopupVisible(true);
                    setPopupVisible1(forum.ForumDescription[0]);
                    setEpochtime(forum.EpochTime[0]);
                    Details(forum);
                    if (isRecruiter === 'true') {
                      handleClickOpen();
                    }
                  }}>
                    <ListItemAvatar>
                      {/* <Avatar alt="Remy Sharp" src="/static/images/avatar/1.jpg" /> */}
                      <Avatar alt="No Image" style={{
                        backgroundColor: randomColor()
                      }}>
                        {
                          forum.ForumName
                            .replace(/\s+/g, ' ')
                            .split(' ')
                            .filter(word => word.trim() !== '')
                            .slice(0, 2)
                            .map(word => word.charAt(0))
                            .join('').toUpperCase()
                        }
                      </Avatar>
                      {/* {forum.Counts > 0  && (
                        <span class="badge badge-light" style={{ borderRadius: '50%',borderColor:'transparent', fontSize: "5px", backgroundColor:'transparent' }}><MessageIcons style={{color:'red'}}></MessageIcons></span>
                        // <span class=" p-2 bg-danger border border-light rounded-circle" style={{position:'absolute',top:'17px',left:'10px'}}><MessageIcon></MessageIcon></span>
                      )}
                      {forum.Counts > 9 && (
                        <span class="badge badge-light" style={{ borderRadius: '50%', fontSize: "10px", backgroundColor: 'red' }}>1</span>
                      )} */}
                    </ListItemAvatar>
                    <div style={{ display: 'grid', width: '75%' }} >
                      <div>
                        {forum.ForumName}
                      </div>

                      <div style={{
                        wordWrap: 'break-word',
                        width: '90%',
                        color: 'black',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        whiteSpace: 'nowrap',
                        cursor: 'pointer',
                      }}
                        >

                        {forum.ForumDescription}
                      </div>


                      <div>
                        {isRecruiter !== "true" && popupVisible === false && (
                          <Button
                            variant="contained"
                            onClick={(event) => {
                              event.stopPropagation()
                              handleVideoButtonPress(forum)
                            }}
                            style={{ backgroundColor: '#2BAE66FF', color: 'white', fontSize: '10px', position: 'absolute', right: 15, top: 15, width: '11%' }}
                          >
                            {objectNullandUndefined('155')}
                          </Button>
                        )}
                      </div>
                      <div>
                        {isRecruiter === "true" && (
                          <>
                            <DeleteIcon
                              style={{
                                position: 'absolute',
                                width: '20%',
                                right: 0,
                                top: 15,
                                zIndex: 9999
                              }}
                              onClick={async () => {
                                if (window.confirm(objectNullandUndefined('157'))) {
                                  await ConsolidatedReportGeneration(forum.ForumID, "delete");
                                  setLoading(true);
                                }
                              }}
                            ></DeleteIcon>
                            <ShareIcon
                              onClick={async () => {
                                await ConsolidatedReportGeneration(forum.ForumID, "share");
                              }}
                              style={{
                                position: 'absolute',
                                width: '20%',
                                right: 20,
                                top: 15,
                                zIndex: 9999
                              }}>
                            </ShareIcon>
                          </>
                        )}
                      </div>
                    </div>
                    {forum.Counts > 0  && (
                    <div style={{ display: 'grid', width: '35%',alignSelf:'center',justifyContent:'center'}}>
                      <MessageIcons style={{color:'red'}}></MessageIcons>
                    </div>
                    )}
                  </ListItem>
                  <Divider style={{ borderWidth: '1px', borderColor: 'rgba(0, 0, 0, 5.12)' }} />

                </>
              );
            }
            }

          })
        ) : (
          <ListItem alignItems="flex-start" className="listItem" onClick={handleListItemClick}>
            {isRecruiter === "true" && (
              <ListItemText primary={objectNullandUndefined('262')} />
            )}
            {isRecruiter != "true" && (
              <ListItemText primary={"No Notification "} />
            )}
          </ListItem>
        )}
        {ShowMenuPopList === true && (
        <div className={`modal ${ShowMenuPopList ? 'open' : ''}`} onClick={handleClose1}>
          <div className="modal-content">
            <span className="close" onClick={handleClose1}>&times;</span>
            <h2>{SelectedForumDetailsFromList.ForumName}</h2>

            <button
                        onClick={(event) => 
                          {
                            Details(SelectedForumDetailsFromList);
                          }} > {objectNullandUndefined('293')} ({TotalAppliedCount})</button>

            <button 
            onClick={(event) => 
              {
                ShowRecruitedCandidates(SelectedForumDetailsFromList,RecruitedCandidateCount);
              }}>{objectNullandUndefined('235')} ({RecruitedCandidateCount})</button>

            <button
            onClick={(event) => 
              {
                DownloadAnalytics(SelectedForumDetailsFromList)
              }}>{objectNullandUndefined('294')}</button>

            <button  
            onClick={(event) => 
              {
                event.stopPropagation()
                console.log("clicked")
                recruiterViewMyVideo(SelectedForumDetailsFromList.ForumID)
                // handleVideoButtonPress(forum)
              }} 
            >{objectNullandUndefined('277')}
            <br></br>
            {" ( "+objectNullandUndefined('292')+" "+EndTime +" )"}</button>

          </div>
        </div>
        )}
      </>
    );
  }
  function QRCodePopup() {
    return (
      <div>

        {isOpen && (
          <div className="popup">
            <div className="popup-content">
              <span className="close" onClick={closePopup}>&times;</span>
              <br></br>
              {/* <textarea readOnly>{QrCodeLink}</textarea> */}
              <div style={{ clear: "both" }} className="qrcode-container">
                <QRCode id="qr-gen" value={QrCodeLink} size={290} level={"H"}
                  includeMargin={true} />
              </div>
              <Button color="error" onClick={downloadQRCode} variant="contained">{objectNullandUndefined('188')}</Button>
            </div>
          </div>
        )}
      </div>
    );
  }
  function downloadQRCode() {
    // Generate download with use canvas and stream
    const canvas = document.getElementById("qr-gen");
    const pngUrl = canvas
      .toDataURL("image/png")
      .replace("image/png", "image/octet-stream");
    let downloadLink = document.createElement("a");
    downloadLink.href = pngUrl;
    downloadLink.download = `QrCode.png`;
    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);
  };
}
const customStyles = {
  content: {
    width: '60%',
    maxWidth: '400px',
    margin: 'auto',
    padding: '20px',
    borderRadius: '8px',
    boxShadow: '0 2px 6px rgba(0, 0, 0, 0.1)',
  },
  overlay: {
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    zIndex: 1000,
  },
};

function randomColor() {
  let hex = Math.floor(Math.random() * 0xFFFFFF);
  let color = "#" + hex.toString(16);
  return color;
}
