import React, { useRef, useState,useEffect } from "react";
import RecruiterShort from "./RecruiterShort";
import { useHistory } from 'react-router-dom';
import { objectNullandUndefined } from "../Dictionary/Dictionary";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import { makeStyles } from "@material-ui/core";
import { RecruitedConsolidationReportGenration } from "../../services/LoginLogoutService";
import Modal from '@material-ui/core/Modal';
import CircularProgress from '@material-ui/core/CircularProgress';


function RecruitContainer({isPullable,ConsolidatedReportGeneration,scroll,toggle,...props}) {
  const history = useHistory();
  const [videos, setVideos] = useState(props.videos);
  const [loading, setLoading] = React.useState(false);
  const [checked, setChecked] = React.useState(false);
  const shortContainerRef = useRef();

  useEffect(() => {
    const apiCallInterval = setInterval(() => {
      let checked = localStorage.getItem('RecruitedVideo');
      if(checked==null || checked == "false"){
        ConsolidatedReportGeneration();
      }
    }, 2 * 60 * 1000); // 5 minutes in milliseconds

    // Clean up the interval when the component is unmounted
    return () => clearInterval(apiCallInterval);
  }, []);

  useEffect(() => {
    if (shortContainerRef.current && scroll) {
      shortContainerRef.current.scrollTo(0, 0);
      toggle()
    }
  }, [videos]);

  useEffect(() => {
    setVideos(props.videos)
  }, [props.videos]);

  const handleRemoveVideo = (videoIndex) => {
    const newVideos = videos.filter((_, index) => index !== videoIndex);
    if (newVideos.length != 0) {
      setVideos(newVideos);
    } else {   
      setTimeout(() => {
        history.push('/Notification');
      }, 2000)
    }
  };

  useEffect(() => {
    const fetchData = async () => {

     let data  = localStorage.getItem('nullvalue');
     if(data == "null"){
      setChecked(true);
      localStorage.setItem('RecruitedVideo',true)
     }

    let data1  = localStorage.getItem('ShowRecruitedCandidatesvalue');
    if(data1 == "true"){
    setChecked(true);
    localStorage.setItem('ShowRecruitedCandidatesvalue',"false")
    }
    };
    fetchData();
  }, []);
  const handleChange = async() => {   
    if(checked == true){
      isPullable()
      localStorage.setItem('RecruitedVideo',false)
      setChecked(false);
      window.location.reload();
    }else{
      localStorage.setItem('RecruitedVideo',true)
      isPullable()
      setLoading(true)
      setChecked(true);
      let data1 =  localStorage.getItem('recruitedforumid')
      let data = await RecruitedConsolidationReportGenration(data1)
      if(data.length > 0){
        setTimeout(() => {
          setVideos(data)
          setLoading(false)
          toast.success(objectNullandUndefined('230'));
        }, 2000)  
      }else{
        toast.warning(objectNullandUndefined('231'));
        setTimeout(() => {
          setChecked(false);
          localStorage.setItem('RecruitedVideo',false)
          setLoading(false)
        }, 2000)
      }
       
    }
    
  };
  const classes = useStyles();
  return (
    <>
    <Modal open={loading} className={classes.loaderContainer}>
                <div>
                  <CircularProgress className={classes.loader} size={40} thickness={3} />
                </div>
              </Modal>
    <div className="color1">
      <div className="allign">
          <FormGroup>
        <FormControlLabel
        checked={checked}
        onChange={handleChange}
        control={<Switch defaultChecked color="warning"/>}
        label={objectNullandUndefined('235')}
        classes={{
          label: classes.switchLabel,
        }}
      />

        </FormGroup>
        </div>
        </div>
      <section ref={shortContainerRef} className="short-container">
        {videos.map((short, index) => (
          <RecruiterShort
            shortContainerRef={shortContainerRef}
            short={short}
            key={short.mediaID}
            onRemove={() => handleRemoveVideo(index)}
            ConsolidatedReportGeneration = {ConsolidatedReportGeneration}
          />
        ))}
      </section>
      <div className="arrow">
  

        <div className="navigation-container">
          <div
            onClick={() => {
              shortContainerRef.current.scrollTo(
                0,
                shortContainerRef.current.scrollTop - window.innerHeight
              );
            }}
            className="nav-up"
          >
            <ion-icon name="arrow-up-outline"></ion-icon>
          </div>
          <div
            onClick={() => {
              shortContainerRef.current.scrollTo(
                0,
                shortContainerRef.current.scrollTop + window.innerHeight
              );
            }}
            className="nav-down">
            <ion-icon name="arrow-down-outline"></ion-icon>
          </div>
        </div>
      </div>
    </>
  );
}
const useStyles = makeStyles({
  switchLabel: {
    fontSize: '16px',
    color:'white' 
  },
  switchIcon: {
    width: '24px', 
    height: '24px',
    color:'red'
  },
  loaderContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100vh',
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
  },
loader: {
    color: '#fff',
  },
});
export default RecruitContainer;
