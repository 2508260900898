import React, { useRef, useEffect, useState } from "react";
import './ShortContainer.css'
import Short from "./Short";
import Modal from '@material-ui/core/Modal';
import { decode as base64_decode, encode as base64_encode } from 'base-64';
import { fetchForumInfo, clientSync ,bridgeSendingByUser,DeleteBridge,modifyDeletedBridge} from '../services/LoginLogoutService';
import { useHistory } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { uniqBy } from 'lodash';
import { CommonForumFlow,CommonForumReport,removeMediaIDFromListflowName,CommonForumID, FSServerID, FilterResponse, IndexFlow, MacAddress, RecMessageFlow, RecruiterForumTemp, forumServerIP,RecruitedCandidateUserFlowID, DemoRec1MediaID, DemoRec2MediaID, RecCreateTempName, ChatFlowID } from "./Dictionary/CommonStrings";
import utf8 from 'utf8';
import { objectNullandUndefined } from "./Dictionary/Dictionary";
import Hook from "./Hook";
import ReactGA from 'react-ga';
import PullToRefresh from 'react-simple-pull-to-refresh';
import { v4 as uuidv4 } from 'uuid';
function ShortContainer() {
  const myUuid = uuidv4();
  const customId = "custom-id-yes";
  const [showModal, setShowModal] = useState(false);

  const OkToReload = async() =>{
    setShowModal(false);
    window.location.reload(false);
  }
  const close = async() =>{
    let Demoarray = [];                 
    let Demojsondata = { 
      "companyName": "{\"CompanyName\":\"Demo User1 \",\"MediaID\":\"\",\"City\":\"Bangalore\"}" , 
      "Jd": "Sales representative needed please record your self introduction and send", 
      "jType": "", 
      "mediaID": DemoRec1MediaID, 
      "ForumID": "1f7783ed-b88c-47bc-84f3-f07cb0f5c47a" }  
      Demoarray.push(Demojsondata);
      let Demojsondata1 = { 
        "companyName": "{\"CompanyName\":\"Demo User2 \",\"MediaID\":\"\",\"City\":\"Bangalore\"}" , 
        "Jd": "Sales representative needed please record your self introduction and send", 
        "jType": "", 
        "mediaID": DemoRec2MediaID, 
        "ForumID": "1f7783ed-b88c-47bc-84f3-f07cb0f5c47a" }  
        Demoarray.push(Demojsondata1);
        setData(Demoarray);
    setShowModal(false);
  }

  const notify = () => toast(objectNullandUndefined('58'), {
    toastId: customId,
    autoClose: 20000,
  });

  useEffect(() => {
    const handleServiceWorkerMessage = (event) => {
      if (event.data.type === 'navigate') {
        history.push('/Notification');
      }
    };

    navigator.serviceWorker.addEventListener('message', handleServiceWorkerMessage);

    return () => {
      navigator.serviceWorker.removeEventListener('message', handleServiceWorkerMessage);
    };
  }, []);
  useEffect(() => {
    if (localStorage.getItem('userProfile') === null) {
      history.push("/Login");
    }
    const queryParameters = new URLSearchParams(window.location.search) 
    if(queryParameters.size !=0){
      // const type = queryParameters.get("FiD")
      // const MediaID = queryParameters.get("ViD")
      // const cN = queryParameters.get("cN")
      // const dT = queryParameters.get("dT")
      // let decription = dT
      // let details = cN
      // let forumId = type
      // let videoId = MediaID
      // let json = {
      //   "companyName": details,
      //   "Jd": decription,
      //   "jType": "",
      //   "Duration": "",
      //   "mediaID": videoId,
      //   "ForumID": forumId,
      //   "BridgeID": ""
      // };
      // let array = []
      // array.push(json)

      //this will solve location not displaying issue.
      const type = queryParameters.get("FiD")
      const MediaID = queryParameters.get("ViD")
      const cN = queryParameters.get("cN")
      const dT = queryParameters.get("dT")
      const cY = queryParameters.get("cY")
      let decription = dT
      let details = {"CompanyName":cN,"city":cY,"City":cY}
      let details1 = JSON.stringify(details)
      let forumId = type
      let videoId = MediaID
      let json = {
        "companyName": details1,
        "Jd": decription,
        "jType": "",
        "Duration": "",
        "mediaID": videoId,
        "ForumID": forumId,
        "BridgeID": "",
      };
      let array = []
      array.push(json)


      
  
      // setData(prevMediaIds => [ 
      //   ...array,
      //   ...prevMediaIds]);
      const urlWithoutParams = window.location.href.split('?')[0];
      // let decodeReport = localStorage.getItem("MediaIDS1")
      // if (decodeReport) {
      //   let mediaArr = JSON.parse(decodeReport)
      //   mediaArr.unshift(json)
      //   localStorage.setItem("MediaIDS1", JSON.stringify(mediaArr))
      // }else{
      //   localStorage.setItem("MediaIDS1", JSON.stringify(array))
      // }
      localStorage.setItem("SharedVideoData", JSON.stringify(array))
      window.history.replaceState({}, document.title, urlWithoutParams);
      // window.location.reload();
    }
    ReactGA.pageview(window.location.pathname);

  }, []);
  const shortContainerRef = useRef();
  const history = useHistory();
  const [videoData, setData] = useState([
    // { "companyName": "{\"CompanyName\":\"Demo User2 \",\"MediaID\":\"\",\"City\":\"Mumbai\"}", "Jd": "We need a delivery partner to apply make video", "jType": "", "mediaID": "656051c9eed0c202c41bce9d", "ForumID": "1f7783ed-b88c-47bc-84f3-f07cb0f5c47a" },
    // { "companyName": "{\"CompanyName\":\"Demo User3 \",\"MediaID\":\"\",\"City\":\"Delhi\"}" , "Jd": "Hiring for sales representative record your introduction video", "jType": "", "mediaID":"656423899b7c96429b72ab93", "ForumID": "2d5fd941-a5fa-421a-a7a1-6bc591e4c778" },
    // { "companyName": "Cosmitude", "Jd": "Software Testers needed record your self introduction video", "jType": "", "mediaID": "6482c90685319ca7f2de177b", "ForumID": "ebbe18c1-78a8-4095-afcf-2815d7dfc4cb" }
  ]);
   

  useEffect(() => {
    const interval = setInterval(() => {
      clientSyncData()
    }, 5000);
    videoReload()
    let decodeReport1=  localStorage.getItem("demovideo")
    if(decodeReport1 === "true"){
      let Demoarray = [];   
      let Demojsondata = { 
        "companyName": "{\"CompanyName\":\"Demo User1 \",\"MediaID\":\"\",\"City\":\"Bangalore\"}" , 
        "Jd": "Sales representative needed please record your self introduction and send", 
        "jType": "", 
        "mediaID": DemoRec1MediaID, 
        "ForumID": "1f7783ed-b88c-47bc-84f3-f07cb0f5c47a" }  
        Demoarray.push(Demojsondata);
        let Demojsondata1 = { 
          "companyName": "{\"CompanyName\":\"Demo User2 \",\"MediaID\":\"\",\"City\":\"Bangalore\"}" , 
          "Jd": "Sales representative needed please record your self introduction and send", 
          "jType": "", 
          "mediaID": DemoRec2MediaID, 
          "ForumID": "1f7783ed-b88c-47bc-84f3-f07cb0f5c47a" }  
          Demoarray.push(Demojsondata1);
        setData(Demoarray);
    }

   
  }, []);

  async function DeleteBridgeForProfinity(bridgeData) {
    let userDetails = localStorage.getItem('userProfile');
    userDetails = base64_decode(userDetails);
    userDetails = JSON.parse(userDetails);
    let jsonObject = {
      "ForumID": bridgeData['ForumID'],
      "BridgeID": bridgeData['BridgeID'],
      "FlowID": CommonForumReport,
      "MACAddress": userDetails['MACAddress'],
      'SessionID': userDetails['SessionID'],
      'ServerID': FSServerID,
      'setIsDelete':true
    }
    let deleteBridge = await modifyDeletedBridge(jsonObject);
    if (deleteBridge["ErrorCode"] === 1074) {
      toast.error((objectNullandUndefined('221')));
      setTimeout(() => {
        localStorage.clear();
        sessionStorage.clear();
        history.push('/');
        window.location.reload(false);
      }, 3000)
    }else{
      console.log(deleteBridge["ErrorCode"] )
    }
  }
  
  const findIndexByMediaID = async(decodeReport, targetMediaID) => {
      if(decodeReport && targetMediaID != null){
        let jsonArray = JSON.parse(decodeReport)
        for (let i = 0; i < jsonArray.length; i++) {
          if (jsonArray[i].mediaID === targetMediaID) {
            return i; // Return the index of the matching object
          }
        }
      }
    return -1; // Return -1 if the targetMediaID is not found in the array
  };

  const removeVideo = async(data) => {
    let storedMediaId = data
    let OldMediaIDS1string = localStorage.getItem("MediaIDS1");
    let OldMediaIDS1 = JSON.parse(OldMediaIDS1string);
    let i = await findIndexByMediaID(OldMediaIDS1string,storedMediaId)
    if(i == null || i == "NaN" || i == -1){
      console.log("video not present");
    }else{
      let newVideodataList = [];
      newVideodataList = [...OldMediaIDS1]
      newVideodataList.splice(i, 1);
      localStorage.setItem("MediaIDS1",JSON.stringify(newVideodataList));
      if (history.location.pathname === '/ShortContainer') {
        // Reload the page
        window.location.reload(false);
      }
    }
  }
  const insertVideo = async(data) => {
    let storedMediaId = localStorage.getItem("LastePlayedMID");
    let OldMediaIDS1string = localStorage.getItem("MediaIDS1");
    let OldMediaIDS1 = JSON.parse(OldMediaIDS1string);
    let i = await findIndexByMediaID(OldMediaIDS1string,storedMediaId)
    if(i == null || i == "NaN" || i == -1){
      i = 0;
    }else{
      i = parseInt(i);
    }
    let newVideodataList = [];
    if(i == 0 && OldMediaIDS1 != null){
      newVideodataList = [data,...OldMediaIDS1]
    }else if( OldMediaIDS1 == null){
      newVideodataList.push(data)
    }else{
      // newVideodataList = [...OldMediaIDS1.slice(0, i+1),
      // data,
      // ...OldMediaIDS1.slice(i+1)]
      newVideodataList = [...OldMediaIDS1,data]
    }
    // localStorage.setItem("LastePlayedMID", String(data['mediaID']));
    // newVideodataList = [...OldMediaIDS1.slice(0, i+1),
    //   data,
    //   ...OldMediaIDS1.slice(i+1)]
    localStorage.setItem("MediaIDS1",JSON.stringify(newVideodataList));
    setTimeout(() => {
      if (history.location.pathname === '/ShortContainer') {
        // toast.success(objectNullandUndefined('260'))
        // Reload the page
        window.location.reload(false);
      }
    }, 3000);
    // videoReload();
  }

  const videoReload = async () => {
    notify()
    if (videoData.length == 0) {
      setData(null)
      let decodeReport = localStorage.getItem("MediaIDS1")
      let storedMediaId = localStorage.getItem("LastePlayedMID");
      let i = await findIndexByMediaID(decodeReport,storedMediaId)
      console.log("stored index",i);
      if(i == null || i == "NaN" || i == -1){
        i = 0;
      }else{
        i = parseInt(i);
      }
      if (decodeReport) {
        let mediaArr = []
        mediaArr = JSON.parse(decodeReport)
        // setData(mediaArr);
        if(mediaArr.length <= i){
          i = 0;
          localStorage.removeItem("LastePlayedMID");
        }

        let LastPlayedVideoArray =  mediaArr.slice(i);
        let SharedVideoDataString = localStorage.getItem("SharedVideoData")
        if(SharedVideoDataString != null || SharedVideoDataString != undefined){
          let SharedVideoData = JSON.parse(SharedVideoDataString)
          LastPlayedVideoArray = [SharedVideoData[0],...LastPlayedVideoArray]
          // localStorage.removeItem("SharedVideoData")
        }
        setData(LastPlayedVideoArray);
      }else{
        let SharedVideoDataString = localStorage.getItem("SharedVideoData")
        if(SharedVideoDataString != null || SharedVideoDataString != undefined){
          let SharedVideoData = JSON.parse(SharedVideoDataString)
          setData(SharedVideoData)
        }else{
          let candidateIntrestString = localStorage.getItem("CandidateIntres")
          let selectedLocationString = localStorage.getItem("selectedLocation")
          if(candidateIntrestString != null  && selectedLocationString != null ){
            if(candidateIntrestString == "[]"){
              toast.dismiss()
              toast.error((objectNullandUndefined('261')), {
                autoClose: 3000,
              });
              setTimeout(() => {
              let Demoarray = [];   
              let Demojsondata = { 
                "companyName": "{\"CompanyName\":\"Demo User1 \",\"MediaID\":\"\",\"City\":\"Bangalore\"}" , 
                "Jd": "Sales representative needed please record your self introduction and send", 
                "jType": "", 
                "mediaID": DemoRec1MediaID, 
                "ForumID": "1f7783ed-b88c-47bc-84f3-f07cb0f5c47a" }  
                Demoarray.push(Demojsondata);
                let Demojsondata1 = { 
                  "companyName": "{\"CompanyName\":\"Demo User2 \",\"MediaID\":\"\",\"City\":\"Bangalore\"}" , 
                  "Jd": "Sales representative needed please record your self introduction and send", 
                  "jType": "", 
                  "mediaID": DemoRec2MediaID, 
                  "ForumID": "1f7783ed-b88c-47bc-84f3-f07cb0f5c47a" }  
                  Demoarray.push(Demojsondata1);
                setData(Demoarray);
              }, 3000)
            }else if(candidateIntrestString != "[]" && videoData.length == 0){
              let showDemo = localStorage.getItem('showDemoAfterPullDown');
              if(showDemo){
                toast.dismiss()
              toast.info((objectNullandUndefined('270')), {
                autoClose: 5000,
              });
              setTimeout(() => {
              let Demoarray = [];   
              let Demojsondata = { 
                "companyName": "{\"CompanyName\":\"Demo User1 \",\"MediaID\":\"\",\"City\":\"Bangalore\"}" , 
                "Jd": "Sales representative needed please record your self introduction and send", 
                "jType": "", 
                "mediaID": DemoRec1MediaID, 
                "ForumID": "1f7783ed-b88c-47bc-84f3-f07cb0f5c47a" }  
                Demoarray.push(Demojsondata);
                let Demojsondata1 = { 
                  "companyName": "{\"CompanyName\":\"Demo User2 \",\"MediaID\":\"\",\"City\":\"Bangalore\"}" , 
                  "Jd": "Sales representative needed please record your self introduction and send", 
                  "jType": "", 
                  "mediaID": DemoRec2MediaID, 
                  "ForumID": "1f7783ed-b88c-47bc-84f3-f07cb0f5c47a" }  
                  Demoarray.push(Demojsondata1);
                setData(Demoarray);
              }, 5000)
            }
          }
          }else{
            toast.dismiss()
            toast.error((objectNullandUndefined('261')), {
              autoClose: 3000,
            });
            setTimeout(() => {
            let Demoarray = [];   
            let Demojsondata = { 
              "companyName": "{\"CompanyName\":\"Demo User1 \",\"MediaID\":\"\",\"City\":\"Bangalore\"}" , 
              "Jd": "Sales representative needed please record your self introduction and send", 
              "jType": "", 
              "mediaID": DemoRec1MediaID, 
              "ForumID": "1f7783ed-b88c-47bc-84f3-f07cb0f5c47a" }  
              Demoarray.push(Demojsondata);
              let Demojsondata1 = { 
                "companyName": "{\"CompanyName\":\"Demo User2 \",\"MediaID\":\"\",\"City\":\"Bangalore\"}" , 
                "Jd": "Sales representative needed please record your self introduction and send", 
                "jType": "", 
                "mediaID": DemoRec2MediaID, 
                "ForumID": "1f7783ed-b88c-47bc-84f3-f07cb0f5c47a" }  
                Demoarray.push(Demojsondata1);
              setData(Demoarray);
            }, 3000)
          }
        }
      }
    }else{
      setData(null)
      let decodeReport = localStorage.getItem("MediaIDS1")
      let storedMediaId = localStorage.getItem("LastePlayedMID");
      let i = await findIndexByMediaID(decodeReport,storedMediaId)
      console.log("stored index",i);
      if(i == null || i == "NaN" || i == -1){
        i = 0;
      }else{
        i = parseInt(i);
      }
      if (decodeReport) {
        let mediaArr = []
        mediaArr = JSON.parse(decodeReport)
        // setData(mediaArr);
        if(mediaArr.length <= i){
          i = 0;
          localStorage.removeItem("LastePlayedMID");
        }

        let LastPlayedVideoArray =  mediaArr.slice(i);
        let SharedVideoDataString = localStorage.getItem("SharedVideoData")
        if(SharedVideoDataString != null || SharedVideoDataString != undefined){
          let SharedVideoData = JSON.parse(SharedVideoDataString)
          LastPlayedVideoArray = [SharedVideoData[0],...LastPlayedVideoArray]
          localStorage.removeItem("SharedVideoData")
        }
        setData(LastPlayedVideoArray);
      }else{
        let SharedVideoDataString = localStorage.getItem("SharedVideoData")
        if(SharedVideoDataString != null || SharedVideoDataString != undefined){
          let SharedVideoData = JSON.parse(SharedVideoDataString)
          setData(SharedVideoData)
        }
      }
    }
  }
  const loadOlderVideo = () => {
    localStorage.removeItem('LastePlayedMID')
    localStorage.removeItem("SharedVideoData")
    window.location.reload(false)
    localStorage.setItem("showDemoAfterPullDown",true)
  }

  async function bridgeSendingForCandidateVideoToCommonForum(allData,MediaID,ForumnId,Username,MobileNumber) {
    let commonForumTemp = localStorage.getItem("commonUserFlows")
    let parsedFlow = JSON.parse(commonForumTemp)
    let parsedCommonFlow = parsedFlow["UserFlow"]
    let commonTempName = parsedFlow["Template"]
    let CandidateAppliedFlowId = parsedCommonFlow[commonTempName + '_CandidateAppliedVid']
    // let filterFlowID = parsedCommonFlow[commonTempName + '_CandidateAppliedVid']
  try {
    let uuid = myUuid
  
    let userDetails = localStorage.getItem('userProfile');
    userDetails = base64_decode(userDetails);
    userDetails = JSON.parse(userDetails);
    let sessionID = userDetails.SessionID;
    let macAddress = userDetails.MACAddress;
    let flowName = commonTempName + '_CandidateAppliedVid';;
    let fID = CandidateAppliedFlowId['FID']
    let tempJson = {
      ForumID: CommonForumID,
      SessionID: sessionID,
      MACAddress: macAddress,
      Time: '6516516156161',
      ScheduledDateTime: 'now',
      ScheduledBoolean: 0,
      FlowID: flowName,
      EnableChat: 0,
      FlowType: 'Custom',
      BridgeForward: 0,
      TemplateID: parsedFlow["TemplateID"],
      TextCount: 6,
      ImageCount: 0,
      InvoiceID: '',
      DocumentCount: 0,
      User: true,
      VideoCount: 0,
      ServerID: FSServerID,
      ReplyBridgeID: '',
      HiddenFlow: false,
      BilledForum: '',
      TempBridgeId: uuid,
      FID: fID,
      SentTo: '0',
    };
    
    let jsonData = {
      [CommonForumFlow + '_CandidateAppliedVid_Main-447da86d-859f-4470-8ae0-f8f11a9e60c4_2']: base64_encode(JSON.stringify(allData)),
      [CommonForumFlow + '_CandidateAppliedVid_Main-447da86d-859f-4470-8ae0-f8f11a9e60c4_4']: base64_encode(MediaID),
      [CommonForumFlow + '_CandidateAppliedVid_Main-447da86d-859f-4470-8ae0-f8f11a9e60c4_6']: base64_encode(ForumnId),
      [CommonForumFlow + '_CandidateAppliedVid_Main-447da86d-859f-4470-8ae0-f8f11a9e60c4_8']: base64_encode(Username),
      [CommonForumFlow + '_CandidateAppliedVid_Main-447da86d-859f-4470-8ae0-f8f11a9e60c4_10']: base64_encode(MobileNumber),
    };
    tempJson[flowName] = jsonData;
    const data = new FormData();
    data.append('Data', JSON.stringify(tempJson));
    let bridgeData = await bridgeSendingByUser(data);
    if (bridgeData["ErrorCode"] === 1074) {
      toast.error((objectNullandUndefined('221')));
      setTimeout(() => {
        localStorage.clear();
        sessionStorage.clear();
        history.push('/');
        window.location.reload(false);
      }, 3000)
    }
  } catch (err) {
    return;
  }
}

  function clientSyncData() {
    let userDetails = localStorage.getItem('userProfile');
    let decoded = base64_decode(userDetails);
    userDetails = JSON.parse(decoded);
    let clientSyncJson = {
      SessionID: userDetails.SessionID,
      MACAddress: userDetails.MACAddress,
      ServerID: FSServerID,
    };
    fetch(forumServerIP + 'clientSync', {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(clientSyncJson),
    })
      .then(response => {
        return response.json();
      })
      .then(data => {
        if (data["ErrorCode"] === 1074) {
          toast.error((objectNullandUndefined('221')));
          setTimeout(() => {
            localStorage.clear();
            sessionStorage.clear();
            history.push('/');
            window.location.reload(false);
          }, 3000)
        }
        let dataArr = data["ErrorMessage"]['User']
        let mediaIdArr = []
        if (dataArr.length > 0) {
          for (let i = 0; i < dataArr.length; i++) {
            let dataJson = data["ErrorMessage"]['User'][i]
            let keys = Object.keys(dataJson)
            if (keys[0] === 'Bridge') {
              const bridgeData = dataJson['Bridge'];
              let flowID = bridgeData['FlowID']
              let filterResponse = CommonForumFlow + '_FilterResponse'
              if (flowID === filterResponse) {
                let decodeData = [];
                let Data = dataJson["Bridge"][filterResponse][CommonForumFlow + '_FilterResponse_Main-856d28e5-f438-4ddb-9634-e8bef106af51_2']
                if (Data.constructor == Array){
                  for(let j=0;j<Data.length;j++){
                    let DataDecode = base64_decode(Data[j])
                    let MediaData = JSON.parse(DataDecode);
                    decodeData = [...decodeData, ...MediaData]
                  }
                }else{
                  let decodeData1 = base64_decode(Data)
                  decodeData = JSON.parse(decodeData1)
                }
                if (decodeData.length === 0) {
                  toast.dismiss();
                  toast.warning(objectNullandUndefined('163'), {
                    position: toast.POSITION.TOP_CENTER,
                    autoClose: 3000, // 3 seconds
                    hideProgressBar: false,
                  });
                  let decodeReport3 =  localStorage.getItem("MediaIDS1")
                  let link122 = localStorage.getItem('parameter');
                  if(decodeReport3 == null && link122 == "0"){
                    let Demoarray = [];                 
                    let Demojsondata = { 
                      "companyName": "{\"CompanyName\":\"Demo User1 \",\"MediaID\":\"\",\"City\":\"Bangalore\"}" , 
                      "Jd": "Sales representative needed please record your self introduction and send", 
                      "jType": "", 
                      "mediaID": DemoRec1MediaID, 
                      "ForumID": "1f7783ed-b88c-47bc-84f3-f07cb0f5c47a" }  
                      Demoarray.push(Demojsondata);
                      let Demojsondata1 = { 
                        "companyName": "{\"CompanyName\":\"Demo User2 \",\"MediaID\":\"\",\"City\":\"Bangalore\"}" , 
                        "Jd": "Sales representative needed please record your self introduction and send", 
                        "jType": "", 
                        "mediaID": DemoRec2MediaID, 
                        "ForumID": "1f7783ed-b88c-47bc-84f3-f07cb0f5c47a" }  
                        Demoarray.push(Demojsondata1);
                        setData(Demoarray);
                        localStorage.setItem("demovideo","true");                 
                  }else{
                    localStorage.setItem('parameter',"0");
                    // let storedrray = JSON.parse(decodeReport3);
                    // setData(storedrray);
                    console.log('Data found')
                  }                
                }
                else {
                  let indexFlows = IndexFlow + "_Main-383cd425-9234-41e1-9a12-4a76ba4718a4"
                  for (let j = 0; j < decodeData.length; j++) {
                    let companyName = decodeData[j][indexFlows+'_2']
                    let Jd = decodeData[j][indexFlows+'_4']
                    let jType = decodeData[j][indexFlows+'_6']
                    let Duration = decodeData[j][indexFlows+'_8']
                    let ForumID = decodeData[j][indexFlows+'_10']
                    let mediaID = decodeData[j][indexFlows+'_12']
                    let bridgeId = decodeData[j]['BridgeID']
                    try{
                      let encodedCompanyName = base64_decode(companyName)
                      companyName = utf8.decode(encodedCompanyName)
                      let encodedJd = base64_decode(Jd)
                      Jd = utf8.decode(encodedJd)
                    }catch{
                      companyName = base64_decode(companyName)
                      Jd = base64_decode(Jd)
                    }
                    let json = {
                      "companyName": companyName,
                      "Jd": Jd,
                      "jType": base64_decode(jType),
                      "Duration": base64_decode(Duration),
                      "mediaID": base64_decode(mediaID),
                      "ForumID": base64_decode(ForumID),
                      "BridgeID": bridgeId
                    };
                    let decodedreport = json;
                    // console.log(decodedreport)
                    mediaIdArr.push(decodedreport);
                  }
                  localStorage.setItem("MediaIDS", JSON.stringify(mediaIdArr))
                  let decodeReport1=  localStorage.getItem("MediaIDS1")
                  if(decodeReport1){
                    let mediaArr1 = JSON.parse(decodeReport1)
                    let newList = [...mediaArr1,...mediaIdArr];
                    const uniqueMediaIDs = new Set();
                    const filteredArray = newList.filter((item) => {
                      const mediaID = item.mediaID;
                      if (!uniqueMediaIDs.has(mediaID)) {
                        uniqueMediaIDs.add(mediaID);
                        return true;
                      }
                      return false;
                    });
                    setData(filteredArray);
                    localStorage.setItem("MediaIDS1",JSON.stringify(filteredArray));
                  }else{
                    let decodeReport=  localStorage.getItem("MediaIDS")
                    let mediaArr = JSON.parse(decodeReport)
                    let newList = [...mediaArr];
                    const uniqueMediaIDs = new Set();
                    const filteredArray = newList.filter((item) => {
                      const mediaID = item.mediaID;
                      if (!uniqueMediaIDs.has(mediaID)) {
                        uniqueMediaIDs.add(mediaID);
                        return true;
                      }
                      return false;
                    });
                    setData(filteredArray);
                    localStorage.setItem("MediaIDS1",JSON.stringify(filteredArray));
                  }
                  // toast.success(objectNullandUndefined('216'), {
                  //   position: toast.POSITION.TOP_CENTER,
                  //   autoClose: 2000, // 3 seconds
                  //   hideProgressBar: false,
                  // });
                  localStorage.setItem("demovideo","false");
                  if (history.location.pathname === '/ShortContainer') {
                    window.location.reload(false);
                    // setShowModal(true);
                  }
                }
              }else if(flowID === ChatFlowID){
                let element = bridgeData
                let singleMessage = {}
                singleMessage['BridgeID'] = element['BridgeID']
                singleMessage['MobileNumber'] = element['MobileNumber']
                let textMessage = '';
                try{
                  textMessage = decodeURIComponent(escape(base64_decode(element[ChatFlowID]['PreScreenerRec111_Chat_Main-ff165321-0b90-4d07-917c-da5d002f11ba_2'])))
                }catch(err){
                  textMessage = utf8.decode(element[ChatFlowID]['PreScreenerRec111_Chat_Main-ff165321-0b90-4d07-917c-da5d002f11ba_2'])
                }
                singleMessage['textMessage'] = textMessage
                const date = new Date(element['Time'] * 1000);
                const options = {
                  timeZone: 'Asia/Kolkata',
                  hour: '2-digit',
                  minute: '2-digit',
                  year: 'numeric',
                  month: 'short',
                  day: 'numeric',
                };
                let Time = date.toLocaleString('en-IN', options)
                singleMessage['Time'] = Time
                singleMessage['UserName'] = element['UserName']
                console.log(singleMessage)
                let userData = JSON.parse(localStorage.getItem('userData'))
                const newList = userData
                .filter((item, index, self) => {
                  // Keep only the first occurrence of each BridgeID
                  return index === self.findIndex(t => t.ForumID === item['ForumID']);
                })
                .map(item => {
                  // Check if the BridgeID matches the condition
                  if (item.ForumID === bridgeData['ForumID']) {
                    // Replace the matching item with the new singleMessage
                    let updatedForumNotification = item
                    updatedForumNotification['ForumDescription'] = textMessage
                    updatedForumNotification['Counts'] = item['Counts'] + 1
                    return updatedForumNotification;
                  } else {
                    // Keep the other items unchanged
                    return item;
                  }
                });
                localStorage.setItem('userData',JSON.stringify(newList))
                let oldmessageList = JSON.parse(localStorage.getItem(bridgeData['ForumID']+"MessagesList"))
                const uniqueBridgeIDs = new Set();
                const filteredState = oldmessageList.filter(item => {
                  if (!uniqueBridgeIDs.has(item.BridgeID)) {
                    uniqueBridgeIDs.add(item.BridgeID);
                    return true; // Include the first occurrence
                  }
                  return false; // Exclude duplicates
                });
                let newMessageList = []
                if (!uniqueBridgeIDs.has(singleMessage.BridgeID)) {
                  newMessageList = [...filteredState , singleMessage]
                }else{
                  newMessageList = [...filteredState]
                }
                localStorage.setItem(bridgeData['ForumID']+"MessagesList",JSON.stringify(newMessageList))
              }
              else if(flowID === CommonForumFlow + "_NotifyUser"){
                let forumID = bridgeData["ForumID"]
                let bridgeID = bridgeData["BridgeID"]
                let data = bridgeData[flowID]
                let status = base64_decode(data[CommonForumFlow + '_NotifyUser_Main-3696a421-97d2-407d-add3-15a07edcfb10_2'])
                let RequestDatas = base64_decode(data[CommonForumFlow + '_NotifyUser_Main-3696a421-97d2-407d-add3-15a07edcfb10_4'])
                let type = base64_decode(data[CommonForumFlow + '_NotifyUser_Main-3696a421-97d2-407d-add3-15a07edcfb10_6'])
                let getUserItem = localStorage.getItem('userData')
                let userData = JSON.parse(getUserItem)
                let RequestData = RequestDatas
                data = RequestData
                let isCandidate = ""
                if(status === "Under Manual Review" || status === "Profinity Passed" || status === "Profinity Failed"){
                  RequestData = JSON.parse(RequestDatas)
                  data = RequestData['CompanyName']['CompanyName']
                  isCandidate = RequestData['isCandidate']
                } 
                let notify = {}
                notify['status'] = status
                notify['RequestData'] = data
                notify['type'] = type
                notify['BridgeID'] = bridgeID
                notify['isCandidate'] = isCandidate
                let notifyExists = userData.some(item => item.BridgeID === notify.BridgeID);
                let newList = []
                if (!notifyExists) {
                    newList = [notify, ...userData];
                    localStorage.setItem('userData', JSON.stringify(newList))
                    // setForumDetailsArr(newList)
                }
              }else if (bridgeData['TemplateID'] == RecruiterForumTemp) {
                // const bridgeData = dataJson['Bridge'];
                let notifications = {};
                if (flowID == RecruitedCandidateUserFlowID){
                  let allData = bridgeData;
                  let MediaID = bridgeData['MediaID'][0][RecCreateTempName+'_User_Main-d219b924-c3cd-437c-99b7-32c61d46c263_6']
                  let lastSentMediaid = JSON.parse(localStorage.getItem("lastSentMediaid"))
                  let Username = bridgeData['UserName']
                  let MobileNumber = bridgeData['MobileNumber']
                  let ForumnId = bridgeData['ForumID']
                  if(lastSentMediaid === null){
                    localStorage.setItem("lastSentMediaid",JSON.stringify([MediaID]))
                    bridgeSendingForCandidateVideoToCommonForum(allData,MediaID,ForumnId,Username,MobileNumber)
                  }else if(lastSentMediaid !== null && !lastSentMediaid.includes(MediaID)){
                    lastSentMediaid.push(MediaID)
                    localStorage.setItem("lastSentMediaid",JSON.stringify(lastSentMediaid))
                    bridgeSendingForCandidateVideoToCommonForum(allData,MediaID,ForumnId,Username,MobileNumber)
                  }
                  // DeleteBridgeForProfinity(bridgeData)
                }else{
                  let replyBridgeID =bridgeData['ReplyBridgeID']
                  if (bridgeData['ReplyBridgeID'] != "") {
                    // if (bridgeData['TemplateID'] == 'e896414f-114d-ceff-6a4f-5315c8ddd194') {
                      if (userDetails['MobileNumber'] == bridgeData['WhisperReceiverMobileNumber']) {
                        let jobForum = bridgeData['ForumID']
                        // let userData = JSON.parse(localStorage.getItem('userData'))
                        let receiverNotification = base64_decode(bridgeData[RecMessageFlow][RecCreateTempName+'_RecruiterMessage_Main-6b141153-d01e-4749-9ddb-5542358d0ba4_2']);
                        let userData = JSON.parse(localStorage.getItem('userData'))
                          for (let i = 0; i < userData.length; i++) {
                            let forumData = userData[i];
                            let forumID = forumData["ForumID"]
                            if(forumID === jobForum){
                              userData[i]['ForumDescription'] = receiverNotification
                              userData[i]['Counts'] = 1
                            }
                          }
                            localStorage.setItem('userData',JSON.stringify(userData))
                      }
                    // }
                  }
                }
              }else if (flowID === CommonForumFlow + "_Data2") {
                let data = dataJson["Bridge"][CommonForumFlow + "_Data2"]
                let jobSeeker = base64_decode(data[CommonForumFlow + '_Data2_Main-19ae7edb-d2ea-4a00-865a-711709c736b8_2'])
                if(jobSeeker === "Candidate Pay Status"){
                let jobSeekerPayment = base64_decode(data[CommonForumFlow + "_Data2_Main-19ae7edb-d2ea-4a00-865a-711709c736b8_4"])
                localStorage.setItem("candidatePayment", jobSeekerPayment)
                }
              }else if(flowID === IndexFlow){
                console.log("this should be latest data bridgedata",bridgeData)
                let data = bridgeData[IndexFlow]
                let indexFlows = IndexFlow + "_Main-383cd425-9234-41e1-9a12-4a76ba4718a4"
                let companyName = data[indexFlows+'_2']
                let Jd = data[indexFlows+'_4']
                let jType = data[indexFlows+'_6']
                let Duration = data[indexFlows+'_8']
                let ForumID = data[indexFlows+'_10']
                let mediaID = data[indexFlows+'_12']
                let bridgeId = bridgeData['BridgeID']
                try{
                  let encodedCompanyName = base64_decode(companyName)
                  companyName = utf8.decode(encodedCompanyName)
                  let encodedJd = base64_decode(Jd)
                  Jd = utf8.decode(encodedJd)
                } catch{
                  companyName = base64_decode(companyName)
                  Jd = base64_decode(Jd)
                }
                let json = {
                    "companyName": companyName,
                    "Jd": Jd,
                    "jType": base64_decode(jType),
                    "Duration":base64_decode(Duration),
                    "mediaID": base64_decode(mediaID),
                    "ForumID": base64_decode(ForumID),
                    "BridgeID":bridgeId
                };
                let decodedreport = json;
                localStorage.setItem("demovideo","false");
                insertVideo(json);
              }else if(flowID === removeMediaIDFromListflowName){
                let data = bridgeData[removeMediaIDFromListflowName]
                let mediaID = data[removeMediaIDFromListflowName + "_Main-e481eeda-4c4f-4ba4-a84d-7583e758138a_2"]
                mediaID = base64_decode(mediaID)
                removeVideo(mediaID)
              }
            }
            else if (keys[0] === 'UserProfileUpdated') {
              let userDetails1 = localStorage.getItem('userProfile');
              let decoded = base64_decode(userDetails1)
              let userDetails2 = JSON.parse(decoded);
              let profilepicture = dataJson['UserProfileUpdated']['UserProfilePicture']
              userDetails2['UserProfilePicture'] = profilepicture;
              localStorage.removeItem('userProfile')
              localStorage.setItem('userProfile', base64_encode(JSON.stringify(userDetails2)));

            }
          }
        }
      })
      .catch(error => {
        console.log(error);
      });
  }



  return (

    <>
    
     <Hook
        short={'FEED'}
      />
                
      <PullToRefresh onRefresh={loadOlderVideo}>
      <Modal open={showModal} className="loaderContainer2">
            <div className="location1">
                <div >
                  <p style={{fontWeight:'bold',marginBottom:'5px',textAlign:'center'}}>{objectNullandUndefined('269')}</p>
                  </div>
                  <div className="buttonsss">
                    <button className="close-button" onClick={close}>{objectNullandUndefined('16')}</button>
                    <button className="close-button" style={{textTransform: 'uppercase',}}onClick={OkToReload}>{objectNullandUndefined('196')}</button>
                  </div>
            </div>
          </Modal>
    <div className="container11">
      {/* <ToastContainer position="top-center" autoClose={2000} hideProgressBar/> */}
      <section ref={shortContainerRef} className="short-container">
      {uniqBy(videoData, 'mediaID').map((short, index) => (
          <Short
            shortContainerRef={shortContainerRef}
            short={short}
            // videoUrl = {short.videoUrl}
            key={index}
          />
        ))}
        {/* <CircularProgress  size={40} thickness={4} /> */}
        {/* <CircularProgressbar /> */}
      </section>
      <ToastContainer position="top-center" autoClose={3000}/>
      <div className="navigation-container1">
        <div
          onClick={() => {
            shortContainerRef.current.scrollTo(
              0,
              shortContainerRef.current.scrollTop - window.innerHeight
            );
          }}
          className="nav-up"
        >
          <ion-icon name="arrow-up-outline"></ion-icon>
        </div>
        <div
          onClick={() => {
            shortContainerRef.current.scrollTo(
              0,
              shortContainerRef.current.scrollTop + window.innerHeight
            );
          }}
          className="nav-down">
          <ion-icon name="arrow-down-outline"></ion-icon>
        </div>
      </div>
      </div>
      </PullToRefresh>
    </>
  );
}

export default ShortContainer;
