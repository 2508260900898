import './Hook.css';
import { CommonForumID, CAServerID,FSServerID, CommonForumFlow } from "./Dictionary/CommonStrings";
import { forumDetails } from '../services/LoginLogoutService';
import { decode as base64_decode, encode as base64_encode } from 'base-64';
import { useState, useEffect, useRef } from 'react';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import PolicyIcon from '@material-ui/icons/Policy';
import NotificationsIcon from '@material-ui/icons/Notifications';
import ArchiveIcon from '@mui/icons-material/Archive';
import PersonIcon from '@mui/icons-material/Person';
import { logOutFromSystem } from '../App';
import { useHistory } from 'react-router-dom';
import Drawer from '@material-ui/core/Drawer';
import MenuIcon from '@material-ui/icons/Menu';
import Dropdown from './User/Filter';
import Avatar from '@material-ui/core/Avatar';
import TranslatePopup, { objectNullandUndefined } from './Dictionary/Dictionary';
import TranslateIcon from '@mui/icons-material/Translate';
import Modal from '@material-ui/core/Modal';
import { Button } from '@mui/material';
import CircularProgress from '@material-ui/core/CircularProgress';
import { Card } from 'react-bootstrap';
import { Divider } from 'rsuite';
import { ListItem } from '@material-ui/core';
import StarsTwoToneIcon from '@mui/icons-material/StarsTwoTone';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import Multiselect from 'multiselect-react-dropdown';
import { ToastContainer, toast } from 'react-toastify';
import { saveLocationToUserProfile } from '../services/LoginLogoutService';
import { bridgeSendingByUser } from '../services/LoginLogoutService';
import NotificationsNoneOutlinedIcon from '@mui/icons-material/NotificationsNoneOutlined';
import uuid from 'react-uuid';
let selctedLanguageLocationList = {}
export default function Hook(short) {
  const history = useHistory();
  const myUuid = uuid();
  const [showMenu, setShowMenu] = useState(false);
  const [key, setKey] = useState('');
  const [openDrawer, setOpenDrawer] = useState(false);
  const [componentName, setComponentName] = useState('');
  const [activeComponent, setActiveComponent] = useState('');
  const [showFilter, setShowFilter] = useState(false);
  const [dropdownEnabled, setDropdownEnabled] = useState(true);
  const [showTranslatePopup, setShowTranslatePopup] = useState(false);
  const [logout, setLogout] = useState(false);
  const [loading, setLoading] = useState(false);
  const [locations, setLocationsCatogary] = useState([]); 
  const [showLocationFilter, setshowLocationFilter] = useState(false);
  const [selectedItem, setSelectedItem] = useState(localStorage.getItem('selectedItem') || '');
  const [selectedLocation, setselectedLocation] = useState();
  const [count, setCount] = useState('5');
  const [jobCategories, setJobCatogary] = useState([]);
  const [options, setJobObjCatogary] = useState([]);
  const jobLocation = useRef();
  const menuRef = useRef();

  // const options = [
  //   {id: "Software Engineer", label:objectNullandUndefined('24')},
  //   {id: "Human Resource Manager", label: objectNullandUndefined('27')},
  //   {id: "Quality Control Analyst", label:objectNullandUndefined('39')},
  //   { id: "Sales Representative", label: objectNullandUndefined('30') },
  //   { id: "Operations Manager", label: objectNullandUndefined('31') },
  //   { id: "Nurse", label: objectNullandUndefined('32') },
  //   { id: "Physical Therapist", label: objectNullandUndefined('33') },
  //   { id: "Delivery Partner", label: objectNullandUndefined('37') },
  //   { id: "Supply Chain Manager", label: objectNullandUndefined('38') },
  //   { id: "Domestic Worker", label: objectNullandUndefined('40') },
  //   { id: "Chef", label: objectNullandUndefined('41') },
  //   { id: "Construction Worker", label: objectNullandUndefined('42') },
  //   { id: "Agriculture Worker", label: objectNullandUndefined('43') },
  //   { id: "Garments Worker", label: objectNullandUndefined('44') },
  //   // {id: "Digital Marketing Intern", label:objectNullandUndefined('181')},
  //   // {id: "Content Creator", label: objectNullandUndefined('180')}
  //   // { id: "Testing Forum", label: "Testing Forum" }
  // ]
  const toggleDropdown = () => {
    setDropdownEnabled(!dropdownEnabled);
  };

  useEffect(() => {
    locationCall()
  }, []);

  const changeLocationList = async (LocationList) => {
    let selectedLocationList = [];
    for (let index = 0; index < LocationList.length; index++) {
      let element = LocationList[index];
      let item = objectNullandUndefined(element);
      if(item == undefined || item == null){
        item = element
      }
      selctedLanguageLocationList[item] = element
      selectedLocationList.push(item)
    }
    return selectedLocationList
  }
  const fetchLocationAndCatergoryFromServer = async () => {
    let userDetails = localStorage.getItem('userProfile');
    let decoded = base64_decode(userDetails)
    userDetails = JSON.parse(decoded);
    let tempJson = {
      "MACAddress": userDetails['MACAddress'],
      "ForumID": CommonForumID,
      "SessionID": userDetails['SessionID'],
      "ServerID": CAServerID
    }
    let flowsCommon = await forumDetails(tempJson)
    if (flowsCommon["ErrorCode"] === 1074) {
      toast.error((objectNullandUndefined('221')));
      setTimeout(() => {
        localStorage.clear();
        sessionStorage.clear();
        history.push('/');
        window.location.reload(false);
      }, 3000)
    }
    let commonFlows = flowsCommon['ErrorMessage']['ForumDataArray']
    if (commonFlows.length > 0) {
      for (let i = 0; i < commonFlows.length; i++) {
        let recForumData = commonFlows[i]
        let forumKey = Object.keys(recForumData)
        if (forumKey[0] === CommonForumID) {
          let commonUserFlows = recForumData[CommonForumID]['NewForumJsonData']
          let parsedCommonFlow = commonUserFlows["UserFlow"]
          let commonTempName = commonUserFlows["Template"]

          let reportFlowID = parsedCommonFlow[commonTempName + '_Locations']
          let formats = reportFlowID["Formats"][0]
          let locationFlows = parsedCommonFlow[formats]
          let key = Object.keys(locationFlows)
          let locationKeys = locationFlows[key][2]
          let locations1 = locationKeys["Options"]
          let locationList = Object.keys(locations1)
          let seletedLanaguageLoctionList = await changeLocationList(locationList)
          setLocationsCatogary(seletedLanaguageLoctionList)
          localStorage.setItem("locationList",JSON.stringify(locationList))

          let reportFlowID_JobCategories = parsedCommonFlow[commonTempName + '_JobCategories']
          let formats_JobCategories = reportFlowID_JobCategories["Formats"][0]
          let Flows_JobCategories = parsedCommonFlow[formats_JobCategories]
          let locationKeys_JobCategories = Flows_JobCategories['row1'][2]
          let JobCategories = locationKeys_JobCategories["Options"]
          let JobCategoriesList = Object.keys(JobCategories)
          console.log("job lint in filter hook",JobCategoriesList)
          setJobCatogary(JobCategoriesList)
          let val = await updateJobsObject(JobCategoriesList)
          setJobObjCatogary(val)
          localStorage.setItem("JobCategoriesList",JSON.stringify(val))
          // locations.push(locationList)
          let selectedLocation = localStorage.getItem('selectedLocation')
          let selectedLocation1 = objectNullandUndefined(selectedLocation)
          if (selectedLocation1 == undefined || selectedLocation1 == null){
            selectedLocation1 = selectedLocation
          }
          setselectedLocation(selectedLocation1)
        }
      }
    }
  }

  const locationCall = async () => {
    // let commonUserFlows = await AsyncStorage.getItem('commonUserFlows');
    // commonUserFlows = JSON.parse(commonUserFlows)
    // // let JobCategoriesList = [];
    // if(commonUserFlows != null){
    //       let parsedCommonFlow = commonUserFlows["UserFlow"]
    //       let commonTempName = commonUserFlows["Template"]

    //       let reportFlowID_JobCategories = parsedCommonFlow[commonTempName + '_JobCategories']
    //       let formats_JobCategories = reportFlowID_JobCategories["Formats"][0]
    //       let Flows_JobCategories = parsedCommonFlow[formats_JobCategories]
    //       let key_JobCategories = Object.keys(Flows_JobCategories)
    //       let locationKeys_JobCategories = Flows_JobCategories[key_JobCategories][2]
    //       let JobCategories = locationKeys_JobCategories["Options"]
    //       jobCategories = Object.keys(JobCategories)
    //       console.log(jobCategories)
    //       jobs = updateJobsObject(jobCategories)
    //       console.log("jobs",jobs)
    // }
    // else{
    let storedLocationListString = localStorage.getItem("locationList")
    let storedLocationList = JSON.parse(storedLocationListString)
    let storedCategoryListString = localStorage.getItem("JobCategoriesList")
    let storedCategoryList = JSON.parse(storedCategoryListString)
    if(storedLocationList != null && storedCategoryList != null){
      let seletedLanaguageLoctionList = []
      seletedLanaguageLoctionList = await changeLocationList(storedLocationList)
      setLocationsCatogary(seletedLanaguageLoctionList)
      setJobObjCatogary(storedCategoryList)
      let selectedLocation = localStorage.getItem('selectedLocation')
      let selectedLocation1 = objectNullandUndefined(selectedLocation)
      if (selectedLocation1 == undefined || selectedLocation1 == null){
        selectedLocation1 = selectedLocation
      }
      setselectedLocation(selectedLocation1)
    }else{
      fetchLocationAndCatergoryFromServer()
    }
  }

  async function updateJobsObject(JobCategoriesList){
    let newJobsIndexObj = []
    for (let i = 0; i < JobCategoriesList.length; i++) {
      let obj = {}
      obj['id'] = JobCategoriesList[i];
      obj['label'] = objectNullandUndefined(String(JobCategoriesList[i]))
      if(obj['label'] == undefined){
        obj['label'] = JobCategoriesList[i];
      }
      newJobsIndexObj.push(obj)
    }
   return newJobsIndexObj;
  }

  useEffect(() => {
    let short1 = short.short;
    if (short1 === "RECRUIT ALERT") {
      setCount('0');
      const recruiterName = objectNullandUndefined('49');
      setSelectedItem(recruiterName);
      localStorage.setItem('selectedItem', recruiterName);
    }
    if (short1 === "NOTIFICATION") {
      setCount('0');
      const recruiterName = objectNullandUndefined('5');
      setSelectedItem(recruiterName);
      localStorage.setItem('selectedItem', recruiterName);
    }
    if (short1 === "ACCOUNT") {
      const recruiterName = objectNullandUndefined('17');
      setSelectedItem(recruiterName);
      localStorage.setItem('selectedItem', recruiterName);
    }
    if (short1 === "RECRUITMENT") {
      const recruiterName = objectNullandUndefined('50');
      setSelectedItem(recruiterName);
      localStorage.setItem('selectedItem', recruiterName);
    }
    if (short1 === "FEED") {
      const recruiterName = objectNullandUndefined('2');
      setSelectedItem(recruiterName);
      localStorage.setItem('selectedItem', recruiterName);
    }
    if (short1 === "INTERESTS"){
      const recruiterName = objectNullandUndefined('233');
      setSelectedItem(recruiterName);
      localStorage.setItem('selectedItem', recruiterName);
    }
    if (short1 === "VIDEO") {
      const recruiterName = "";
      setSelectedItem(recruiterName);
      localStorage.setItem('selectedItem', recruiterName);
    }
    
 
  }, []);


  async function showRecruiter() {

    history.push('/Recruiter');
    setActiveComponent('RECRUITMENT');
  }

  async function showNotification() {
    history.push('/Notification');
  }

  async function showRecNotification() {
    setCount('0');
    history.push('/Notification');
    setActiveComponent('NOTIFICATION');
  }

  async function showshortContainer() {
    const recruiterName = objectNullandUndefined('2');
    setSelectedItem(recruiterName);
    localStorage.setItem('selectedItem', recruiterName);
    setShowFilter(true);
    history.push('/ShortContainer');
  }

  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        setShowMenu(false);
      }
    };
    const handleEscapeKey = (event) => {
      if (event.keyCode === 27) {
        setShowMenu(false);
      }
    };
    document.addEventListener('mousedown', handleOutsideClick);
    document.addEventListener('keydown', handleEscapeKey);
    return () => {
      document.removeEventListener('mousedown', handleOutsideClick);
      document.removeEventListener('keydown', handleEscapeKey);
    };
  }, []);

  const ToAccount = () => {
    history.push('/Account');
    window.location.reload();
    setActiveComponent('ACCOUNT');
  };
  const toSavedIntrest = () => {
    history.push('/PriorityList');
    setActiveComponent('PriorityList');
  };
  const ToArchive = () => {
    const recruiterName = objectNullandUndefined('51');
    setSelectedItem(recruiterName);
    localStorage.setItem('selectedItem', recruiterName);
    history.push('/ArchiveVideo');
    setActiveComponent('ARCHIVE');
  };

  const HandleLogOut = async () => {
    setLoading(true);
    let res = await logOutFromSystem();
    localStorage.clear();
    sessionStorage.clear();
    history.push('/');
    window.location.reload(false);
    setLoading(false);

  };


    const onSelect = (selectedItem) =>{
      setselectedLocation(selectedItem[0])
    }

    const close = () =>{
      let selectedLocation = localStorage.getItem('selectedLocation')
      let selectedLocation1 = objectNullandUndefined(selectedLocation)
      if (selectedLocation1 == undefined || selectedLocation1 == null){
        selectedLocation1 = selectedLocation
      }
      setselectedLocation(selectedLocation1)
      setshowLocationFilter(false)
    }
    async function bridgeSending(selectedJobCategoryList,selectedLocation) {
      let commonForumTemp = localStorage.getItem("commonUserFlows")
      let parsedFlow = JSON.parse(commonForumTemp)
      let parsedCommonFlow = parsedFlow["UserFlow"]
      let commonTempName = parsedFlow["Template"]
      let filterFlowID = parsedCommonFlow[commonTempName + '_CandidateFilter']
    try {
      let uuid = myUuid
    
      let userDetails = localStorage.getItem('userProfile');
      userDetails = base64_decode(userDetails);
      userDetails = JSON.parse(userDetails);
      let sessionID = userDetails.SessionID;
      let macAddress = userDetails.MACAddress;
      let flowName = commonTempName + '_CandidateFilter';;
      let fID = filterFlowID['FID']
      let tempJson = {
        ForumID: CommonForumID,
        SessionID: sessionID,
        MACAddress: macAddress,
        Time: '6516516156161',
        ScheduledDateTime: 'now',
        ScheduledBoolean: 0,
        FlowID: flowName,
        EnableChat: 0,
        FlowType: 'Custom',
        BridgeForward: 0,
        TemplateID: parsedFlow["TemplateID"],
        TextCount: 6,
        ImageCount: 0,
        InvoiceID: '',
        DocumentCount: 0,
        User: true,
        VideoCount: 0,
        ServerID: FSServerID,
        ReplyBridgeID: '',
        HiddenFlow: false,
        BilledForum: '',
        TempBridgeId: uuid,
        FID: fID,
        SentTo: '0',
      };
      
      let data1 = {
        'FilterType': (selectedJobCategoryList),
        'CityType': (selectedLocation)
      }
  
      let jsonData = {
        [CommonForumFlow + '_CandidateFilter_Main-88f212c1-b826-490c-a351-ff093f97b87b_2']: base64_encode(JSON.stringify(data1)),
      };
      tempJson[flowName] = jsonData;
      const data = new FormData();
      data.append('Data', JSON.stringify(tempJson));
      let bridgeData = await bridgeSendingByUser(data);
      if (bridgeData["ErrorCode"] === 1074) {
        toast.error((objectNullandUndefined('221')));
        setTimeout(() => {
          localStorage.clear();
          sessionStorage.clear();
          history.push('/');
          window.location.reload(false);
        }, 3000)
      }
    } catch (err) {
      return;
    }
    }

    const callChangeLocation = async() =>{
      let CandidateIntrestListString = localStorage.getItem('CandidateIntres')
      if(CandidateIntrestListString != null || CandidateIntrestListString != undefined){
        let CandidateIntrestList = JSON.parse(CandidateIntrestListString)
        let selectedLocation1 = selctedLanguageLocationList[selectedLocation]
        await saveLocationToUserProfile(selectedLocation1)
        localStorage.setItem('selectedLocation', selectedLocation1);
        if(CandidateIntrestListString != "[]") {
          bridgeSending(CandidateIntrestList,selectedLocation1);
          toast.success(objectNullandUndefined('69'),{id: 'custom-toast-id',});
          setshowLocationFilter(false)
        }else{
          let data = objectNullandUndefined('263')
          if (data == undefined){
            data = "Update your interest"
          }
          alert(data);
          // toast.error((objectNullandUndefined('263')));
          setshowLocationFilter(false)
        }
      }
      
      history.push("/ShortContainer");  
    }
  const retrieve = () => {
    const data = localStorage.getItem('recruitValue');
    if (data === 'true') {
      setKey(true);
    } else if (data === 'false') {
      setKey(false);
    }


  };

  useEffect(() => {
    retrieve();
  }, []);

  const toggleDrawer = () => {
    setOpenDrawer(!openDrawer);
  };

  const toggleTranslatePopup = () => {
    setShowTranslatePopup(!showTranslatePopup);
  };

  const renderDropdown = () => {
    let short1 = short.short;
    if (short1 != "ACCOUNT" && short1 != "NOTIFICATION" && short1 != "RECRUIT ALERT" && short1 != "VIDEO" && short1 != "INTERESTS") {
      return <Dropdown options={options} />;
    }



  };

  if (key === true) {
    return (
      <>
        {logout === true && (
          <div className="transparent-overlay1">
            <Card className="popup0">
              <div style={{ textAlign: 'center', paddingTop: '20px' }}>
                <p style={{ fontSize: 'large', color: 'black', overflow: "hidden", padding: '10px 20px 10px 20px' }}>
                  {objectNullandUndefined('21')}
                </p>
              </div>
              <div className='Loginbutton'>
                <Button
                  variant="text"
                  onClick={() => setLogout(false)}
                  style={{ fontSize: '16px', width: '30%', textTransform: 'none' }}
                >
                  {objectNullandUndefined('16')}
                </Button>
                <Button
                  variant="text"
                  onClick={() => HandleLogOut()}
                  style={{ fontSize: '16px', width: '30%', textTransform: 'none' }}
                >
                  {objectNullandUndefined('13')}
                </Button>
              </div>
            </Card>
          </div>
        )}
        <div>
          <nav className="nav1">
            <div className="left-menu">
              <div>
                <p className="menu-button" onClick={toggleDrawer}>
                  <MenuIcon style={{ color: 'black', fontSize: '2.5rem' }} />
                </p>
              </div>
            </div>
            <div className="nav-bar-title">{componentName}</div>
            <div className="color1">
              <Drawer
                anchor="left"
                open={openDrawer}
                onClose={toggleDrawer}
                classes={{
                  paper: 'drawer-paper',
                }}
                BackdropProps={{
                  invisible: true,
                }}
              >
                <div className="drawer-wrapper">
                  <div className="drawer-content">
                    <ListItem
                      style={{
                        gap: '10px',
                        justifyContent: 'flex-start'
                      }}
                      className={`drawer-item1`}
                      onClick={() => {
                        showRecruiter();
                        toggleDrawer();
                      }}
                    >
                      <PersonIcon style={{ color: 'black' }} /> {objectNullandUndefined('50')}
                    </ListItem>

                    <ListItem
                      style={{
                        gap: '10px',
                        justifyContent: 'flex-start'
                      }}
                      className={`drawer-item1`}
                      onClick={() => {
                        showNotification();
                        toggleDrawer();
                      }}
                    >
                      <NotificationsIcon style={{ color: 'black' }} /> {objectNullandUndefined('49')}
                    </ListItem>

                    <ListItem
                      style={{
                        gap: '10px',
                        justifyContent: 'flex-start'
                      }}
                      className={`drawer-item1`}
                      onClick={toggleTranslatePopup}
                    >
                      <TranslateIcon style={{ color: 'black' }} /> {objectNullandUndefined('116')}
                    </ListItem>
                    {showTranslatePopup && <TranslatePopup onClose={toggleTranslatePopup} />}

                    <ListItem
                      style={{
                        gap: '10px',
                        justifyContent: 'flex-start'
                      }}
                      className={`drawer-item1`}
                      onClick={() => {
                        ToArchive();
                        toggleDrawer();
                      }}
                    >
                      <ArchiveIcon style={{ color: 'black' }} /> {objectNullandUndefined('51')}
                    </ListItem>

                    <ListItem
                      style={{
                        gap: '10px',
                        justifyContent: 'flex-start'
                      }}
                      className={'drawer-item1'}
                      onClick={() => {
                        ToAccount();
                        toggleDrawer();
                      }}
                    >
                      <AccountCircleIcon style={{ color: 'black' }} /> {objectNullandUndefined('17')}
                    </ListItem>


                    <a
                      href="https://prescreener.in/#/policy"
                      target="_blank"
                      rel="noopener noreferrer"
                      style={{ textDecoration: 'none', color: 'inherit' }}
                    >
                      <ListItem
                        style={{
                          gap: '10px',
                          justifyContent: 'flex-start'
                        }} className="drawer-item1">
                        <PolicyIcon style={{ color: 'black' }} /> {objectNullandUndefined('18')}
                      </ListItem>
                    </a>


                    <ListItem
                      style={{
                        gap: '10px',
                        justifyContent: 'flex-start'
                      }}
                      className="drawer-item1"
                      onClick={() => {
                        setLogout(true);
                        toggleDrawer();
                      }}
                    >
                      <ExitToAppIcon style={{ color: 'black' }} /> {objectNullandUndefined('13')}
                    </ListItem>

                  </div>
                </div>
              </Drawer>
            </div>
            <div style={{ position: "absolute", right: "20px" }}>
            <a href="https://prescreener.in/#/" target="_blank" rel="noopener noreferrer" style={{ color: 'inherit' }}>
                    <Avatar sx={{ width: 56, height: 56 }} style={{ marginRight: "0" }} alt="" src="/logo.png" />
                    </a>
            </div>

            <div style={{ fontWeight: 'bold', fontSize: '22px', color: 'black' }}>
              {selectedItem}
            </div>
            <div className="count"
              onClick={() => {
                showRecNotification();
              }}>
              {/* <NotificationsIcon  className='bell' style={{ marginRight: "70px", fontSize: "35px", marginTop: '5px',color:'white' }} />   */}
            </div>

          </nav>

          <nav className="nav2">
            <h6 style={{ backgroundColor: 'lightgrey', width: '100%', textAlign: 'center' }}>
              <a href="https://cosmitude.com" target="_blank" rel="noopener noreferrer" style={{ color: 'inherit' }}>
                By Cosmitude Softwares Private Limited
              </a>
            </h6>
          </nav>


        </div>
      </>
    );

  }
  else if (key === false) {
    return (
      <>
        {logout === true && (
          <div className="transparent-overlay1">
            <Card className="popup0">
              <div style={{ textAlign: 'center', paddingTop: '20px' }}>
                <p style={{ fontSize: 'large', color: 'black', overflow: "hidden", padding: '10px 20px 10px 20px' }}>
                  {objectNullandUndefined('21')}
                </p>
              </div>
              <div className='Loginbutton'>
                <Button
                  variant="text"
                  onClick={() => setLogout(false)}
                  style={{ fontSize: '16px', width: '30%', textTransform: 'none' }}
                >
                  {objectNullandUndefined('16')}
                </Button>
                <Button
                  variant="text"
                  onClick={() => HandleLogOut()}
                  style={{ fontSize: '16px', width: '30%', textTransform: 'none' }}
                >
                  {objectNullandUndefined('13')}
                </Button>
              </div>
            </Card>
          </div>
        )}
        <div>
          <nav className="nav">
            <div className="left-menu">
              <div>
                <p className="menu-button" onClick={toggleDrawer}>
                  <MenuIcon style={{ color: 'black', fontSize: '2.5rem', marginLeft: '20px' }} />
                </p>
              </div>
              <div>
                <div>{renderDropdown()}</div>
              </div>
            </div>
            <div className="nav-bar-title1">{componentName}</div>
            <div className="color1">
              <Drawer
                anchor="left"
                open={openDrawer}
                onClose={toggleDrawer}
                classes={{
                  paper: 'drawer-paper',
                }}
                BackdropProps={{
                  invisible: true,
                }}
              >
                <div className="drawer-wrapper">
                  <div className="drawer-content">
                    <ListItem
                      style={{
                        gap: '10px',
                        justifyContent: 'flex-start'
                      }}
                      className={'drawer-item1'}
                      onClick={() => {
                        showshortContainer()
                        toggleDrawer();
                      }}
                    >
                      <PersonIcon style={{ color: 'black' }} />  {objectNullandUndefined('1')}
                    </ListItem>

                    <ListItem
                      style={{
                        gap: '10px',
                        justifyContent: 'flex-start'
                      }}
                      className={'drawer-item1'}
                      onClick={() => {
                        showRecNotification();
                        toggleDrawer();
                        setDropdownEnabled(false);
                      }}
                    >
                      <NotificationsIcon style={{ color: 'black' }} />  {objectNullandUndefined('5')}
                    </ListItem>

                    <ListItem
                      style={{
                        gap: '10px',
                        justifyContent: 'flex-start'
                      }}
                      className={`drawer-item1 ${activeComponent === 'Language' ? 'active' : ''}`}
                      onClick={toggleTranslatePopup}
                    >
                      <TranslateIcon style={{ color: 'black' }} /> {objectNullandUndefined('116')}
                    </ListItem>
                    {showTranslatePopup && <TranslatePopup onClose={toggleTranslatePopup} />}


                    <ListItem
                      style={{
                        gap: '10px',
                        justifyContent: 'flex-start'
                      }}
                      className={'drawer-item1'}
                      onClick={() => {
                        ToAccount();
                        toggleDrawer();
                        setDropdownEnabled(false);
                      }}
                    >
                      <AccountCircleIcon style={{ color: 'black' }} /> {objectNullandUndefined('17')}
                    </ListItem>

                    <a
                      href="https://prescreener.in/#/policy"
                      target="_blank"
                      rel="noopener noreferrer"
                      style={{ textDecoration: 'none', color: 'inherit' }}
                    >
                      <ListItem className="drawer-item1" style={{
                        gap: '10px',
                        justifyContent: 'flex-start'
                      }}>
                        <PolicyIcon style={{ color: 'black' }} /> {objectNullandUndefined('18')}
                      </ListItem>
                    </a>

                    <ListItem
                      style={{
                        gap: '10px',
                        justifyContent: 'flex-start'
                      }}
                      className="drawer-item1"
                      onClick={() => {
                        // setLogout(true)
                        toSavedIntrest()
                        toggleDrawer();
                      }}
                    >
                      <StarsTwoToneIcon style={{ color: 'black' }} /> {objectNullandUndefined('237')}
                    </ListItem>

                    <ListItem
                      style={{
                        gap: '10px',
                        justifyContent: 'flex-start'
                      }}
                      className="drawer-item1"
                      onClick={() => {
                        fetchLocationAndCatergoryFromServer()
                        setshowLocationFilter(true)
                        toggleDrawer();
                      }}
                    >
                      <LocationOnIcon style={{ color: 'black' }} /> {objectNullandUndefined('238')}
                    </ListItem>

                    <ListItem
                      style={{
                        gap: '10px',
                        justifyContent: 'flex-start'
                      }}
                      className="drawer-item1"
                      onClick={() => {
                        setLogout(true)
                        toggleDrawer();
                      }}
                    >
                      <ExitToAppIcon style={{ color: 'black' }} /> {objectNullandUndefined('13')}
                    </ListItem>

                  </div>
                </div>
              </Drawer>
            </div>


            <div style={{ position: "absolute", right: "20px" }}>
            <a href="https://prescreener.in/#/" target="_blank" rel="noopener noreferrer" style={{ color: 'inherit' }}>
                    <Avatar sx={{ width: 56, height: 56 }} style={{ marginRight: "0" }} alt="" src="/logo.png" />
                    </a>
            </div>

            <div style={{ fontWeight: 'bold', fontSize: '22px', color: 'black' }}>
              {selectedItem}
            </div>



            <div className="count"
              onClick={() => {

                showRecNotification();

              }}>
              {/* <NotificationsIcon className='bell' style={{ marginRight: "70px", fontSize: "35px", marginTop: '5px',color:'white' }} />  */}

                {/* <span class="badge badge-light" style={{ borderRadius: '50%', fontSize: "10px",backgroundColor:'red' }}>.</span> */}
           
            </div>





          </nav>
          <nav className="nav2">
            <h6 style={{ backgroundColor: 'lightgrey', width: '100%', textAlign: 'center' }}>
              <a href="https://cosmitude.com" target="_blank" rel="noopener noreferrer" style={{ color: 'inherit' }}>
                By Cosmitude Softwares Private Limited
              </a>
            </h6>
          </nav>
          <Modal open={loading} className="loaderContainer2">
            <div>
              <CircularProgress className="loader2" size={40} thickness={4} />
            </div>
          </Modal>


          <Modal open={showLocationFilter} className="loaderContainer2">
            <div className="location1">
                <div >
                  <p style={{fontWeight:'bold',marginBottom:'5px'}}>{objectNullandUndefined('53')}</p>
                  <Multiselect
                    placeholder ={objectNullandUndefined('93')}
                    id="jobLocation"
                    ref={jobLocation}
                    value={[selectedLocation]}
                    displayValue="key"
                    isObject={false}
                    onSelect={onSelect}
                    showCheckbox = {false}
                    options={locations}
                    loading = {false}
                    singleSelect
                    label="Job Category"
                    selectionLimit={1}
                    showArrow = {true}
                    // emptyRecordMsg = {objectNullandUndefined('194')}
                    emptyRecordMsg = {objectNullandUndefined('254')}
                    // selectedValues={[localStorage.getItem('selectedLocation')]}
                    selectedValues={[selectedLocation]}
                  />
                  </div>
                  <div className="buttonsss">
                    <button className="close-button" onClick={close}>{objectNullandUndefined('11')}</button>
                    <button className="close-button" style={{textTransform: 'uppercase',}}onClick={callChangeLocation}>{objectNullandUndefined('48')}</button>
                  </div>
            </div>
          </Modal>

        </div>
        <Divider />
        {/* <ToastContainer position="top-center" autoClose={1000} hideProgressBar /> */}
      </>
    );
  }

}




