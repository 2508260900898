import React, { useRef, useState } from "react";
import ArchiveShort from "./ArchiveShort";
import { makeStyles } from '@material-ui/core/styles';
import { objectNullandUndefined } from "../Dictionary/Dictionary";
import { useHistory } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './Archivecontainer.css'


function ArchiveContainer(props) {
   const history = useHistory();
   const [videos, setVideos] = useState(props.videos); 

  const shortContainerRef = useRef();
  const classes = useStyles();
  const handleRemoveVideo = (videoIndex) => {
    // Create a new array without the removed video
    const newVideos = videos.filter((_, index) => index !== videoIndex);
    if(newVideos.length != 0){
      setVideos(newVideos);
      }else{
      //  toast(objectNullandUndefined('121'))
        setTimeout(()=>{
          history.push('/Notification');
        },5000)
      }
  };
  // if(props.companyName == null)
  // {
  //   return (
  //     <div className={classes.roo}>
  //       <p className={classes.text}>
  //         {objectNullandUndefined('122')}
  //       </p>
  //     </div>
  //   );

  // }
  // else{
    return (
      <>
        <section ref={shortContainerRef} className="short-container111">
          {videos.map((short, index) => (
            <ArchiveShort
              shortContainerRef={shortContainerRef}
              short={short}
              key={short.mediaID}
              onRemove={() => handleRemoveVideo(index)}
            />
          ))}
        </section>
        <div className="arrow">
  
        <div className="navigation-container">
          <div
            onClick={() => {
              shortContainerRef.current.scrollTo(
                0,
                shortContainerRef.current.scrollTop - window.innerHeight
              );
            }}
            className="nav-up"
          >
            <ion-icon name="arrow-up-outline"></ion-icon>
          </div>
          <div
            onClick={() => {
              shortContainerRef.current.scrollTo(
                0,
                shortContainerRef.current.scrollTop + window.innerHeight
              );
            }}
            className="nav-down">
            <ion-icon name="arrow-down-outline"></ion-icon>
          </div>
        </div>
        </div>
      </>
    );

  }

 

const useStyles = makeStyles((theme) => ({
  roo: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    minHeight: '93vh',
    width:'100%',
    backgroundColor: '#333',
  },
  text:{
    color:'white',
    fontSize:36
  }
}));

export default ArchiveContainer;
