import "./App.css";
import React from 'react'; 
import { HashRouter as Router, Route, Switch } from 'react-router-dom';
import Login from "./components/Login/Login";
import Account from "./components/Settings/Account";
import Hook from './components/Hook'
import Video from "./components/Admin/Video"
import ArchiveVideo from "./components/Archive/ArchiveVideo";
import { logOut } from "./services/LoginLogoutService";
import { useHistory } from 'react-router-dom';
import { decode as base64_decode } from 'base-64';
import User from "./components/User/User";
import Recruiter from "./components/Recruiter/RecruitmentVideoUpload";
import { Filter } from "@material-ui/icons";
import Notification from "./components/Notification/Notification";
import Short from "./components/Short";
import ShortContainer from "./components/ShortContainer";
import Candidatevideo from "./components/Notification/Candidatevideo";
import JobLocationFilter from "./components/JobLocationfilter/JobLocationFilter";
import { LLServerID,loginServerIP } from "./components/Dictionary/CommonStrings";
import PushNotification from "./components/PushNotification";
import { Token } from "@mui/icons-material";
import { useState,useEffect } from "react";
import { NotificationComponent,requestForToken } from "./Firebase";
import PriorityList from "./components/Settings/PriorityList";
import RecruitContainer from "./components/Recruiter/RecruitContainer";




export const logOutFromSystem =  async ()=>{  
  let userDetails = localStorage.getItem('userProfile');
  let decoded = base64_decode(userDetails);
  let decode = JSON.parse(decoded)
  let logoutJson ={
    'MACAddress': decode['MACAddress'],
    'ServerID' : LLServerID,
    "SessionID": decode['SessionID']
  }
  let res = await fetch(loginServerIP + 'logOutMachine', {
    method: 'POST',
    headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
    },
    body: JSON.stringify(logoutJson)
})
    .then(response => {
        return response.json();
    });
    return res;
}

 



export default function App() {
  const [show, setShow] = useState(false);
  const [notification, setNotification] = useState({title: '', body: ''});

  const [isTokenFound, setTokenFound] = useState(false);
  requestForToken(setTokenFound);
 

  // onMessageListener().then(payload => {
  //   setShow(true);
  //   setNotification({title: payload.notification.title, body: payload.notification.body})
  //   console.log(payload);
  // }).catch(err => console.log('failed: ', err));
    return (
   
      <Router>
        <Switch>
        <Route path="/" exact component={Login} />
        {/* <Route path="/" component={PushNotification}/> */}
          <Route path="/Login"  component={Login} />
          <Route path="/JobLocationFilter" component={JobLocationFilter} />
          <Route path="/Notification" component={Notification}/>
          <Route path="/Account" component={Account} />
          <Route path="/Recruiter" component={Recruiter}/>
          <Route path="/ShortContainer" component={ShortContainer}/>
          <Route path="/Shorts" component={Short}/>
          <Route path="/Candidatevideo" component={Candidatevideo}/>
          <Route path="/PriorityList" component={PriorityList}/>
          <Route path="/RecruitContainer" component={RecruitContainer}/>
          


          <Route>
            <Hook />
          <Route path="/Video" component={Video} />
          <Route path="/ArchiveVideo" component={ArchiveVideo} />
          <Route path="/User" component={User}/>
          </Route>
        </Switch>
        </Router>
  );
  };


