// local test Server.............
// export const caServerIP = 'http://192.168.0.105:8050/';
// export const loginServerIP = 'http://192.168.0.105:8084/';
// export const templateServerIP = 'http://192.168.0.105:8082/';
// export const forumServerIP = 'http://192.168.0.105:8086/';
// // export const caServerIP = 'http://192.168.0.120:8050/';
// // export const loginServerIP = 'http://192.168.0.120:8084/';
// // export const templateServerIP = 'http://192.168.0.120:8082/';
// // export const forumServerIP = 'http://192.168.0.120:8086/';
// export const PreScreenerIndexTemplateName = 'PreScreenerIndex1'
// export const CAServerID = "3ded7ee1-b1ce-0d98-d08d-a623b456d5c4"
// export const FSServerID = "8f642690-ca50-78d1-7c83-f47bcff414db"
// export const LLServerID = "12d3239b-49b5-0ccb-8d71-56773bc9ed3a"
// export const TSServerID = "a457aa53-c41e-0802-a684-3523cc02e245"
// export const MacAddress = 'Bridge-Web';
// export const RecMessageFlowID = '304bc6af-03a6-eb6d-2af1-82901a9a2458';
// export const RecruitCreateFlowID = 'e892d536-8926-0e4d-7217-368bb0664924';
// export const RecUserFlowID = 'f945924a-31d1-cc25-c626-f493aaa9d04c';
// export const CommonForumRecCreate = 'PreScreenerTemplate_RecruitmentCreate';
// export const CommonForumRecCreateID ='c448a9fa-d674-9220-a14a-a3e7b071e2e7';
// export const CommonForumFlow = 'PreScreenerTemplate';
// export const CandidateFilterFlow = 'PreScreenerTemplate_CandidateFilter';
// export const CandidateFilterFlowID = '1ca4b9e9-8073-b13a-1c53-af94302923b2';
// export const ReportContent = 'PreScreenerTemplate_ReportContent';
// export const ReportContentID = 'ead2d842-050b-7a2b-f34b-135f0fc35076';
// export const ArchiveTempName = 'ArchiveTemp'
// export const ArchiveTempFlow = 'ArchiveTemp_ArchiveVideo';
// export const ArchiveTempFlowID = '57dcb8b3-d2e3-6ff9-d465-90cdbf706840';
// export const ArchiveForumReport = "ArchiveTemp_ArchiveVideo_57dcb8b3-d2e3-6ff9-d465-90cdbf706840_Admin";
// export const RecruitDeleteFlow = 'PreScreenerTemplate_DeleteRecruitment';
// export const RecruitDeleteFlowID = 'ff8f4131-869a-3313-8841-842582444fcd';
// export const FilterResponse = 'PreScreenerTemplate_FilterResponse'
// export const IndexFlow = PreScreenerIndexTemplateName+"_Index"
// export const RecruitedCandidateTempFlowID = '084fdeb9-ab16-2ef8-b7e8-c49d41641132';
// export const removeMediaIDFromListflowName = 'PreScreenerTemplate_removeMediaFromList';
// export const DemoUser1MediaID = '657853168688fc935ccdedec'
// export const DemoUser2MediaID = '657852918688fc935ccde634'
// export const DemoUser3MediaID = '657852c48688fc935ccde8bd'
// export const DemoRec1MediaID = '657853798688fc935ccdf34e'
// export const DemoRec2MediaID = '657853038688fc935ccdec36'
// export const requestChange = 'PreScreenerTemplate_RequestChange'
// export const CommonForumID = '3fcab2b6-6cfe-4563-988d-ac25ade75749'; //changed
// export const CommonForumTemp = 'b74d0ccb-ffe2-5541-dd2e-1478fef4e9cd';  //changed
// export const ArchiveTemp = '3f7687e5-b11c-075a-29e2-2eb91996d620'; //changed
// export const IndexTemplateID = "24f834e7-ce31-282d-7f84-a9c785eaf4ff"; //changed
// export const restoreBotPhoneNumber = '274343477f0968b52541be999a1192ac2adf6c' //changed
// export const RecruiterForumTemp = "57d2157c-079b-6e6b-90e8-96399a95a16b";  //changed
// export const RecruitedCandidateTemp = '57d2157c-079b-6e6b-90e8-96399a95a16b'; //changed
// export const RecMessageFlow = 'PreScreenerRec111_RecruiterMessage';
// export const RecCreateTempName = 'PreScreenerRec111'
// export const RecruitCreateFlow = 'PreScreenerRec111_Recruiter';
// export const RecruitFlowReport = "PreScreenerRec111_Recruiter_e892d536-8926-0e4d-7217-368bb0664924_Admin";
// export const RecUserFlow = "PreScreenerRec111_User";
// export const CommonForumReport = "PreScreenerRec111_User_f945924a-31d1-cc25-c626-f493aaa9d04c_User"
// export const RecruitedCandidateTempFlow = 'PreScreenerRec111_RecruitedCandidate';
// export const RecruitedCandidateUserFlowID = 'PreScreenerRec111_User';
// export const RecruitedCandidateForumReport = 'PreScreenerRec111_RecruitedCandidate_084fdeb9-ab16-2ef8-b7e8-c49d41641132_Admin';
// export const ChatFID = '336edb05-5433-c16f-c15d-62d539d20fc3'
// export const ChatFlowID = 'PreScreenerRec111_Chat'
// export const chatReportid = 'PreScreenerRec111_Chat_336edb05-5433-c16f-c15d-62d539d20fc3_User';
// export const ShareLinkReportFlow = 'PreScreenerRec111_RecruiterMessage_304bc6af-03a6-eb6d-2af1-82901a9a2458_Admin'

//gcpProduction

export const caServerIP = "https://ca.cosmitude.com/";
export const loginServerIP = 'https://ll.cosmitude.com/';
export const templateServerIP = 'https://ts.cosmitude.com/';
export const forumServerIP = 'https://fs.cosmitude.com/';
// export const caServerIP = 'http://20.85.156.243:8050/';
// export const loginServerIP = 'http://20.85.156.243:8084/';
// export const templateServerIP = 'http://20.85.156.243:8082/';
// export const forumServerIP = 'http://20.85.156.243:8086/';
export const PreScreenerIndexTemplateName = 'PreScreenerIndex'
export const CAServerID = "c9b6722d-5dbf-4b4f-a28e-692b4d26c1cf-7870eb8e-2f45-458a-9a70-d6b2d71ee871"
export const FSServerID = "247366f9-f180-4c54-927d-0c008d07fff4-c8786a26-5320-439b-b0f7-aff6d1655525"
export const LLServerID = "f61eb7ea-3ff5-11e9-b210-d663bd873d93-085ac6ce-3ff6-11e9-b210-d663bd873d93"
export const TSServerID = "8ff836f8-4004-11e9-b210-d663bd873d93-b7359eae-4004-11e9-b210-d663bd873d93"
export const MacAddress = 'Bridge-Web';
export const RecMessageFlow = 'PreScreenerRec111_RecruiterMessage';
export const RecMessageFlowID = '304bc6af-03a6-eb6d-2af1-82901a9a2458';
export const RecruiterForumTemp = "1829b879-3e2d-e8e3-d90c-48806f802171";  //changed
export const RecCreateTempName = 'PreScreenerRec111'
export const RecruitCreateFlow = 'PreScreenerRec111_Recruiter';
export const RecruitCreateFlowID = 'e892d536-8926-0e4d-7217-368bb0664924';
export const RecruitFlowReport = "PreScreenerRec111_Recruiter_e892d536-8926-0e4d-7217-368bb0664924_Admin";
export const RecUserFlow = "PreScreenerRec111_User";
export const RecUserFlowID = 'f945924a-31d1-cc25-c626-f493aaa9d04c';
export const CommonForumRecCreate = 'PreScreenerTemplate_RecruitmentCreate';
export const CommonForumRecCreateID ='c448a9fa-d674-9220-a14a-a3e7b071e2e7';
export const CommonForumFlow = 'PreScreenerTemplate';
export const CommonForumReport = "PreScreenerRec111_User_f945924a-31d1-cc25-c626-f493aaa9d04c_User"
export const CommonForumID = '09360a15-264b-48f2-bcb5-9e6c09cd1d34'; //changed
export const CommonForumTemp = '80900ed5-e06a-e846-c076-6a665e22e568';  //changed
export const CandidateFilterFlow = 'PreScreenerTemplate_CandidateFilter';
export const CandidateFilterFlowID = '1ca4b9e9-8073-b13a-1c53-af94302923b2';
export const ReportContent = 'PreScreenerTemplate_ReportContent';
export const ReportContentID = 'ead2d842-050b-7a2b-f34b-135f0fc35076';
export const ArchiveTempName = 'ArchiveTemp'
export const ArchiveTempFlow = 'ArchiveTemp_ArchiveVideo';
export const ArchiveTempFlowID = '57dcb8b3-d2e3-6ff9-d465-90cdbf706840';
export const ArchiveTemp = 'a4d46cf5-3fa2-7091-1bc9-b0c584a47ae5'; //changed
export const ArchiveForumReport = "ArchiveTemp_ArchiveVideo_57dcb8b3-d2e3-6ff9-d465-90cdbf706840_Admin";
export const RecruitDeleteFlow = 'PreScreenerTemplate_DeleteRecruitment';
export const RecruitDeleteFlowID = 'ff8f4131-869a-3313-8841-842582444fcd';
export const FilterResponse = 'PreScreenerTemplate_FilterResponse'
export const IndexFlow = PreScreenerIndexTemplateName+"_Index"
export const RecruitedCandidateTempFlow = 'PreScreenerRec111_RecruitedCandidate';
export const RecruitedCandidateUserFlowID = 'PreScreenerRec111_User';
export const RecruitedCandidateTempFlowID = '084fdeb9-ab16-2ef8-b7e8-c49d41641132';
export const RecruitedCandidateTemp = '1829b879-3e2d-e8e3-d90c-48806f802171'; //changed
export const RecruitedCandidateForumReport = 'PreScreenerRec111_RecruitedCandidate_084fdeb9-ab16-2ef8-b7e8-c49d41641132_Admin';
export const IndexTemplateID = "97a2804b-8a02-da89-c09f-fddf3146e520"; //changed
export const removeMediaIDFromListflowName = 'PreScreenerTemplate_removeMediaFromList';
export const DemoUser1MediaID = '659beb8add4994ac172d1705'
export const DemoUser2MediaID = '659beb65dd4994ac172d1458'
export const DemoUser3MediaID = '659beb45dd4994ac172d11d8'
export const DemoRec1MediaID = '659bead2dd4994ac172d0bdf'
export const DemoRec2MediaID = '659beb01dd4994ac172d0eaf'
export const restoreBotPhoneNumber = '27434342fa8432e8e94822a340ba7a81bc385a' //changed
export const requestChange = 'PreScreenerTemplate_RequestChange'
export const ChatFID = '336edb05-5433-c16f-c15d-62d539d20fc3'
export const ChatFlowID = 'PreScreenerRec111_Chat'
export const chatReportid = 'PreScreenerRec111_Chat_336edb05-5433-c16f-c15d-62d539d20fc3_User';
export const ShareLinkReportFlow = 'PreScreenerRec111_RecruiterMessage_304bc6af-03a6-eb6d-2af1-82901a9a2458_Admin'


//local Sumith Server
// // export const caServerIP = 'http://192.168.0.110:8050/';
// // export const loginServerIP = 'http://192.168.0.110:8084/';
// // export const templateServerIP = 'http://192.168.0.110:8082/';
// // export const forumServerIP = 'http://192.168.0.110:8086/';
// export const caServerIP = 'http://192.168.0.106:8050/';
// export const loginServerIP = 'http://192.168.0.106:8084/';
// export const templateServerIP = 'http://192.168.0.106:8082/';
// export const forumServerIP = 'http://192.168.0.106:8086/';
// export const PreScreenerIndexTemplateName = 'PreScreenerIndex'
// export const CAServerID = "6256af8c-8021-3d7b-e457-cb74f8c6da2e"
// export const FSServerID = "87aba666-be23-6021-0a6f-ff301826c5d6"
// export const LLServerID = "b001088d-dca6-3b78-8879-c29116771464"
// export const TSServerID = "e4ff4923-bbe4-58aa-0998-575afe7b4ad5"
// export const MacAddress = 'Bridge-Web';
// export const RecMessageFlow = 'PreScreenerRec111_RecruiterMessage';
// export const RecMessageFlowID = '304bc6af-03a6-eb6d-2af1-82901a9a2458';
// export const RecruiterForumTemp = "e21fab1b-b771-7537-8897-8fe29f78db5e";  //changed
// export const RecCreateTempName = 'PreScreenerRec111'
// export const RecruitCreateFlow = 'PreScreenerRec111_Recruiter';
// export const RecruitCreateFlowID = 'e892d536-8926-0e4d-7217-368bb0664924';
// export const RecruitFlowReport = "PreScreenerRec111_Recruiter_e892d536-8926-0e4d-7217-368bb0664924_Admin";
// export const RecUserFlow = "PreScreenerRec111_User";
// export const RecUserFlowID = 'f945924a-31d1-cc25-c626-f493aaa9d04c';
// export const CommonForumRecCreate = 'PreScreenerTemplate_RecruitmentCreate';
// export const CommonForumRecCreateID ='c448a9fa-d674-9220-a14a-a3e7b071e2e7';
// export const CommonForumFlow = 'PreScreenerTemplate';
// export const CommonForumReport = "PreScreenerRec111_User_f945924a-31d1-cc25-c626-f493aaa9d04c_User"
// export const CommonForumID = 'cf0b7b7c-501f-4e4e-a32d-01ab405f7fbf'; //changed
// export const CommonForumTemp = '80900ed5-e06a-e846-c076-6a665e22e568';  //changed
// export const CandidateFilterFlow = 'PreScreenerTemplate_CandidateFilter';
// export const CandidateFilterFlowID = '1ca4b9e9-8073-b13a-1c53-af94302923b2';
// export const ReportContent = 'PreScreenerTemplate_ReportContent';
// export const ReportContentID = 'ead2d842-050b-7a2b-f34b-135f0fc35076';
// export const ArchiveTempName = 'ArchiveTemp'
// export const ArchiveTempFlow = 'ArchiveTemp_ArchiveVideo';
// export const ArchiveTempFlowID = '57dcb8b3-d2e3-6ff9-d465-90cdbf706840';
// export const ArchiveTemp = "2a1ecf24-16f6-ccaa-551d-d603a2be21e7"; //changed
// export const ArchiveForumReport = "ArchiveTemp_ArchiveVideo_57dcb8b3-d2e3-6ff9-d465-90cdbf706840_Admin";
// export const RecruitDeleteFlow = 'PreScreenerTemplate_DeleteRecruitment';
// export const RecruitDeleteFlowID = 'ff8f4131-869a-3313-8841-842582444fcd';
// export const FilterResponse = 'PreScreenerTemplate_FilterResponse'
// export const IndexFlow = PreScreenerIndexTemplateName+"_Index"
// export const RecruitedCandidateTempFlow = 'PreScreenerRec111_RecruitedCandidate';
// export const RecruitedCandidateUserFlowID = 'PreScreenerRec111_User';
// export const RecruitedCandidateTempFlowID = '084fdeb9-ab16-2ef8-b7e8-c49d41641132';
// export const RecruitedCandidateTemp = 'e21fab1b-b771-7537-8897-8fe29f78db5e'; //changed
// export const RecruitedCandidateForumReport = 'PreScreenerRec111_RecruitedCandidate_084fdeb9-ab16-2ef8-b7e8-c49d41641132_Admin';
// export const IndexTemplateID = "312cd262-772e-a3dd-bd58-54db6da2e8f4"; //changed
// export const removeMediaIDFromListflowName = 'PreScreenerTemplate_removeMediaFromList';
// export const DemoUser1MediaID = '659beb8add4994ac172d1705'
// export const DemoUser2MediaID = '659beb65dd4994ac172d1458'
// export const DemoUser3MediaID = '659beb45dd4994ac172d11d8'
// export const DemoRec1MediaID = '659bead2dd4994ac172d0bdf'
// export const DemoRec2MediaID = '659beb01dd4994ac172d0eaf'
// export const restoreBotPhoneNumber = '27434330ce858c5ccf48d7ac9be8bf46543deb' //changed
// export const requestChange = 'PreScreenerTemplate_RequestChange'
// export const ChatFID = '336edb05-5433-c16f-c15d-62d539d20fc3'
// export const ChatFlowID = 'PreScreenerRec111_Chat'
// export const chatReportid = 'PreScreenerRec111_Chat_336edb05-5433-c16f-c15d-62d539d20fc3_User';
// export const ShareLinkReportFlow = 'PreScreenerRec111_RecruiterMessage_304bc6af-03a6-eb6d-2af1-82901a9a2458_Admin'


//local 003 Server
// // export const caServerIP = 'http://192.168.0.110:8050/';
// // export const loginServerIP = 'http://192.168.0.110:8084/';
// // export const templateServerIP = 'http://192.168.0.110:8082/';
// // export const forumServerIP = 'http://192.168.0.110:8086/';
// export const caServerIP = 'http://192.168.0.105:8050/';
// export const loginServerIP = 'http://192.168.0.105:8084/';
// export const templateServerIP = 'http://192.168.0.105:8082/';
// export const forumServerIP = 'http://192.168.0.105:8086/';
// export const PreScreenerIndexTemplateName = 'PreScreenerIndex'
// export const CAServerID = "9515a401-e9cb-efbd-2a0d-4523b8166d59"
// export const FSServerID = "1086462d-d3de-8bd3-e309-7671f46fa0e1"
// export const LLServerID = "f294f5e1-288b-8f5c-be9b-88852ae6c252"
// export const TSServerID = "a797213c-26b8-bd9a-8c72-46371dfe4c9c"
// export const MacAddress = 'Bridge-Web';
// export const RecMessageFlow = 'PreScreenerRec111_RecruiterMessage';
// export const RecMessageFlowID = '304bc6af-03a6-eb6d-2af1-82901a9a2458';
// export const RecruiterForumTemp = "c14696e4-8285-1d9d-8afe-6cb719a43a3a";  //changed
// export const RecCreateTempName = 'PreScreenerRec111'
// export const RecruitCreateFlow = 'PreScreenerRec111_Recruiter';
// export const RecruitCreateFlowID = 'e892d536-8926-0e4d-7217-368bb0664924';
// export const RecruitFlowReport = "PreScreenerRec111_Recruiter_e892d536-8926-0e4d-7217-368bb0664924_Admin";
// export const RecUserFlow = "PreScreenerRec111_User";
// export const RecUserFlowID = 'f945924a-31d1-cc25-c626-f493aaa9d04c';
// export const CommonForumRecCreate = 'PreScreenerTemplate_RecruitmentCreate';
// export const CommonForumRecCreateID ='c448a9fa-d674-9220-a14a-a3e7b071e2e7';
// export const CommonForumFlow = 'PreScreenerTemplate';
// export const CommonForumReport = "PreScreenerRec111_User_f945924a-31d1-cc25-c626-f493aaa9d04c_User"
// export const CommonForumID = '02e09058-a364-419d-bf84-5164277b7103'; //changed
// export const CommonForumTemp = '80900ed5-e06a-e846-c076-6a665e22e568';  //changed
// export const CandidateFilterFlow = 'PreScreenerTemplate_CandidateFilter';
// export const CandidateFilterFlowID = '1ca4b9e9-8073-b13a-1c53-af94302923b2';
// export const ReportContent = 'PreScreenerTemplate_ReportContent';
// export const ReportContentID = 'ead2d842-050b-7a2b-f34b-135f0fc35076';
// export const ArchiveTempName = 'ArchiveTemp'
// export const ArchiveTempFlow = 'ArchiveTemp_ArchiveVideo';
// export const ArchiveTempFlowID = '57dcb8b3-d2e3-6ff9-d465-90cdbf706840';
// export const ArchiveTemp = "f1af6daa-1561-9c07-ccfe-ee4615fea1cb"; //changed
// export const ArchiveForumReport = "ArchiveTemp_ArchiveVideo_57dcb8b3-d2e3-6ff9-d465-90cdbf706840_Admin";
// export const RecruitDeleteFlow = 'PreScreenerTemplate_DeleteRecruitment';
// export const RecruitDeleteFlowID = 'ff8f4131-869a-3313-8841-842582444fcd';
// export const FilterResponse = 'PreScreenerTemplate_FilterResponse'
// export const IndexFlow = PreScreenerIndexTemplateName+"_Index"
// export const RecruitedCandidateTempFlow = 'PreScreenerRec111_RecruitedCandidate';
// export const RecruitedCandidateUserFlowID = 'PreScreenerRec111_User';
// export const RecruitedCandidateTempFlowID = '084fdeb9-ab16-2ef8-b7e8-c49d41641132';
// export const RecruitedCandidateTemp = 'c14696e4-8285-1d9d-8afe-6cb719a43a3a'; //changed
// export const RecruitedCandidateForumReport = 'PreScreenerRec111_RecruitedCandidate_084fdeb9-ab16-2ef8-b7e8-c49d41641132_Admin';
// export const IndexTemplateID = "8838ca42-a65f-fb83-21c5-01973400ea8e"; //changed
// export const removeMediaIDFromListflowName = 'PreScreenerTemplate_removeMediaFromList';
// export const DemoUser1MediaID = '659beb8add4994ac172d1705'
// export const DemoUser2MediaID = '659beb65dd4994ac172d1458'
// export const DemoUser3MediaID = '659beb45dd4994ac172d11d8'
// export const DemoRec1MediaID = '659bead2dd4994ac172d0bdf'
// export const DemoRec2MediaID = '659beb01dd4994ac172d0eaf'
// export const restoreBotPhoneNumber = '27434330ce858c5ccf48d7ac9be8bf46543deb' //changed
// export const requestChange = 'PreScreenerTemplate_RequestChange'
// export const ChatFID = '336edb05-5433-c16f-c15d-62d539d20fc3'
// export const ChatFlowID = 'PreScreenerRec111_Chat'
// export const chatReportid = 'PreScreenerRec111_Chat_336edb05-5433-c16f-c15d-62d539d20fc3_User';
// export const ShareLinkReportFlow = 'PreScreenerRec111_RecruiterMessage_304bc6af-03a6-eb6d-2af1-82901a9a2458_Admin'

export const fireBaseRestApi = "https://firebasedynamiclinks.googleapis.com/v1/shortLinks?key=AIzaSyC2dn6GYGu3Z6rvvS6kyGhMNa-EUkdwGeI"