import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './Candidatevideo.css';
import { useEffect, useRef, useState } from 'react';
import { downloadMedia, exitForum, fetchForumInfo } from '../../services/LoginLogoutService';
import uuid from 'react-uuid';
import { colors } from '@mui/material';
import { objectNullandUndefined } from '../Dictionary/Dictionary';
import { useHistory } from 'react-router-dom';
import CircularProgress from '@material-ui/core/CircularProgress';
import Modal from '@material-ui/core/Modal';
import Notification from './Notification';
import { decode as base64_decode, encode as base64_encode } from 'base-64';
import { FSServerID, MacAddress } from '../Dictionary/CommonStrings';
import Hook from '../Hook';

function Candidatevideo() {

  const playPauseRef = useRef();
  const videoRef = useRef();
  const [isPlaying, setIsPlaying] = useState(false);
  const [isMuted, setIsMuted] = useState(false);
  const [videoUrl, setVideoUrl] = useState(null);
  const [name, setName] = useState('');
  const [loading, setLoading] = useState(false);
  const history = useHistory();

  useEffect(() => {
    downloadVideo();
    setIsPlaying(!videoRef.current.paused);
    setIsMuted(videoRef.current.muted);

    window.addEventListener('blur', () => {
      try {
        if (isActiveVideo(videoRef)) {
          videoRef.current.pause();
          setIsPlaying(false);
        }
      } catch (e) { }
    });

    window.addEventListener('focus', () => {
      try {
        if (isActiveVideo(videoRef)) {
          videoRef.current.play();
          setIsPlaying(true);
        }
      } catch (e) { }
    });
  }, []);
  const toggleVideoPlayback = () => {
    try {
      if (videoRef.current) {
        if (videoRef.current.paused) {
          videoRef.current.play()
            .then(() => setIsPlaying(true))
            .catch((error) => console.error('Error playing video:', error));
        } else {
          videoRef.current.pause();
          setIsPlaying(false);
        }
      }
    } catch (e) {
      console.error(e);
    }
  };

  // Add a click event listener to the video element
  useEffect(() => {
    const videoElement = videoRef.current;

    const handleClick = () => {
      toggleVideoPlayback();
    };

    videoElement.addEventListener('click', handleClick);

    return () => {
      videoElement.removeEventListener('click', handleClick);
    };
  }, []);
  const exitForum1 = async () => {
    const confirmed = window.confirm(objectNullandUndefined('63'));
    if (!confirmed) {
      return;
    }
    setLoading(true);
    let mediaID = localStorage.getItem('mediaID')
    let data = JSON.parse(mediaID);
    let id = data.Forumid;
    let data3 = await exitForum(id);
    if (data3["ErrorCode"] === 1074) {
      toast.error((objectNullandUndefined('221')));
      setTimeout(() => {
        localStorage.clear();
        sessionStorage.clear();
        history.push('/');
        window.location.reload(false);
      }, 3000)
    }
    if(data3['ErrorCode'] == 1030){
      let userData = JSON.parse(localStorage.getItem('userData'))
      for(let i = 0; i< userData.length; i++){
        let deleteForum = userData[i]["ForumID"] 
        if(id === deleteForum){
          userData.splice(i, 1);
          break;
        }
      }
      localStorage.setItem('userData', JSON.stringify(userData));
      setLoading(false);
      toast.success(objectNullandUndefined('165'));
      setTimeout(() => {
        history.push('/Notification');
      }, 2000)
      fetchForumInfo();
    }else{
      toast.error(objectNullandUndefined('56'));
    }
  }
  async function downloadVideo() {
    let mediaID = localStorage.getItem('mediaID')
    let data = JSON.parse(mediaID);
    let name = data.Forumname
    setName(name);
    let userDetails = localStorage.getItem('userProfile');
    userDetails = base64_decode(userDetails);
    userDetails = JSON.parse(userDetails)
    let jsonData = {
      SessionID: userDetails['SessionID'],
      MACAddress: userDetails['MACAddress'],
      MediaID: data.mediaID,
      ServerID: FSServerID,
    };

    const resValue = await downloadMedia(jsonData);
    let ObjectUrl = window.URL.createObjectURL(new Blob([resValue]));
    setVideoUrl(ObjectUrl);
  }
  const toggleVideoMute = () => {
    videoRef.current.muted = !isMuted;
    setIsMuted(!isMuted);
  };
  const handlePlayPauseIconClick = () => {
    toggleVideoPlayback();
  };

  const isRecruiter = localStorage.getItem('recruitValue');
  return (
    <><Hook
      short={'VIDEO'} />
      <div className='reel15'>
        <div className="reel">
          <div className="video">
            <video
              ref={videoRef}
              disableRemotePlayback
              playsInline
              loop
              src={videoUrl}
              height="100%"
              width="100%"
            ></video>
            <div
              ref={playPauseRef}
              className={`play-pause ${isPlaying ? '' : 'show-play-pause'}`}
              onClick={handlePlayPauseIconClick}
            >
              <ion-icon name="play-outline"></ion-icon>
            </div>

            <div className="controls">
              <span onClick={toggleVideoPlayback}>
                <ion-icon name={`${isPlaying ? 'pause' : 'play'}-outline`}></ion-icon>
              </span>
              <span onClick={toggleVideoMute}>
                <ion-icon name={`volume-${isMuted ? 'mute' : 'medium'}-outline`}></ion-icon>
              </span>
            </div>
            {isRecruiter !== "true" && (
              <div className="foot">
                <div className="title">{objectNullandUndefined('187')} {name}</div>
                <div className="rec-info">
                  <div>
                  </div>
                    
                      {/* <button onClick={() => {
                        exitForum1();

                      } }>
                        {objectNullandUndefined('156')}
                      </button> */}
                </div>
              </div>
            )}

          </div>
          <ToastContainer position="top-center" autoClose={2000} hideProgressBar />


        </div>
        <Modal open={loading} className="loaderContainer2">
          <div>
            <CircularProgress className="loader2" size={40} thickness={4} />
          </div>
        </Modal>
      </div></>
  );




}
function isActiveVideo(videoRef) {
  const videoTop = videoRef.current.getBoundingClientRect().top;
  return videoTop > 0 && videoTop <= 150;
}

export default Candidatevideo;




